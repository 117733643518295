import { useContext, useEffect, useRef, useState } from "react";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useInView } from "react-hook-inview";
import PerfectScrollbar from "react-perfect-scrollbar";

import Lightbox from "@components/Lightbox/Lightbox";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import ImageWrapper from "@components/Image/ImageWrapper";
import { useDevice } from "@utilities/react/get-device/get-device";
import RenderComponents from "@utilities/renderComponents";
import { StoryContext } from "../C253_StoriesProvider";

import IconCross from "../../../public/image/svg/cross.svg";

import styles from "../C253_StoriesContainer.module.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

//@ts-ignore
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

interface StoriesTextAndImageRightData {
  isBlue: boolean;
  enablePopupText: boolean;
  showChevron: boolean;
  h3Text: string;
  quoteImage: ImageWrapperType;
  quoteImageFull: ImageWrapperType;
  readMoreContent: any;
  storyHeading: any;
  isVimeoCode: boolean;
  videoCode: string;
  displayVideo: boolean;
}

export interface StoriesTextAndImageRightProps {
  content: StoriesTextAndImageRightData;
  index: number;
  theme?: any;
}

export const StoriesTextAndImageRight = (
  props: StoriesTextAndImageRightProps
) => {
  const { content, index, theme } = props;
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const {
    isBlue,
    enablePopupText,
    showChevron,
    h3Text,
    quoteImage,
    quoteImageFull,
    isVimeoCode,
    videoCode,
    displayVideo,
    readMoreContent,
    storyHeading,
  } = content;

  const lineRef: any = useRef();
  const mobileLineRef: any = useRef();
  const sectionRef: any = useRef();

  const isFirst = index === 0;

  const [showLightbox, setShowLightbox] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [hasLineAnimated, setHasLineAnimated] = useState(false);

  const { updateNavigation, lineHeights } = useContext(StoryContext);

  const device = useDevice();
  const isMobile = device === "mobile";

  const displayImage = quoteImageFull || quoteImage;

  const [ref, isVisible] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (isVisible) {
      if (!hasAnimated) {
        setHasAnimated(true);
      }

      updateNavigation(index, !isBlue);
    }
  }, [isVisible, hasAnimated]);

  useEffect(() => {
    const getLineHeight = (ref) => {
      const lineY = ref.current.offsetTop;
      const sectionHeight = sectionRef.current.offsetHeight;
      const indent = isMobile ? 25 : 70;
      return sectionHeight - lineY + indent;
    };

    if (lineHeights[index] && !hasLineAnimated) {
      lineRef.current.style.height = getLineHeight(lineRef) + "px";
      mobileLineRef.current.style.height = getLineHeight(mobileLineRef) + "px";
      setHasLineAnimated(true);
    }
  }, [lineHeights]);

  const closeLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    }, 300);
  };

  const videoUrl = isVimeoCode
    ? `https://vimeo.com/${videoCode}`
    : `https://www.youtube.com/watch?v=${videoCode}&modestbranding=1`;

  useEffect(() => {
    if (isMobile) {
      if (readMoreOpen) {
        document.querySelector("html")?.classList.add("story-opened");
      } else {
        document.querySelector("html")?.classList.remove("story-opened");
      }
    }
  }, [readMoreOpen]);

  return (
    <section
      ref={(node) => {
        ref(node);
        sectionRef.current = node;
      }}
      className={clsx(
        theme["small-quote"],
        isBlue && theme.blue,
        isBlue && "blue",
        quoteImageFull && theme["img-flush"],
        readMoreOpen && theme.open,
        hasAnimated && theme.animate
      )}
    >
      <div className="wrapper">
        <div
          className={clsx(
            theme.content,
            !quoteImageFull && quoteImage && theme["slim"]
          )}
        >
          <h3
            className={theme.title}
            dangerouslySetInnerHTML={{ __html: h3Text }}
          ></h3>
          {storyHeading && RenderComponents(storyHeading)}
          {isFirst && <div className={clsx(theme.initial, theme.line)}></div>}
          {enablePopupText && (
            <a
              className={theme["read-more"]}
              href="#readmore"
              // aria-haspopup="true"
              onClick={(e) => {
                e.preventDefault();
                setReadMoreOpen((previousState) => !previousState);
              }}
            >
              Read more
            </a>
          )}
          <div ref={lineRef} className={theme.line}></div>
        </div>

        {displayVideo && videoCode ? (
          <div className={theme.picture}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowLightbox(true);
              }}
              className="play-on-image"
            >
              <div className="play-icon">
                <img src="/assets2019/img/vidicon.png" alt="play icon" />
                <span className={theme["visually-hidden"]}>Play video</span>
              </div>
              {displayImage && <ImageWrapper image={displayImage} />}
            </a>
          </div>
        ) : (
          displayImage && (
            <div className={theme.picture}>
              <ImageWrapper image={displayImage} />
            </div>
          )
        )}

        {enablePopupText && (
          <div className={theme["read-more-offscreen"]}>
            <PerfectScrollbar className={theme["read-more-inner"]}>
              <>{RenderComponents(readMoreContent)}</>
            </PerfectScrollbar>
            <div
              className={theme.close}
              tabIndex={0}
              aria-label="close search"
              onClick={() => setReadMoreOpen(false)}
            >
              <IconCross />
            </div>
          </div>
        )}
        {showLightbox && (
          <Lightbox isBanner onCloseFunction={closeLightbox}>
            <ReactPlayer width="100%" height="100%" url={videoUrl} />
          </Lightbox>
        )}
      </div>

      <div ref={mobileLineRef} className={clsx(theme.line, theme.mobile)}></div>

      {showChevron && (
        <span
          className={theme["slide-down"]}
          onClick={(e) => updateNavigation(index + 1, !isBlue, true, e.target)}
        >
          <span className="chevron" />
        </span>
      )}
    </section>
  );
};

export default themr(
  "StoriesTextAndImageRight",
  styles
)(StoriesTextAndImageRight);

import { useSettings } from "@utilities/context/settings";
import richTextify from "@utilities/richtext";
import { FormErrors } from "@customTypes/FormErrors";
import RadioButtons from "@components/RadioButtons/RadioButtons";
import { FieldValues, FormState } from "react-hook-form";

export interface FormPrivacyProps {
  register: Function;
  formState: FormState<FieldValues>;
  chosenEmailSubscription?: string;
  embagPrivacyInformRadioYes?: string;
  embagPrivacyInformRadioNo?: string;
  isShowStayInformed?: boolean;
  isShowPrivacyTitle?: boolean;
  policyType?: "regular" | "embag";
}

const FormPrivacySection = (props: FormPrivacyProps) => {
  const {
    register,
    formState,
    isShowStayInformed = true,
    isShowPrivacyTitle = true,
    policyType = "regular",
  } = props;
  const { siteSettings } = useSettings();

  const privacyAndPolicySource =
    policyType === "regular"
      ? "privacyAndPolicyTextItems"
      : "privacyAndPolicyEmbagTextItems";
  const radioButtonOptions = {
    name: "isStayInformed",
    options: [
      {
        id: "chosenEmailSubscriptionYes",
        val: true,
        label: richTextify(
          siteSettings?.[privacyAndPolicySource]?.stayInformRadioYes?.json
        ),
      },
      {
        id: "chosenEmailSubscriptionNo",
        val: false,
        label: richTextify(
          siteSettings?.[privacyAndPolicySource]?.stayInformRadioNo?.json
        ),
      },
    ],
  };

  return (
    <>
      {isShowPrivacyTitle && <h3 className="section-title">Your Privacy</h3>}
      <div className="form-row">
        {richTextify(
          siteSettings?.[privacyAndPolicySource]?.privacyTextData?.json
        )}
      </div>
      {isShowStayInformed && (
        <>
          <h3 className="section-title">Stay Informed</h3>
          <div className="form-row">
            {richTextify(
              siteSettings?.[privacyAndPolicySource]?.stayInformedText?.json
            )}
          </div>
          <RadioButtons
            register={register}
            options={radioButtonOptions}
            formState={formState}
          />
          <div className="form-row">
            {richTextify(
              siteSettings?.[privacyAndPolicySource]?.emailOptOutText?.json
            )}
          </div>
        </>
      )}
    </>
  );
};
export default FormPrivacySection;

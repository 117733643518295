import C282_Electives from "@components/C282_Electives/C282_Electives";
import { Course } from "@components/C282_Electives/ElectiveItem";
import { useSettings } from "@utilities/context/settings";
import { fetchApi } from "@utilities/fetchApi";
import { useEffect, useState } from "react";
import { useFacultyDetails } from "../../context/facultyDetails";

export interface FacultyCoursesProps {
  singleDisplay?: boolean;
  defaultOpen?: boolean;
}

const FacultyCourses = (props: { content: FacultyCoursesProps }) => {
  const { content } = props;
  const [courses, setCourses] = useState<Array<Course>>([]);
  const { courseTypeSettings } = useSettings();
  const { facultyProfileData } = useFacultyDetails();

  useEffect(() => {
    const getData = async () => {
      const facultyCodeString =
        facultyProfileData?.fields?.profileLbsNo?.toString();
      const courseData: Course[] = await fetchApi("/api/getCoursesByField", {
        field: "facultyCode",
        value: facultyCodeString,
      });

      const courseList = courseData.map((course) => ({
        ...(courseTypeSettings &&
          courseTypeSettings.courses.find(
            (settings) => settings.courseTypeCode === course.courseType
          )),
        ...course,
      }));

      setCourses(courseList);
    };
    if (facultyProfileData?.fields.profileLbsNo) {
      getData();
    }
  }, [facultyProfileData]);

  return (
    <C282_Electives
      courses={courses}
      columns={courses.length}
      displayFacultyList={false}
      {...content}
    />
  );
};

export default FacultyCourses;

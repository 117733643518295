import { themr } from "@friendsofreactjs/react-css-themr";
import { useEffect, useState } from "react";
import styles from "./C245_AnimatedNumbers.module.scss";

export interface AnimatedNumberData {
  odoNumber: string;
  prefix: string;
  suffix: string;
  heading: string;
  description: string;
}

export interface AnimatedNumbersData {
  animatedNumbers: Array<{
    metadata: { tags: string[] };

    // fields: AnimatedNumberData;
    odoNumber: string;
    prefix: string;
    suffix: string;
    heading: string;
    description: string;
  }>;
}
interface AnimatedNumberProps extends AnimatedNumberData {
  isVisible: boolean;
  theme?: any;
}

export const AnimatedNumber = (props: AnimatedNumberProps) => {
  const { odoNumber, prefix, suffix, heading, description, isVisible, theme } =
    props;
  const [currentOdo, setCurrentOdo] = useState("0");

  const format = (number) => {
    return Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  useEffect(() => {
    let interval;

    if (isVisible) {
      const value = parseInt(odoNumber.replace(/,/g, ""));
      const perTick = value / 200;
      let currentValue = 0;

      interval = setInterval(() => {
        if (currentValue < value) {
          currentValue = Math.round((currentValue + perTick) * 1e12) / 1e12;
          setCurrentOdo(format(currentValue));
        } else {
          setCurrentOdo(format(currentValue));
          clearInterval(interval);
        }
      }, 10);
    }

    return () => clearInterval(interval);
  }, [isVisible]);

  return (
    <div className={theme.item}>
      <span className={theme.hidden}>{odoNumber}</span>
      <p className={theme.number} aria-hidden="true">
        {prefix && prefix}
        <span className={theme.odo}>{currentOdo}</span>
        {suffix && suffix}
      </p>
      {heading && <p className={theme.heading}>{heading}</p>}
      {description && <p>{description}</p>}
    </div>
  );
};

export default themr("AnimatedNumber", styles)(AnimatedNumber);

import { ProgrammeProvider } from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsProvider";
import { SettingsProvider } from "@utilities/context/settings";
import formatCoveoMetadata, {
  formatPublicationAuthors,
} from "@utilities/formatCoveoMetadata";
import Layout, { LayoutProps } from "../Layout/Layout";
import Components from "../__Components__/Components";
import { use, useEffect, useState } from "react";
import setGADataLayer from "@utilities/setGADataLayer";
//temporary until post go live where these should be transfered to a suitable place in Contentful
import GALandingPages from "../../data/GALandingPages.json";
import { useRouter } from "next/router";
import { componentsWithAPICall } from "../../data/componentsWithAPICall";
import { checkApiHealth } from "../../services/forms";

export default function Page({ props }) {
  const {
    globalContent,
    pageContent,
    settings,
    programmeData,
    preview,
    canonicalUrl,
  } = props;
  const { programmeTypeCode, programmeTypeGroup } = pageContent;
  const isMaintananceModeOn = globalContent?.siteSettings
    ?.turnOnSiteMaintenanceMode
    ? true
    : false;
  const [isFormPage, setIsFormPage] = useState(false);
  const pageTitle = pageContent.basePageTemplate?.fields?.pageTitle;
  const pageDescription = pageContent.basePageTemplate?.fields?.pageDescription;
  const pageSlug = pageContent.slug;
  const router = useRouter();

  useEffect(() => {
    if (
      (pageContent.component == "eventsDetail" &&
        (pageContent.registrationForm !== undefined ||
          pageContent.eventSignupUrl === undefined)) ||
      pageContent.bodyContent?.find((component) =>
        componentsWithAPICall.includes(component.sys.contentType)
      )
    ) {
      setIsFormPage(true);

      const maintanancePage =
        globalContent?.siteSettings?.siteMaintenancePage?.fields?.slug;
      if (isMaintananceModeOn) {
        router.push(`/${maintanancePage}`);
      }
      /*try{
      const checkApiStatus = async () => {
        const response = await checkApiHealth();
        if (response.statusCode != 200 || response.status === "Unhealthy") {
          router.push(`/${maintanancePage}`);
        }
      };
      checkApiStatus();
    }catch(e){
      router.push(`/${maintanancePage}`);
    }*/
    } else {
      setIsFormPage(false);
    }
  }, [pageContent]);

  const setLandingPageDataLayer = (url?: string) => {
    const programmeNameArr = pageSlug.split("/");
    const nameArr = [...programmeNameArr];
    const programmeName = nameArr.length > 1 ? nameArr.reverse()[0] : "";
    const match = url ? url.replace("/", "") : pageSlug;
    if (GALandingPages.find((source) => source === match)) {
      //add to the dataLayer
      setGADataLayer({
        LandingType: programmeNameArr[0].includes("executive-education")
          ? "EE"
          : "DE",
        ProgrammeName: programmeName,
        event: "LandingPageView",
      });
    }
  };

  useEffect(() => {
    setLandingPageDataLayer();
  }, []);

  useEffect(() => {
    router.events.on("routeChangeComplete", setLandingPageDataLayer);
    return () => {
      router.events.off("routeChangeComplete", setLandingPageDataLayer);
    };
  }, [router.events]);

  function getSubNavLinks(entries): LayoutProps["subNavLinks"] {
    if (entries) {
      let anchorHeadings = [];
      for (let key in entries) {
        if (Array.isArray(entries[key])) {
          anchorHeadings = anchorHeadings.concat(
            entries[key]
              .filter((entry) => {
                if (
                  entry?.sys?.contentType?.sys?.id === "anchorHeading" || // this will need removing after all normalisation is done
                  entry?.sys?.contentType?.sys?.id === "tabbedContent" || // this will need removing after all normalisation is done
                  entry?.sys?.contentType === "anchorHeading" ||
                  entry?.sys?.contentType === "tabbedContent"
                ) {
                  return entry.fields;
                }
              })
              .map((entry) => entry.fields)
          );
        } else if (entries[key] && typeof entries[key] === "object") {
          anchorHeadings = anchorHeadings.concat(getSubNavLinks(entries[key]));
        }
      }
      return anchorHeadings as LayoutProps["subNavLinks"];
    }
    return [];
  }

  let coveoMetadata;

  coveoMetadata = {
    templateName: pageContent?.component,
  };

  globalContent.siteHeader.displayNav = true;
  globalContent.siteHeader.displayBreadcrumbs = true;
  globalContent.footer.displayFooter = true;

  switch (pageContent?.component) {
    case "publicationDetail":
      coveoMetadata = {
        ...coveoMetadata,
        fields: {
          publicationAuthors: formatPublicationAuthors(
            pageContent?.publicationAuthors
          ),
          publicationSubjectAreasList: formatCoveoMetadata(
            pageContent?.publicationSubjectAreasList
          ),
          publicationYear: pageContent?.publicationYear,
          publicationResearchCenterNameList: formatCoveoMetadata(
            pageContent?.publicationResearchCenterNameList
          ),
        },
      };
      break;
    case "campaignLandingPage2019":
      if (pageContent?.showNavigation !== undefined) {
        globalContent.siteHeader.displayNav = pageContent?.showNavigation;
      }
      if (pageContent?.showBreadcrumbs !== undefined) {
        globalContent.siteHeader.displayBreadcrumbs =
          pageContent?.showBreadcrumbs;
      }
      if (pageContent?.showFooter !== undefined) {
        globalContent.footer.displayFooter = pageContent?.showFooter;
      }
      break;
    case "programmeDetails":
      if (pageContent?.showNavigation !== undefined) {
        globalContent.siteHeader.displayNav = pageContent?.showNavigation;
      }
      if (pageContent?.showBreadcrumbs !== undefined) {
        globalContent.siteHeader.displayBreadcrumbs =
          pageContent?.showBreadcrumbs;
      }
      if (pageContent?.showFooter !== undefined) {
        globalContent.footer.displayFooter = pageContent?.showFooter;
      }
      break;
  }

  const allTags = globalContent.tags.map((item) => ({
    name: item.name,
    id: item.sys.id,
  }));

  return (
    <>
      {!(isFormPage && isMaintananceModeOn) && (
        <SettingsProvider
          settings={{
            currentPageContext: `${pageContent.uid}|${pageSlug}|${pageTitle}|${pageDescription}`,
            ...{
              ...settings,
              allTags: allTags,
            },
          }}
        >
          <ProgrammeProvider
            data={{ ...programmeData, programmeTypeCode, programmeTypeGroup }}
          >
            <Layout
              subNavLinks={getSubNavLinks(pageContent)}
              globalContent={globalContent}
              pageMeta={{
                ...pageContent.basePageTemplate?.fields,
                googleSiteVerification:
                  settings?.siteSettings?.googleSiteVerification,
              }}
              slug={`${process.env.NEXT_PUBLIC_URL}${canonicalUrl}`}
              title={pageContent.basePageTemplate?.fields?.pageTitle}
              headerImage={
                pageContent?.headerBanner?.fields?.desktopImage?.fields?.image
                  ?.fields?.file?.url
              }
              coveoMetadata={coveoMetadata}
              tags={pageContent?.tags || []}
            >
              {Components(pageContent, globalContent, globalContent)}
            </Layout>
          </ProgrammeProvider>
        </SettingsProvider>
      )}

      {!!preview && (
        <div className="preview-mode">
          Preview Mode Enabled
          <a href="/api/preview/exit">Exit Preview</a>
        </div>
      )}
    </>
  );
}

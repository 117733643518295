import { useEffect, useState, useContext, Fragment } from "react";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import {
  getBroadIndustries,
  getPersonalDetails,
  getReasonForReservation,
  getTitles,
  setReserveAPlaceLoggedIn,
  getPreferredDates,
  setReserveAPlaceNotLoggedIn,
} from "../../services/forms";
import Grid from "@components/Grid/Grid";
import { UserContext } from "../../context/user";
import FormField from "@components/Form/FormFields/FormFields";
import getCookie from "@utilities/getCookie";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import { handleSubmitFormErrors } from "@utilities/handleSubmitFormErrors";
import { useSettings } from "@utilities/context/settings";
import { getFormInitialValues, permanentFields } from "./InitialData";
import { ProfileDetailsProps, SelectItem } from "./types";
import styles from "./C74_ReserveAPlace.module.scss";
import { useRouter } from "next/router";
import ClipLoader from "react-spinners/ClipLoader";
import { LOGIN_URL } from "../../constants";
import CountryStateSection from "@components/CountryStateSection/CountryStateSection";
import {
  findMatchingSelectId,
  findMatchingSelectValue,
} from "@utilities/mapSelectValue";
import FormPrivacySection from "@components/FormPrivacySection/FormPrivacySection";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";

let firstInteract = 0;

export const C74_ReserveAPlace = (props: {
  content: ProfileDetailsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    registerFormTitle,
    failedMessage,
    registerFormLoggedInTitleText,
    introText,
    introLoggedInText,
  } = content;

  const [submittedUserEmail, setSubmittedUserEmail] = useState<string>("");
  const [showNationalCountriesList, setShowNationalCountriesList] =
    useState<boolean>(false);
  const [countryInputValue, setCountryInputValue] = useState<any>("");
  const [countriesList, setCountriesList] = useState<any>(null);
  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [preferredDates, setPreferredDates] = useState<SelectItem[]>([]);
  const [reasons, setReasons] = useState<any>(null);
  const [titles, setTitles] = useState<any>(null);
  const [industries, setIndustries] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [handledSubmitFormErrors, setHandledSubmitFormErrors] =
    useState<Array<string> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const { user } = useContext(UserContext);
  const [programmeTypeCode, setProgrammeTypeCode] = useState<string>("");

  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const [authToken, setAuthToken] = useState<string>("");
  const [canContactByEmail, setCanContactByEmail] = useState<boolean>(false);
  const { apiErrorMessages } = useSettings();

  useEffect(() => {
    setProgrammeTypeCode(router?.query?.programmeTypeCode as string);
  }, [router?.query?.programmeTypeCode]);

  useEffect(() => {
    user ? setLoggedInUser(true) : setLoggedInUser(null);
    if (user) {
      setAuthToken(getCookie("access_token"));
    }
  }, [user]);

  const { register, handleSubmit, formState, watch, setValue, control } =
    useForm({
      mode: "onBlur",
    });

  const setLoggedInUserInitialData = async (industries: SelectItem[]) => {
    const personalDetails = await getPersonalDetails(authToken);
    if (personalDetails?.result) {
      const {
        emailAddress,
        firstName,
        lastName,
        employment,
        address,
        broadIndustry,
        telephoneNumber,
        yearsOfManagerialExperience,
      } = personalDetails.result;

      setValue("emailAddress", emailAddress);
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("emailAddress", emailAddress);
      setValue("companyName", employment?.company);
      setValue("homeCountry", address?.homeCountry);
      setValue("industry", findMatchingSelectValue(industries, broadIndustry));
      setValue("telephoneNumber", telephoneNumber);
      setValue("yearsManagerialExperience", yearsOfManagerialExperience);
      setUserPersonalDetails(personalDetails);
      setCanContactByEmail(
        loggedInUser && personalDetails?.result?.isStayInformed
      );
    }
  };

  const prepareFormData = async (loggedInUser: boolean) => {
    try {
      const preferredDates = await getPreferredDates(programmeTypeCode);
      const reasons = await getReasonForReservation();
      const industries = await getBroadIndustries();
      const titles = await getTitles();
      setIndustries(industries);
      setTitles(titles);
      if (preferredDates && preferredDates.length) {
        setPreferredDates(preferredDates);
        setValue("preferredDates", preferredDates[0].value);
      }
      if (reasons && reasons.length) {
        setReasons(reasons);
        setValue("reasons", reasons[0].value);
      }

      if (loggedInUser) {
        setLoggedInUserInitialData(industries);
      }
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if (programmeTypeCode) {
      prepareFormData(loggedInUser);
    }
  }, [programmeTypeCode, loggedInUser]);

  const FormFields = getFormInitialValues(
    content,
    userPersonalDetails,
    loggedInUser,
    preferredDates,
    titles,
    industries,
    reasons,
    watch
  );

  const redirectToSuccessPage = () => {
    setGADataLayer({
      event: "formComplete",
      formName: "reserve a place",
      programmeCode: `${router.query.programme || ""}`,
      businessUnit: getBusinessUnit(),
      eventType: "",
    });

    router.push(
      `/reserve-a-place-success?returnToPreviousPageUrl=${router?.query?.referPage}&referPage=${router?.query?.referPage}`
    );
  };

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "reserve a place",
        });
        firstInteract++;
      }
      return () => subscription.unsubscribe();
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    setLoading(true);
    setHandledSubmitFormErrors(null);
    const submittedData = {
      profile: {
        isStayInformed: canContactByEmail || data?.isStayInformed === "true",
        emailAddress: data.emailAddress,
        title: findMatchingSelectId(titles, data.title),
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        industry: findMatchingSelectId(industries, data.industry),
        yearsOfManagerialExperience: data.yearsManagerialExperience,
      },
      programmeType: {
        isProgrammeTypeRequired: true,
        programmeTypeCode: programmeTypeCode,
        programmeTypeGroup: router?.query?.programmeTypeGroup,
      },
      preferredDateId: findMatchingSelectId(
        preferredDates,
        data.preferredDates
      ),
      company: data.companyName,
      yearsOfManagerialExperience: data.yearsManagerialExperience,
      industry: findMatchingSelectId(industries, data.industry),
      telephoneNumber: !!data.telephoneNumber ? data.telephoneNumber : null,
      homeCountry: data.homeCountry,
      objectives: data.objectivesTextLabelText,
      whatLedYouToReserveAplace: findMatchingSelectId(reasons, data.reasons),
    };
    try {
      setSubmittedUserEmail(data.emailAddress);
      const setReserveAPlace = loggedInUser
        ? setReserveAPlaceLoggedIn
        : setReserveAPlaceNotLoggedIn;
      const response = await setReserveAPlace(
        submittedData,
        sessionStorage.getItem("crm_campaign")
      );

      if (response.status === 201) {
        redirectToSuccessPage();
      } else {
        const handledSubmitFormErrors = handleSubmitFormErrors(
          response,
          apiErrorMessages
        );
        window.scrollTo(0, 0);
        setHandledSubmitFormErrors(handledSubmitFormErrors);
      }
      setLoading(false);
    } catch (e) {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        null,
        apiErrorMessages
      );
      setHandledSubmitFormErrors(handledSubmitFormErrors);
      setLoading(false);
    }
  };

  const FieldJSX = ({ labelText = "", dataText }) => (
    <div className={theme["field-row"]}>
      <label>{labelText}:</label>
      {dataText ? <p>{dataText}</p> : ""}
    </div>
  );

  const renderFormSection = () => {
    if (!programmeTypeCode || (loggedInUser && !userPersonalDetails))
      return (
        <div className={styles.spinner}>
          <ClipLoader
            color="#001e62"
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      );

    return (
      <section className={theme["form-group-wrapper"]}>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className={theme["form-row"]} />
          {FormFields.map((field: any, index) => {
            if (field?.isHidden) return null;

            if (field.propertyName === "homeCountry") {
              return (
                <CountryStateSection
                  key={`${field.labelText}${index}`}
                  isNationalityVariant={true}
                  register={register}
                  control={control}
                  setValue={setValue}
                  errors={formState.errors}
                  countryInputValue={countryInputValue}
                  setCountryInputValue={setCountryInputValue}
                  countries={countriesList}
                  setCountries={setCountriesList}
                  selectedCountry={watch(field?.propertyName) as string}
                  setSelectedCountry={(countryVal) => {
                    setValue(field?.propertyName, countryVal);
                  }}
                  showCountriesList={showNationalCountriesList}
                  setShowCountriesList={setShowNationalCountriesList}
                  countryOfResidenceLabelText={field.labelText}
                  countryValidationMsg={field.validationMsg}
                />
              );
            }

            return (
              <Fragment key={`container-${field?.labelText}-${index}`}>
                {loggedInUser &&
                permanentFields.includes(field.propertyName) ? (
                  <FieldJSX
                    key={`${field?.labelText}-${index}`}
                    labelText={field?.labelText}
                    dataText={field?.dataText}
                  />
                ) : (
                  <FormField
                    formType={field?.formType}
                    watch={watch}
                    validation={field?.validation}
                    type={field?.type || "text"}
                    register={register}
                    property={field?.dataText}
                    key={`${field.labelText}${index}`}
                    hintText={field?.hintText}
                    placeholder={field.labelText}
                    options={field?.options}
                    optionalText={field?.optionalText}
                    name={field?.propertyName}
                    errors={formState.errors}
                    setSelectValue={(e) => setValue(field?.propertyName, e)}
                    selectValue={watch(field?.propertyName) as string}
                  />
                )}
              </Fragment>
            );
          })}

          {!canContactByEmail && (
            <FormPrivacySection register={register} formState={formState} />
          )}

          <div className="btn-and-text-wrapper">
            <SubmitButton loading={loading} id="submitButton" text="Submit" />
          </div>
        </form>
      </section>
    );
  };

  return (
    <div className={clsx(theme["edit-details"], "wrapper component-wrapper")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(theme.component, "component", theme.form)}>
            <h1 className="h2">{registerFormTitle}</h1>
            <h1 className="h2">
              {loggedInUser ? registerFormLoggedInTitleText : ""}
            </h1>
            {error && (
              <FormErrorMessage
                failedRichText={failedMessage?.fields?.content}
                failedTinyMceRichText={failedMessage?.fields?.contentTinyMce}
              />
            )}
            {handledSubmitFormErrors &&
              handledSubmitFormErrors.map((error, index) => (
                <FormErrorMessage
                  key={index}
                  text={error}
                  userEmail={submittedUserEmail}
                />
              ))}
          </div>
          <section
            className={clsx(
              theme.component,
              theme["my-profile"],
              theme["form"],
              "form",
              theme.cf
            )}
          >
            <div className={theme["fields-floated"]}>
              <Grid row>
                <Grid column sm={12}>
                  <p>{loggedInUser ? introLoggedInText : introText}</p>
                  <br />
                  <br />
                  {!loggedInUser && (
                    <p>
                      Already registered?{" "}
                      <a className="underline" href={LOGIN_URL}>
                        Log in here.
                      </a>
                    </p>
                  )}
                </Grid>
              </Grid>
              {renderFormSection()}
            </div>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("C74_ReserveAPlace", styles)(C74_ReserveAPlace);

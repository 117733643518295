import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C68_ChangePassword.module.scss";
import { useEffect, useState, useContext } from "react";
import Grid from "@components/Grid/Grid";
import ApiError from "@components/GenericApiError/Error";
import { UserContext } from "../../context/user";
import React from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";

export interface ChangePasswordProps {
  title: string;
  changePasswordText: string;
  processText: string;
  cancelText: string;
  successPage: {
    fields: {
      slug: string;
    };
  };
}

export const C68_ChangePassword = (props: {
  content: ChangePasswordProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { title, changePasswordText, processText, cancelText, successPage } =
    content;
  const { user } = useContext(UserContext);
  const [userEmail, setUserEmail] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);

  const router = useRouter();
  const { handleSubmit } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    const userEmail = router?.query?.userEmail ?? "";
    setUserEmail(userEmail);
  }, [router?.query?.userEmail]);

  useEffect(() => {
    const getEmail = async () => {
      if (user) {
        try {
          setUserEmail(user["userData"]["email"]);
        } catch (e) {
          console.log(e);
          setError(true);
        }
      }
    };
    getEmail();
  }, [user]);

  const onSubmit = async () => {
    try {
      const response = await fetch("/.netlify/functions/changePassword", {
        method: "POST",
        body: JSON.stringify({
          userEmail,
        }),
      });

      if (response.ok) {
        window.location.href = `/.netlify/functions/logout?returnTo=${successPage?.fields?.slug}`;
      } else {
        const error = await response.json();
        console.log(error);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  if (error) {
    return <ApiError />;
  }

  return (
    <div className="wrapper">
      <Grid row></Grid>
      <Grid column md={12} sm={12}>
        <div className={theme["change-password"]}>
          <div className={theme.coont}>
            <h1>{title}</h1>
            <p className="section-title">{changePasswordText}</p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row"></div>
              <div className="button-wrapper">
                <input
                  type="submit"
                  id="ConfirmButton"
                  value={processText}
                  className="cta primary"
                  disabled={!userEmail}
                />
                <button
                  type="button"
                  className="cta tertiary"
                  onClick={() => router.back()}
                >
                  <span className="icon-cross">
                    <svg
                      width="20"
                      height="20"
                      viewBox="5 5 14 14"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      focusable="false"
                    >
                      <path
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                        stroke="none"
                        stroke-width="1"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span>{cancelText}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default themr("C68_ChangePassword", styles)(C68_ChangePassword);

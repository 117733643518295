import Cta from "@components/Cta/Cta";
import { Link } from "@customTypes/link";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useDevice } from "@utilities/react/get-device/get-device";
import clsx from "clsx";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";
import ProofPoint, { ProofPointData } from "./C256_ProofPoint";

import styles from "./C256_ProofPointWrapper.module.scss";

export interface ProofPointWrapperProps {
  optionalCTALink: Link;
  description: string;
  optionalHeading?: string;
  cssClass: string;
  proofPoints: {
    fields: ProofPointData;
  }[];
}

export const ProofPointWrapper = (props: {
  content: ProofPointWrapperProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    optionalCTALink,
    description,
    optionalHeading,
    cssClass,
    proofPoints,
  } = content;

  const device = useDevice();
  const isDesktop = ["desktop", "desktop-large"].includes(device);
  const isSingle =
    !optionalCTALink &&
    !description &&
    !optionalHeading &&
    proofPoints.length === 1;

  return (
    <div
      className={clsx(
        "component",
        theme["icon-list"],
        isSingle && theme.solo,
        cssClass && theme[cssClass]
      )}
    >
      <div className="wrapper">
        {optionalHeading && <h3>{optionalHeading}</h3>}

        {description && <p className={theme["large"]}>{description}</p>}

        <div className={theme["point-container"]}>
          {proofPoints.length > 2 && !isDesktop ? (
            <SwiperCarousel className="swiper-pb-0" loop={true}>
              {proofPoints.map((proofPoint, index) => {
                return (
                  <SwiperSlide key={`${proofPoint.fields?.title}${index}`}>
                    <ProofPoint theme={theme} {...proofPoint.fields} />
                  </SwiperSlide>
                );
              })}
            </SwiperCarousel>
          ) : (
            <>
              {proofPoints.map((proofPoint, index) => {
                return (
                  <ProofPoint
                    key={`${proofPoint.fields?.title}${index}`}
                    theme={theme}
                    {...proofPoint.fields}
                  />
                );
              })}
            </>
          )}
        </div>
        {optionalCTALink && (
          <Cta
            type="secondary"
            light={cssClass === "blue"}
            url={optionalCTALink.fields.url || ""}
            linkText={optionalCTALink.fields.linkText || ""}
          />
        )}
      </div>
    </div>
  );
};

export default themr("ProofPointWrapper", styles)(ProofPointWrapper);

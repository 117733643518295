import moment from "moment";
import { SiteSettings } from "@customTypes/SiteSettings";
import { Results } from "../CoveoSearchComponent";
import { SocialShare } from "@components/C285_SocialShare/C285_SocialShare";

interface EventsDetailsResultsTemplateProps {
  siteSettings?: SiteSettings;
  result: Results;
}

export const EventsDetailsResultsTemplate = (
  props: EventsDetailsResultsTemplateProps
) => {
  const { result, siteSettings } = props;

  function getSlugByType(resultPageCollection, type) {
    const item = resultPageCollection.items.find((item) => item.type === type);
    return item;
  }

  const linkData =
    siteSettings &&
    getSlugByType(
      siteSettings?.searchResultsPagesCollection,
      "think-articletopics"
    );

  const fullDateFormat = "dddd DD MMM yyy";
  const timeFormat = "HH:mm";

  return (
    <>
      <div>
        <div className="text1">
          {result.raw.eventtype && (
            <p className="primary-tag">{result.raw.eventtype}</p>
          )}
          <h2>
            <a className="coveo-link" href={result.clickUri}>
              {(result.raw.eventtitle || result.title) && (
                <h3 className="h3">{result.raw.eventtitle || result.title}</h3>
              )}
            </a>
          </h2>

          {result.raw.eventstarttime && (
            <p className="text-with-icon">
              <span className="icon">
                <svg
                  height="20"
                  width="20"
                  viewBox="5 3 30 34"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  focusable="false"
                >
                  <path d="M15 13.333h-3.333v3.334H15v-3.334zm6.667 0h-3.334v3.334h3.334v-3.334zm6.666 0H25v3.334h3.333v-3.334zm3.334-6.666H30V3.333h-3.333v3.334H13.333V3.333H10v3.334H8.333c-1.85 0-3.316 1.5-3.316 3.333L5 33.333a3.332 3.332 0 003.333 3.334h23.334c1.833 0 3.333-1.5 3.333-3.334V10c0-1.833-1.5-3.333-3.333-3.333zm0 26.666H8.333V10h23.334v23.333z"></path>
                </svg>
              </span>
              {moment.utc(result.raw.eventstarttime).format(fullDateFormat)}
            </p>
          )}

          {result.raw.eventstarttime && (
            <p className="text-with-icon">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  focusable="false"
                >
                  <title>time</title>
                  <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67V7z"></path>
                  <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0 01-8 8z"></path>
                </svg>
              </span>
              {moment.utc(result.raw.eventstarttime).format(timeFormat)}
              {result.raw.eventendtime
                ? ` to ${moment
                    .utc(result.raw.eventendtime)
                    .format(timeFormat)}`
                : ""}
            </p>
          )}
          {(result.raw.eventlocationname || result.raw.eventcity) && (
            <p className="text-with-icon">
              <span className="icon">
                <svg
                  width="16"
                  height="24"
                  viewBox="4.2 1.2 15.6 22"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  focusable="false"
                >
                  <path d="M12 1.25A7.745 7.745 0 0119.75 9c0 1.322-.39 2.81-1.11 4.441-.625 1.42-1.484 2.914-2.518 4.447a46.786 46.786 0 01-3.565 4.615l-.557.616-.557-.616a43.107 43.107 0 01-1.12-1.323 46.786 46.786 0 01-2.445-3.292c-1.034-1.533-1.893-3.028-2.518-4.447C4.64 11.81 4.25 10.321 4.25 9A7.745 7.745 0 0112 1.25zm0 10.25a2.5 2.5 0 010-5 2.5 2.5 0 010 5zm.511 8.734a45.305 45.305 0 002.367-3.185c.988-1.464 1.804-2.884 2.39-4.212.642-1.458.982-2.754.982-3.837A6.245 6.245 0 0012 2.75 6.245 6.245 0 005.75 9c0 1.083.34 2.38.982 3.837.586 1.328 1.402 2.748 2.39 4.212A45.305 45.305 0 0012 20.853c.161-.191.332-.398.511-.619z"></path>
                </svg>
              </span>
              {result.raw.eventlocationname || result.raw.eventcity}
            </p>
          )}

          <footer>
            {result.raw.taz120xonomytopics &&
              result.raw.taz120xonomytopics.map((topic, i) => (
                <div key={`${topic}${i}`} className="component tag cf">
                  <a
                    href={`/${linkData?.url?.slug}?${topic
                      ?.split(":")[0]
                      .toLowerCase()}=${topic?.split(":")[1]}`}
                  >
                    {topic?.split(":")[1]}
                  </a>
                </div>
              ))}
            <SocialShare url={result.clickUri} />
          </footer>
        </div>
      </div>
    </>
  );
};

export default EventsDetailsResultsTemplate;

export const programmeTypeGroupFieldsInfo = {
  MBA1Y: {
    emailAddress: { enabled: true },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: true },
    countryState: { enabled: true },
    city: { enabled: true },
    companyName: { enabled: true },
    yearsRelevanceExperience: { enabled: true },
    institution: { enabled: true },
    masterProgrammeDegreeTitle: { enabled: true },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },

  MBA: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: false },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: true },
    countryState: { enabled: true },
    city: { enabled: true },
    yearsRelevanceExperience: { enabled: true },
    yearsManagerialExperience: { enabled: false },
    companyName: { enabled: true },
    industry: { enabled: true },
    seniorityLevel: { enabled: true },
    university: { enabled: false },
    graduationYear: { enabled: false },
    programmeSection: { enabled: false },
    hrInformation: { enabled: false },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
  MAM: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: false },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: true },
    countryState: { enabled: true },
    city: { enabled: true },
    yearsRelevanceExperience: { enabled: true },
    yearsManagerialExperience: { enabled: false },
    companyName: { enabled: false },
    industry: { enabled: false },
    seniorityLevel: { enabled: false },
    university: { enabled: true },
    graduationYear: { enabled: true },
    programmeSection: { enabled: false },
    hrInformation: { enabled: false },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
  MIF: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: false },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: true },
    countryState: { enabled: true },
    city: { enabled: true },
    yearsRelevanceExperience: { enabled: true },
    yearsManagerialExperience: { enabled: false },
    companyName: { enabled: true },
    industry: { enabled: true },
    seniorityLevel: { enabled: false },
    university: { enabled: false },
    graduationYear: { enabled: false },
    programmeSection: { enabled: true },
    hrInformation: { enabled: false },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
  MIM: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: false },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: true },
    countryState: { enabled: true },
    city: { enabled: true },
    yearsRelevanceExperience: { enabled: true },
    yearsManagerialExperience: { enabled: false },
    companyName: { enabled: false },
    industry: { enabled: false },
    seniorityLevel: { enabled: false },
    university: { enabled: true },
    graduationYear: { enabled: true },
    programmeSection: { enabled: true },
    hrInformation: { enabled: false },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
  MFA: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: false },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: true },
    countryState: { enabled: true },
    city: { enabled: true },
    yearsRelevanceExperience: { enabled: true },
    yearsManagerialExperience: { enabled: false },
    companyName: { enabled: false },
    industry: { enabled: false },
    seniorityLevel: { enabled: false },
    university: { enabled: true },
    graduationYear: { enabled: true },
    programmeSection: { enabled: false },
    hrInformation: { enabled: false },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
  EMBA: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: false },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: true },
    countryState: { enabled: true },
    city: { enabled: true },
    yearsRelevanceExperience: { enabled: true },
    yearsManagerialExperience: { enabled: true },
    companyName: { enabled: false },
    industry: { enabled: false },
    seniorityLevel: { enabled: false },
    university: { enabled: false },
    graduationYear: { enabled: false },
    programmeSection: { enabled: true },
    hrInformation: { enabled: false },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
  EMBAG: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: false },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: true },
    countryState: { enabled: true },
    city: { enabled: true },
    yearsRelevanceExperience: { enabled: false },
    yearsManagerialExperience: { enabled: false },
    companyName: { enabled: false },
    industry: { enabled: false },
    seniorityLevel: { enabled: false },
    university: { enabled: false },
    graduationYear: { enabled: false },
    programmeSection: { enabled: false },
    hrInformation: { enabled: false },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
  SLOAN: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: false },
    title: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: true },
    countryOfResidence: { enabled: false },
    countryState: { enabled: false },
    city: { enabled: false },
    yearsRelevanceExperience: { enabled: false },
    yearsManagerialExperience: { enabled: true },
    companyName: { enabled: false },
    industry: { enabled: false },
    seniorityLevel: { enabled: false },
    university: { enabled: false },
    graduationYear: { enabled: false },
    programmeSection: { enabled: false },
    hrInformation: { enabled: false },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
  EXECED: {
    emailAddress: { enabled: true },
    countryNumber: { enabled: true },
    title: { enabled: false },
    firstName: { enabled: true },
    lastName: { enabled: true },
    nationalityOf: { enabled: false },
    countryOfResidence: { enabled: true },
    countryState: { enabled: false },
    city: { enabled: false },
    yearsRelevanceExperience: { enabled: false },
    yearsManagerialExperience: { enabled: false },
    companyName: { enabled: false },
    industry: { enabled: false },
    seniorityLevel: { enabled: true },
    university: { enabled: false },
    graduationYear: { enabled: false },
    programmeSection: { enabled: false },
    hrInformation: { enabled: true },
    password: { enabled: true },
    confirmPassword: { enabled: true },
  },
};

export const permanentFields = [
  "emailAddress",
  "title",
  "firstName",
  "lastName",
];

export const programmesListData = {
  MIM: [
    {
      id: "10001",
      name: "Masters in Management",
      value: "MIM",
    },
    {
      id: "10002",
      name: "Global MiM",
      value: "GMIM",
    },
  ],
  MIF: [
    {
      id: "10003",
      name: "Masters in Finance Full-time",
      value: "MIFFT",
    },
    {
      id: "10004",
      name: "Masters in Finance Part-time",
      value: "MIFPT",
    },
  ],
  EMBA: [
    {
      id: "10005",
      name: "Executive MBA (London)",
      value: "EMBA London",
    },
    {
      id: "10006",
      name: "Executive MBA (Dubai)",
      value: "EMBA Dubai",
    },
  ],
};

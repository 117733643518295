import { themr } from "@friendsofreactjs/react-css-themr";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { useRouter } from "next/router";

import Grid from "@components/Grid/Grid";
import FormField from "@components/Form/FormFields/FormFields";
import FormPrivacySection from "@components/FormPrivacySection/FormPrivacySection";
import { getPersonalDetails, postContactForm } from "../../services/forms";
import CountryNumber from "@components/CountryNumber/CountryNumber";
import { useSettings } from "@utilities/context/settings";
import { countryCodesData } from "../CountryNumber/CountryCodes";

import { UserContext } from "../../context/user";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import { handleSubmitFormErrors } from "@utilities/handleSubmitFormErrors";

import styles from "./ConsultantForm.module.scss";
import getCookie from "@utilities/getCookie";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export interface UserConsultantDetailsProps {
  consultantFormTitleText: string;
  emailLabelText: string;
  titleLabelText: string;
  firstNameLabelText: string;
  lastNameLabelText: string;
  countryCodeLabelText: string;
  telephoneNumberPlaceholderText: string;
  telephoneNumberHintText: string;
  telephoneInputHintText: string;
  commentEnquiryLabelText: string;
  successPageUrl;
  failedMessage: {
    fields: {
      content: any;
      contentTinyMce: any;
    };
  };
}

let firstInteract = 0;

export const ConsultantForm = (props: {
  content: UserConsultantDetailsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    consultantFormTitleText,
    emailLabelText,
    firstNameLabelText,
    lastNameLabelText,
    countryCodeLabelText,
    telephoneNumberPlaceholderText,
    telephoneNumberHintText,
    commentEnquiryLabelText,
    successPageUrl,
    failedMessage,
  } = content;

  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [selectedDialCode, setSelectedDialCode] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [handledSubmitFormErrors, setHandledSubmitFormErrors] =
    useState<Array<string> | null>(null);
  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const [authToken, setAuthToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();
  const { user } = useContext(UserContext);
  let genuinePath = router?.query?.referPage || router?.asPath;

  const { register, handleSubmit, reset, formState, watch } = useForm({
    mode: "onBlur",
  });

  const { apiErrorMessages } = useSettings();

  useEffect(() => {
    user ? setLoggedInUser(true) : setLoggedInUser(null);
    if (user) {
      setAuthToken(getCookie("access_token"));
    }
  }, [user]);

  useEffect(() => {
    if (loggedInUser) {
      const contactUsFormData = async () => {
        try {
          const userPersonalDetails = await getPersonalDetails(authToken);

          setUserPersonalDetails(userPersonalDetails);
        } catch (e) {
          setError(true);
        }
      };

      contactUsFormData();
    }
  }, [loggedInUser]);

  const FormFields = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      dataText: userPersonalDetails?.result?.emailAddress,
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
      dataText: userPersonalDetails?.result?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
      dataText: userPersonalDetails?.result?.lastName,
    },
    {
      propertyName: "telephone",
      labelText: "",
      hintText: "",
      optionalText: false,
      options: null,
    },
    {
      propertyName: "objectivesText",
      validation: {
        isRequired: true,
        maxLength: 140,
      },
      labelText: commentEnquiryLabelText,
      formType: "textarea",
    },
  ];

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "consultantForm",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (userPersonalDetails) {
      const inputProp = [...FormFields].reduce(
        (o, key: any) => ({ ...o, [key.propertyName]: key.dataText }),
        {}
      );
      reset(inputProp);
    }
  }, [userPersonalDetails]);

  const redirectToSuccessPage = () => {
    setGADataLayer({
      event: "formComplete",
      formName: "consultantForm",
      businessUnit: getBusinessUnit(),
      programmeCode: "",
      eventType: "",
    });

    const fullSuccessPageUrl: string =
      "/" + successPageUrl + "?returnToPreviousPageUrl=" + genuinePath;

    router.push(fullSuccessPageUrl);
  };

  const onSubmit = async (data) => {
    setHandledSubmitFormErrors(null);
    setLoading(true);

    const {
      emailAddress,
      firstName,
      lastName,
      telephone,
      noBulkEmail,
      objectivesText,
    } = data;

    const formPayloadData = {
      profile: {
        isStayInformed: noBulkEmail,
        emailAddress,
        firstName,
        lastName,
      },
      programmeType: {
        programmeTypeCode: router?.query?.programmeTypeCode || null,
        programmeType: router?.query?.programmeType || null,
      },
      telephoneNumber: telephone || null,
      comments: objectivesText,
    };

    try {
      const response = await postContactForm(
        formPayloadData,
        sessionStorage.getItem("crm_campaign")
      );

      setLoading(false);

      if (response.status === 201) {
        redirectToSuccessPage();
      } else {
        const handledSubmitFormErrors = handleSubmitFormErrors(
          response,
          apiErrorMessages
        );
        window.scrollTo(0, 0);
        setHandledSubmitFormErrors(handledSubmitFormErrors);
      }
      setLoading(false);
    } catch (e) {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        null,
        apiErrorMessages
      );
      window.scrollTo(0, 0);
      setHandledSubmitFormErrors(handledSubmitFormErrors);
      setLoading(false);
    }
  };

  return (
    <div className="wrapper component-wrap">
      <Grid row>
        <Grid column sm={12} md={6}>
          <section
            className={clsx(
              theme.component,
              theme["consult-form"],
              "form",
              theme.cf
            )}
          >
            <h1 className="h2">{consultantFormTitleText}</h1>
            {error && (
              <FormErrorMessage
                failedRichText={failedMessage?.fields?.content}
                failedTinyMceRichText={failedMessage?.fields?.contentTinyMce}
              />
            )}
            {handledSubmitFormErrors &&
              handledSubmitFormErrors.map((error, index) => (
                <FormErrorMessage key={index} text={error} />
              ))}
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className={theme["fields-floated"]}>
                <p>
                  Please complete the form below with details of your request.
                  We’ll get back to you by email, so make sure you include the
                  right email address.
                </p>

                <div className="form-row">
                  <section className={theme["form-group-wrapper"]}>
                    {FormFields.map((field: any, index) => {
                      if (field.propertyName === "telephone") {
                        return (
                          <CountryNumber
                            key={`${countryCodeLabelText}${index}`}
                            theme={theme}
                            dialCodeLabelText={countryCodeLabelText}
                            telephoneInputPlaceholderText={
                              telephoneNumberPlaceholderText
                            }
                            telephoneNumberHintText={telephoneNumberHintText}
                            optionsData={countryCodesData}
                            selectedDialCode={selectedDialCode}
                            setSelectedDialCode={setSelectedDialCode}
                            numberValue={numberValue}
                            setNumberValue={setNumberValue}
                            register={register}
                            formState={formState}
                            watch={watch}
                            errors={formState.errors}
                            fieldIsRequired={false}
                          />
                        );
                      }
                      return (
                        <FormField
                          formType={field?.formType}
                          watch={watch}
                          validation={field?.validation}
                          type="text"
                          register={register}
                          property={field.dataText}
                          key={`${field.labelText}${index}`}
                          hintText={field?.hintText}
                          placeholder={field.labelText}
                          options={field.options}
                          optionalText={field?.optionalText}
                          name={field?.propertyName}
                          errors={formState.errors}
                        />
                      );
                    })}
                  </section>
                </div>

                <FormPrivacySection
                  register={register}
                  formState={formState}
                  chosenEmailSubscription={
                    loggedInUser && userPersonalDetails?.result?.isStayInformed
                  }
                />
                <div className="btn-and-text-wrapper">
                  <SubmitButton
                    loading={loading}
                    id="submitButton"
                    text="Submit"
                  />
                </div>
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("ConsultantForm", styles)(ConsultantForm);

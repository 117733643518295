function determinePageType(topic) {
  const topicNormalized = topic.toLowerCase();
  if (topicNormalized.includes("podcast")) {
    return "podcasts";
  } else if (topicNormalized.includes("ahead")) {
    return "think-ahead";
  } else {
    return "think-articletopics";
  }
}

export { determinePageType };

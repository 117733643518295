import { createContext, ReactNode, useContext } from "react";

interface ProgrammeContextProps {
  programmeTypeCode: string;
  programmeTypeGroup: string;
  programmeCode: string;
  importDataProgrammeTypeCode: string;
  programmeStartDate: string;
  programmeFee: string;
  programmeDuration: string;
  programmeLocation?: string;
  programmeLocationMapping?: {
    filterOptions?: string[];
  };
  programmeInclusiveDates?: string;
}

interface ProgrammeProviderProps {
  children: ReactNode;
  data: ProgrammeContextProps;
}

export const ProgrammeContext = createContext<ProgrammeContextProps>({
  programmeTypeCode: "",
  programmeTypeGroup: "",
  programmeCode: "",
  importDataProgrammeTypeCode: "",
  programmeStartDate: "",
  programmeFee: "",
  programmeDuration: "",
  programmeLocation: "",
  programmeLocationMapping: {
    filterOptions: [],
  },
  programmeInclusiveDates: "",
});

export const ProgrammeProvider = ({
  children,
  data,
}: ProgrammeProviderProps) => {
  const {
    programmeTypeCode,
    programmeTypeGroup,
    programmeCode,
    importDataProgrammeTypeCode,
    programmeStartDate,
    programmeFee,
    programmeDuration,
    programmeLocation,
    programmeLocationMapping,
    programmeInclusiveDates,
  } = data;

  return (
    <ProgrammeContext.Provider
      value={{
        programmeTypeCode,
        programmeTypeGroup,
        programmeCode,
        importDataProgrammeTypeCode,
        programmeStartDate,
        programmeFee,
        programmeDuration,
        programmeLocation,
        programmeLocationMapping,
        programmeInclusiveDates,
      }}
    >
      {children}
    </ProgrammeContext.Provider>
  );
};

export const useProgramme = () => useContext(ProgrammeContext);

export const programmeImportMock = {
  programmeCode: "ADP",
  importDataProgrammeTypeCode: "ADP231",
  programmeStartDate: "2024-02-11T00:00:00.000Z",
  programmeFee: "£29500",
  programmeDuration: "2 x 2 weeks",
  programmeLocation: "In person - London",
  programmeLocationMapping: {
    filterOptions: ["In-person-London", "Online"],
  },
  programmeInclusiveDates: "12-24 February & 04-16 June 2023",
};

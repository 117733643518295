import { themr } from "@friendsofreactjs/react-css-themr";
import styleNames from "../../utils/react/style-names";
import styles from "./Breadcrumb.module.scss";
import Breadcrumb, { CrumbProps } from "./C45_Breadcrumb";
import SocialShare from "./SocialShare";

export interface BreadcrumbSocialShareWrapperProps {
  slug: string;
  title: string;
  breadcrumbs?: CrumbProps[];
  theme?: any;
}

export const BreadcrumbSocialShareWrapper = ({
  slug,
  title,
  breadcrumbs,
  theme,
}: BreadcrumbSocialShareWrapperProps) => {
  return (
    <div className={`${styleNames(theme, ["socialshare", "blue"])} flush`}>
      <div className={`wrapper ${theme.wrapper}`}>
        <Breadcrumb
          slug={slug}
          title={title}
          breadcrumbs={breadcrumbs}
          theme={theme}
        />
        <span></span>
        <SocialShare />
      </div>
    </div>
  );
};

export default themr(
  "BreadcrumbSocialShareWrapper",
  styles
)(BreadcrumbSocialShareWrapper);

import { themr } from "@friendsofreactjs/react-css-themr";
import DynamicPieExtended, { extendedPie } from "./DynamicPieExtended";

import styles from "./C252_DynamicPie.module.scss";

export interface ExtendedPieWrapper {
  title: string;
  isBigTitle: boolean;
  graphs: Array<{ fields: extendedPie }>;
}

export const DynamicPieWrapperExtended = ({
  theme,
  content,
}: {
  theme?: any;
  content: ExtendedPieWrapper;
}) => {
  const { title, isBigTitle, graphs } = content;

  return (
    <div
      className={`${theme["dynamic-pie"]} ${theme.multiple}`}
      data-items={graphs.length}
    >
      {isBigTitle ? <h2>{title}</h2> : <h3>{title}</h3>}
      <div className="wrapper">
        <div className={`${styles.container}`}>
          {graphs?.map((pie, i) => {
            return (
              <DynamicPieExtended
                key={`${title}${i}`}
                theme={theme}
                {...pie.fields}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default themr(
  "DynamicPieWrapperExtended",
  styles
)(DynamicPieWrapperExtended);

import { Fragment } from "react";

import { Contact } from "@components/__templates__/EventsDetail/EventsDetail";
import IconGlobal from "../../../public/image/svg/global.svg";
import IconCall from "../../../public/image/svg/phone.svg";

const ContactsSection = (props: { theme?: any; contacts: Contact[] }) => {
  const { theme, contacts } = props;
  return (
    <dl>
      {contacts
        .filter((contact) => !!contact.fields?.fullName)
        .map((contact, index) => (
          <Fragment key={`contact-${index}`}>
            <dt>{contact.fields?.fullName}</dt>
            <dd>
              {!!contact.fields?.email && (
                <p>
                  <i className={theme["icon-global"]}>
                    <IconGlobal />
                  </i>
                  {contact?.fields?.email}
                </p>
              )}
              {!!contact?.fields?.phoneNumber && (
                <p>
                  <i className={theme["icon-call"]}>
                    <IconCall />
                  </i>
                  <a href={`tel:${contact?.fields?.phoneNumber}`}>
                    {contact?.fields?.phoneNumber}
                  </a>
                </p>
              )}
            </dd>
          </Fragment>
        ))}
    </dl>
  );
};

export default ContactsSection;

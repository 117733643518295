import Grid from "@components/Grid/Grid";
import Link from "@components/Link/Link";
import { Fragment } from "react";

export interface SiteMapProps {
  content: {
    leftColumn?: ColumnProps[];
    rightColumn?: ColumnProps[];
  };
}

interface ColumnProps {
  level2Item: {
    linkText: string;
    pageLink: string;
  }[];
  linkText: string;
  pageLink: string;
}

const Column = ({ items = [] }: { items: ColumnProps[] }) => {
  return (
    <>
      {items.map((item, i) => {
        const slug = item.pageLink === "home" ? "/" : `/${item.pageLink}`;
        return (
          <Fragment key={`${item.linkText}-${i + 1}`}>
            {!item.level2Item ? (
              <h2 key={`${item.linkText}-${i}`}>
                <Link href={slug} passHref>
                  <a>{item.linkText}</a>
                </Link>
              </h2>
            ) : (
              <Fragment key={`${item.linkText}-${i}`}>
                <h2>
                  <Link href={slug} passHref>
                    <a>{item.linkText}</a>
                  </Link>
                </h2>
                <ul>
                  {item.level2Item.map((child, i) => (
                    <li key={`${child.linkText}--${i}`}>
                      <Link href={`/${child.pageLink}`} passHref>
                        <a>{child.linkText}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Fragment>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

const SiteMap = (props: SiteMapProps) => {
  const { content } = props;
  return (
    <div className="rich-text wrapper sitemap">
      <Grid row>
        <Grid column md={6}>
          <Column items={content?.rightColumn ?? []} />
        </Grid>
        <Grid column md={6}>
          <Column items={content?.leftColumn ?? []} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SiteMap;

import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import { Image } from "@customTypes/image";
import { Link as LinkType } from "@customTypes/link";
import { themr } from "@friendsofreactjs/react-css-themr";
import Link from "@components/Link/Link";
import { useInView } from "react-hook-inview";
import styleNames from "../../utils/react/style-names";
import styles from "./C20_PromotionalArticles.module.scss";

export interface PromotionalArticlesProps {
  imageFullWide: Image;
  imageWide: Image;
  imageMedium: Image;
  imageSmall: Image;
  imageMobile: Image;
  label: string;
  title: string;
  description: string;
  articleLink: LinkType;
  linkUrlForRender: LinkType;
  reverse: boolean;
  isLight: boolean;
  isSeventyPercentVariation: boolean;
}

export const PromotionalArticles = (props: {
  content: PromotionalArticlesProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    imageFullWide,
    imageWide,
    imageMedium,
    imageSmall,
    imageMobile,
    label,
    title,
    description,
    articleLink,
    linkUrlForRender,
    reverse,
    isLight,
    isSeventyPercentVariation,
  } = content;

  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  let promotionalType = 0;
  let cssClass = "";

  if (imageMedium) {
    promotionalType = 1;
  } else if (imageFullWide && imageWide) {
    promotionalType = 2;
    cssClass = isSeventyPercentVariation ? "small-width" : "wide";
  } else if (imageSmall) {
    promotionalType = 3;
    cssClass = "alt";
  }

  return (
    <Grid row>
      <Grid column sm={12}>
        <div
          className={`component ${styleNames(theme, [
            "promotional-articles",
            "solo",
            reverse && "reverse",
            isLight ? "white" : "dark",
            cssClass,
            isVisible && "animate",
          ])}`}
          ref={ref}
        >
          <div className={theme["wrapper"]}>
            <div className={theme["container"]}>
              <article>
                {imageMobile && linkUrlForRender && (
                  <Link
                    passHref={true}
                    href={linkUrlForRender.fields.url || "#"}
                  >
                    <a>
                      <picture>
                        {promotionalType === 1 && (
                          <source
                            data-srcset={imageMedium.fields.file.url}
                            media="(min-width:768px)"
                          ></source>
                        )}
                        {promotionalType === 2 && (
                          <>
                            <source
                              data-srcset={imageFullWide.fields.file.url}
                              media="(min-width:1024px)"
                            ></source>
                            <source
                              data-srcset={imageWide.fields.file.url}
                              media="(min-width:768px)"
                            ></source>
                          </>
                        )}
                        {promotionalType === 3 && (
                          <source
                            data-srcset={imageSmall.fields.file.url}
                            media="(min-width:768px)"
                          ></source>
                        )}
                        <img
                          src={imageMobile.fields.file.url}
                          alt={imageMobile.fields.title}
                        />
                      </picture>
                    </a>
                  </Link>
                )}
                <div className={theme["text"]}>
                  {(promotionalType === 1 || promotionalType === 2) && (
                    <div className={theme["line"]}></div>
                  )}
                  {label && <p className={theme["label"]}>{label}</p>}
                  {title && linkUrlForRender?.fields?.url && (
                    <h4>
                      <Link href={linkUrlForRender?.fields?.url}>{title}</Link>
                    </h4>
                  )}
                  {description && <p>{description}</p>}
                  {articleLink && (
                    <Cta
                      {...articleLink.fields}
                      type="secondary"
                      light={
                        (promotionalType === 1 || promotionalType === 2) &&
                        !isLight
                      }
                      openInNewTab={articleLink.fields.openInNewTab}
                    />
                  )}
                </div>
              </article>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default themr("PromotionalArticles", styles)(PromotionalArticles);

import { LOGIN_URL, RECOVER_PASSWORD_URL } from "../../constants";

export const shortCodesToHtml = (
  shortCodesText: string,
  userEmail?: string
): string => {
  const mappingData: { shortCode: string; htmlCode: string }[] = [
    {
      shortCode: "{{log_in}}",
      htmlCode: `<a href="${LOGIN_URL}" class=underline>login</a>`,
    },
    {
      shortCode: "{{recover_your_password}}",
      htmlCode: `<a href="${RECOVER_PASSWORD_URL}?userEmail=${userEmail}" class=underline>recover your password</a>`,
    },
  ];

  mappingData.forEach((item) => {
    shortCodesText = shortCodesText.replaceAll(item.shortCode, item.htmlCode);
  });
  return shortCodesText;
};

import { themr } from "@friendsofreactjs/react-css-themr";
import Link from "@components/Link/Link";
import styles from "./Breadcrumb.module.scss";

export interface BreadcrumbProps {
  slug: string;
  title: string;
  breadcrumbs?: CrumbProps[];
  theme?: any;
}

export interface CrumbProps {
  title: string;
  url: string | null;
}

export const Breadcrumb = ({
  slug,
  title,
  breadcrumbs,
  theme,
}: BreadcrumbProps) => {
  return (
    <div className={theme.breadcrumb}>
      <div className={theme.wrapper}>
        <ul className={theme["breadcrumb-links"]}>
          {slug !== "home" ? (
            <>
              <li>
                <Link href="/">
                  <a>Home</a>
                </Link>
              </li>
              {breadcrumbs?.map((crumb: any, i) => {
                if (!crumb.title) {
                  return null;
                }
                return (
                  <li key={`${crumb.title}${i}`}>
                    <Link href={`/${crumb.url}`}>
                      <a>{crumb.title}</a>
                    </Link>
                  </li>
                );
              })}
              <li className={theme["current-location"]}>{title}</li>
            </>
          ) : (
            <li>Home</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default themr("Breadcrumb", styles)(Breadcrumb);

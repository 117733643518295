/* COMPONENT - C243 Coveo styling 1.0 */
"use strict";

import { SocialShare } from "./c285_social-share.js";
import { Results } from "./c50.1_results.js";
import { VideoPodcastLightbox } from "./c284_video-podcast-lightbox";
import { TopicFilter } from "./c206_topic-filter.js";

export class CoveoStyling {
  constructor(element, options) {
    this.root = element;
    this.facetCounts = {};
    this.facetContainer = this.root.querySelector(".coveo-facet-column");
    this.topicListing = this.root.classList.contains("topic-listing");
    this.mobile = window.matchMedia("(max-width: 767px)");
    this.tablet = window.matchMedia(
      "(min-width: 768px) and (max-width: 1023px)"
    );
    this.modulo = this.tablet.matches ? 3 : 4;
    this.firstQuery = true;
    let timer;

    timer = setInterval(() => {
      if (this.root.classList.contains("coveo-after-initialization")) {
        this.init();
        clearInterval(timer);
      }
    }, 50);
  }

  init() {
    if (this.root.querySelectorAll(".coveo-facet-header").length) {
      this.loading = this.root.querySelectorAll(
        ".coveo-facet-header-wait-animation"
      );
      this.facetHeaders = this.root.querySelectorAll(".coveo-facet-header");
      this.facetValues = this.root.querySelectorAll(".coveo-facet-values");

      if (this.facetValues.length > 0) {
        this.setTabIndex();
        this.applyFacetListeners();
        this.checkForEmpty();
        this.calculateCounts();
        this.addToggle();
      }
    }

    this.applyListeners();
    this.transformSort();
  }

  setTabIndex() {
    if (this.facetHeaders && this.facetHeaders.length) {
      [...this.facetHeaders].forEach((el) => {
        const p = el.parentElement;

        if (p.classList.contains("coveo-facet-empty")) {
          el.setAttribute("tabindex", "-1");
        } else {
          el.setAttribute("tabindex", "0");
        }
      });
    }
  }

  addToggle() {
    let btn = document.createElement("button");

    btn.classList.add("primary");
    btn.classList.add("cta");
    btn.classList.add("filter-toggle");
    btn.textContent = "Filters";

    this.facetContainer.insertAdjacentElement("afterend", btn);

    btn.addEventListener("click", this.toggleFilters.bind(this));
  }

  toggleFilters() {
    const btn = this.root.querySelector(".filter-toggle");

    this.facetContainer.classList.toggle("open");

    if (this.facetContainer.classList.contains("open")) {
      btn.textContent = "Close filters";
      this.facetContainer.style.height =
        this.facetContainer.scrollHeight + "px";

      setTimeout(() => {
        this.facetContainer.style.height = "auto";
      }, 300);
    } else {
      this.facetContainer.style.height =
        this.facetContainer.scrollHeight + "px";
      setTimeout(() => {
        this.facetContainer.style.height = "0px";
      }, 10);
      btn.textContent = "Filters";
    }
  }

  transformSort() {
    if (this.root.querySelectorAll(".coveo-sort-section").length) {
      let sortBy = document.createElement("div");
      sortBy.classList.add("sort-label");
      sortBy.textContent = "Sort by:";

      this.root.querySelector(".coveo-sort-section").prepend(sortBy);
    }
  }

  toggleFacet(header, e) {
    let i;

    if (header.parentElement.classList.contains("coveo-facet-empty")) {
      return;
    }

    if (header.classList.contains("open")) {
      header.classList.remove("open");
    } else {
      for (i = 0; i < this.facetHeaders.length; i += 1) {
        this.facetHeaders[i].classList.remove("open");
      }
      header.classList.add("open");
    }
  }

  calculateCounts() {
    let items = [
        ...this.root.querySelectorAll(
          ".coveo-breadcrumb-items .coveo-breadcrumb-item"
        ),
      ],
      results = {};

    items.forEach((item) => {
      results[
        item
          .querySelector(".coveo-facet-breadcrumb-title")
          .textContent.split(":")[0]
      ] = item.querySelectorAll(".coveo-facet-breadcrumb-value").length;
    });

    this.facetCounts = results;
    this.renderCounts();
  }

  renderCounts() {
    [...this.facetHeaders].forEach((header) => {
      let title = header.querySelector(".coveo-facet-header-title").title;

      if (this.facetCounts[title]) {
        header.dataset.count = this.facetCounts[title];
      } else {
        header.dataset.count = 0;
      }
    });
  }

  checkForEmpty() {
    if (this.facetContainer) {
      let visible = [];

      [...this.facetContainer.children].forEach((el) => {
        let facet = el.querySelector(".CoveoFacet");

        if (facet) {
          visible.push(el);
        }
      });

      [...visible].forEach((el, i) => {
        el.classList.remove("first-in-line");

        if (i % this.modulo === 0) {
          el.classList.add("first-in-line");
        }
      });
    }
  }

  valueClick() {
    setTimeout(() => {
      let cont = true;

      [...this.loading].forEach((load) => {
        if (load.style.visibility !== "hidden") {
          cont = false;
        }
      });

      if (cont) {
        this.calculateCounts();
        this.checkForEmpty();
        this.setTabIndex();
      } else {
        this.valueClick();
      }
    }, 500);
  }

  handleKeyPress(type, el, e) {
    switch (type) {
      case "open":
        if (e.which === 13) {
          this.toggleFacet(el);
        }
        if (e.which === 9) {
          [...this.facetHeaders].forEach((h) => {
            if (h !== el) {
              h.classList.remove("open");
            }
          });
        }
        break;
      case "apply":
        if (e.which === 13) {
          this.valueClick(el);
        }
        break;
      default:
        return false;
    }
  }

  applyFacetListeners() {
    let i;

    for (i = 0; i < this.facetHeaders.length; i += 1) {
      this.facetHeaders[i].addEventListener(
        "click",
        this.toggleFacet.bind(this, this.facetHeaders[i])
      );
      this.facetHeaders[i].addEventListener(
        "keyup",
        this.handleKeyPress.bind(this, "open", this.facetHeaders[i])
      );
    }

    for (i = 0; i < this.facetValues.length; i += 1) {
      this.facetValues[i].addEventListener("click", this.valueClick.bind(this));
      this.facetValues[i].addEventListener(
        "keyup",
        this.handleKeyPress.bind(this, "apply", this.facetValues[i])
      );
    }

    this.mobile.addListener(() => {
      if (!this.mobile.matches) {
        const btn = this.root.querySelector(".filter-toggle");

        this.facetContainer.classList.remove("open");
        this.facetContainer.removeAttribute("style");
        btn.textContent = "Filters";
      }
    });
  }

  applyListeners() {
    document
      .querySelector(".CoveoSearchInterface")
      .addEventListener("click", (e) => {
        if (
          e.target.classList.contains("coveo-breadcrumb-clear-all") ||
          e.target.parentElement.classList.contains(
            "coveo-breadcrumb-clear-all"
          )
        ) {
          this.valueClick(this);
        }
      });

    this.tablet.addListener(() => {
      if (this.tablet.matches) {
        this.modulo = 3;
      } else {
        this.modulo = 4;
      }

      this.checkForEmpty();
    });

    document.addEventListener("deferredQuerySuccess", () => {
      const facetExists = this.root.querySelector(".CoveoFacet");

      if (this.topicListing && this.firstQuery && facetExists) {
        this.topicFilter = new TopicFilter(
          this.root.querySelector(".CoveoFacet"),
          {}
        );
        this.firstQuery = false;
      }
    });

    document.addEventListener("clearBreadcrumb", () => {
      this.calculateCounts();
      this.checkForEmpty();
      this.setTabIndex();
    });

    document.addEventListener("newResultDisplayed", (e) => {
      const socialShareEl = e.detail.item.querySelector(".social-share");
      const profileTile = e.detail.item.querySelector(".component.results");
      const mediaLightbox = e.detail.item.querySelector(
        ".video-podcast-lightbox"
      );

      if (socialShareEl) {
        new SocialShare(socialShareEl, {});
      }

      if (profileTile) {
        new Results(profileTile, {});
      }

      if (mediaLightbox) {
        new VideoPodcastLightbox(mediaLightbox, {});
      }

      this.setTabIndex();
    });

    document.addEventListener("keyup", (e) => {
      const facet = e.target.closest(".coveo-facet-column");
      if (e.which === 9 && !facet) {
        if (this.facetHeaders) {
          [...this.facetHeaders].forEach((h) => {
            h.classList.remove("open");
          });
        }
      }
    });
  }
}

const Component = {
  elements: [],
  objects: [],
  init: (className, options = {}) => {
    let i;
    Component.elements = document.querySelectorAll(className);
    for (i = 0; i < Component.elements.length; i += 1) {
      Component.objects.push(
        new CoveoStyling(Component.elements[i], Object.assign({}, options))
      );
    }
  },
};
export default Component;

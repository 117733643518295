import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C207_PopularAuthors.module.scss";
import clsx from "clsx";
import { Author } from "@customTypes/Author";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import Cta from "@components/Cta/Cta";
import Image, { NextImageProps } from "@components/Image/NextImage";
import Link from "@components/Link/Link";
import { useSettings } from "@utilities/context/settings";
import { useBSRContentPages } from "../../context/bsrContentPage";
import { SearchLandingType } from "@customTypes/SearchLandingType";

export interface PopularAuthorsProps {
  authorsHeading?: string;
  authorsList?: Author[];
}

export const PopularAuthors = (props: {
  content: PopularAuthorsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { authorsHeading, authorsList } = content;

  const templateAuthorsHeading = useBSRContentPages().authorsHeading;
  const templateAuthorsList = useBSRContentPages().authorsList;
  const { siteSettings } = useSettings();

  const heading = authorsHeading || templateAuthorsHeading;
  const authors = authorsList || templateAuthorsList;

  const thinkAuthorsPage =
    siteSettings?.searchResultsPagesCollection?.items?.filter(
      ({ type }) => type === SearchLandingType.ThinkAuthors
    )[0];

  return (
    <section className={clsx("component", theme["popular-authors"])}>
      <div className="wrapper">
        {heading && <h2>{heading}</h2>}
        <div className={theme["authors-container"]}>
          {authors &&
            authors.map((author, i) => {
              const {
                articleAuthorName,
                articleAuthorProfileLink,
                articleAuthorDescription,
                articleAuthorImage,
                articleAuthorFreeHtmlSection,
                articleAuthorViewArticlesCtaText,
                articleAuthorFacultyProfile,
              } = author?.fields;

              const profileLink = articleAuthorProfileLink
                ? articleAuthorProfileLink?.fields?.url
                : articleAuthorFacultyProfile?.fields?.slug;

              const title =
                articleAuthorFacultyProfile?.fields?.facultyProfileData?.fields
                  ?.profilePositionTitle ||
                articleAuthorFacultyProfile?.fields.profilePositionTitle;

              const imageProps: NextImageProps = {
                src: articleAuthorImage
                  ? articleAuthorImage.fields.image.fields.file.url
                  : "",
                objectFit: "cover",
                layout: "fill",
                alt: articleAuthorImage
                  ? articleAuthorImage.fields.image.fields.file.title
                  : "",
              };
              const imageAlt = articleAuthorName;
              return (
                <article className={theme.author} key={`popular-author-${i}`}>
                  <div className={theme["wrapper-image"]}>
                    {articleAuthorImage && profileLink ? (
                      <a href={profileLink}>
                        <Image alt={imageAlt} {...imageProps} />
                      </a>
                    ) : (
                      articleAuthorImage && (
                        <Image alt={imageAlt} {...imageProps} />
                      )
                    )}
                  </div>
                  <h3>
                    {profileLink ? (
                      <Link
                        key={`link-${articleAuthorName}`}
                        href={profileLink}
                      >
                        {articleAuthorName}
                      </Link>
                    ) : (
                      articleAuthorName
                    )}
                  </h3>
                  <p className={theme["description-copy"]}>
                    {title ? title : articleAuthorDescription}
                  </p>
                  <div className={clsx("rich-text", theme["text-with-icon"])}>
                    <RichTextDisplay
                      richText={articleAuthorFreeHtmlSection?.fields?.content}
                      tinyMceRichText={
                        articleAuthorFreeHtmlSection?.fields?.contentTinyMce
                      }
                    />
                  </div>
                  <p className={theme["author-footer"]}>
                    <Cta
                      type="tertiary"
                      customClass={clsx(theme.tertiary, theme.cta)}
                      url={`/${thinkAuthorsPage?.url?.slug}?${
                        thinkAuthorsPage?.queryParams
                      }=${author.fields.articleAuthorName.replaceAll(" ", "")}`}
                      icon="icon-arrow"
                      linkText={
                        articleAuthorViewArticlesCtaText || "View articles"
                      }
                    />
                  </p>
                </article>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default themr("PopularAuthors", styles)(PopularAuthors);

import { getBusinessUnit, getPageCategory } from "./getBusinessUnit";
import { getUserType, getUserTypeV2 } from "./getUserType";

// Croud model
export const getGlobalTrackingModel = (
  user: {
    lbsNumber: string;
    userData: { email: string };
  } | null,
  title: string
) => {
  const loggedIn = user;
  return {
    login_status: loggedIn ? "Logged In" : "Logged Out",
    user_id: user ? loggedIn?.lbsNumber : "",
    user_type: loggedIn ? getUserTypeV2(loggedIn.userData.email) : "",
    page_category: getPageCategory(),
    page_canonical_title: title || "",
  };
};

//FreshEgg model
export const getTrackingModel = (
  user: {
    lbsNumber: string;
    userData: { email: string };
  } | null,
  programmeTypeCode: string
) => {
  const loggedIn = user;
  return {
    userId: user ? loggedIn?.lbsNumber : "",
    userType: loggedIn ? getUserType(loggedIn.userData.email) : "",
    loginStatus: loggedIn ? "logged in" : "logged out",
    programmeCode: programmeTypeCode || "",
    businessUnit: getBusinessUnit(),
    event: "page_load",
  };
};

import React, { useEffect, useState, useContext } from "react";
import styles from "./RadioButtons.module.scss";
import { UserContext } from "../../context/user";
import { getProfileContactPreferences } from "../../services/forms";
import getCookie from "@utilities/getCookie";

export interface OptionsProps {
  id: string;
  label: any;
  val: boolean;
}
export interface RadioButtonsProps {
  register: any;
  formState: {
    errors: Object;
  };
  options: {
    name: string;
    options: OptionsProps[];
  };
}

const RadioButtons = (props: RadioButtonsProps) => {
  const { register, options, formState } = props;

  const { user } = useContext(UserContext);
  const [preferences, setPreferences] = useState<any>(null);

  useEffect(() => {
    const getPreferences = async () => {
      if (user) {
        try {
          const emailSubscriptions = await getProfileContactPreferences(
            getCookie("access_token")
          );
          setPreferences(emailSubscriptions?.result?.canContactByEmail);
        } catch (e) {
          console.log(e);
        }
      }
    };
    getPreferences();
  }, [user]);

  return (
    <div className={styles["radio-buttons"]}>
      {options?.options?.map(({ id, label, val }) => (
        <React.Fragment key={`${id}-${label}`}>
          <input
            type="radio"
            id={id}
            name={options.name}
            onClick={() => {
              setPreferences(val);
            }}
            checked={preferences === val}
            value={val}
            {...register(options.name, {
              required: "This field is required",
            })}
          />
          <label htmlFor={id}>{label}</label>
        </React.Fragment>
      ))}
      {formState?.errors?.[options.name] && (
        <div className={styles.error}>
          <p>Please choose an option</p>
        </div>
      )}
    </div>
  );
};

export default RadioButtons;

import { fetchApi } from "@utilities/fetchApi";
import { useEffect, useState } from "react";
import { Author } from "@customTypes/Author";
import { themr } from "@friendsofreactjs/react-css-themr";
import {
  EventTile,
  NewsTile,
  ArticleTile,
} from "@components/C19_20_Tiles/Tiles";

import styles from "./C19.3.1_PromotionalFullWidth.module.scss";

export interface PromotionalFullWidthProps {
  withImage: boolean;
  reducedContent: boolean;
  tags?: { sys: { id: string } }[];
  itemsToShow?: number;
  author: Author;
  excludeArticle: boolean;
  excludeEvent: boolean;
  excludeNews: boolean;
}

const tiles = {
  article: ArticleTile,
  event: EventTile,
  news: NewsTile,
};

const PromotionalRelatedFullWidth = ({
  content,
  theme,
}: {
  content: PromotionalFullWidthProps;
  theme?: any;
}) => {
  const {
    withImage,
    reducedContent,
    tags,
    itemsToShow,
    author,
    excludeArticle,
    excludeEvent,
    excludeNews,
  } = content;
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    const payload: any = {};

    payload.tags = tags?.map((tag) => tag?.sys?.id);
    author && (payload.author = author?.sys?.id);
    itemsToShow && (payload.limit = itemsToShow);
    payload.excludeArticle = Boolean(excludeArticle);
    payload.excludeEvent = Boolean(excludeEvent);
    payload.excludeNews = Boolean(excludeNews);

    const getData = async () => {
      const newsItems = await fetchApi(
        "/api/getNewsOrArticlesOrEvents",
        payload
      );
      setItems(newsItems);
    };

    getData();
  }, []);

  return (
    <div
      className={`component ${theme["promotional-related-content"]} ${
        (itemsToShow === 2 || items.length === 2) && theme.two
      }`}
    >
      <div className={`wrapper ${theme.wrapper}`}>
        {items?.map((item, i) => {
          const Tile = tiles[item.type];
          const itemTags = item.contentfulMetadata?.tags || [];
          return (
            <Tile
              withImage={withImage}
              reducedContent={reducedContent}
              model={item}
              key={`${item.type}${i}`}
              theme={theme}
              tags={itemTags}
            />
          );
        })}
      </div>
    </div>
  );
};

export default themr(
  "PromotionalRelatedFullWidth",
  styles
)(PromotionalRelatedFullWidth);

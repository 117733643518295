import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import {
  ProgrammeDetails,
  RelatedProgrammeDetails,
} from "@customTypes/programmeDetails";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import { useDevice } from "@utilities/react/get-device/get-device";
import styleNames from "@utilities/react/style-names";
import clsx from "clsx";
import Link from "@components/Link/Link";
import { Pagination } from "swiper/modules";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";

import styles from "./C250_RelatedProgrammes.module.scss";
import MissingContent from "@components/MissingContentWarning";
import ErrorBoundary from "@components/ErrorBoundary";
import Image from "@components/Image/Image";

export interface RelatedProgrammesProps {
  title: string;
  image: ImageWrapperType;
  headline: string;
  description: string;
  relatedProgrammes: RelatedProgrammeDetails[];
  BlueVariation?: boolean;
}

export const RelatedProgrammes = (props: {
  content: RelatedProgrammesProps;
  theme?: any;
}) => {
  const { content, theme } = props;

  const {
    image,
    headline,
    title,
    description,
    relatedProgrammes,
    BlueVariation,
  } = content;
  const device = useDevice();
  const { programmeRoute } = useSettings();

  const perPage = device === "mobile" ? 1 : device === "tablet" ? 2 : 3;

  return (
    <div className={styleNames(theme, ["related-courses", image && "image"])}>
      <div className="wrapper">
        <Grid row>
          {image && (
            <div className={styles.picture}>
              <Image
                src={image.fields.image.fields.file.url}
                alt={image.fields.altText || ""}
                width={800}
                height={800}
              />
            </div>
          )}
          <Grid column sm={12}>
            <p className="label">{headline}</p>
          </Grid>
          <Grid column sm={12} md={6}>
            <h2>{title}</h2>
          </Grid>
          <Grid column sm={12} md={6}>
            <p className={theme.large}>{description}</p>
          </Grid>
        </Grid>
        <div className={theme.programmes}>
          <div className={theme.container}>
            <ErrorBoundary>
              <SwiperCarousel
                className={clsx({
                  ["on-dark"]: BlueVariation,
                })}
                spaceBetween={40}
                slidesPerView={perPage}
                slidesPerGroup={perPage}
                loop={true}
                enabled={!["desktop-large", "desktop"].includes(device)}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
              >
                {relatedProgrammes?.map((relatedProgramme, i) => {
                  try {
                    const { slug, programmeTitle, programmeDescription } =
                      relatedProgramme;

                    const url = slug;

                    return (
                      <SwiperSlide key={`${programmeTitle}${i}`}>
                        <div className={theme["programme"]}>
                          <h3>
                            <Link href={url}>
                              <a>{programmeTitle}</a>
                            </Link>
                          </h3>
                          <p>{programmeDescription}</p>
                          <div className={theme["bottom"]}>
                            <p>
                              <Cta
                                type="tertiary"
                                url={url}
                                linkText="Read more"
                                icon="icon-arrow"
                              />
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  } catch (e) {
                    return (
                      <MissingContent
                        error={e}
                        component="C250_RelatedProgrammes.tsx"
                      />
                    );
                  }
                })}
              </SwiperCarousel>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default themr("RelatedProgrammes", styles)(RelatedProgrammes);

import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { GeneralLink } from "@customTypes/generalLink";
import { themr } from "@friendsofreactjs/react-css-themr";
import { fetchApi } from "@utilities/fetchApi";
import RenderComponents from "@utilities/renderComponents";
import clsx from "clsx";
import Head from "next/head";
import { useEffect, useState } from "react";
import Components from "../../__Components__/Components";
import styles from "./DPOPage.module.scss";

export interface dpoSettingsProps {
  keyDetailsHeading?: string;
  fundedByLabel?: string;
  valueLabel?: string;
  openToLabel?: string;
  programmesLabel?: string;
}

export interface DPOPageProps {
  theme?: any;
  headerBanner?: any;
  content: {
    bodyContent?: any;
    dpoTitle: string;
    eligibilityCtaLink: GeneralLink;
    intakeInfo: string;
    eligibilityTitle: string;
    eligibilityDescription: string;
    value: number;
    valueText: string;
    openTo: string;
    shortOpenTo: string;
    openToTinyMce: string;
    programmesText: string;
    nationality: {
      fields: {
        nationality: string;
      };
    }[];
    eligibilityDescriptionTinyMce?: any;
    provider: {
      fields: {
        provider: string;
        providerType: string;
      };
    };
    fundingType: string;
    programmes: {
      fields: {
        basePageTemplate: {
          fields: {
            pageTitle: string;
          };
        };
      };
    }[];
    residency: {
      fields: {
        countryresidency: string;
      };
    }[];
    genders: {
      fields: {
        label: string;
      };
    }[];
    industry: any;
  };
}

export const DPOPage = (props: DPOPageProps) => {
  const { content, theme, headerBanner } = props;
  const {
    bodyContent,
    dpoTitle,
    eligibilityCtaLink,
    intakeInfo,
    eligibilityTitle,
    eligibilityDescriptionTinyMce,
    eligibilityDescription,
    value,
    valueText,
    openTo,
    shortOpenTo,
    openToTinyMce,
    programmesText,
    provider,
    fundingType,
    programmes,
    residency,
    genders,
    industry,
    nationality,
  } = content;

  const [dpoSettings, setDpoSettings] = useState<dpoSettingsProps>({});

  useEffect(() => {
    const getData = async () => {
      const dpoSettingsData = await fetchApi("/api/getDPOSettings");
      setDpoSettings(dpoSettingsData.dpoSettings);
    };
    getData();
  }, []);

  return (
    <>
      <Head>
        {programmes && (
          <meta
            name="dpoprogrammelist"
            content={programmes
              .map((programme, index) => {
                return programme.fields.basePageTemplate.fields.pageTitle;
              })
              .join(";")}
          />
        )}
        {fundingType && <meta name="dpofundingtype" content={fundingType} />}
        {provider?.fields?.provider && (
          <meta name="dpoprovidertype" content={provider.fields.providerType} />
        )}
        {genders && (
          <meta
            name="dpogender"
            content={genders
              .map((gender, index) => {
                return gender.fields.label;
              })
              .join(";")}
          />
        )}
        {nationality?.length && (
          <meta
            name="dponationality"
            content={nationality
              .map((nation, index) => {
                return nation.fields.nationality;
              })
              .join(";")}
          />
        )}
        {residency && (
          <meta
            name="dporesidency"
            content={residency
              .map((country, index) => {
                return country.fields.countryresidency;
              })
              .join(";")}
          />
        )}
        {industry && (
          <meta
            name="dpoindustrylist"
            content={industry
              .map((name, index) => {
                return name.fields.industry;
              })
              .join(";")}
          />
        )}
        {dpoTitle && <meta name="dpotitle" content={dpoTitle} />}
        {valueText && <meta name="dpovaluetext" content={valueText} />}
        {value && <meta name="dpovalue" content={value.toLocaleString()} />}
        {shortOpenTo && <meta name="dpoopento" content={shortOpenTo} />}
      </Head>
      <Grid row>
        <Grid column sm={12}>
          {headerBanner &&
            Components(
              {
                component: headerBanner.sys.contentType,
                uid: headerBanner.sys.id,
                ...headerBanner.fields,
              },
              headerBanner.fields
            )}
          <div className={clsx("component", theme["dpo-key-details"])}>
            <div className="wrapper">
              <Grid row>
                <Grid column sm={8}>
                  <h1 className={clsx(theme["section-title"], theme["h2"])}>
                    {dpoTitle}
                  </h1>
                  <div className={theme["key-details-section"]}>
                    <div className={theme["top-section"]}>
                      <h2 className={theme["sub-title"]}>
                        {dpoSettings?.keyDetailsHeading}
                      </h2>
                    </div>

                    {intakeInfo && <p>{intakeInfo}</p>}
                    {provider && (
                      <p>
                        <strong>{dpoSettings?.fundedByLabel}</strong>{" "}
                        {provider.fields.provider}
                      </p>
                    )}
                    <p>
                      <strong>{dpoSettings?.valueLabel}</strong> {valueText}
                    </p>

                    <p>
                      <strong>{dpoSettings?.openToLabel}</strong>{" "}
                    </p>
                    <RichTextDisplay
                      richText={openTo}
                      tinyMceRichText={openToTinyMce}
                    />
                    <p>
                      <strong>{dpoSettings?.programmesLabel}</strong>{" "}
                      {programmesText}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
            {(eligibilityTitle || eligibilityDescription) && (
              <div className={clsx(theme["outer-wrapper"], theme["blue"])}>
                <div className="wrapper">
                  <Grid row>
                    <Grid column sm={8}>
                      <div className={theme["eligibility-section"]}>
                        <h2 className={theme["sub-title"]}>
                          {eligibilityTitle}
                        </h2>
                        <RichTextDisplay
                          richText={eligibilityDescription}
                          tinyMceRichText={eligibilityDescriptionTinyMce}
                        />
                        {eligibilityCtaLink && (
                          <Cta
                            {...eligibilityCtaLink?.fields}
                            type="tertiary"
                            light
                            icon="icon-external"
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </div>
          {RenderComponents(bodyContent)}
        </Grid>
      </Grid>
    </>
  );
};

export default themr("DPOPage", styles)(DPOPage);

export enum noIndexValues {
  NONE = "none",
  ALL = "all",
  NOINDEX = "noindex",
}

export enum noFollowValues {
  NONE = "none",
  FOLLOW = "follow",
  NOFOLLOW = "nofollow",
}

export const generateNoIndexValue = (
  val: boolean,
  url = "https://www.london.edu"
) => {
  const noindex =
    process.env.NEXT_PUBLIC_URL !== url
      ? noIndexValues.NONE
      : val === false
        ? noIndexValues.ALL
        : noIndexValues.NOINDEX; // only index if the live site and if doNotIndex is not set or set 'no'
  return noindex;
};

export const generateNoFollowValue = (
  val: boolean,
  url = "https://www.london.edu"
) => {
  const nofollow =
    process.env.NEXT_PUBLIC_URL !== url
      ? noFollowValues.NONE
      : val === false
        ? noFollowValues.FOLLOW
        : noFollowValues.NOFOLLOW;
  return nofollow;
};

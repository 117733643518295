export const findMatchingSelectValue = (dataObject, selectedIdValue) => {
  if (!dataObject) return null;
  for (let i = 0; i < dataObject.length; i++) {
    if (dataObject[i].id === selectedIdValue) {
      return dataObject[i].value;
    }
  }
  return null;
};

export const findMatchingSelectId = (dataObject, selectedValue) => {
  for (let i = 0; i < dataObject.length; i++) {
    if (dataObject[i].value === selectedValue) {
      return dataObject[i].id;
    }
  }
  return null;
};

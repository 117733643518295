import { GeneralLink } from "@customTypes/generalLink";
import { NavLinkList, NavLinkType } from "@customTypes/megaNav";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import Link from "@components/Link/Link";
import { useContext } from "react";
import { NavigationStateContext } from "../../context/navigationContext";
import IconChevron from "../../public/image/svg/chevron.svg";
import styles from "./MegaNav.module.scss";
import { navigationGAClick } from "@utilities/navigationGAClick";

function* yieldPrimaryLinkIndex(): Generator<number> {
  let currentIndex = 0;
  while (true) {
    yield currentIndex;
    currentIndex++;
  }
}

const PrimaryLinkList = ({ primaryLinkList, theme }) => {
  const primaryLinkIndex = yieldPrimaryLinkIndex();
  const {
    primaryNavSelectedIndex,
    setPrimaryNavSelectedIndex,
    setPrimaryNavSelected,
    handleReset,
  } = useContext(NavigationStateContext);

  return primaryLinkList?.map((primaryLink, index) => {
    const primaryLinkType = primaryLink?.sys?.contentType?.sys?.id;

    switch (primaryLinkType) {
      case NavLinkType.NavLinkList:
        const primaryNavLinkList = primaryLink as NavLinkList;
        const primaryNavLinkListOwnLink = primaryNavLinkList?.fields?.ownLink;
        const currentPrimaryLinkIndex = primaryLinkIndex?.next()?.value;
        return (
          <li
            className={clsx(
              theme["panel-link"],
              primaryNavSelectedIndex === currentPrimaryLinkIndex &&
                theme.active
            )}
            key={`primary-nav-link-${primaryLinkIndex}-${index}`}
            onMouseEnter={() => {
              setPrimaryNavSelected(true);
              setPrimaryNavSelectedIndex(currentPrimaryLinkIndex);
            }}
          >
            {primaryNavLinkListOwnLink?.fields?.linkText ? (
              <Link href={primaryNavLinkListOwnLink?.fields?.url || "#"}>
                <a
                  onClick={(e) => {
                    navigationGAClick(e);
                    handleReset();
                  }}
                >
                  {primaryNavLinkListOwnLink?.fields?.linkText}
                </a>
              </Link>
            ) : null}
            <IconChevron />
          </li>
        );
      case NavLinkType.GeneralLink:
        const primaryGeneralLink = primaryLink as GeneralLink;
        return (
          <li
            key={`primary-nav-general-link${index}`}
            onMouseEnter={() => setPrimaryNavSelectedIndex(null)}
          >
            {primaryGeneralLink?.fields?.linkText ? (
              <Link href={primaryGeneralLink?.fields?.url || "#"}>
                <a
                  onClick={(e) => {
                    navigationGAClick(e);
                    handleReset();
                  }}
                >
                  {primaryGeneralLink?.fields?.linkText}
                </a>
              </Link>
            ) : null}
          </li>
        );
    }
  });
};

export default themr("PrimaryLinkList", styles)(PrimaryLinkList);

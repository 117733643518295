import Grid from "@components/Grid/Grid";
import { themr } from "@friendsofreactjs/react-css-themr";
import { Pagination } from "swiper/modules";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";
import { useDevice } from "../../utils/react/get-device/get-device";
import styleNames from "../../utils/react/style-names";
import ProgrammeBlock, { Fields } from "./C25_ProgrammeBlock";

import clsx from "clsx";
import styles from "./C25_NavigationManual.module.scss";

export interface ProgrammeBlockData {
  metadata: { tags: string[] };
  fields: Fields;
}

export interface NavigationManualProps {
  programmeBlocks: ProgrammeBlockData[];
  cssClass: "blue" | undefined;
  bulletColor: "white" | undefined;
}

export const NavigationManual = (props: {
  content: NavigationManualProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { programmeBlocks, cssClass, bulletColor } = content;
  const device = useDevice();
  const isDesktop = ["desktop", "desktop-large"].includes(device);
  const fullWidth = programmeBlocks.length === 1;

  return (
    <div
      className={`navigation-manual ${styleNames(theme, [
        "navigation-manual",
        cssClass,
        bulletColor,
        fullWidth && "full-width",
      ])}`}
    >
      <div className="wrapper">
        {programmeBlocks?.length > 2 && !isDesktop ? (
          <SwiperCarousel
            className={clsx(
              theme["swiper"],
              {
                ["on-dark"]: cssClass !== "blue",
              },
              {
                ["bullet-white"]: bulletColor === "white",
              }
            )}
            loop={true}
            pagination={{ clickable: true }}
            modules={[Pagination]}
          >
            {programmeBlocks.map((programmeBlock, index) => {
              return (
                <SwiperSlide key={`${programmeBlock.fields?.title}${index}`}>
                  <ProgrammeBlock
                    fullWidth={fullWidth}
                    {...programmeBlock.fields}
                  />
                </SwiperSlide>
              );
            })}
          </SwiperCarousel>
        ) : (
          <Grid row>
            {programmeBlocks.map((programmeBlock, index) => {
              return (
                <Grid
                  key={`${programmeBlock.fields?.title}${index}`}
                  column
                  sm={12}
                  md={
                    programmeBlocks.length <= 4
                      ? 12 / programmeBlocks.length
                      : 4
                  }
                >
                  <ProgrammeBlock
                    fullWidth={fullWidth}
                    {...programmeBlock.fields}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default themr("NavigationManual", styles)(NavigationManual);

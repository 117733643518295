import { SelectItem } from "./types";
import { findMatchingSelectValue } from "@utilities/mapSelectValue";
import { EventRegistrationForm } from "@customTypes/SiteSettings";
export const permanentFields = [
  "emailAddress",
  "title",
  "firstName",
  "lastName",
];

export const getFormInitialValues = <any>(
  content: EventRegistrationForm,
  userPersonalDetails,
  loggedInUser: boolean,
  titles: SelectItem[],
  watch
) => {
  const {
    emailLabelText,
    firstNameLabelText,
    lastNameLabelText,
    countryOfResidenceLabelText,
    programmesHeadingText,
    titleLabelText,
    passwordLabelText,
    passwordHintText,
    confirmPasswordLabelText,
  } = content;

  return [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      dataText: userPersonalDetails?.result?.emailAddress,
    },
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      options: titles,
      formType: "select",
      labelText: titleLabelText,
      dataText:
        titles &&
        findMatchingSelectValue(titles, userPersonalDetails?.result?.title),
    },

    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
      dataText: userPersonalDetails?.result?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
      dataText: userPersonalDetails?.result?.lastName,
    },
    {
      validation: {
        maxLength: 50,
        isRequired: true,
      },
      propertyName: "homeCountry",
      labelText: countryOfResidenceLabelText,
      validationMsg: "Country of residence is required",
    },
    {
      propertyName: "programmeSection",
      optionalText: false,
      options: null,
      labelText: programmesHeadingText,
      validation: {
        isRequired: true,
      },
    },
    {
      propertyName: "password",
      validation: {
        isRequired: true,
        maxLength: 30,
        password: true,
      },
      type: "password",
      hintText: passwordHintText,
      labelText: passwordLabelText,
      dataText: userPersonalDetails?.password,
      isHidden: loggedInUser,
    },
    {
      propertyName: "confirmPassword",
      validation: {
        isRequired: true,
        maxLength: 30,
        confirmPassword: true,
      },
      disabled: !watch("password"),
      type: "password",
      labelText: confirmPasswordLabelText,
      isHidden: loggedInUser,
    },
  ];
};

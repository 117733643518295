import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { shortCodesToHtml } from "@utilities/formatters/shortCodesToHtml";
import styles from "./FormErrorMessage.module.scss";

export interface FormErrorMessageProps {
  text?: any;
  failedRichText?: any;
  failedTinyMceRichText?: any;
  userEmail?: string;
}

const FormErrorMessage = (props: FormErrorMessageProps) => {
  const { text, failedRichText, failedTinyMceRichText, userEmail = "" } = props;

  return (
    <div className={`${styles["error-message"]} field_error_msg`}>
      {text ? (
        <p
          dangerouslySetInnerHTML={{
            __html: shortCodesToHtml(text, userEmail),
          }}
        />
      ) : (
        <RichTextDisplay
          richText={failedRichText}
          tinyMceRichText={failedTinyMceRichText}
        />
      )}
    </div>
  );
};

export default FormErrorMessage;

/* COMPONENT - C285 Social share 1.0 */
"use strict";

export class SocialShare {
  constructor(element, options) {
    this.root = element;
    this.options = options;
    this.btn = this.root.querySelector(".share-toggle");
    this.shareLinks = this.root.querySelector(".share-links");

    this.applyListeners();
  }

  applyListeners() {
    const body = document.getElementsByTagName("body")[0];

    this.btn.addEventListener("click", (e) => {
      e.preventDefault();
      this.root.classList.toggle("show");
    });

    body.addEventListener(
      "click",
      (e) => {
        if (!this.root.contains(e.target)) {
          this.root.classList.remove("show");
        }
      },
      true
    );
  }
}

const Component = {
  elements: [],
  objects: [],
  init: (className, options = {}) => {
    let i;
    Component.elements = document.querySelectorAll(className);
    for (i = 0; i < Component.elements.length; i += 1) {
      Component.objects.push(
        new SocialShare(Component.elements[i], Object.assign({}, options))
      );
    }
  },
};
export default Component;

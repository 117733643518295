import Grid from "@components/Grid/Grid";
import ImageWrapper from "@components/Image/ImageWrapper";
import { BSRArticleType } from "@customTypes/BSRArticle";
import { EventDetail } from "@customTypes/EventDetail";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import { parseISO } from "date-fns";
import { useSettings } from "@utilities/context/settings";

import styles from "./C281_EditorsChoice.module.scss";
import EditorsChoiceMedia, {
  EditorsChoiceMediaType,
} from "./EditorsChoiceMedia";
import EditorsChoiceTile, {
  EditorsChoiceTileType,
  TileSize,
} from "./EditorsChoiceTile";

export interface EditorsChoiceProps {
  headerTitle?: string;
  headerDescription?: string;
  primaryArticle: BSRArticleType | EventDetail;
  secondaryArticle: BSRArticleType | EventDetail;
  tertiaryArticles: (BSRArticleType | EventDetail)[];
  editorsChoicePrimaryArticleImage: any;
  editorsChoiceSecondaryArticleImage: any;
}

const EditorsChoice = (props: { content: EditorsChoiceProps; theme?: any }) => {
  const { content, theme } = props;
  const {
    headerTitle,
    headerDescription,
    primaryArticle,
    secondaryArticle,
    tertiaryArticles,
    editorsChoicePrimaryArticleImage,
    editorsChoiceSecondaryArticleImage,
  } = content;

  const { allTags } = useSettings();

  function getTagData(pageTags, allTags) {
    const tagsCollection = pageTags?.map((tag) => {
      const name = allTags?.find(
        (t) => t.id.toLocaleLowerCase() === tag.sys.id.toLocaleLowerCase()
      )?.name;
      return {
        displayNameAndParamValue: name?.split(":")[1],
        id: tag?.sys?.id,
        queryParameter: name?.split(":")[0].toLocaleLowerCase(),
      };
    });

    return tagsCollection;
  }

  const formatBSRArticle = (
    article: BSRArticleType,
    articleImageObject?: any
  ): EditorsChoiceTileType => {
    const {
      articleTitle,
      articlePublishedDate,
      slug,
      shortDescription,
      articleDescription,
      articleImage,
      authorsList,
      youTubeVideoId,
      vimeoVideoId,
      soundcloudPodcastId,
    } = article.fields;

    const tags = getTagData(article.metadata.tags, allTags);

    return {
      title: articleTitle,
      tags: tags ?? [],
      date: parseISO(articlePublishedDate),
      linkUrl: slug,
      description: shortDescription ?? articleDescription,
      isEvent: false,
      image:
        articleImageObject != undefined ? articleImageObject : articleImage,
      authors: authorsList?.length
        ? authorsList.map(({ fields }) => fields.articleAuthorName)
        : undefined,
      mediaToRender:
        youTubeVideoId || vimeoVideoId
          ? EditorsChoiceMediaType.VIDEO
          : !!soundcloudPodcastId
          ? EditorsChoiceMediaType.PODCAST
          : undefined,
    };
  };

  const formatEventDetail = (event: EventDetail): EditorsChoiceTileType => ({
    title: event.fields.eventTitle,
    date: parseISO(event.fields.eventStarts),
    linkUrl: event.fields.slug,
    description:
      event.fields.eventShortDescription ?? event.fields.eventLongDescription,
    isEvent: true,
    image: !!event.fields.eventTopImage
      ? {
          fields: {
            image: event.fields.eventTopImage,
          },
        }
      : undefined,
    authors: event.fields.eventOrganisedByList,
  });

  const formatData = (
    articleOrEvent: BSRArticleType | EventDetail,
    articleImageObject?: any
  ) => {
    return articleOrEvent.sys.contentType.sys.id === "bsrContentPage"
      ? formatBSRArticle(articleOrEvent as BSRArticleType, articleImageObject)
      : formatEventDetail(articleOrEvent as EventDetail);
  };

  const largeTileViewModel = formatData(
    primaryArticle,
    editorsChoicePrimaryArticleImage
  );
  const smallTileUpperViewModel = formatData(tertiaryArticles[0]);
  const smallTileLowerViewModel = formatData(tertiaryArticles[1]);
  const mediumTileViewModel = formatData(
    secondaryArticle,
    editorsChoiceSecondaryArticleImage
  );

  return (
    <div className="wrapper">
      <div className={clsx("component", theme["editors-choice"])}>
        {headerTitle && (
          <div className={theme["editors-choice--header"]}>
            <h4 className={theme["title-header"]}>{headerTitle}</h4>
            {headerDescription && <p>{headerDescription}</p>}
          </div>
        )}

        <Grid row>
          <Grid column sm={12} md={8} customClass={theme["left-col"]}>
            <div className={theme["editors-choice--image"]}>
              <div className={theme["editor-choice-tile"]}>
                <div className={theme["tile-image"]}>
                  {/* TODO: Update this to allow mobile and desktop image sizes */}
                  {!!largeTileViewModel.image && (
                    <ImageWrapper image={largeTileViewModel.image} />
                  )}
                </div>
                <EditorsChoiceMedia
                  mediaToRender={largeTileViewModel.mediaToRender}
                  size={TileSize.LARGE}
                />
              </div>
            </div>
          </Grid>

          <Grid column sm={12} md={4} customClass={theme["right-col"]}>
            <div
              className={clsx(
                theme["editors-choice--small"],
                theme["desktop-only"]
              )}
            >
              <div className={theme["editor-choice-tile"]}>
                <EditorsChoiceTile
                  tile={smallTileUpperViewModel}
                  size={TileSize.SMALL}
                />
              </div>
            </div>
            <div className={theme["editors-choice--small"]}>
              <div className={theme["editor-choice-tile"]}>
                <EditorsChoiceTile
                  tile={smallTileLowerViewModel}
                  size={TileSize.SMALL}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid row>
          <Grid column sm={12} md={8} customClass={theme["left-col"]}>
            <div
              className={clsx(
                theme["editors-choice--image"],
                theme["mobile-only"]
              )}
            >
              <div className={theme["editor-choice-tile"]}>
                <div className={theme["tile-image"]}>
                  <EditorsChoiceMedia
                    mediaToRender={largeTileViewModel.mediaToRender}
                    size={TileSize.LARGE}
                  />
                  {/* TODO: Check Image Rendering */}
                  {largeTileViewModel.image && (
                    <ImageWrapper image={largeTileViewModel.image} />
                  )}
                </div>
              </div>
            </div>

            <div className={theme["editors-choice--large"]}>
              <div className={theme["editor-choice-tile"]}>
                <EditorsChoiceTile
                  tile={largeTileViewModel}
                  size={TileSize.LARGE}
                />
              </div>
            </div>
          </Grid>

          <Grid column sm={12} md={4} customClass={theme["right-col"]}>
            <div
              className={clsx(
                theme["editors-choice--small"],
                theme["mobile-only"]
              )}
            >
              <div className={theme["editor-choice-tile"]}>
                <EditorsChoiceTile
                  tile={smallTileUpperViewModel}
                  size={TileSize.SMALL}
                />
              </div>
            </div>
            <div className={theme["editors-choice--medium"]}>
              <div className={clsx(theme["editor-choice-tile"], theme["blue"])}>
                <div className={theme["tile-image"]}>
                  <EditorsChoiceMedia
                    mediaToRender={mediumTileViewModel.mediaToRender}
                    size={TileSize.MEDIUM}
                  />
                  {/* TODO: Check Image Rendering */}
                  {mediumTileViewModel.image && (
                    <ImageWrapper image={mediumTileViewModel.image} />
                  )}
                </div>
                <EditorsChoiceTile
                  tile={mediumTileViewModel}
                  size={TileSize.MEDIUM}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default themr("EditorsChoice", styles)(EditorsChoice);

import { FC, AnchorHTMLAttributes } from "react";
import NextLink, { LinkProps } from "next/link";

const Link: FC<LinkProps & AnchorHTMLAttributes<any>> = (props) => {
  const { children } = props;
  return (
    <NextLink {...props} prefetch={false} legacyBehavior>
      {children}
    </NextLink>
  );
};

export default Link;

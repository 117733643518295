import { themr } from "@friendsofreactjs/react-css-themr";
import RenderComponents from "@utilities/renderComponents";
import slugify from "@utilities/slugify";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Grid from "@components/Grid/Grid";

import styles from "./C248_TabbedContent.module.scss";

export interface TabItem {
  fields: {
    title: string;
    content: any;
  };
}

export interface TabbedContentProps {
  pageTitle: string;
  title: string;
  description: string;
  tabs: TabItem[];
  anchorKey?: string;
  blueBackground?: boolean;
}

export const TabbedContent = (props: {
  content: TabbedContentProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { pageTitle, title, description, tabs, blueBackground, anchorKey } =
    content;
  const [tabIndex, setTabIndex] = useState(0);
  const [didMount, setDidMount] = useState(false);
  const tabsRef = useRef<HTMLElement>(null);
  const { asPath, replace } = useRouter();

  useEffect(() => {
    if (!didMount) {
      setDidMount(true);
      let id = /(#)([a-z]+[\w\-\.]*)$/gi.exec(asPath);

      if (!id) return;

      const tabIndex = tabs.findIndex(
        (tab) => slugify(tab.fields.title) === id![2]
      );

      if (tabIndex === -1) return;

      setTabIndex(tabIndex);

      if (tabsRef) tabsRef.current?.scrollIntoView();
      return;
    }

    replace({
      hash: slugify(tabs[tabIndex].fields.title),
    });
  }, [tabIndex]);

  return (
    <div
      className={clsx("component", theme["programme-benefits"], {
        [theme["blue"]]: blueBackground,
      })}
    >
      <div className="wrapper">
        <div className={theme["heading-section"]}>
          <h2 className={theme["small-top-heading"]}>{pageTitle}</h2>
          <div className={theme["main-heading-with-intro"]}>
            <Grid row>
              <Grid column md={6}>
                <h2 id={anchorKey} tabIndex={0}>
                  {title}
                </h2>
              </Grid>
              <Grid column md={6}>
                <p className={styles.paddingTop}>{description}</p>
              </Grid>
            </Grid>
          </div>
        </div>
        <section ref={tabsRef} className={clsx(theme["tabs"], "cf")}>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              {tabs.map(({ fields: { title } }, index) => (
                <Tab key={`${title}${index}`}>{title}</Tab>
              ))}
            </TabList>

            {tabs.map(({ fields: { content } }, index) => (
              <TabPanel key={`${content}${index}`}>
                {RenderComponents(content)}
              </TabPanel>
            ))}
          </Tabs>
        </section>
      </div>
    </div>
  );
};

export default themr("TabbedContent", styles)(TabbedContent);

import { CourseTypeSettings } from "@customTypes/CourseTypeSettings";
import {
  ErrorMessagesTypes,
  HandledSubmitFormError,
} from "@customTypes/ErrorMessagesTypes";
import { ProgrammeSettings } from "@customTypes/ProgrammeSettings";
import { ProgrammeType } from "@customTypes/ProgrammeType";
import { PublicationSettings } from "@customTypes/PublicationSettings";
import { SiteSettings, Tags } from "@customTypes/SiteSettings";
import { createContext, ReactNode, useContext } from "react";
import { LOGOUT_URL } from "../../constants";

interface SettingsContextProps {
  programmeSettings: ProgrammeSettings;
  programmeRoute: (type: ProgrammeType, slug: string) => string;
  siteSettings?: SiteSettings;
  publicationSettings?: PublicationSettings;
  courseTypeSettings?: CourseTypeSettings;
  currentPageContext?: string;
  apiErrorMessages?: Array<HandledSubmitFormError>;
  allTags?: Array<Tags>;
}

interface SettingsProviderProps {
  children: ReactNode;
  settings: {
    programmeSettings: ProgrammeSettings;
    siteSettings: SiteSettings;
    publicationSettings: PublicationSettings;
    courseTypeSettings: CourseTypeSettings;
    globalErrorMessages: ErrorMessagesTypes;
    apiErrorMessages: ErrorMessagesTypes;
    currentPageContext: string;
    allTags: Tags[];
  };
}

export const SettingsContext = createContext<SettingsContextProps>({
  courseTypeSettings: { courses: [] },
  programmeSettings: {},
  programmeRoute: (type, slug) => {
    return "";
  },
});

export const SettingsProvider = ({
  children,
  settings,
}: SettingsProviderProps) => {
  const {
    programmeSettings,
    siteSettings,
    publicationSettings,
    courseTypeSettings,
    globalErrorMessages,
    currentPageContext,
    allTags,
  } = settings;

  const programmeRoute = (type: ProgrammeType, slug: string): string => {
    const rootSlug =
      type === ProgrammeType.DE
        ? slug.includes(programmeSettings.deRoot as string)
          ? ""
          : programmeSettings.deRoot
        : slug.includes(programmeSettings.eeRoot as string)
        ? ""
        : programmeSettings.eeRoot;

    return [rootSlug, slug].filter(Boolean).join("/");
  };

  const apiErrorMessages =
    globalErrorMessages?.items[0]?.messagesCollection?.items;
  siteSettings.LogOutFuncLink = LOGOUT_URL;
  if (siteSettings.globalSocialShareCollection.items) {
    const formattedSocialShareIcons =
      siteSettings?.globalSocialShareCollection?.items?.map((item) => {
        return {
          fields: {
            darkIcon: {
              fields: {
                file: {
                  url: item.darkIcon.url,
                },
              },
            },
            lightIcon: {
              fields: {
                file: {
                  url: item.darkIcon.url,
                },
              },
            },
            shareLink: item.shareLink,
          },
        };
      });

    siteSettings.globalSocialShareCollection = formattedSocialShareIcons;
  }

  return (
    <SettingsContext.Provider
      value={{
        programmeSettings,
        siteSettings,
        publicationSettings,
        courseTypeSettings,
        programmeRoute,
        apiErrorMessages,
        currentPageContext,
        allTags,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);

export const settingsMock = {
  programmeSettings: {
    deRoot: "masters-degrees",
    eeRoot: "executive-education",
    programmeReasonsLabel: "Reasons to choose this programme",
    programmeApplyCtaLabel: "Apply now",
    programmeAlumniStatusText: "Alumni status",
    programmeContentLabel: "Programme Content",
    entryRequirementsLabel: "Entry Requirements",
    keyDifferentiatorsLabel: "Key Differentiators",
    scholarshipsLabel: "Scholarships",
    averageWorkExperienceInClassLabel: null,
    classProfileLabel: null,
    programmeContent: "Programme content",
    entryRequirements: "Entry requirements",
    keyDifferentiators: "Key differentiators ",
    scholarships: null,
    averageWorkExperienceInClass: null,
    classProfile: null,
    programmeFeaturesLabel: "Features",
    programmeInfoCtaLabel: "Find out more",
    programmeDurationLabel: "Duration",
    programmeLocationLabel: "Location",
    programmeFeesLabel: "Fees",
    programmeKeyTakeAwaysLabel: "Key take-aways",
    programmeWhoAttendsLabel: "Who attends?",
    programmeTestimonialLabel: "Testimonial",
    programmeSeniorityLabel: "Seniority",
    programmeNextStartDateLabel: "Next start date",
    programmeDeliveryLabel: "Delivery",
    programmeComparisonOverlayText: "Select up to 4 programmes to compare",
    programmeComparisonOverlayMoreText: "Select up to 4 programmes",
    programmeComparisonOverlayOneMoreText: "Select one more to compare",
    programmeComparisonOverlayCtaText: "Compare",
    programmeComparisonOverlayCtaLinkEe: {
      slug: "/charlie-test-page-ee",
    },
    programmeComparisonOverlayCtaLinkDe: {
      slug: "/charlie-test-page",
    },
    fallbackProgrammeIterationDuration: "To be confirmed",
    fallbackProgrammeIterationFee: "To be confirmed",
    noProgrammeDateAvailableMessage: "To be confirmed",
    fallbackProgrammeIterationLocation: "To be confirmed",
    programmeCareerStageDropDownLabel: "Filter by experience",
    programmeNextStartDateShortLabel: "Next",
    programmeSearchInputText: "Find the right programme for you",
  },
  siteSettings: {
    programmeCalendar: {
      title: "IK Vimeo Sample",
      description: "",
      url: "https://videos.ctfassets.net/hxo16fanegqh/5GSycvp8G2v5QpOwyLxC6O/e148cafde12d57f1a51b927dedc35ca4/sample_vimeo_video__720p_.mp4",
      fileName: "sample_vimeo_video (720p).mp4",
    },
    title: "Site settings",
    robotsConfig: "User-agent: *\nDisallow: /",
    defaultSeoImage: {
      title: "Lbs Logo Wide",
      description: "",
      contentType: "image/webp",
      fileName: "logo_wide-600x315.webp",
      size: 5152,
      url: "https://images.ctfassets.net/qp026pq0h7c4/6NSkb9eWyf72EXwG9ZJffw/8caedaf4a98e970281d97e47eae21bc0/logo_wide-600x315.webp",
      width: 600,
      height: 315,
    },
    twitterHandle: "@LBS",
    siteName: "London Business School",
    genericApiError: "An Error Has Occured",
    searchResultsPagesCollection: {
      items: [
        {
          type: "News",
          queryParams: "home",
          url: {
            __typename: "EeLandingPage",
            slug: "home",
          },
        },
      ],
    },
    globalSocialShareCollection: [
      {
        fields: {
          darkIcon: {
            fields: {
              file: {
                url: "https://images.ctfassets.net/hxo16fanegqh/1qSnDSeabecdUjFStVsnDi/a730904660dbef70afda0825bb54fed7/facebook-dark.svg",
              },
            },
          },
          lightIcon: {
            fields: {
              file: {
                url: "https://images.ctfassets.net/hxo16fanegqh/1qSnDSeabecdUjFStVsnDi/a730904660dbef70afda0825bb54fed7/facebook-dark.svg",
              },
            },
          },
          shareLink: "https://www.facebook.com/sharer/sharer.php?u=%URL%",
        },
      },
      {
        fields: {
          darkIcon: {
            fields: {
              file: {
                url: "https://images.ctfassets.net/hxo16fanegqh/6332RbRxrmPtV8fmgvrUK6/9881a0b45e2e8f1f89d4d96a6adbe9ff/linkedin-dark.svg",
              },
            },
          },
          lightIcon: {
            fields: {
              file: {
                url: "https://images.ctfassets.net/hxo16fanegqh/6332RbRxrmPtV8fmgvrUK6/9881a0b45e2e8f1f89d4d96a6adbe9ff/linkedin-dark.svg",
              },
            },
          },
          shareLink:
            "https://www.linkedin.com/sharing/share-offsite/?url=%URL%",
        },
      },
      {
        fields: {
          darkIcon: {
            fields: {
              file: {
                url: "https://images.ctfassets.net/hxo16fanegqh/3Kq1VuRX7ORBem2GuQKiBn/86f20a66492723aa221520697795fd18/twitter-dark.svg",
              },
            },
          },
          lightIcon: {
            fields: {
              file: {
                url: "https://images.ctfassets.net/hxo16fanegqh/3Kq1VuRX7ORBem2GuQKiBn/86f20a66492723aa221520697795fd18/twitter-dark.svg",
              },
            },
          },
          shareLink: "https://twitter.com/intent/tweet?url=%URL%&via=LBS",
        },
      },
    ],
    embagGlobalHeaderCollection: {
      items: [
        {
          logo: {
            title: "LBS Logo",
            url: "https://images.ctfassets.net/hxo16fanegqh/4lHQXAn2I4rqN1qjdnTNVz/04ac9b782116a59730c4a55f1e266949/lbs-logo.webp",
          },
          link: "/",
        },
        {
          logo: {
            title: "CBS Logo",
            url: "https://images.ctfassets.net/hxo16fanegqh/7F0fWnUiZ9IO6SF7tjbP37/7561a0ef75ba13dc90f763078867f651/cbs-logo.png",
          },
          link: "http://www8.gsb.columbia.edu/",
        },
        {
          logo: {
            title: "LBS Logo",
            url: "https://images.ctfassets.net/hxo16fanegqh/4lHQXAn2I4rqN1qjdnTNVz/04ac9b782116a59730c4a55f1e266949/lbs-logo.webp",
          },
          link: "/hku-bs-link",
        },
      ],
    },
    privacyAndPolicyTextItems: {
      privacyTextData: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    "London Business School takes your privacy very seriously. We may process your personal information for carefully considered and specific purposes which are in our interests and enable us to enhance the services we provide, but which we believe also benefit our customers. View our ",
                  nodeType: "text",
                },
                {
                  data: {
                    uri: "https://www.london.edu/about/london-business-school/policies-and-legislation/website-privacy-policy",
                  },
                  content: [
                    {
                      data: {},
                      marks: [
                        {
                          type: "underline",
                        },
                      ],
                      value: "Privacy Policy",
                      nodeType: "text",
                    },
                  ],
                  nodeType: "hyperlink",
                },
                {
                  data: {},
                  marks: [],
                  value:
                    " to learn more about these interests and when we may process your information in this way.",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
      },
      stayInformedText: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    "We'd love to keep you up to date with the latest big ideas and business insights from our world-renowned faculty as well as news of courses and events that could boost your career. Would you like to receive emails from LBS?",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
      },
      errorAccountExistsUnverified: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    "Yes please, I'd like to receive emails containing the latest thought leadership, invitations to events and news about courses that could ",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  value: "enhance my career",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [],
                  value: ".",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
      },
      infoUserAccountAlreadyExist: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    "Yes please, I'd like to receive emails containing the latest thought leadership, invitations to events and news about courses that could ",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  value: "enhance my career",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [],
                  value: ".",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
      },
      errorAccountExistsDeactivated: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    "Yes please, I'd like to receive emails containing the latest thought leadership, invitations to events and news about courses that could ",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  value: "enhance my career",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [],
                  value: ".",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
      },
      stayInformRadioYes: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    "Yes please, I'd like to receive emails containing the latest thought leadership, invitations to events and news about courses that could ",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  value: "enhance my career",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [],
                  value: ".",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
      },
      stayInformRadioNo: {
        json: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "paragraph",
              data: {},
              content: [
                {
                  nodeType: "text",
                  value: "No. I do not wish to receive emails from LBS.",
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
      },
      emailOptOutText: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  value: "You can opt-out of receiving our emails at any time",
                  nodeType: "text",
                },
                {
                  data: {},
                  marks: [],
                  value: " by visiting: ",
                  nodeType: "text",
                },
                {
                  data: {
                    uri: "https://www.london.edu/my-profile-preferences",
                  },
                  content: [
                    {
                      data: {},
                      marks: [
                        {
                          type: "underline",
                        },
                      ],
                      value: "www.london.edu/my-profile-preferences",
                      nodeType: "text",
                    },
                  ],
                  nodeType: "hyperlink",
                },
                {
                  data: {},
                  marks: [],
                  value:
                    " or by unsubscribing through the link provided in our emails. See our ",
                  nodeType: "text",
                },
                {
                  data: {
                    uri: "https://www.london.edu/about/london-business-school/policies-and-legislation/website-privacy-policy",
                  },
                  content: [
                    {
                      data: {},
                      marks: [
                        {
                          type: "underline",
                        },
                      ],
                      value: "Privacy Policy",
                      nodeType: "text",
                    },
                  ],
                  nodeType: "hyperlink",
                },
                {
                  data: {},
                  marks: [],
                  value: " for more information on your rights.",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
      },
    },
    scholarshipFundingUrl: "funding-aid",
    brandIconTitleText: "Think - AT LONDON BUSINESS SCHOOL",
    programmeCtaPagesCollection: {
      items: [
        {
          type: "Apply",
          queryParams: "programmecode",
          url: {
            __typename: "Content2019Page",
            slug: "apply",
          },
        },
        {
          type: "Reserve A Place",
          queryParams: "reserve-a-place",
          url: {
            __typename: "Content2019Page",
            slug: "reserve-a-place",
          },
        },
      ],
    },
  },
  publicationSettings: {
    publicationsArticleFinderUrl:
      "http://ra5jz3az8r.search.serialssolutions.com/",
    publicationsDownloadBaseUrl: "http://facultyresearch.london.edu/docs/",
  },
  courseTypeSettings: {
    courses: [
      {
        courseTypeTitle: "PhD courses",
        courseTypeDescription: "The first step to academic excellence.",
        courseTypeCode: "PHD",
        accordionText: "View courses",
        courseProgrammeHeaderText: null,
        courseFacultyHeaderText: null,
      },
      {
        courseTypeTitle: "Executive Education",
        courseTypeDescription:
          "Short programmes offering academic excellence, global focus and exceptional diversity of perspective.",
        courseTypeCode: "EE",
        accordionText: "View programmes",
        courseProgrammeHeaderText: null,
        courseFacultyHeaderText: "FACULTY IN THIS SUBJECT AREA",
      },
      {
        courseTypeTitle: "Master Degree Core electives",
        courseTypeDescription:
          "Optional courses providing a deep dive into specialist areas.",
        courseTypeCode: "Elective",
        accordionText: "View electives",
        courseProgrammeHeaderText: "PROGRAMMES WITH THIS ELECTIVE",
        courseFacultyHeaderText: "ELECTIVE CURRENTLY TAUGHT BY",
      },
      {
        courseTypeTitle: "Master Degree Core Courses",
        courseTypeDescription:
          "A key part of our Masters programmes curriculum.",
        courseTypeCode: "Core",
        accordionText: "View courses",
        courseProgrammeHeaderText: "PROGRAMMES WITH THIS CORE COURSE",
        courseFacultyHeaderText: "CORE COURSE CURRENTLY TAUGHT BY",
      },
    ],
  },
  currentPageId: "32u8xvMIJVT8vh1HxzZZlC",
};

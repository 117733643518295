import { SiteSettings } from "@customTypes/SiteSettings";
import { Results } from "../CoveoSearchComponent";
import Image from "@components/Image/NextImage";
import { SocialShare } from "@components/C285_SocialShare/C285_SocialShare";

interface BsrResultsTemplateProps {
  siteSettings?: SiteSettings;
  result: Results;
}

export const BsrResultsTemplate = (props: BsrResultsTemplateProps) => {
  const { result, siteSettings } = props;
  const dateString = result?.raw?.articledatetext || "";

  function getSlugByType(resultPageCollection, type) {
    const item = resultPageCollection.items.find((item) => item.type === type);
    return item;
  }

  const linkData =
    siteSettings &&
    getSlugByType(
      siteSettings?.searchResultsPagesCollection,
      "think-articletopics"
    );

  return (
    <>
      <div>
        {result?.raw?.articleimageurl &&
          !result?.raw?.articleimageurl.includes("undefined") && (
            <div className="wrapperImage">
              <a className="imageLink" href={result.clickUri}>
                <Image
                  src={result.raw.articleimageurl}
                  alt=""
                  width={896}
                  height={504}
                />
              </a>
            </div>
          )}
        <div className="text1">
          <p className="primary-tag">Think at London Business School</p>
          <h2>
            <a className="coveo-link" href={result.clickUri}>
              {result?.raw?.articletitle && (
                <h3 className="h3">{result?.raw?.articletitle}</h3>
              )}
            </a>
          </h2>
          <p className="sub-heading medium-light">{dateString}</p>
          <p
            className="CoveoFieldValue copy medium-light"
            data-field="@shortdescription"
          >
            <span>{result.raw.pagedescription}</span>
          </p>
          {result.raw.articleauthorsnames && (
            <p
              className="CoveoFieldValue info-field"
              data-field="@articleauthorsnames"
            >
              By
              <span className="authors">
                {result?.raw?.articleauthorsnames
                  .map((author) => author)
                  .join(", ")}
              </span>
            </p>
          )}
          <footer>
            {result.raw.taz120xonomytopics &&
              result.raw.taz120xonomytopics.map((topic, i) => (
                <div key={`${topic}${i}`} className="component tag cf">
                  <a
                    href={`/${linkData?.url?.slug}?${topic
                      ?.split(":")[0]
                      .toLowerCase()}=${topic?.split(":")[1]}`}
                  >
                    {topic?.split(":")[1]}
                  </a>
                </div>
              ))}
            <SocialShare url={result.clickUri} />
          </footer>
        </div>
      </div>
    </>
  );
};

export default BsrResultsTemplate;

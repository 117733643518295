import Components from "../__Components__/Components";

import styles from "./News.module.scss";

export const News = ({ content }) => {
  const { bodySection } = content;
  return (
    <main className="site-content">
      <div className={styles.wrapper}>
        <h2>{content.title}</h2>
      </div>
      {bodySection?.map((block: any) => {
        const component = {
          component: block.sys.contentType.sys.id,
          uid: block.sys.id,
          ...block.fields,
        };
        return Components(component, block.fields);
      })}
    </main>
  );
};

export default News;

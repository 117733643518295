import React from "react";
import Columns from "./Columns";
import Sublayout7025 from "./Sublayout7025";
import Sublayout7030 from "./Sublayout7030";
import SublayoutWhiteAndBlue from "./SublayoutWhiteAndBlue";
import Grid from "@components/Grid/Grid";

export interface SublayoutProps {
  columns: any;
  type?: string;
  backgroundColour?: string;
}

const Sublayout = (props: { content: SublayoutProps }) => {
  const { columns, type, backgroundColour } = props.content;

  return (
    <>
      {!type && (
        <div
          className={`sublayout outer-wrap ${
            backgroundColour ? backgroundColour : ""
          }`}
        >
          <div className="wrapper">
            <Grid row>
              <Columns columns={columns} />
            </Grid>
          </div>
        </div>
      )}

      {type === "White and Blue" && <SublayoutWhiteAndBlue columns={columns} />}

      {type === "70 / 30 Divider" && (
        <Sublayout7030 columns={columns} backgroundColour={backgroundColour} />
      )}

      {type === "70 / 25 Divider" && (
        <Sublayout7025 columns={columns} backgroundColour={backgroundColour} />
      )}
    </>
  );
};

export default Sublayout;

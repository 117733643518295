import IconDownload from "../../../public/image/svg/download.svg";

const AttachmentsSection = (props: { theme?: any; attachments: any[] }) => {
  const { theme, attachments } = props;

  return (
    <ul className={theme["download-list"]}>
      {attachments &&
        attachments.length > 0 &&
        attachments.map((attachment, index) => (
          <li key={`attachment-${index}`}>
            <i className={theme["icon-download"]}>
              <IconDownload />
            </i>
            {/* TODO: Update link to download media */}
            <a href={attachment?.url}>{attachment?.title}</a>
          </li>
        ))}
    </ul>
  );
};

export default AttachmentsSection;

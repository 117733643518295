import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./MyProfileTabItems.module.scss";
import React from "react";
import Link from "@components/Link/Link";
import clsx from "clsx";
import Grid from "@components/Grid/Grid";
import { useRouter } from "next/router";
import RenderComponents from "@utilities/renderComponents";
import { GeneralLink } from "@customTypes/generalLink";

interface MyProfileTabItemsProps {
  myProfileTabItem: {
    fields: {
      tabItem: GeneralLink;
      tabContent?: any;
    };
  }[];
}

export const MyProfileTabItems = (props: {
  theme?: any;
  content: MyProfileTabItemsProps;
}) => {
  const { query } = useRouter();
  const pageUrl = query.slug || [];

  const { content, theme } = props;
  const { myProfileTabItem } = content;
  const activeIndex = myProfileTabItem.find(
    ({
      fields: {
        tabItem: {
          fields: { url },
        },
      },
    }) => url === `/${pageUrl[0]}`
  );

  return (
    <div className="wrapper">
      <section
        className={clsx(
          "component",
          theme.tabs,
          theme["tabs--my-profile"],
          theme["js-tabs"],
          "cf"
        )}
      >
        <Grid row>
          <Grid column sm={12} md={8}>
            <ul className={theme["js-tablist"]} data-hx="h2">
              {myProfileTabItem.map(
                (
                  {
                    fields: {
                      tabItem: {
                        fields: { linkText, url },
                      },
                    },
                  },
                  i
                ) => (
                  <li key={`${url}${i}`} className={theme["js-tablist-item"]}>
                    <Link passHref href={url || "#"}>
                      <a
                        className={clsx(
                          theme["js-tablist-link"],
                          theme["tabs-link"]
                        )}
                        aria-selected={
                          url === `/${pageUrl[0]}` ? "true" : "false"
                        }
                      >
                        {linkText}
                      </a>
                    </Link>
                  </li>
                )
              )}
            </ul>
          </Grid>
        </Grid>
        <div className={theme["js-tabcontent"]} tabIndex={0}>
          {activeIndex && RenderComponents(activeIndex.fields.tabContent)}
        </div>
      </section>
    </div>
  );
};

export default themr("MyProfileTabItems", styles)(MyProfileTabItems);

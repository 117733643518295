import Grid from "@components/Grid/Grid";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import { Fragment, MouseEvent, useState } from "react";
import IconCross from "../../public/image/svg/cross.svg";
import { useSettings } from "@utilities/context/settings";
import styles from "./C203_PopularTopics.module.scss";
import { determinePageType } from "./topicConfig";

export interface PopularTopicsProps {
  heading: string;
  topics: string[];
  viewAllLabel: string;
  display: string;
}

const PopularTopics = (props: { content: PopularTopicsProps; theme?: any }) => {
  const { content, theme } = props;
  const { heading, topics, viewAllLabel, display } = content;
  const [open, setOpen] = useState(false);

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    setOpen(true);
  };
  console.log(display);
  const { siteSettings } = useSettings();

  function getSlugByType(resultPageCollection, topic) {
    const type = determinePageType(topic);
    const item = resultPageCollection.items.find((item) => item.type === type);
    return item;
  }

  const linkData = (topic) => {
    return (
      siteSettings &&
      getSlugByType(siteSettings?.searchResultsPagesCollection, topic)
    );
  };

  const currentUrl =
    typeof window !== "undefined"
      ? window.location.origin + window.location.pathname
      : "";

  return (
    <section className={clsx("component", theme["popular-topics"])}>
      <div className="wrapper">
        <Grid row>
          <Grid column sm={12} md={3}>
            <div className={theme["popular-topic"]}>
              <h2>{heading}</h2>
            </div>
          </Grid>
          <Grid column sm={12} md={9}>
            <div className={theme["popular-topic"]}>
              {topics && topics.length > 0 && (
                <ul className={clsx({ [theme["open"]]: open })}>
                  {topics.map((topic, index) => {
                    const t = topic?.split(":")[1] ?? topic?.split(":")[0];
                    const link = `/${
                      linkData(t.toLowerCase())?.url?.slug
                    }?topics=${t}`;
                    return (
                      <Fragment key={`${viewAllLabel}${index}`}>
                        {index === 6 && (
                          <li className={theme["view-all"]}>
                            <a
                              className={theme["view-all-items"]}
                              href="#"
                              onClick={handleClick}
                            >
                              <i className="icon-cross">
                                <IconCross />
                              </i>
                              {viewAllLabel}
                              <span></span>
                            </a>
                          </li>
                        )}
                        <li
                          className={clsx(
                            { [theme["all-item"]]: index >= 5 },
                            display !== "Related Topics"
                              ? theme["popular-topic"]
                              : theme["related-topic"]
                          )}
                        >
                          <a href={link}>{topic.split(":").reverse()[0]}</a>
                        </li>
                      </Fragment>
                    );
                  })}
                </ul>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default themr("PopularTopics", styles)(PopularTopics);

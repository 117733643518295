import React from "react";
import Components from "../../__Components__/Components";
import Grid from "@components/Grid/Grid";
import RenderComponents from "@utilities/renderComponents";

export interface Content2019PageProps {
  headerBanner?: any;
  bodyContent?: any;
}

export const Content2019Page = (props: { content: Content2019PageProps }) => {
  const { content } = props;
  const { bodyContent, headerBanner } = content;
  return (
    <Grid row>
      <Grid column sm={12}>
        {headerBanner &&
          Components(
            {
              component: headerBanner.sys.contentType,
              uid: headerBanner.sys.id,
              ...headerBanner.fields,
            },
            headerBanner.fields
          )}

        {RenderComponents(bodyContent)}
      </Grid>
    </Grid>
  );
};

export default Content2019Page;

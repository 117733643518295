import { SiteSettings } from "@customTypes/SiteSettings";
import { Results } from "../CoveoSearchComponent";

interface DefaultResultsTemplateProps {
  siteSettings?: SiteSettings;
  result: Results;
}

export const DefaultResultsTemplate = (props: DefaultResultsTemplateProps) => {
  const { result, siteSettings } = props;

  function getGlobalSearchTagTextAndPathCollection(collection) {
    let data: any[] = [];

    collection.forEach((e) => {
      let item = {};
      item["key"] = e.split("-")[0];
      item["phrase"] = e.split("-")[1];
      data.push(item);
    });

    return data;
  }

  const TagTextAndPathCollection = getGlobalSearchTagTextAndPathCollection(
    siteSettings?.globalSearchTagTextAndPath
  );

  const itemPath =
    result.ClickUri != null
      ? result.ClickUri.split("/")[3].split("-").join(" ")
      : "";

  function getPhraseAndPath(searchSectionDisplayNames, itemPath) {
    const item = searchSectionDisplayNames.find(
      (p) => p.key.toLowerCase().slice(1) === itemPath
    );

    if (item) {
      let tagObject = {};
      tagObject["phrase"] = item.phrase;
      tagObject["path"] = result?.ClickUri?.split("/")[3];

      return tagObject;
    }
  }

  let tagObject = getPhraseAndPath(TagTextAndPathCollection, itemPath);
  return (
    <>
      <div className="coveo-result">
        <div>
          <a className="coveo-link" href={result.clickUri}>
            {result.title && <h3 className="h3">{result.title}</h3>}
          </a>
          {result.raw.pagedescription && (
            <p className="page-description">
              <span>{result.raw.pagedescription}</span>
            </p>
          )}
        </div>

        {tagObject !== undefined && (
          <div className="component tag">
            <a href={`/${tagObject["path"]}`}>{tagObject["phrase"]}</a>
          </div>
        )}
      </div>
    </>
  );
};

export default DefaultResultsTemplate;

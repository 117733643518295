import React from "react";
import Components from "../../__Components__/Components";

type PageProps = {
  content: {
    title: string;
    bodySection?: any;
    heroSection?: any;
  };
  siteHeader: {
    primaryNavigation: {
      fields: {
        navTitle: string;
        pageLink: {
          fields: {
            slug: string;
          };
        };
        subNavigationItems: {
          fields: {
            navTitle: string;
            pageLink: {
              fields: {
                slug: string;
              };
            };
          };
        }[];
      };
    }[];
  };
};

export const Page = (props: PageProps) => {
  const { content } = props;
  const { bodySection, heroSection } = { ...content };

  return (
    <>
      {heroSection &&
        Components(
          {
            component: heroSection.sys.contentType.sys.id,
            uid: heroSection.sys.id,
            ...heroSection.fields,
          },
          heroSection.fields
        )}
      {bodySection?.map((block: any) => {
        if (!block?.sys?.contentType?.sys?.id) {
          return null;
        }
        const component = {
          component: block.sys.contentType.sys.id,
          uid: block.sys.id,
          ...block.fields,
        };
        return Components(component, block.fields);
      })}
    </>
  );
};
export default Page;

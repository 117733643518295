import { DownloadBrochureFormProps, SelectItem } from "./interfaces";
import { findMatchingSelectValue } from "@utilities/mapSelectValue";

export const permanentFields = [
  "emailAddress",
  "title",
  "firstName",
  "lastName",
];

export const getFormInitialValues = (
  content: DownloadBrochureFormProps,
  userPersonalDetails,
  titles: SelectItem[],
  industries: SelectItem[],
  institutions: SelectItem[],
  seniorityLevels: SelectItem[],
  user: any, //
  watch,
  titlesSelectValue: any,
  setTitlesSelectValue: (value: any) => void,
  industriesSelectValue: any,
  setIndustriesSelectValue: (value: any) => void,
  institutionsSelectValue: any,
  setInstitutionsSelectValue: (value: any) => void,
  seniorityLevelsSelectValue: any,
  setSeniorityLevelsSelectValue: (value: any) => void
) => {
  const {
    emailLabelText,
    firstNameLabelText,
    lastNameLabelText,
    titleLabelText,
    industryLabelText,
    cityLabelText,
    yearsManagerialExperienceLabelText,
    passwordLabelText,
    passwordHintText,
    confirmPasswordLabelText,
    yearsRelevantExperienceLabelText,
    universityLabelText,
    companyNameLabelText,
    seniorityLabelText,
    graduationYearLabelText,
    institutionLabelText,
    mastersProgrammeDegreeTitleLabelText,
  } = content;

  const hasInstitutionMatch = (value: string, institutions) =>
    institutions &&
    institutions.find(
      (institution) => institution.value === value || value === "None of these"
    );

  return [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      dataText:
        (user && user["userData"]["email"]) ||
        userPersonalDetails?.result?.emailAddress,
    },
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      labelText: titleLabelText,
      options: titles,
      formType: "select",
      selectValue: findMatchingSelectValue(titles, titlesSelectValue),
      setValue: setTitlesSelectValue,
      dataText: findMatchingSelectValue(
        titles,
        userPersonalDetails?.result?.title
      ),
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
      dataText: userPersonalDetails?.result?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
      dataText: userPersonalDetails?.result?.lastName,
    },
    {
      propertyName: "countryNumber",
      labelText: "",
      hintText: "",
      optionalText: false,
      options: null,
      dataText: userPersonalDetails?.result?.telephoneNumber,
    },
    {
      propertyName: "countryOfResidence",
      labelText: "",
      hintText: "",
      optionalText: false,
      options: null,
      validation: {
        isRequired: true,
      },
      dataText: userPersonalDetails?.result?.address?.homeCountry,
    },
    {
      propertyName: "city",
      validation: {
        isRequired: false,
        maxLength: 30,
      },
      labelText: cityLabelText,
      dataText: userPersonalDetails?.result?.address?.city,
    },
    {
      propertyName: "nationalityOf",
      labelText: "",
      hintText: "",
      optionalText: false,
      options: null,
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      dataText: userPersonalDetails?.result?.nationality,
    },
    {
      propertyName: "yearsRelevanceExperience",
      validation: {
        isRequired: true,
        minLength: 1,
        maxLength: 2,
        integerValidator: true,
      },
      labelText: yearsRelevantExperienceLabelText,
      dataText: userPersonalDetails?.result?.yearsOfExperience,
    },
    {
      propertyName: "yearsManagerialExperience",
      validation: {
        isRequired: true,
        minLength: 1,
        maxLength: 2,
        integerValidator: true,
      },
      labelText: yearsManagerialExperienceLabelText,
      dataText: userPersonalDetails?.result?.yearsOfManagerialExperience,
    },
    {
      propertyName: "university",
      validation: {
        isRequired: true,
      },
      labelText: universityLabelText,
      dataText: userPersonalDetails?.result?.education?.academicInstitution,
    },
    {
      propertyName: "companyName",
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      labelText: companyNameLabelText,
      dataText: userPersonalDetails?.result?.employment?.company,
    },
    {
      propertyName: "industry",
      validation: {
        isRequired: true,
      },
      labelText: industryLabelText,
      options: industries,
      formType: "select",
      selectValue: findMatchingSelectValue(industries, industriesSelectValue),
      setValue: setIndustriesSelectValue,
      dataText: findMatchingSelectValue(
        industries,
        userPersonalDetails?.result?.broadIndustry
      ),
    },
    {
      propertyName: "seniorityLevel",
      validation: {
        isRequired: true,
      },
      labelText: seniorityLabelText,
      options: seniorityLevels,
      formType: "select",
      selectValue: findMatchingSelectValue(
        seniorityLevels,
        seniorityLevelsSelectValue
      ),
      setValue: setSeniorityLevelsSelectValue,
      dataText: findMatchingSelectValue(
        seniorityLevels,
        userPersonalDetails?.result?.jobLevel
      ),
    },
    {
      propertyName: "graduationYear",
      validation: {
        isRequired: true,
        minLength: 4,
        maxLength: 4,
        integerValidator: true,
      },
      labelText: graduationYearLabelText,
      dataText: userPersonalDetails?.result?.education?.yearOfGraduation,
    },
    {
      propertyName: "programmeSection",
      labelText: "",
      hintText: "",
      optionalText: false,
      options: null,
      validation: {
        isRequired: true,
      },
    },
    {
      propertyName: "hrInformation",
      labelText: "",
      hintText: "",
      optionalText: false,
      validation: {
        isRequired: false,
      },
    },
    {
      propertyName: "institution",
      validation: {
        required: "Institution is required",
        validate: (value) =>
          !!hasInstitutionMatch(value, institutions) ||
          "Please select a valid institution or None of these",
      },
      labelText: institutionLabelText,
      options: institutions,
      selectValue: findMatchingSelectValue(
        institutions,
        institutionsSelectValue
      ),
      dataText: "",
    },
    {
      propertyName: "masterProgrammeDegreeTitle",
      validation: {
        isRequired: true,
      },
      labelText: mastersProgrammeDegreeTitleLabelText,
      dataText: "",
    },
    {
      propertyName: "password",
      validation: {
        isRequired: true,
        maxLength: 30,
        password: true,
      },
      type: "password",
      hintText: passwordHintText,
      labelText: passwordLabelText,
      dataText: userPersonalDetails?.password,
    },
    {
      propertyName: "confirmPassword",
      validation: {
        isRequired: true,
        maxLength: 30,
        confirmPassword: true,
      },
      disabled: !watch("password"),
      type: "password",
      labelText: confirmPasswordLabelText,
    },
  ];
};

export const convertToBulletedList = (text) => {
  if (text && text.includes("*")) {
    const jsx = (
      <ul>
        {text?.split("*").map((item, i) => {
          if (item) {
            return <li key={`list-item-${i}`}>{item}</li>;
          }
        })}
      </ul>
    );
    return jsx;
  } else {
    return text;
  }
};

export const convertToBulletedListInCoveoTemplate = (text) => {
  if (text && text.includes("*")) {
    const jsx = (
      <ul>
        {text?.split("\\n*").map((item, i) => {
          if (i == 0 && item != null) {
            let result = item.slice(1);
            return <li key={`list-item-${i}`}>{result}</li>;
          } else {
            return <li key={`list-item-${i}`}>{item}</li>;
          }
        })}
      </ul>
    );
    return jsx;
  } else {
    return text;
  }
};

import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import styles from "./C272_ProgrammeComparisonTable.module.scss";
import { isUrl } from "@utilities/regex";
import Link from "@utilities/link";

interface ProgrammeDEProps {
  count: number;
  programmeDetails: ProgrammeDetails;
  theme?: any;
}

export const ProgrammeDE = (props: ProgrammeDEProps) => {
  const { count, programmeDetails, theme } = props;

  const {
    programmeSettings: {
      programmeContentLabel,
      entryRequirementsLabel,
      keyDifferentiatorsLabel,
      programmeDurationLabel,
      programmeLocationLabel,
      programmeFeesLabel,
      fallbackProgrammeIterationFee,
      fallbackProgrammeIterationDuration,
      fallbackProgrammeIterationLocation,
      programmeInfoCtaLabel,
    },
  } = useSettings();

  const {
    slug,
    programmeTitle,
    entryRequirements,
    keyDifferentiators,
    programmeContent,
    programmeImportData,
  } = programmeDetails;

  const link = slug.match(isUrl) ? slug : `/${slug}`;

  return (
    <li className={`${theme["programme-column"]} programme-column`}>
      <div
        className={`${theme["top-heading"]} top-heading`}
        data-reference="10"
      >
        {
          <Link href={link}>
            <h3>{programmeTitle}</h3>
          </Link>
        }
      </div>
      <div className={theme.info}>
        {count === 1 && (
          <div className={theme["labels-wrapper"]}>
            <div className={`${theme.labels} labels`}>
              <span data-reference="1" aria-hidden="true">
                <span>{programmeContentLabel}</span>
              </span>
              <span data-reference="2" aria-hidden="true">
                <span>{entryRequirementsLabel}</span>
              </span>
              <span data-reference="3" aria-hidden="true">
                <span>{keyDifferentiatorsLabel}</span>
              </span>
              <span data-reference="4" aria-hidden="true">
                <span>{programmeFeesLabel}</span>
              </span>
              <span data-reference="5" aria-hidden="true">
                <span>{programmeDurationLabel}</span>
              </span>
              <span data-reference="6" aria-hidden="true">
                <span>{programmeLocationLabel}</span>
              </span>
            </div>
          </div>
        )}
        <div data-reference="1">
          <span className={`${theme["inner-wrapper"]} leftside-text`}>
            <span className={theme["d-invis"]}>{programmeContentLabel}</span>
            {programmeContent}
          </span>
        </div>
        <div data-reference="2">
          <span className={`${theme["inner-wrapper"]} leftside-text`}>
            <span className={theme["d-invis"]}>{entryRequirementsLabel}</span>
            {entryRequirements}
          </span>
        </div>
        <div data-reference="3">
          <span className={`${theme["inner-wrapper"]} leftside-text`}>
            <span className={theme["d-invis"]}>{keyDifferentiatorsLabel}</span>
            {keyDifferentiators}
          </span>
        </div>
        <div data-reference="4">
          <span
            className={`${theme["inner-wrapper"]} rich-text ${theme["rich-text"]}`}
          >
            <span className={theme["d-invis"]}>{programmeFeesLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeFee
              ? programmeImportData[0]?.programmeFee
              : fallbackProgrammeIterationFee}
          </span>
        </div>
        <div data-reference="5">
          <span className={theme["inner-wrapper"]}>
            <span className={theme["d-invis"]}>{programmeDurationLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeDuration
              ? programmeImportData[0]?.programmeDuration
              : fallbackProgrammeIterationDuration}
          </span>
        </div>
        <div data-reference="6">
          <span className={theme["inner-wrapper"]}>
            <span className={theme["d-invis"]}>{programmeLocationLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeLocation
              ? programmeImportData[0]?.programmeLocation
              : fallbackProgrammeIterationLocation}
          </span>
        </div>
      </div>
      <div className={theme["bottom-ctas"]}>
        {
          <Link href={link} className="cta">
            {programmeInfoCtaLabel}
          </Link>
        }
      </div>
    </li>
  );
};

export default themr("ProgrammeDE", styles)(ProgrammeDE);

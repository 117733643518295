import Grid from "@components/Grid/Grid";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import { ProgrammeType } from "@customTypes/ProgrammeType";
import { useSettings } from "@utilities/context/settings";
import { getFeeRange } from "@utilities/getFeeRange";
import RenderComponents from "@utilities/renderComponents";
import { format, parseISO } from "date-fns";
import Head from "next/head";
import { ProgrammeDetailsPageProvider } from "../../../context/programmeDetailsPage";
import Components from "../../__Components__/Components";
import { useProgramme } from "./ProgrammeDetailsProvider";

export interface ProgrammeDetailsPageProps {
  type: ProgrammeType;
  programmeTypeCode: string;
  programmeDeliveryText?: string;
  programmeCareerStagesEeList?: string[];
  programmeCareerStagesDeList?: string[];
  programmeAttribute?: string[];
  headerBanner?: any;
  topContent?: any;
  bodyContent?: any;
  programmeApplyOnlineParameterValue: string;
}

export const ProgrammeDetailsPage = (props: {
  content: ProgrammeDetailsPageProps;
}) => {
  const { content } = props;
  const {
    type,
    programmeTypeCode,
    programmeDeliveryText,
    programmeCareerStagesEeList,
    programmeCareerStagesDeList,
    programmeAttribute,
    bodyContent,
    topContent,
    headerBanner,
    programmeApplyOnlineParameterValue,
  } = content;

  const {
    programmeStartDate,
    programmeDuration,
    programmeFee,
    programmeLocation,
    programmeLocationMapping,
    programmeInclusiveDates,
  } = useProgramme();

  const { siteSettings } = useSettings();

  // Apply URL - Set in Site Settings on the Programme CTA Pages field
  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];

  const applyUrl = `/${apply?.url?.slug}?applyID=${programmeApplyOnlineParameterValue}`;

  // Reserve A Place URL - Set in Site Settings on the Programme CTA Pages field
  const reserveAPlace = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.ReserveAPlace
  )[0];
  const reserveAPlaceUrl = `/${reserveAPlace?.url?.slug}?${reserveAPlace?.queryParams}=${programmeTypeCode}&programmeTypeGroup=EXECED&referPage=/executive-education/programme-search-results`;

  // Get Career Stages by type
  const careerStageNames =
    type === ProgrammeType.EE
      ? programmeCareerStagesEeList
      : programmeCareerStagesDeList;

  const programmeLocationPair =
    programmeLocationMapping?.filterOptions?.join(";");

  const delivery = programmeDeliveryText
    ? JSON.stringify(programmeDeliveryText).slice(1, -1)
    : "";

  return (
    <ProgrammeDetailsPageProvider data={content}>
      <>
        <Head>
          {/* --- Programme Details Page --- */}
          <meta
            name="programmetopicsnames"
            content={programmeAttribute?.join(";")}
          />
          <meta
            name="programmecareerstagesnames"
            content={careerStageNames?.join(";")}
          />
          <meta name="programmetype" content={type.toLowerCase()} />
          <meta name="programmedelivery" content={delivery} />

          {/* --- Programme Import Data --- */}
          {programmeLocationPair && (
            <meta
              name="programmelocationpair"
              content={programmeLocationPair}
            />
          )}
          {programmeDuration && (
            <meta name="programmeduration" content={programmeDuration} />
          )}
          {programmeInclusiveDates && (
            <meta
              name="programmenextiteration"
              content={programmeInclusiveDates}
            />
          )}
          {programmeLocation && (
            <meta name="programmelocation" content={programmeLocation} />
          )}
          {programmeFee && (
            <>
              <meta name="programmefees" content={programmeFee} />
              <meta
                name="programmefeesnumber"
                content={programmeFee.replace("£", "")}
              />
              <meta
                name="programmepricerange"
                content={getFeeRange(programmeFee)}
              />
            </>
          )}
          <meta name="programmeapplynowlink" content={applyUrl} />
          <meta name="programmereserveaplacelink" content={reserveAPlaceUrl} />
          {programmeStartDate && (
            <meta
              name="programmestartdate"
              content={format(parseISO(programmeStartDate), "yyyy/MM/dd")}
            />
          )}
        </Head>

        <Grid row>
          <Grid column sm={12}>
            {headerBanner &&
              Components(
                {
                  component: headerBanner.sys.contentType,
                  uid: headerBanner.sys.id,
                  ...headerBanner.fields,
                },
                headerBanner.fields
              )}

            {topContent && RenderComponents(topContent)}

            {RenderComponents(bodyContent)}
          </Grid>
        </Grid>
      </>
    </ProgrammeDetailsPageProvider>
  );
};

export default ProgrammeDetailsPage;

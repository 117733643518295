import Cta from "@components/Cta/Cta";
import { NavLinkList, NavLinkType } from "@customTypes/megaNav";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import { useContext, useRef } from "react";
import { NavigationStateContext } from "../../context/navigationContext";
import LinkPanels from "./LinkPanels";
import styles from "./MegaNav.module.scss";
import PromoItemAside from "./PromoItemAside";
import TabLinkList from "./TabLinkList";
import { navigationGAClick } from "@utilities/navigationGAClick";
import { useRouter } from "next/router";

export const MegaNavTabList = ({
  linkList,
  menuActive,
  theme,
}: {
  linkList: NavLinkList;
  menuActive: boolean;
  theme?: any;
}) => {
  const mainMenuLink = linkList?.fields;
  const { handleReset, tabSelectedIndex, primaryNavSelectedIndex } = useContext(
    NavigationStateContext
  );
  const router = useRouter();
  const tabWrapperRef = useRef<HTMLDivElement | null>(null);

  let isShowPromo = true;
  if (
    menuActive &&
    tabSelectedIndex !== null &&
    primaryNavSelectedIndex !== null
  ) {
    const tabList = mainMenuLink?.linkList.filter(
      (item) => item?.sys?.contentType?.sys?.id === NavLinkType.NavLinkList
    );

    const secondList =
      // @ts-ignore
      tabList[tabSelectedIndex]?.fields?.linkList[primaryNavSelectedIndex]
        ?.fields?.linkList;
    if (secondList && secondList?.length > 14) {
      isShowPromo = false;
    }
  }

  return (
    <>
      {/* first column */}
      <div ref={tabWrapperRef} className={clsx(theme.tabs)}>
        <TabLinkList mainMenuLink={mainMenuLink} />
      </div>

      {/* second column */}
      <LinkPanels mainMenuLink={mainMenuLink} menuActive={menuActive} />

      {isShowPromo && <PromoItemAside mainMenuLink={mainMenuLink} />}
      <div className={clsx(theme.bottom, theme.desktop)}>
        <p>{mainMenuLink.description || ""}</p>

        {mainMenuLink.promoCtAs &&
          mainMenuLink.promoCtAs.slice(0, 2).map((promoCTA, index) => {
            return (
              <Cta
                key={`main-menu-link${index}`}
                btnTheme={theme["ctaBottom"]}
                linkText={promoCTA?.fields?.linkText}
                type={"secondary"}
                light
                url={promoCTA?.fields?.url}
                onClick={(e) => {
                  navigationGAClick(e);
                  handleReset();
                  router.push(promoCTA?.fields?.url);
                }}
              />
            );
          })}
      </div>
    </>
  );
};

export default themr("MegaNavTabList", styles)(MegaNavTabList);

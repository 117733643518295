import { SocialLink } from "@customTypes/SocialLink";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import { sortBy } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styles from "./C15_SocialSharing.module.scss";
import Image from "@components/Image/NextImage";

export interface SocialSharingProps {
  socialLinks: SocialLink[];
  isSocialIconDark?: boolean;
  enableSaveToProfileLink?: boolean;
  small?: boolean;
  mobileAlignment?: boolean;
  theme?: any;
}

export const SocialSharing = (props: SocialSharingProps) => {
  const {
    socialLinks,
    isSocialIconDark,
    small,
    mobileAlignment,
    enableSaveToProfileLink,
    theme,
  } = props;
  const [url, setUrl] = useState("");
  const { route } = useRouter();

  const replace = (shareLink) => {
    shareLink = shareLink.replace("%URL%", encodeURIComponent(url));
    return shareLink;
  };

  useEffect(() => {
    const url = document.URL;
    setUrl(url);
  }, [route]);

  return (
    <div
      className={clsx(
        "component",
        theme["social-sharing"],
        small && theme.small,
        mobileAlignment && theme.mobile
      )}
    >
      <ul className={theme["social-links"]}>
        {sortBy(socialLinks, ["fields.name"]).map(
          ({ fields: { name, lightIcon, darkIcon, shareLink } }, index) => {
            const icon = isSocialIconDark ? darkIcon.fields : lightIcon.fields;

            return (
              <li key={`${name}${index}`}>
                <a
                  aria-label={`${name} - Opens a new window`}
                  href={replace(shareLink)}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.icon}
                >
                  <Image src={icon.file.url} alt={name} layout="fill" />
                </a>
              </li>
            );
          }
        )}
      </ul>
      {/*
        const saveLink = "Save";
        const saveToProfile = (event: MouseEvent) => {
          event.preventDefault();
          // window.location.href='@Url.Action("Save", new { redirectingItem = Sitecore.Context.Item.ID.Guid.ToString() })';
        };
        enableSaveToProfileLink && (
            string saveLink = Model.IsPageSavedForUser ? Model.AlreadySaveToMyProfile : Model.SaveToMyProfile;
          <a
            id="SaveToProfileLink"
            href="#"
            onClick={(event) => saveToProfile(event)}
            className="cta-save-to-my-profile"
            title="@(Model.UserIsAuthenticated ? saveLink : Model.LoggedOutTitleText)"
          >
            {saveLink}
          </a>
        )
      */}
    </div>
  );
};

export default themr("SocialSharing", styles)(SocialSharing);

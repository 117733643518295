import { findMatchingSelectId } from "@utilities/mapSelectValue";

export const prepareDataForSubmit = (
  data,
  titles,
  genders,
  industries,
  careerAspirations,
  studyFormats,
  studyLocations,
  intendedYearsOfStudy
) => {
  for (const property in data) {
    if (data[property] === "") {
      data[property] = null;
    }
  }

  return {
    isStayInformed: data.isStayInformed,
    emailAddress: data.emailAddress,
    title: findMatchingSelectId(titles, data.title),
    firstName: data.firstName,
    lastName: data.lastName,
    gender: findMatchingSelectId(genders, data.gender),
    nationality: data.nationality,
    secondNationality: data.secondNationality,
    telephoneNumber: data.telephoneNumber?.toString() || null,
    linkedInUrl: data.linkedInProfile,
    facebookProfile: data.facebookAccount,
    twitterHandle: data.twitterHandle,
    skypeId: data.skypeId,
    industry: findMatchingSelectId(industries, data.industry),
    yearsOfManagerialExperience: data.yearsOfManagerialExperience,
    yearsOfExperience: data.yearsRelevantExperience,
    intendedYearOfStudy: findMatchingSelectId(
      intendedYearsOfStudy,
      data.intendedYEarOfStudy
    ),
    preferredStudyFormat: findMatchingSelectId(
      studyFormats,
      data?.preferredStudyFormat
    ),
    preferredStudyLocation: findMatchingSelectId(
      studyLocations,
      data?.preferredStudyLocation
    ),
    careerAspiration: findMatchingSelectId(
      careerAspirations,
      data?.careerAspirations
    ),
    address: {
      addressLine1: data.HomeAddressLine1,
      addressLine2: data.HomeAddressLine2,
      postCode: data.HomePostCode,
      homeCountry: data.HomeCountry,
      stateCounty: data.HomeStateCounty,
      city: data.HomeCity,
    },
    employment: {
      company: data.companyName,
      jobTitle: data.jobTitle,
      companyTurnOver: data.companyTurnOver || null,
      companyEmployees: data.numberOfEmployees || null,
      address: {
        addressLine1: data.WorkAddressLine1,
        addressLine2: data.WorkAddressLine2,
        postCode: data.WorkPostCode,
        homeCountry: data.WorkCountry,
        stateCounty: data.WorkStateCounty,
        city: data.WorkCity,
      },
    },
    education: {
      academicInstitution: data.academicInstitution,
      highestDegree: data.highestDegree,
    },
    scores: {
      gmatScore: data.gmatScore,
      gmatFocusTotalScore: data.gmatFocusTotalScore,
      verbalScore: data.greVerbalScore,
      verbalPercentile: data.greVerbalPercentile,
      quantativeScore: data.greQuantitativeScore,
      quantativePercentile: data.greQuantitativePercentile,
      awaScore: data.awaScore,
      awaPercentile: data.awaPercentileScore,
    },
  };
};

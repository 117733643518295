import { HandledSubmitFormError } from "@customTypes/ErrorMessagesTypes";

export const handleSubmitFormErrors = (
  response,
  apiErrorMessages?: Array<HandledSubmitFormError>
): Array<string> | null => {
  let errorMessages;

  if (response?.errors) {
    errorMessages = apiErrorMessages?.filter((message) =>
      response?.errors.some((error) => message.code === error.errorCode)
    );
  } else if (response?.errorCode) {
    errorMessages = apiErrorMessages?.filter(
      (message) => message.code === response?.errorCode
    );
  }

  const filteredErrors = errorMessages?.length
    ? errorMessages
    : apiErrorMessages?.filter((message) => message.code === "GENERIC_MESSAGE");

  return (
    filteredErrors
      ?.map((item) => item.error)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      }) || ["Something went wrong"]
  );
};

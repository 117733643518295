const facetsConfig = [
  { facetId: "publicationsubjectareaslist", content: "Subject" },
  { facetId: "publicationresearchcenternamelist", content: "Research Area" },
  { facetId: "publicationyearstring", content: "Year" },
  { facetId: "publicationauthorslist", content: "Author" },
  { facetId: "programmefees", content: "Price" },
  { facetId: "programmecareerstagesnames", content: "Career level" },
  { facetId: "programmelocation", content: "Format / Location" },
  { facetId: "programmetopicsnames", content: "Topic" },
  { facetId: "profileprogramme", content: "Programme" },
  { facetId: "profilephd", content: "Programme " },
  { facetId: "articlemediatype", content: "Media" },
  { facetId: "taxonomytopics", content: "Topic" },
  { facetId: "profileindustry", content: "Industry" },
  { facetId: "eventmonth", content: "Month" },
  { facetId: "profilesurname", content: "Surname A-Z" },
  { facetId: "profileisscholar", content: "Scholar Recipient" },
  { facetId: "profileisambassador", content: "Ambassador Filter" },
  { facetId: "profilenationality", content: "Nationality" },
  { facetId: "eventstartsyear", content: "Year" },
  { facetId: "eventwhoisaimedatlist", content: "Event for" },
  { facetId: "eventprogrammetypes", content: "LBS programmes" },
  { facetId: "eventtype", content: "Event type" },
  { facetId: "eventregion", content: "Region" },
  { facetId: "profilesubjectarea", content: "Subject" },
  { facetId: "profilesurname", content: "Surname" },
  {
    facetId: "dpoindustrylist",
    content: "Industry-specific scholarships only",
  },
  { facetId: "dporesidency", content: "Residency" },
  { facetId: "dpogender", content: "Gender-specific scholarships only" },
  { facetId: "dponationality", content: "National of" },
  { facetId: "dpoprovidertype", content: "Provider type" },
  { facetId: "dpofundingtype", content: "Type of Funding" },
  { facetId: "dpoprogrammelist", content: "Programme" },
  { facetId: "taxonomyattributes", content: "Topic" },
  { facetId: "taz120xonomytopics", content: "Topics" },
  { facetId: "articledatemonth", content: "Month" },
  { facetId: "articledateyear", content: "Year" },
  { facetId: "newstopic", content: "Topic" },
  { facetId: "eventmonth", content: "Month" },
  { facetId: "profileprogramme", content: "Programme" },
  { facetId: "articledateyear", content: "Year" },
  { facetId: "source", content: "Source" },
];

export default facetsConfig;

import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import clsx from "clsx";
import Link from "@components/Link/Link";
import styles from "./EMBAGGlobalHeader.module.scss";
import Image from "@components/Image/NextImage";

export const EMBAGGlobalHeader = ({ theme }: { theme?: any }) => {
  const { siteSettings } = useSettings();

  return (
    <header id="main-header">
      <div className={theme["header-wrap"]}>
        <h1 className={clsx(theme.branding, theme.embag)}>
          {siteSettings?.embagGlobalHeaderCollection?.items.map(
            (item, index) => {
              return (
                <Link href={item.link} key={`${item.logo.title}${index}`}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <Image
                      width={item.logo.width}
                      height={item.logo.height}
                      src={item.logo.url}
                      alt={item.logo.title || ""}
                    />
                  </a>
                </Link>
              );
            }
          )}
        </h1>
      </div>
    </header>
  );
};

export default themr("EMBAGGlobalHeader", styles)(EMBAGGlobalHeader);

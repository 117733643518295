import C19_PromotionalArticles, {
  ArticlePromotionType,
} from "@components/C19_PromotionalArticles/C19_PromotionalArticles";
import { fetchApi } from "@utilities/fetchApi";
import { format, parseISO } from "date-fns";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export interface PromotionalArticlesProps {
  isBlue: boolean;
  topics?: string[];
  numberOfItems?: number;
  news?: any;
}

const PromotionalArticlesNews = ({ content }) => {
  const { news, isBlue, topics, numberOfItems } = content;
  const [newsArticles, setNewsArticles] = useState<ArticlePromotionType[]>([]);

  const { asPath } = useRouter();
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(asPath);
  }, [asPath]);

  useEffect(() => {
    const getData = async () => {
      const newsItems = await fetchApi("/api/getNewsByTopic", {
        topics,
        path,
        limit: numberOfItems,
      });

      setNewsArticles(newsItems);
    };

    if (topics && numberOfItems) {
      getData();
    } else {
      setNewsArticles(
        news.map((article) => ({
          subtitle: format(parseISO(article.fields.date), "MMM dd yyyy"),
          title: article.fields.newsTitle,
          description: article.fields.shortDescription,
          image: article.fields.thumbnailImage,
          slug: article.fields.slug,
        }))
      );
    }
  }, [topics, path, numberOfItems]);

  return (
    <C19_PromotionalArticles
      articles={newsArticles}
      isBlue={isBlue}
      ctaText="Read more"
    />
  );
};

export default PromotionalArticlesNews;

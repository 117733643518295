import Cta from "@components/Cta/Cta";
import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import { useContext, useEffect, useState } from "react";
import { ProgrammeContext } from "../../context/programmeComparison";
import IconChevron from "../../public/image/svg/chevron.svg";
import IconExternal from "../../public/image/svg/external.svg";
import { ItemState } from "./Accordion";
import styles from "./C11_VerticalAccordion.module.scss";

export interface AccordionItemProgrammeProps {
  accordionState: ItemState;
  item: number;
  toggleAccordion: (event, item) => void;
  isCompare?: boolean;
  programme: ProgrammeDetails;
  theme?: any;
}

export const AccordionItemProgramme = (props: AccordionItemProgrammeProps) => {
  const {
    accordionState,
    item,
    toggleAccordion,
    isCompare,
    programme,
    theme = styles,
  } = props;
  const [isVisible, setVisibility] = useState(false);
  const [contentHeight, setContentHeight] = useState(
    accordionState.contentHeight
  );
  const [checked, setChecked] = useState(false);

  const { programmeRoute, programmeSettings } = useSettings();

  const {
    programmeDetailsExternalLink,
    programmeTitle,
    programmeDescription,
    programmeLocationInfo,
    slug,
    programmeTypeCode,
    type,
    programmeImportData,
  } = programme;

  const {
    programmeNextStartDateShortLabel,
    programmeDurationLabel,
    programmeLocationLabel,
    programmeFeesLabel,
    programmeInfoCtaLabel,
    fallbackProgrammeIterationFee,
    fallbackProgrammeIterationDuration,
    fallbackProgrammeIterationLocation,
    noProgrammeDateAvailableMessage,
  } = programmeSettings;

  const getFormattedDateFromISO = (datetime) => {
    const date = new Date(datetime);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const { programmes, addProgramme, removeProgramme } =
    useContext(ProgrammeContext);

  useEffect(() => {
    setChecked(!!programmes.find((p) => p.programmeCode === programmeTypeCode));
  }, [programmes]);

  useEffect(() => {
    // Set height for animation
    setContentHeight(accordionState.contentHeight);

    // If it's open, set visibility and set content to auto so it can be responsive on resize
    if (accordionState.isOpen) {
      setVisibility(true);

      setTimeout(() => {
        setContentHeight("auto");
      }, 300);
    } else {
      setTimeout(() => {
        setContentHeight("0");
      }, 10);

      setTimeout(() => {
        setVisibility(false);
      }, 300);
    }
  }, [accordionState.isOpen]);

  return (
    <li className={theme["accordion-programme"]}>
      {programmeDetailsExternalLink ? (
        // <div className={theme["external-link-container"]}>
        <>
          <a
            href={programmeDetailsExternalLink}
            target="_blank"
            rel="noreferrer"
          >
            <IconExternal />
          </a>
          <a
            href="#"
            className={theme["toggle-link"]}
            aria-expanded={isVisible}
            onClick={(e) => {
              toggleAccordion(e, item);
            }}
          >
            <div className={theme["top-section"]}>
              <div className={theme["padded-wrapper"]}>
                <h4>{programmeTitle}</h4>
                <div className="tick icon-chevron">
                  <IconChevron />
                </div>
              </div>
            </div>
          </a>
        </>
      ) : (
        ///* </div> */}
        <a
          href="#"
          className={theme["toggle-link"]}
          aria-expanded={isVisible}
          onClick={(e) => {
            toggleAccordion(e, item);
          }}
        >
          <div className={theme["top-section"]}>
            <div className={theme["padded-wrapper"]}>
              <h4>{programmeTitle}</h4>
              <div className="tick icon-chevron">
                <IconChevron />
              </div>
            </div>
          </div>
        </a>
      )}
      <div
        className={theme["accordion-drop"]}
        aria-hidden={!isVisible}
        style={{
          visibility: isVisible ? "visible" : "hidden",
          height: contentHeight,
        }}
      >
        <div
          className={`${theme["content"]} ${theme["inner-wrapper"]} ${theme["padded-wrapper"]}`}
        >
          <div className={theme["description"]}>
            <p>{programmeDescription}</p>
          </div>
          <div className={theme["section-row-wrapper"]}>
            <div className={theme["section-row"]}>
              <span className={theme["label"]}>
                {programmeNextStartDateShortLabel}
              </span>
              <span className={theme["info"]}>
                {programmeImportData
                  ? programmeImportData[0]?.programmeInclusiveDates ||
                    getFormattedDateFromISO(
                      programmeImportData[0].programmeStartDate
                    )
                  : noProgrammeDateAvailableMessage}
                {/* TODO: Add when Programme Iteration */}
                {/* {programmeImportData?.programmeAvailabilityString && (
                  <span className={theme["sub-text"]}>
                    {programmeImportData?.programmeAvailabilityString}
                  </span>
                )} */}
              </span>
            </div>
            <div className={theme["section-row"]}>
              <span className={theme["label"]}>{programmeDurationLabel}</span>
              <span className={theme["info"]}>
                {programmeImportData
                  ? programmeImportData[0]?.programmeDuration
                  : fallbackProgrammeIterationDuration}
              </span>
            </div>
            <div className={theme["section-row"]}>
              <span className={theme["label"]}>{programmeLocationLabel}</span>
              <div className={theme["info"]}>
                {programmeImportData
                  ? programmeImportData[0]?.programmeLocation
                  : fallbackProgrammeIterationLocation}
                {programmeLocationInfo && (
                  <div
                    className={`${theme["message-wrapper"]} ${theme["light"]}`}
                    tabIndex={0}
                    aria-describedby="message1"
                  >
                    <p className={theme["message"]} id="message1">
                      {programmeLocationInfo}
                      {/* <a href="#close" aria-label="Close" class="icon-cross" data-grunticon-embed=""></a> */}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className={theme["section-row"]}>
              <span className={theme["label"]}>{programmeFeesLabel}</span>
              <div className={theme["info"]}>
                {programmeImportData
                  ? programmeImportData[0]?.programmeFee
                  : fallbackProgrammeIterationFee}
                {/* TODO: Add when Programme Iteration */}
                {/* {programmeImportData?.programmeFeeInfo && (
                  <div
                    className={`${theme["message-wrapper"]} ${theme["light"]}`}
                    tabIndex={0}
                    aria-describedby="message2"
                  >
                    <p className={theme["message"]} id="message2">
                      {programmeImportData?.programmeFeeInfo}
                      <a href="#close" aria-label="Close" class="icon-cross" data-grunticon-embed=""></a>
                    </p>
                  </div>
                )} */}
              </div>
            </div>
            <div className={theme["button-wrapper"]}>
              <Cta
                type="tertiary"
                url={
                  programme?.programmeDetailsExternalLink
                    ? programme?.programmeDetailsExternalLink
                    : slug || "#"
                }
                linkText={programmeInfoCtaLabel || ""}
                icon="icon-arrow"
                openInNewTab={!!programmeDetailsExternalLink}
              />
            </div>
          </div>
        </div>
      </div>
      {isCompare && (
        <div className={`${theme["compare-option"]} form`}>
          <div className="checkbox">
            <input
              id={programmeTypeCode}
              checked={checked}
              type="checkbox"
              onChange={(event) => {
                setChecked(event.target.checked);
                if (event.target.checked) {
                  addProgramme({
                    programmeTitle,
                    programmeCode: programmeTypeCode,
                    programmeType: type,
                  });
                  return;
                }

                removeProgramme({
                  programmeTitle,
                  programmeCode: programmeTypeCode,
                });
              }}
            />
            <span className="tick"></span>
            <label htmlFor={programmeTypeCode}>Select up to 4 to compare</label>
          </div>
        </div>
      )}
    </li>
  );
};

export default themr("AccordionItemProgramme", styles)(AccordionItemProgramme);

import C522_StudentDetails from "@components/C52.2_StudentDetails/C52.2_StudentDetails";
import C54_ProfilePhotoLinkBox from "@components/C54_ProfilePhotoLinkBox/C54_ProfilePhotoLinkBox";
import Grid from "@components/Grid/Grid";
import { ContentfulImage } from "@customTypes/ContentfulImage";
import { Industry } from "@customTypes/Industry";
import { Nationality } from "@customTypes/Nationality";
import { Scholarship } from "@customTypes/Scholarship";
import { GeneralLink } from "@customTypes/generalLink";
import { Media } from "@customTypes/media";
import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { themr } from "@friendsofreactjs/react-css-themr";
import RenderComponents from "@utilities/renderComponents";
import { Document as RichTextContent } from "@contentful/rich-text-types";
import Head from "next/head";
import { StudentDetailsProvider } from "../../../context/studentDetail";

import styles from "./StudentProfileDetail.module.scss";

export interface StudentProfileDetailProps {
  bodyContent?: any;
  profileContent?: any;
  sidebarContent?: any;
  carouselCTATextLink: GeneralLink;
  profileCVFile: Media;
  profileDescription: string;
  profileEmail: string;
  profileFirstname: string;
  profileIndustry: Industry;
  profileIsAmbassador: Boolean;
  profileIsScholar: Boolean;
  profileJobPostProgramme: string;
  profileJobPreProgramme: string;
  profileLinkedInLink: string;
  profileNationality: Nationality;
  profilePersonalWebPage: string;
  profilePhd: string;
  profilePhone: string;
  profilePhoto: ContentfulImage;
  profileProgramme: { fields: ProgrammeDetails };
  profileSurname: string;
  profileText: RichTextContent;
  profileTwitterLink: string;
  scholarshipTitle: Scholarship;
  tags: any;
}

function getTagValueByProp(prop, pageTags) {
  const tag = pageTags?.find((t) => t?.name?.split(":")[0] == prop);
  return tag?.name?.split(":")[1];
}

const StudentProfileDetail = (props: {
  content: StudentProfileDetailProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { bodyContent, profileContent, sidebarContent, tags, ...details } =
    content;
  const {
    profileIsAmbassador,
    profileIsScholar,
    profileNationality,
    profilePhoto,
    profileProgramme,
    profileSurname,
    profilePhd,
  } = details;

  return (
    <StudentDetailsProvider details={details}>
      <Head>
        <meta
          name="profilenationality"
          content={profileNationality?.fields?.nationality || ""}
        />
        <meta name="profilesurname" content={profileSurname || ""} />
        <meta
          name="profileprogramme"
          content={profileProgramme?.fields?.programmeTitle || ""}
        />
        <meta
          name="profileisambassador"
          content={profileIsAmbassador ? "Ambassador" : "Student/Alumni"}
        />
        <meta
          name="profileisscholar"
          content={profileIsScholar ? "Scholar" : "Student/Alumni"}
        />
        <meta
          name="profilephotosmallurl"
          content={profilePhoto?.fields?.file?.url || ""}
        />
        <meta name="profilephd" content={profilePhd || ""} />
        <meta
          name="profilephotolargeurl"
          content={profilePhoto?.fields?.file?.url || ""}
        />
        <meta
          name="studenttype"
          content={tags ? getTagValueByProp("studenttype", tags) : ""}
        />
      </Head>

      <div className="outer-wrap">
        <div className="wrapper">
          <Grid row justify="space-between">
            <Grid column sm={12} md={8}>
              <C522_StudentDetails />
              {RenderComponents(profileContent)}
            </Grid>
            <Grid column sm={12} md={4} customClass="tp4">
              <C54_ProfilePhotoLinkBox content={{ ...details }} />
              {RenderComponents(sidebarContent)}
            </Grid>
            {bodyContent && (
              <Grid sm={12}>{RenderComponents(bodyContent)}</Grid>
            )}
          </Grid>
        </div>
      </div>
    </StudentDetailsProvider>
  );
};

export default themr("StudentProfileDetail", styles)(StudentProfileDetail);

import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C65_MyProfileApplications.module.scss";
import { getApplications } from "../../services/forms";
import { useEffect, useState, useContext } from "react";
import Cta from "../Cta/Cta";
import Grid from "@components/Grid/Grid";
import ApiError from "@components/GenericApiError/Error";
import { UserContext } from "../../context/user";
import getCookie from "@utilities/getCookie";
import { useSettings } from "@utilities/context/settings";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import ClipLoader from "react-spinners/ClipLoader";
import { format, parseISO } from "date-fns";

interface ApplicationProps {
  id: string;
  programmeName: string;
  year: string;
  editDate: string;
  studyMethod?: {
    id: number;
    value: string;
  };
  programmeStartDate?: string;
  programmeClosingDate: string;
  applicationStatus: {
    id: number;
    value: string;
  };
}

export interface ApplicationsProps {
  typeLabel: string;
  startDateLabel: string;
  applicationDeadlineLabel: string;
  completeApplicationText: string;
  showApplicationText: string;
  noItemsText: string;
}

export const MyApplications = (props: {
  content: ApplicationsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    typeLabel,
    startDateLabel,
    applicationDeadlineLabel,
    completeApplicationText,
    showApplicationText,
    noItemsText,
  } = content;

  const [applications, setApplications] = useState<ApplicationProps[] | null>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { siteSettings } = useSettings();
  const [noApplicationsMessage, setNoApplicationsMessage] = useState(false);
  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];

  const { user } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    const getPreferences = async () => {
      setNoApplicationsMessage(false);
      try {
        const applicationResults = await getApplications(
          getCookie("access_token")
        );
        if (!applicationResults || applicationResults?.status === 404) {
          setNoApplicationsMessage(true);
        } else {
          setApplications(applicationResults?.result);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(true);
      }
    };

    getPreferences();
  }, [user]);

  if (error) {
    return <ApiError />;
  }

  if (loading) {
    return (
      <ClipLoader
        color="#001e62"
        size={70}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    );
  }

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12}>
          <div className={theme["profile-applications"]}>
            {noApplicationsMessage && (
              <div>{noItemsText ?? "There are no saved applications"}</div>
            )}
            {applications?.map((application, i) => {
              return (
                <article
                  key={`${application.programmeName}${i}`}
                  className={theme.application}
                >
                  <h2 className={theme["section-title"]}>
                    {application.programmeName}
                  </h2>
                  <ul className={theme.details}>
                    {application?.studyMethod?.value ? (
                      <li>
                        <strong>{typeLabel}:</strong>{" "}
                        {application?.studyMethod?.value}
                      </li>
                    ) : (
                      ""
                    )}
                    {application.programmeStartDate && (
                      <li>
                        <strong>{startDateLabel}:</strong>{" "}
                        {format(
                          parseISO(application.programmeStartDate),
                          "dd-MM-yyyy"
                        )}
                      </li>
                    )}
                    <li>
                      <strong>{applicationDeadlineLabel}:</strong>{" "}
                      {application.programmeClosingDate
                        ? format(
                            parseISO(application.programmeClosingDate),
                            "dd-MM-yyyy"
                          )
                        : "TBC"}
                    </li>
                  </ul>
                  <div className="button-wrappers">
                    <Cta
                      url={user ? `/interact` : `${apply?.url.slug}`}
                      linkText={
                        application?.applicationStatus?.value === "Applying"
                          ? showApplicationText
                          : completeApplicationText
                      }
                      type="primary"
                    />
                  </div>
                  <div className={theme.action}></div>
                </article>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("MyApplications", styles)(MyApplications);

import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import { FC } from "react";
import IconPlay from "../../public/image/svg/play-two.svg";
import IconPodcast from "../../public/image/svg/podcast.svg";

import styles from "./C281_EditorsChoice.module.scss";
import { TileSize } from "./EditorsChoiceTile";

export enum EditorsChoiceMediaType {
  NONE = "NONE",
  PODCAST = "PODCAST",
  VIDEO = "VIDEO",
  VIMEO = "VIMEO",
}

interface EditorsChoiceMediaProps {
  mediaToRender: EditorsChoiceMediaType;
  size: TileSize;
  theme: any;
}

const EditorsChoiceMedia: FC<EditorsChoiceMediaProps> = ({
  mediaToRender,
  size,
  theme,
}) => {
  if (!mediaToRender || mediaToRender === EditorsChoiceMediaType.NONE)
    return null;

  if (
    mediaToRender === EditorsChoiceMediaType.PODCAST ||
    mediaToRender === EditorsChoiceMediaType.VIDEO ||
    mediaToRender === EditorsChoiceMediaType.VIMEO
  ) {
    return (
      <span
        className={clsx(theme["icon"], {
          [theme["icon-podcast"]]:
            mediaToRender === EditorsChoiceMediaType.PODCAST,
          [theme["icon-play"]]: mediaToRender === EditorsChoiceMediaType.VIDEO,
          [theme["icon-small"]]: size === TileSize.SMALL,
          [theme["icon-medium"]]: size === TileSize.MEDIUM,
          [theme["icon-large"]]: size === TileSize.LARGE,
        })}
        style={{ backgroundImage: "none" }}
      >
        {mediaToRender === EditorsChoiceMediaType.PODCAST ? (
          <IconPodcast />
        ) : (
          <IconPlay />
        )}
      </span>
    );
  }
  return null;
};

export default themr("EditorsChoiceMedia", styles)(EditorsChoiceMedia);

import Lightbox from "@components/Lightbox/Lightbox";
import { MQ_AND_BELOW } from "@customTypes/Breakpoints";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import styleNames from "../../utils/react/style-names";
import styles from "./C27_NavigationalQuote.module.scss";
import { Quote, QuoteType } from "./C27_Quote";

export interface NavigationalQuoteProps {
  theme?: any;
  content: Quotes | QuoteType;
}

export interface Quotes {
  quotes?: { fields: QuoteType }[];
}

export const NavigationalQuote = ({
  theme,
  content,
}: NavigationalQuoteProps) => {
  const { quotes } = content as Quotes;
  const [longQuoteId, setLongQuoteId] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);
  const [pQuotes, setPQuotes] = useState(null);
  useEffect(() => {
    /*
     * Get only published quotes
     * this logic should be moved to the data mappers un utils
     * Only accessible data should reach the frontend component
     * AB
     */
    setPQuotes(quotes?.filter((quote) => quote.fields !== undefined));
  }, []);

  const sliderSettings = {
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: MQ_AND_BELOW.MOBILE,
        settings: {
          adaptiveHeight: false,
        },
      },
    ],
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div key={`custom-paging${i}`} className={`${theme["tns-nav"]}`}>
        <span></span>
      </div>
    ),
  };

  const onReadMoreClick = (clicked?: number) => {
    clicked && setLongQuoteId(clicked);
    setShowLightbox(true);
  };

  const closeLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    }, 300);
  };

  if (!pQuotes) {
    return <Quote theme={theme} content={content as QuoteType} />;
  }
  return (
    <div>
      {pQuotes && pQuotes.length > 1 ? (
        <div className={theme["navigational-quote-carousel"]}>
          <Slider {...sliderSettings}>
            {pQuotes &&
              pQuotes.map((quote, i) => (
                <Quote
                  key={`${quote.fields?.quote}${i}`}
                  quoteId={i}
                  content={{ ...quote.fields }}
                  theme={theme}
                  onReadMoreClick={onReadMoreClick}
                />
              ))}
          </Slider>

          {showLightbox && (
            <Lightbox
              customClass="quote-lightbox"
              onCloseFunction={closeLightbox}
            >
              <div
                className={styleNames(styles, [
                  "overlay-content",
                  "long-quote-overlay",
                ])}
              >
                <div
                  className={`wrapper ${styleNames(styles, [
                    "long-quote-content",
                    "popup",
                  ])}`}
                >
                  <div className={styleNames(styles, ["qt-line"])}></div>
                  <p>
                    {pQuotes[longQuoteId].fields.longQuote
                      ? pQuotes[longQuoteId].fields.quote +
                        pQuotes[longQuoteId].fields.longQuote
                      : pQuotes[longQuoteId].fields.quote}
                    <br />
                    <br />
                    <b>{pQuotes[longQuoteId].fields.authorName}</b>,{" "}
                    {pQuotes[longQuoteId].fields.authorJobTitle}
                  </p>

                  <div className={styles["qt-second-line"]}></div>
                </div>
              </div>
            </Lightbox>
          )}
        </div>
      ) : pQuotes[0].fields.fullWidth ? (
        <div className={theme["navigational-quote-carousel"]}>
          <Quote theme={theme} content={{ ...pQuotes[0].fields }} />
        </div>
      ) : (
        <Quote theme={theme} content={{ ...pQuotes[0].fields }} />
      )}
    </div>
  );
};

export default themr("NavigationalQuote", styles)(NavigationalQuote);

import { useEffect } from "react";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useState } from "react";

import styles from "./C249_FacultyProfileCarousel.module.scss";
import clsx from "clsx";

import Lightbox from "@components/Lightbox/Lightbox";
import Image from "@components/Image/NextImage";
import Cta from "../Cta/Cta";

import { FacultyCarouselItemTypes } from "@customTypes/FacultyCarouselItemTypes";
import { Nationality } from "@customTypes/Nationality";
import { GeneralLink } from "@customTypes/generalLink";
import { ContentfulImage } from "@customTypes/ContentfulImage";

import FacultyProfileSolo, {
  FacultyProfileSoloProps,
} from "./C249_FacultyProfileSolo";

export interface FacultyProfileMultiProps {
  academicFocus?: string;
  associatedPrograms?: string;
  careerHighlight?: string;
  carouselCtaTextLink?: GeneralLink;
  contentTinyMce?: any;
  experience?: any;
  experienceTinyMce?: any;
  facultyProfile?: any;
  facultyProfileData?: {
    fields: {
      profileName?: string;
      profileFirstname?: string;
      profileSurname?: string;
      profilePositionTitle?: string;
    };
  };
  nationality?: Nationality;
  optionalCtaOne?: GeneralLink;
  optionalCtaTwo?: GeneralLink;
  optionalCtaTextLink?: GeneralLink;
  profileName?: string;
  profileDetailsText?: any;
  profileDetailsTextTinyMce?: any;
  profileFirstname?: string;
  profilePicture?: ContentfulImage;
  profilePhoto?: ContentfulImage;
  profilePositionTitle?: string;
  profileProgramme;
  profileSurname?: string;
  profileText?: any;
  profileTextTinyMce?: any;
  recentClients?: string;
  role?: string;
  slug?: string;
  showSubHeading?: boolean;
}
interface CarouselContentProps {
  profilePicture?: string;
  profileName?: string;
  role?: string;
  associatedPrograms?: string;
  careerHighlight?: string;
  academicFocus?: string;
  recentClients?: string;
  experience?: any;
  experienceTinyMce?: any;
  optionalCtaOne?: GeneralLink;
  optionalCtaTwo?: GeneralLink;
  url?: string;
  nationality?: string;
  optionalCtaTextLink?: GeneralLink;
  showSubHeading?: boolean;
}

const FacultyProfileMulti = (props: {
  content: FacultyProfileMultiProps;
  theme?: any;
  type: string;
}) => {
  const { content, theme, type } = props;
  const {
    academicFocus,
    associatedPrograms,
    careerHighlight,
    carouselCtaTextLink,
    contentTinyMce,
    experience,
    experienceTinyMce,
    facultyProfile,
    facultyProfileData,
    nationality,
    optionalCtaOne,
    optionalCtaTwo,
    profileName,
    profileDetailsText,
    profileDetailsTextTinyMce,
    profileFirstname,
    profilePicture,
    profilePhoto,
    profilePositionTitle,
    profileProgramme,
    profileSurname,
    profileText,
    profileTextTinyMce,
    recentClients,
    role,
    slug,
    showSubHeading,
  } = content;

  const [carouselContent, setCarouselContent] = useState<CarouselContentProps>(
    {}
  );
  const title =
    facultyProfileData?.fields?.profilePositionTitle || profilePositionTitle;
  useEffect(() => {
    if (FacultyCarouselItemTypes.FacultyProfileDetail === type) {
      // Faculty
      setCarouselContent({
        profilePicture: profilePhoto?.fields?.file?.url,
        profileName: facultyProfileData?.fields?.profileName,
        role: title,
        optionalCtaTextLink: carouselCtaTextLink,
        experience: profileDetailsText,
        experienceTinyMce: profileDetailsTextTinyMce,
        url: slug,
      });
    } else if (FacultyCarouselItemTypes.ContributorProfileDetail === type) {
      // Contributor
      setCarouselContent({
        profilePicture: profilePhoto?.fields?.file?.url,
        profileName: `${profileFirstname} ${profileSurname}`,
        role: title,
        optionalCtaTextLink: carouselCtaTextLink,
        experience: profileDetailsText,
        experienceTinyMce: contentTinyMce,
        url: slug,
      });
    } else if (FacultyCarouselItemTypes.StudentProfileDetail === type) {
      // Student
      setCarouselContent({
        profilePicture: profilePhoto?.fields?.file?.url,
        profileName: `${profileFirstname} ${profileSurname}`,
        role: profileProgramme?.fields?.programmeTitle,
        optionalCtaTextLink: carouselCtaTextLink,
        experience: profileText,
        experienceTinyMce: profileTextTinyMce,
        url: slug,
      });
    } else if (FacultyCarouselItemTypes.Extended === type) {
      // Extended
      setCarouselContent({
        profilePicture: profilePicture?.fields?.file?.url,
        profileName: facultyProfile?.fields?.facultyProfileData?.fields
          ?.profileName
          ? facultyProfile?.fields?.facultyProfileData?.fields?.profileName
          : profileName,
        role,
        associatedPrograms,
        careerHighlight,
        academicFocus,
        recentClients,
        experience,
        experienceTinyMce,
        optionalCtaOne,
        optionalCtaTwo,
        url: facultyProfile?.fields?.slug,
        nationality: nationality?.fields?.nationality,
        showSubHeading,
      });
    }
  }, []);

  const [showLightbox, setShowLightbox] = useState(false);

  const closeLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    }, 300);
  };

  return (
    <>
      <div className={theme["profile-wrapper"]}>
        <div className={theme["pw-inner-wrapper"]}>
          <div className={theme["image-and-text-wrapper"]}>
            <div className={theme["image-wrapper"]}>
              {carouselContent.profilePicture && (
                <Image
                  src={carouselContent.profilePicture}
                  alt={carouselContent.profileName || ""}
                  isCrop
                  cropFocus="top"
                  objectFit="cover"
                  layout="fill"
                />
              )}
            </div>
            <div className={theme["text-wrapper"]}>
              {carouselContent.profileName && (
                <h3 className={theme["profile-name"]}>
                  {carouselContent.profileName}
                </h3>
              )}
              {carouselContent.role && (
                <p className="role">{carouselContent.role}</p>
              )}
              {carouselContent.associatedPrograms && (
                <>
                  <p
                    className={clsx(
                      theme["programme-type-label"],
                      theme["sub-heading"]
                    )}
                  >
                    Associated Programmes
                  </p>
                  <p className={theme["programme-type"]}>
                    {carouselContent.associatedPrograms}
                  </p>
                </>
              )}
              <div className={theme["button-wrapper"]}>
                {carouselContent?.optionalCtaTextLink ? (
                  <Cta
                    type="tertiary"
                    url={carouselContent.optionalCtaTextLink?.fields?.url}
                    linkText={
                      carouselContent.optionalCtaTextLink?.fields?.linkText
                    }
                    openInNewTab={
                      carouselContent.optionalCtaTextLink?.fields?.openInNewTab
                    }
                    icon="icon-arrow"
                  />
                ) : (
                  <button
                    role="button"
                    className={clsx(
                      "cta",
                      "secondary",
                      "no-stretch",
                      theme["promo-cta"]
                    )}
                    onClick={() => {
                      setShowLightbox(true);
                    }}
                  >
                    Show details
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLightbox && (
        <Lightbox
          onCloseFunction={closeLightbox}
          customInnerClass="faculty-profile-carousel"
        >
          <FacultyProfileSolo
            content={carouselContent}
            theme={theme}
            lightbox={true}
          />
        </Lightbox>
      )}
    </>
  );
};

export default themr("FacultyProfileMulti", styles)(FacultyProfileMulti);

import { useSettings } from "@utilities/context/settings";
import Link from "@components/Link/Link";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

export interface ProgrammeReserveAPlaceCtaProps {
  programmeSelection?: {
    fields: {
      programmeTypeCode: string;
      programmeTypeGroup: string;
    };
  };
}

export const ProgrammeReserveAPlaceCta = (props: {
  content: ProgrammeReserveAPlaceCtaProps;
}) => {
  const { content } = props;
  const { programmeSelection } = content;
  const programmeTypeCode = programmeSelection?.fields?.programmeTypeCode;
  const programmeTypeGroup = programmeSelection?.fields?.programmeTypeGroup;
  const router = useRouter();
  const [referPage, setReferPage] = useState("");
  useEffect(() => {
    setReferPage(router?.asPath);
  }, [router]);

  const { siteSettings } = useSettings();

  // Reserve A Place URL - Set in Site Settings on the Programme CTA Pages field
  const reserveAPlace = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.ReserveAPlace
  )[0];

  const reserveUrl = `/${reserveAPlace?.url?.slug}?programmeTypeCode=${programmeTypeCode}&programmeTypeGroup=${programmeTypeGroup}&referPage=${referPage}`;

  return (
    <p>
      Not ready to apply yet? <Link href={reserveUrl}>Reserve a place</Link>
    </p>
  );
};

export default ProgrammeReserveAPlaceCta;

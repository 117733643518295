import { themr } from "@friendsofreactjs/react-css-themr";
import DynamicPieRegular, { standardPie } from "./DynamicPie";

import styles from "./C252_DynamicPie.module.scss";

export interface StandardPieWrapper {
  title: string;
  graphs: Array<{ fields: standardPie }>;
  isBigTitle: boolean;
  isBlue?: boolean;
}

export const DynamicPieWrapperStandard = ({
  theme,
  content,
}: {
  theme?: any;
  content: StandardPieWrapper;
}) => {
  const { title, graphs, isBlue, isBigTitle } = content;

  return (
    <div className={`outer-wrap ${isBlue && "blue"}`}>
      <div className={theme["dynamic-pie"]} data-items={graphs.length}>
        {isBigTitle ? <h2>{title}</h2> : <h3>{title}</h3>}
        <div className="wrapper">
          <div className={`${styles.container}`}>
            {graphs?.map((pie, i) => {
              return (
                <DynamicPieRegular
                  key={`${title}${i}`}
                  theme={theme}
                  {...pie.fields}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default themr(
  "DynamicPieWrapperStandard",
  styles
)(DynamicPieWrapperStandard);

import { Results } from "../CoveoSearchComponent";
import { convertToBulletedListInCoveoTemplate } from "@utilities/convertToBulletedList";
import { useContext } from "react";
import {
  ProgrammeContext,
  ProgrammeProps,
} from "../../../context/programmeComparison";

interface ProgrammeDetailResultsTemplateProps {
  result: Results;
  addProgramme: Function;
  removeProgramme: Function;
}

const ProgrammeDetailResultsTemplate = (
  props: ProgrammeDetailResultsTemplateProps
) => {
  const { addProgramme, removeProgramme, result } = props;
  const { programmes } = useContext(ProgrammeContext);
  const overLayCheck = (e, programme: ProgrammeProps) => {
    if (e.target.checked) {
      addProgramme({
        programmeTitle: programme.programmeTitle,
        programmeCode: programme.programmeCode,
        programmeType: "EE",
      });
      return;
    }

    removeProgramme({
      programmeCode: programme.programmeCode,
    });
  };

  const programmeTitles = result.title?.split("|");
  const programmeTitle =
    programmeTitles?.length != 0 ? programmeTitles[0] : result.title;

  const programmeObject = {
    programmeTitle: programmeTitle,
    /* this is brittle code. We should pass the programme code to coveo results
      rather than using part of a url that could potential change structure
    */
    programmeCode:
      result.raw.programmereserveaplacelink != null
        ? result.raw.programmereserveaplacelink.split("=")[1].split("&")[0]
        : "",
    programmeType: "EE",
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month =
    result.raw.programmestartdatestring != null
      ? result.raw.programmestartdatestring.split("/")[1]
      : "";

  return (
    <>
      <div>
        <div className="component course-search-results">
          <div className="application-wrapper wrapper">
            <div className="row">
              <div className="base12">
                <div className="application-box">
                  {/* left section */}
                  <div className="left-section">
                    <a
                      className="CoveoResultLink title-link"
                      href={result.ClickUri || "#"}
                    >
                      {result.title && <h4>{programmeTitle}</h4>}
                    </a>

                    <p>{result.raw.programmedescription}</p>

                    <div className="form">
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          id={"demo-" + programmeObject.programmeCode}
                          name={"demo-" + programmeObject.programmeCode}
                          onChange={(e) => {
                            overLayCheck(e, programmeObject);
                          }}
                          checked={
                            programmes.find(
                              (p) =>
                                p.programmeCode ===
                                programmeObject.programmeCode
                            )
                              ? true
                              : false
                          }
                        />
                        <span className="tick"></span>
                        <label
                          htmlFor={"demo-" + programmeObject.programmeCode}
                        >
                          Compare
                        </label>
                      </div>
                    </div>

                    <p className="tag-info m-hide">
                      {result.raw.programmetopicsnames &&
                        result.raw.programmetopicsnames.length > 0 && (
                          <>
                            <span className="tag-label">Tags </span>

                            <span>
                              {result.raw.programmetopicsnames.join(",")}
                            </span>
                          </>
                        )}
                    </p>
                    <div className="button-margin"></div>
                    <div className="apply-wrapper">
                      <div className="button-with-link">
                        {result.raw.programmeapplynowlink != null && (
                          <a
                            href={result.raw.programmeapplynowlink}
                            className="cta"
                          >
                            Apply now
                          </a>
                        )}
                        {result.raw.programmereserveaplacelink != null && (
                          <a
                            href={result.raw.programmereserveaplacelink}
                            className="cta tertiary"
                          >
                            <span className="icon-arrow">
                              <svg
                                height="20"
                                width="20"
                                viewBox="4 4 16 16"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                focusable="false"
                              >
                                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                              </svg>
                            </span>
                            <span>Reserve a place</span>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* leftsection end */}

                  {/* right section */}
                  <div className="right-section">
                    <div className="inner-wrapper">
                      <div className="section-row-wrapper">
                        {result.raw.programmestartdatestring && (
                          <p className="section-row">
                            <span className="label">Next </span>
                            <span className="info">
                              {`${
                                result.raw.programmestartdatestring.split(
                                  "/"
                                )[2]
                              } 

                           ${monthNames[parseInt(month) - 1]}

                            
                           
                           
                            
                           ${
                             result.raw.programmestartdatestring.split("/")[0]
                           } `}
                            </span>
                          </p>
                        )}

                        <p className="section-row">
                          <span className="label">Duration </span>
                          {result.raw.programmeduration && (
                            <span className="info">
                              {result.raw.programmeduration}
                            </span>
                          )}
                        </p>
                        <p className="section-row">
                          <span className="label">Location </span>
                          {result.raw.programmelocation && (
                            <span className="info">
                              {result.raw.programmelocation}
                            </span>
                          )}
                        </p>
                        <p className="section-row">
                          <span className="label">Fees </span>
                          {result.raw.programmefees && (
                            <span className="info">
                              {result.raw.programmefees}
                            </span>
                          )}
                        </p>
                        <span className="section-row rich-text">
                          <span className="label">Delivery</span>

                          {result.raw.programmedelivery && (
                            <span className="info delivery-text">
                              {/* //{result.raw.eeprogrammedelivery} */}

                              {convertToBulletedListInCoveoTemplate(
                                result.raw.programmedelivery
                              )}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* right section end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgrammeDetailResultsTemplate;

/* COMPONENT - C206 Topic filter 1.0 */
"use strict";

export class TopicFilter {
  constructor(element, options) {
    this.root = element;
    this.options = options;
    this.valueList = this.root.querySelector(".coveo-facet-values") || [];
    this.values = this.valueList.querySelectorAll(".coveo-facet-value");
    this.mobile = window.matchMedia("(max-width: 767px)");
    this.topicToggle = this.root.querySelector(".topic-toggle");
    this.mobileToggle = this.root.querySelector(".mobile-topic-toggle");
    this.count = 10;

    this.transformLayout();

    this.mobile.addListener(() => {
      this.root.classList.remove("all-visible");
      this.valueList.removeAttribute("style");
      this.mobileToggle.textContent = "Select topics";
      this.topicToggle.textContent = "View all";
    });
  }

  transformLayout() {
    if (!this.values.length) {
      return;
    }

    this.root.classList.add("topic-filter-facet");
    this.addMobileToggle();

    if (this.values.length > this.count) {
      this.addToggle();
      this.applyListeners();
    }
  }

  setVisible() {
    [...this.values].forEach((el, i) => {
      if (i < this.count) {
        el.classList.add("visible");
      }
    });
  }

  addToggle() {
    let toggle = document.createElement("a");
    toggle.classList.add("topic-toggle");
    toggle.setAttribute("href", "#");

    toggle.textContent = "View all";

    this.valueList.insertAdjacentElement("afterend", toggle);
    this.topicToggle = this.root.querySelector(".topic-toggle");
  }

  addMobileToggle() {
    let toggle = document.createElement("button");
    toggle.classList.add("cta");
    toggle.classList.add("primary");
    toggle.classList.add("mobile-topic-toggle");

    toggle.textContent = "Select topics";

    this.root.insertAdjacentElement("beforeend", toggle);
    this.mobileToggle = this.root.querySelector(".mobile-topic-toggle");

    this.mobileToggle.addEventListener(
      "click",
      this.toggleMobileList.bind(this)
    );
  }

  toggleMobileList() {
    if (this.root.classList.contains("all-visible")) {
      this.root.classList.remove("all-visible");
      this.valueList.style.height = "0px";
      this.mobileToggle.textContent = "Select topics";
    } else {
      this.root.classList.add("all-visible");
      this.valueList.style.height = "auto";
      this.mobileToggle.textContent = "Close topics";
    }
  }

  toggleVisible(e) {
    e.preventDefault();

    if (this.root.classList.contains("all-visible")) {
      this.root.classList.remove("all-visible");
      this.topicToggle.textContent = "View all";
    } else {
      this.root.classList.add("all-visible");
      this.topicToggle.textContent = "View less";
    }
  }

  applyListeners() {
    this.topicToggle.addEventListener("click", this.toggleVisible.bind(this));
  }
}

const Component = {
  elements: [],
  objects: [],
  init: (className, options = {}) => {
    let i;
    Component.elements = document.querySelectorAll(className);
    for (i = 0; i < Component.elements.length; i += 1) {
      Component.objects.push(
        new TopicFilter(Component.elements[i], Object.assign({}, options))
      );
    }
  },
};
export default Component;

import { createContext, useRef, useState } from "react";

export interface NavigationState {
  mobileMenuHistory: any;
  setMobileMenuHistory: Function;

  setShowSearch: Function;
  setShowDesktop: Function;
  setShowMobile: Function;
  setOpenMobileMenu: Function;
  setMainNavSelected: Function;
  setMainNavSelectedIndex: Function;
  setTabSelected: Function;
  setTabSelectedIndex: Function;
  setPrimaryNavSelected: Function;
  setPrimaryNavSelectedIndex: Function;
  setSecondaryNavSelected: Function;
  setSecondaryNavSelectedIndex: Function;
  setThirdNavSelected: Function;
  setThirdNavSelectedIndex: Function;
  showSearch: boolean;
  showDesktop: boolean;
  showMobile: boolean;
  isOpenMobileMenu: boolean;
  mainNavSelected: boolean;
  mainNavSelectedIndex: string | number | null;
  tabSelected: boolean;
  tabSelectedIndex: number | null;
  primaryNavSelected: boolean;
  primaryNavSelectedIndex: number | null;
  secondaryNavSelected: boolean;
  secondaryNavSelectedIndex: string | number | null;
  thirdNavSelected: boolean;
  thirdNavSelectedIndex: string | number | null;
  handleEnter: Function;
  handleLeave: Function;
  handleReset: Function;
  navHeight: number;
  setNavHeight: Function;
}

export const initialState = {
  mobileMenuHistory: [],
  setMobileMenuHistory: () => {},

  showSearch: false,
  setShowSearch: () => {},
  showDesktop: false,
  setShowDesktop: () => {},
  showMobile: false,
  setShowMobile: () => {},
  isOpenMobileMenu: false,
  setOpenMobileMenu: () => {},
  mainNavSelected: false,
  setMainNavSelected: () => {},
  mainNavSelectedIndex: "",
  setMainNavSelectedIndex: () => {},
  tabSelected: false,
  setTabSelected: () => {},
  tabSelectedIndex: 0,
  setTabSelectedIndex: () => {},
  primaryNavSelected: false,
  setPrimaryNavSelected: () => {},
  primaryNavSelectedIndex: null,
  setPrimaryNavSelectedIndex: () => {},
  secondaryNavSelected: false,
  setSecondaryNavSelected: () => {},
  secondaryNavSelectedIndex: "",
  setSecondaryNavSelectedIndex: () => {},
  thirdNavSelected: false,
  setThirdNavSelected: () => {},
  thirdNavSelectedIndex: "",
  setThirdNavSelectedIndex: () => {},
  handleEnter: () => {},
  handleLeave: () => {},
  handleReset: () => {},
  navHeight: 350,
  setNavHeight: () => {},
};

export const NavigationStateContext =
  createContext<NavigationState>(initialState);

export const NavigationStateProvider = ({ children }) => {
  const [mobileMenuHistory, setMobileMenuHistory] = useState(
    initialState.mobileMenuHistory
  );
  const [showSearch, setShowSearch] = useState(initialState.showSearch);
  const [showDesktop, setShowDesktop] = useState(initialState.showDesktop);
  const [showMobile, setShowMobile] = useState(initialState.showMobile);
  const [isOpenMobileMenu, setOpenMobileMenu] = useState(
    initialState.isOpenMobileMenu
  );
  const [mainNavSelected, setMainNavSelected] = useState(
    initialState.mainNavSelected
  );
  const [mainNavSelectedIndex, setMainNavSelectedIndex] = useState<string>(
    initialState.mainNavSelectedIndex
  );
  const [tabSelected, setTabSelected] = useState(initialState.tabSelected);
  const [tabSelectedIndex, setTabSelectedIndex] = useState<number | null>(
    initialState.tabSelectedIndex
  );
  const [primaryNavSelected, setPrimaryNavSelected] = useState(
    initialState.primaryNavSelected
  );
  const [primaryNavSelectedIndex, setPrimaryNavSelectedIndex] = useState<
    number | null
  >(initialState.primaryNavSelectedIndex);
  const [secondaryNavSelected, setSecondaryNavSelected] = useState(
    initialState.secondaryNavSelected
  );
  const [secondaryNavSelectedIndex, setSecondaryNavSelectedIndex] =
    useState<string>(initialState.secondaryNavSelectedIndex);
  const [thirdNavSelected, setThirdNavSelected] = useState(
    initialState.thirdNavSelected
  );
  const [thirdNavSelectedIndex, setThirdNavSelectedIndex] = useState(
    initialState.thirdNavSelectedIndex
  );
  const [navHeight, setNavHeight] = useState(initialState.navHeight);

  const hideTimer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const handleEnter = (index: string) => {
    hideTimer.current && clearTimeout(hideTimer.current);

    setShowDesktop(true);
    setMainNavSelected(true);
    setMainNavSelectedIndex(index);
  };

  const handleLeave = () => {
    hideTimer.current = setTimeout(() => {
      setShowDesktop(false);
      setMainNavSelected(false);
      setMainNavSelectedIndex("");
      setNavHeight(0);
    }, 600);
  };

  const handleReset = () => {
    setOpenMobileMenu(false);
    setShowSearch(false);
    setShowDesktop(false);
    setShowMobile(false);
    setMainNavSelected(false);
    setMainNavSelectedIndex("");
    setTabSelected(false);
    setTabSelectedIndex(0);
    setPrimaryNavSelected(false);
    setPrimaryNavSelectedIndex(null);
    setSecondaryNavSelected(false);
    setSecondaryNavSelectedIndex("");
    setThirdNavSelected(false);
    setThirdNavSelectedIndex("");
  };

  return (
    <NavigationStateContext.Provider
      value={{
        mobileMenuHistory,
        setMobileMenuHistory,

        showSearch,
        setShowSearch,
        showDesktop,
        setShowDesktop,
        showMobile,
        setOpenMobileMenu,
        isOpenMobileMenu,
        setShowMobile,
        mainNavSelected,
        setMainNavSelected,
        mainNavSelectedIndex,
        setMainNavSelectedIndex,
        tabSelected,
        setTabSelected,
        tabSelectedIndex,
        setTabSelectedIndex,
        primaryNavSelected,
        setPrimaryNavSelected,
        primaryNavSelectedIndex,
        setPrimaryNavSelectedIndex,
        secondaryNavSelected,
        setSecondaryNavSelected,
        secondaryNavSelectedIndex,
        setSecondaryNavSelectedIndex,
        thirdNavSelected,
        setThirdNavSelected,
        thirdNavSelectedIndex,
        setThirdNavSelectedIndex,
        handleEnter,
        handleLeave,
        handleReset,
        navHeight,
        setNavHeight,
      }}
    >
      {children}
    </NavigationStateContext.Provider>
  );
};

export const mockNavigationStatesContext = {};

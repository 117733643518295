import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C44_ContactUsForm.module.scss";
import clsx from "clsx";
import Grid from "@components/Grid/Grid";
import FormField from "@components/Form/FormFields/FormFields";
import FormPrivacySection from "@components/FormPrivacySection/FormPrivacySection";
import MultiSelect from "@components/MultiSelect/MultiSelect";
import {
  getPersonalDetails,
  getTitles,
  setContactUsFormLoggedIn,
  setContactUsFormNotLoggedIn,
} from "../../services/forms";
import { useContext, useEffect, useState, ChangeEvent, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { UserContext } from "../../context/user";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import getCookie from "@utilities/getCookie";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import { handleSubmitFormErrors } from "@utilities/handleSubmitFormErrors";
import { useSettings } from "@utilities/context/settings";
import { useRouter } from "next/router";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";

export interface UserContactDetailsProps {
  contactUsFormTitleText: string;
  questionSelectbox: any;
  questionTitleLabelText: string;
  topicLabelText: string;
  emailLabelText: string;
  titleLabelText: string;
  firstNameLabelText: string;
  lastNameLabelText: string;
  commentEnquiryLabelText: string;
  passwordLabelText: string;
  passwordHintText: string;
  confirmPasswordLabelText: string;
  successPage: {
    fields: {
      slug: string;
    };
  };
  failedMessage: {
    fields: {
      content: any;
      contentTinyMce: any;
    };
  };
}

let firstInteract = 0;

export const C44_ContactUsForm = (props: {
  content: UserContactDetailsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    contactUsFormTitleText,
    questionSelectbox,
    questionTitleLabelText,
    topicLabelText,
    emailLabelText,
    titleLabelText,
    firstNameLabelText,
    lastNameLabelText,
    commentEnquiryLabelText,
    passwordLabelText,
    passwordHintText,
    confirmPasswordLabelText,
    failedMessage,
    successPage,
  } = content;

  const setAdditionalServiceData = (
    registrationRequired: boolean,
    associatedEmail: string,
    programmeType: string
  ) => {
    setValue("emailMailto", associatedEmail ?? "");
    setValue("programmeTypeCode", programmeType ?? "");
    setRequiresRegistration(registrationRequired);
  };

  const setFormOptionValues = (id: string) => {
    const currentValues = questionSelectbox?.fields?.options?.find(
      (option) => option.fields.value === id
    );
    setAdditionalServiceData(
      currentValues?.fields?.registrationRequired,
      currentValues?.fields?.associatedEmail,
      currentValues?.fields?.programmeType
    );
  };

  const getTopics = (id: string) => {
    const questionOptions = questionSelectbox?.fields?.options;
    const linkedTopics = questionOptions?.find(
      (option) => option.fields.value === id
    );
    const topics = linkedTopics?.fields?.linkedOptions?.map((option) => ({
      ...option,
      value: option.fields.label,
      id: option.fields.value,
    }));

    return id ? topics : { isError: true };
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, fieldName) => {
    const currentOption = event.target.id;

    if (fieldName === "title") {
      setValue("titleId", event.target.id);
    }

    if (fieldName === "topic") {
      const topicDetails = programmeTopics?.find(
        (option) => option.fields.value === currentOption
      );

      setAdditionalServiceData(
        topicDetails?.fields?.registrationRequired,
        topicDetails?.fields?.associatedEmail,
        topicDetails?.fields?.programmeType
      );
    }
  };

  const questionSubjects = questionSelectbox?.fields?.options?.map(
    (option) => ({
      value: option.fields.label,
      id: option.fields.value,
    })
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [submittedUserEmail, setSubmittedUserEmail] = useState<string>("");
  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [userTitleValue, setUserTitleValue] = useState<number | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<any>(
    questionSelectbox?.fields?.options[0]?.fields?.label || ""
  );
  const [selectedOptionId, setSelectedOptionId] = useState<any>(
    questionSelectbox?.fields?.options[0]?.value || "100000001"
  );
  const [programmeTopics, setProgrammeTopics] = useState<any>(null);
  const [titles, setTitles] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [handledSubmitFormErrors, setHandledSubmitFormErrors] =
    useState<Array<string> | null>(null);
  const [authToken, setAuthToken] = useState<string>("");
  const [requiresRegistration, setRequiresRegistration] = useState<boolean>(
    questionSelectbox?.fields?.options[0]?.registrationRequired || false
  );

  const { user } = useContext(UserContext);

  const { register, handleSubmit, formState, watch, setValue } = useForm({
    mode: "onBlur",
  });

  const router = useRouter();
  const [loggedInUser, setLoggedInUser] = useState<any>(null);

  const { apiErrorMessages } = useSettings();
  useEffect(() => {
    user ? setLoggedInUser(true) : setLoggedInUser(null);
    if (user) {
      setAuthToken(getCookie("access_token"));
    }
  }, [user]);

  useEffect(() => {
    setProgrammeTopics(getTopics(selectedOptionId));
    setFormOptionValues(selectedOptionId);
  }, [selectedOptionId]);

  useEffect(() => {
    setProgrammeTopics(
      getTopics(questionSelectbox?.fields?.options[0]?.fields?.value)
    );
    setFormOptionValues(questionSelectbox?.fields?.options[0]?.fields?.value);
  }, []);

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "ContactUs",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (loggedInUser) {
      const contactUsFormData = async () => {
        try {
          const [userPersonalDetails] = await Promise.all([
            getPersonalDetails(authToken),
          ]);
          const [titles] = await Promise.all([getTitles()]);
          const title = titles.find(
            (item) => item.id === userPersonalDetails?.result?.title
          );
          setUserTitleValue(title?.value);
          setUserPersonalDetails(
            userPersonalDetails.isError ? null : userPersonalDetails
          );
        } catch (e) {
          setError(true);
        }
      };

      contactUsFormData();
    } else {
      const contactUsFormData = async () => {
        try {
          const [titles] = await Promise.all([getTitles()]);
          setTitles(titles.isError ? null : titles);
        } catch (e) {
          setError(true);
        }
      };

      contactUsFormData();
    }
  }, [loggedInUser]);

  const FormFields = [
    {
      propertyName: "topic",
      validation: {
        isRequired: true,
      },
      labelText: topicLabelText,
      options: programmeTopics,
      formType: "select",
      dataText: programmeTopics && programmeTopics[0]?.value,
    },
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      dataText: userPersonalDetails?.result?.emailAddress,
    },
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      labelText: titleLabelText,
      options: titles,
      formType: "select",
      dataText: titles && (loggedInUser ? userTitleValue : titles[0]?.value),
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
      dataText: userPersonalDetails?.result?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
      dataText: userPersonalDetails?.result?.lastName,
    },
    {
      propertyName: "objectivesText",
      validation: {
        isRequired: true,
        maxLength: 140,
      },
      labelText: commentEnquiryLabelText,
      formType: "textarea",
    },
    {
      propertyName: "password",
      validation: {
        isRequired: true,
        maxLength: 30,
        password: true,
      },
      type: "password",
      hintText: passwordHintText,
      labelText: passwordLabelText,
      dataText: userPersonalDetails?.password,
    },
    {
      propertyName: "confirmPassword",
      validation: {
        isRequired: true,
        maxLength: 30,
        password: true,
      },
      type: "password",
      labelText: confirmPasswordLabelText,
    },
  ];

  const getSubmitBody = (data, loggedInUser) => {
    let mainData: any = {
      programmeType: {
        isProgrammeTypeRequired: false,
        programmeTypeCode: data.programmeTypeCode,
      },
      comments: data.objectivesText,
    };

    if (!loggedInUser) {
      mainData.profile = {
        emailAddress: data.emailAddress,
        firstName: data.firstName,
        isStayInformed: data.isStayInformed === "true",
        lastName: data.lastName,
        password: data.password,
        title: data.titleId,
      };
    }

    return mainData;
  };

  const redirectToSuccessfulPage = async () => {
    setGADataLayer({
      event: "formComplete",
      formName: "ContactUs",
      businessUnit: getBusinessUnit(),
      programmeCode: "",
      eventType: "",
    });
    const successPageUrl = successPage?.fields?.slug ?? "/404";
    await router.push(successPageUrl);
  };

  const handleRequiresRegistration = async (data) => {
    try {
      let response;
      if (loggedInUser) {
        response = await setContactUsFormLoggedIn(
          getSubmitBody(data, loggedInUser),
          sessionStorage.getItem("crm_campaign"),
          getCookie("access_token")
        );
      } else {
        response = await setContactUsFormNotLoggedIn(
          getSubmitBody(data, loggedInUser),
          sessionStorage.getItem("crm_campaign")
        );
      }
      if (response.status) setLoading(false);
      if (response.status === 201) {
        await redirectToSuccessfulPage();
      } else {
        const handledSubmitFormErrors = handleSubmitFormErrors(
          response,
          apiErrorMessages
        );
        window.scrollTo(0, 0);
        setHandledSubmitFormErrors(handledSubmitFormErrors);
      }
    } catch (e) {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        null,
        apiErrorMessages
      );
      setHandledSubmitFormErrors(handledSubmitFormErrors);
    }
  };

  const handleSendingEmail = async (data) => {
    try {
      await fetch("/.netlify/functions/triggerContactUsEmail", {
        method: "POST",
        body: JSON.stringify({
          question: selectedSubject,
          title: loggedInUser ? userTitleValue : data.title,
          firstName: loggedInUser
            ? userPersonalDetails?.result?.firstName
            : data.firstName,
          lastName: loggedInUser
            ? userPersonalDetails?.result?.lastName
            : data.lastName,
          emailAddress: loggedInUser
            ? userPersonalDetails?.result?.emailAddress
            : data.emailAddress,
          topic: data.topic,
          comment: data.objectivesText,
          emailMailto: data.emailMailto,
        }),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setSubmittedUserEmail(data?.emailAddress);
    if (data.emailMailto) {
      handleSendingEmail(data);
    }

    if (requiresRegistration) {
      await handleRequiresRegistration(data);
    } else {
      await redirectToSuccessfulPage();
    }
  };

  if (error) {
    return (
      <Grid row>
        <Grid column sm={12}>
          <div className="wrapper">
            <RichTextDisplay
              richText={failedMessage?.fields?.content}
              tinyMceRichText={failedMessage?.fields?.contentTinyMce}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  const FieldJSX = ({ labelText = "", dataText }) => (
    <div className={theme["field-row"]}>
      <label>{labelText}:</label>
      {dataText ? <p>{dataText}</p> : ""}
    </div>
  );

  const mapFieldSorted = {
    emailAddress: true,
    firstName: true,
    lastName: true,
    title: true,
    password: false,
    confirmPassword: false,
  };

  const renderRegularFormFields = (field, index) => {
    const isShouldHidePassword = !requiresRegistration || loggedInUser;

    if (field?.propertyName === "password" && isShouldHidePassword) return null;
    if (field?.propertyName === "confirmPassword" && isShouldHidePassword)
      return null;

    return (
      <FormField
        formType={field?.formType}
        watch={watch}
        validation={field?.validation}
        type={field.type ? field.type : "text"}
        register={register}
        property={field.dataText}
        key={`${field?.labelText}${index}`}
        hintText={field?.hintText}
        placeholder={field.labelText}
        options={field.options}
        name={field?.propertyName}
        errors={formState.errors}
        onChange={(e: FormEvent) =>
          handleOnChange(
            e as ChangeEvent<HTMLInputElement>,
            field?.propertyName
          )
        }
      />
    );
  };

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(theme.component, "component", theme.form)}>
            <h1 className="h2">{contactUsFormTitleText}</h1>
          </div>
          <section
            className={clsx(
              theme.component,
              theme["contact-us"],
              theme["form"],
              "form",
              theme.cf
            )}
          >
            {handledSubmitFormErrors &&
              handledSubmitFormErrors.map((error, index) => (
                <FormErrorMessage
                  key={index}
                  text={error}
                  userEmail={submittedUserEmail}
                />
              ))}
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className={theme["fields-floated"]}>
                <p>
                  Please complete the form below with details of your request.
                  We’ll get back to you by email, so make sure you include the
                  right email address.
                </p>

                <div className="form-row">
                  <MultiSelect
                    labelText={questionTitleLabelText}
                    questionSubjects={questionSubjects}
                    setSelectedOptionId={setSelectedOptionId}
                    selectedSubject={selectedSubject}
                    setSelectedSubject={setSelectedSubject}
                    register={register}
                  />
                  <section className={theme["form-group-wrapper"]}>
                    {FormFields.map((field, index) => {
                      return loggedInUser &&
                        mapFieldSorted[field.propertyName] ? (
                        <FieldJSX
                          key={`${field?.labelText}-${index}`}
                          labelText={field?.labelText}
                          dataText={field?.dataText}
                        />
                      ) : (
                        renderRegularFormFields(field, index)
                      );
                    })}
                  </section>
                </div>

                <FormPrivacySection
                  register={register}
                  formState={formState}
                  isShowStayInformed={!loggedInUser && requiresRegistration}
                  isShowPrivacyTitle={!loggedInUser && requiresRegistration}
                />

                <div className="btn-and-text-wrapper">
                  <SubmitButton
                    loading={loading}
                    id="submitButton"
                    text="Submit"
                  />
                </div>
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("C44_ContactUsForm", styles)(C44_ContactUsForm);

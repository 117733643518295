import React from "react";
import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import { ctaLink } from "@customTypes/generalLink";
import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C261_Contact.module.scss";
import MissingContent from "@components/MissingContentWarning";

export interface ContactDetailsProps {
  title: string;
  isExtended: boolean;
  name: string;
  phoneNumber: string;
  emailAddress: string;
  ctaLink1: ctaLink;
  ctaLink2: ctaLink;
  theme?: any;
}

export const ContactDetails = (props: ContactDetailsProps) => {
  const {
    isExtended,
    name,
    phoneNumber,
    emailAddress,
    ctaLink1,
    ctaLink2,
    theme,
  } = props;
  const ctaData = [ctaLink1, ctaLink2];

  const ContactLinks = () => (
    <>
      {phoneNumber && (
        <p className={theme["contact-link"]}>
          <Cta
            linkText={phoneNumber}
            type="tertiary"
            url={`tel:${phoneNumber}`}
            light={true}
            icon="icon-call"
            contentClass="icon"
            openInNewTab
          />
        </p>
      )}
      {emailAddress && (
        <p className={theme["contact-link"]}>
          <Cta
            linkText={emailAddress}
            type="tertiary"
            url={`mailto:${emailAddress}`}
            light={true}
            icon="icon-email"
            contentClass=""
            openInNewTab
          />
        </p>
      )}
    </>
  );

  const ContactOptionalLinks = () => {
    try {
      return (
        <>
          {ctaData &&
            ctaData.map((cta, index) => {
              return cta && cta.linkText ? (
                <p key={`${cta.linkText}${index}`}>
                  <Cta
                    {...cta}
                    type="tertiary"
                    light={true}
                    icon="icon-arrow-small"
                    contentClass="icon"
                  />
                </p>
              ) : null;
            })}
        </>
      );
    } catch (e) {
      return <MissingContent error={e} component="C261_ContactDetails" />;
    }
  };

  return (
    <>
      {!isExtended ? (
        <Grid column sm={12} md={6}>
          <h5>{name}</h5>
          <ContactLinks />
          <div className={theme["option-links"]}>
            <ContactOptionalLinks />
          </div>
        </Grid>
      ) : (
        <Grid row>
          <Grid column sm={12} md={6}>
            <div className={theme["contact-details"]}>
              <ContactLinks />
            </div>
          </Grid>
          <Grid column sm={12} md={6}>
            <div className={theme["contact-ctas"]}>
              <ContactOptionalLinks />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default themr("ContactDetails", styles)(ContactDetails);

import React from "react";
import Components from "../../__Components__/Components";
import Grid from "@components/Grid/Grid";
import RenderComponents from "@utilities/renderComponents";

export interface CampaignLandingPageProps {
  headerBanner?: any;
  bodyContent?: any;
}

export const CampaignLandingPage = (props: {
  content: CampaignLandingPageProps;
}) => {
  const { content } = props;
  const { bodyContent, headerBanner } = content;

  return (
    <Grid row>
      <Grid column sm={12}>
        {headerBanner &&
          Components(
            {
              component: headerBanner.sys.contentType,
              uid: headerBanner.sys.id,
              ...headerBanner.fields,
            },
            headerBanner.fields
          )}

        {RenderComponents(bodyContent)}
      </Grid>
    </Grid>
  );
};

export default CampaignLandingPage;

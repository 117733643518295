import { ImageProps, StaticImageData } from "next/legacy/image";

export type ContentfulImageLoaderProps = {
  src: string | StaticImageData;
  width?: number;
  height?: number;
  quality?: number;
  isCrop?: boolean;
  isSmartCrop?: boolean;
  cropFocus?:
    | "center"
    | "top"
    | "right"
    | "left"
    | "bottom"
    | "top_right"
    | "top_left"
    | "bottom_right"
    | "bottom_left"
    | "faces";
  layout?: ImageProps["layout"];
};
//NonNullable<JSX.IntrinsicElements['img']['style']>
export default function contentfulLoader({
  src,
  width,
  height,
  quality = 75,
  isCrop,
  isSmartCrop,
  cropFocus,
  layout,
}: ContentfulImageLoaderProps) {
  if (typeof src !== "string") return src.src;
  try {
    const url = new URL(src as string, process.env.NEXT_PUBLIC_IMAGE_BASE);
    if (isCrop) {
      url.searchParams.set("fit", "fill");
    } else if (isSmartCrop) {
      // and focuses on faces
      url.searchParams.set("fit", "fill");
      url.searchParams.set("f", "faces");
    } else if (cropFocus) {
      // or focuses on selected option
      url.searchParams.set("fit", "fill");
      url.searchParams.set("f", cropFocus);
    }
    //do not alter quality if the image is an svg;
    if (!src?.includes(".svg")) {
      url.searchParams.set("fm", "webp");
      if (!layout) {
        url.searchParams.set("w", width.toString());
        url.searchParams.set("h", height.toString());
        url.searchParams.set("q", quality.toString());
      }
    }
    return url.href;
  } catch {
    return src;
  }
}

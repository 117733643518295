import { ProfileDetailsProps, SelectItem } from "./types";
import { findMatchingSelectValue } from "@utilities/mapSelectValue";
export const permanentFields = [
  "emailAddress",
  "title",
  "firstName",
  "lastName",
];

export const getFormInitialValues = (
  content: ProfileDetailsProps,
  userPersonalDetails,
  loggedInUser: boolean,
  preferredDates: SelectItem[],
  titles: SelectItem[],
  industries: SelectItem[],
  reasons: SelectItem[],
  watch
) => {
  const {
    emailLabelText,
    firstNameLabelText,
    familyNameLabelText,
    homeCountryLabelText,
    workCompanyNameLabelText,
    telephoneNumberHintHelpText,
    whatLedYouListLabelText,
    telephoneNumberLabelText,
    titleLabelText,
    industryLabelText,
    objectivesTextLabelText,
    preferredDateLabelText,
    yearsManagerialExperienceLabelText,
    passwordLabelText,
    passwordHintText,
    confirmPasswordLabelText,
  } = content;

  return [
    {
      propertyName: "preferredDates",
      validation: {
        isRequired: true,
      },
      labelText: preferredDateLabelText,
      options: preferredDates,
      formType: "select",
    },
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      dataText: userPersonalDetails?.result?.emailAddress,
    },
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      options: titles,
      formType: "select",
      labelText: titleLabelText,
      dataText:
        titles &&
        findMatchingSelectValue(titles, userPersonalDetails?.result?.title),
    },

    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
      dataText: userPersonalDetails?.result?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: familyNameLabelText,
      dataText: userPersonalDetails?.result?.lastName,
    },
    {
      validation: {
        telephone: true,
      },
      optionalText: false,
      hintText: telephoneNumberHintHelpText,
      propertyName: "telephoneNumber",
      labelText: telephoneNumberLabelText,
    },
    {
      validation: {
        maxLength: 50,
        isRequired: true,
      },
      propertyName: "homeCountry",
      labelText: homeCountryLabelText,
      validationMsg: "Country of residence is required",
    },
    {
      validation: {
        maxLength: 50,
        isRequired: true,
      },
      propertyName: "companyName",
      labelText: workCompanyNameLabelText,
    },
    {
      validation: {
        integerValidator: true,
        isRequired: true,
      },
      propertyName: "yearsManagerialExperience",
      labelText: yearsManagerialExperienceLabelText,
    },
    {
      validation: {
        isRequired: true,
      },
      options: industries,
      dataText: userPersonalDetails?.industry?.value,
      propertyName: "industry",
      labelText: industryLabelText,
      formType: "select",
    },
    {
      validation: {
        isRequired: true,
      },
      dataText: reasons && reasons[0]?.value,
      labelText: whatLedYouListLabelText,
      propertyName: "reasons",
      options: reasons,
      formType: "select",
    },
    {
      validation: {
        isRequired: true,
        maxLength: 140,
      },
      propertyName: "objectivesTextLabelText",
      labelText: objectivesTextLabelText,
      formType: "textarea",
    },
    {
      propertyName: "password",
      validation: {
        isRequired: true,
        maxLength: 30,
        password: true,
      },
      type: "password",
      hintText: passwordHintText,
      labelText: passwordLabelText,
      dataText: userPersonalDetails?.password,
      isHidden: loggedInUser,
    },
    {
      propertyName: "confirmPassword",
      validation: {
        isRequired: true,
        maxLength: 30,
        confirmPassword: true,
      },
      disabled: !watch("password"),
      type: "password",
      labelText: confirmPasswordLabelText,
      isHidden: loggedInUser,
    },
  ];
};

import Script from "next/script";
import { useEffect, useState } from "react";
export const LinkedIn = (props: { formId: string }) => {
  const { formId } = props;

  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    setPageLoaded(true);
  }, []);

  return (
    <>
      <Script
        src="https://www.linkedin.com/autofill/js/autofill.js"
        type="text/javascript"
        async
      />

      {pageLoaded ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `
          <script
            type="IN/Form2"
            data-form=${formId}
            data-field-firstname="firstName"
            data-field-lastname="lastName"
            data-field-email="emailAddress"
            data-field-company="companyName"
          >
          </script>`,
          }}
        ></div>
      ) : null}
    </>
  );
};

export default LinkedIn;

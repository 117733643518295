import React from "react";
import Grid from "@components/Grid/Grid";
import clsx from "clsx";
import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "@components/__templates__/CommonSuccessPage/CommonSuccessPage.module.scss";
import Link from "@components/Link/Link";
import { useRouter } from "next/router";

export interface CommonSuccessPageThemeProps {
  component: string;
}

export interface CommonSuccessPageProps {
  theme: CommonSuccessPageThemeProps;
  successHeaderTitleText: string;
  successDescriptionText: string;
  downloadBrochureLinkText: string;
  returnToPreviousPageLinkText: string;
  verifyEmailText: string;
}

const CommonSuccessPage = (props: {
  content: CommonSuccessPageProps;
  theme: CommonSuccessPageThemeProps;
}) => {
  const { content, theme } = props;
  const {
    successHeaderTitleText,
    successDescriptionText,
    downloadBrochureLinkText,
    verifyEmailText,
    returnToPreviousPageLinkText,
  } = content;

  const router = useRouter();

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;
  const returnToPreviousPageUrl = urlParams?.get("returnToPreviousPageUrl");
  const downloadBrochureUrl = urlParams?.get("downloadBrochureUrl");
  const eventName = urlParams?.get("eventName");
  const status = urlParams?.get("status");

  const returnToOriginalPage = () => {
    router.push(`${returnToPreviousPageUrl}`, undefined, {
      shallow: true,
    });
  };

  return (
    <div className="component-wrap">
      <Grid row>
        <Grid column sm={12}>
          <div className={clsx("component", theme["success-common"])}>
            <div className="wrapper">
              <Grid row>
                <Grid column sm={12} md={12} lg={12}>
                  <h1 className={clsx(theme["section-title"], theme["h2"])}>
                    {successHeaderTitleText} {eventName}
                  </h1>
                  <div className={theme["success-text"]}>
                    <p>{successDescriptionText}</p>
                  </div>
                  {status === "verification" && (
                    <div className={theme["success-text"]}>
                      <p>{verifyEmailText}</p>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className={clsx(theme["links-wrapper"])}>
              <div className="wrapper">
                <Grid row>
                  <Grid column sm={12} md={12} lg={12}>
                    <div className={theme["userLink-section"]}>
                      {downloadBrochureUrl && (
                        <p>
                          <Link href={`${downloadBrochureUrl}`}>
                            <a
                              href={`${downloadBrochureUrl}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {downloadBrochureLinkText}
                            </a>
                          </Link>
                        </p>
                      )}

                      {returnToPreviousPageUrl && (
                        <p>
                          <Link href={`${returnToPreviousPageUrl}`}>
                            <a onClick={returnToOriginalPage}>
                              {returnToPreviousPageLinkText}
                            </a>
                          </Link>
                        </p>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("CommonSuccessPage", styles)(CommonSuccessPage);

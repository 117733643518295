import { useEffect, useState, useContext } from "react";
import { themr } from "@friendsofreactjs/react-css-themr";
import ClipLoader from "react-spinners/ClipLoader";
import clsx from "clsx";
import Link from "@components/Link/Link";
import Cta from "../Cta/Cta";
import {
  getCareerAspirations,
  getGenders,
  getIndustries,
  getIntendedYearsOfStudy,
  getPersonalDetails,
  getPreferredStudyFormat,
  getPreferredStudyLocation,
} from "../../services/forms";
import Grid from "@components/Grid/Grid";
import ApiError from "@components/GenericApiError/Error";
import { UserContext } from "../../context/user";
import getCookie from "@utilities/getCookie";
import { getTitles } from "../../services/forms";
import { useSettings } from "@utilities/context/settings";

import styles from "./C63_1_MyProfilePersonalDetails.module.scss";
import { findMatchingSelectValue } from "@utilities/mapSelectValue";

export interface ProfileDetailsProps {
  aboutYouTitle: string;
  countryCodeLabelText: string;
  educationalInstitutesLabelText: string;
  emailLabelText: string;
  firstNameLabelText: string;
  genderLabelText: string;
  gmatScoreLabelText: string;
  gmatFocusScoreLabelText: string;
  gmatTotalScoreLabelText: string;
  gmatFocusInformationTitle: string;
  greAwaPercentileLabelText: string;
  greAwaScoreLabelText: string;
  greInformationTitle: string;
  greQuantitativePercentileLabelText: string;
  greQuantitativeScoreLabelText: string;
  greVerbalPercentileLabelText: string;
  greVerbalScoreLabelText: string;
  highestDegreeLabelText: string;
  homeAddress1LabelText: string;
  homeAddress2LabelText: string;
  homeCityLabelText: string;
  homeCountyStateLabelText: string;
  homePostcodeLabelText: string;
  industryLabelText: string;
  linkedInProfileLabelText: string;
  loginMessage: string;
  yourProfessionalProfileTitle: string;
  nationalityLabelText: string;
  personalInformationTitleLabelText: string;
  secondNationalityLabelText: string;
  skypeAccountLabelText: string;
  telephoneNumberLabelText: string;
  titleLabelText: string;
  workJobTitleLabelText: string;
  yearsManagerialExperienceLabelText: string;
  yearsRelevantExperienceLabelText: string;
  yourEducationProfileTitle: string;
  careerAspirationsLabelText: string;
  workCompanyNameLabelText: string;
  gmatInformationTitle: string;
  familyNameLabelText: string;
  yourAspirationsTitle: string;
  homeCountryLabelText: string;
  facebookAccountLabelText: string;
  twitterAccountLabelText: string;
  preferredStudyLocationLabelText: string;
  preferredStudyFormatLabelText: string;
  intendedYearOfStudyLabelText: string;
  changeEmailText: string;
  changePasswordText: string;
  deactivateAccountText: string;
  yourRightText: string;
}

export const MyProfilePersonalDetails = (props: {
  content: ProfileDetailsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    aboutYouTitle,
    educationalInstitutesLabelText,
    emailLabelText,
    firstNameLabelText,
    genderLabelText,
    gmatTotalScoreLabelText,
    gmatFocusScoreLabelText,
    gmatFocusInformationTitle,
    greAwaPercentileLabelText,
    greAwaScoreLabelText,
    greInformationTitle,
    yourProfessionalProfileTitle,
    greQuantitativePercentileLabelText,
    greQuantitativeScoreLabelText,
    greVerbalPercentileLabelText,
    greVerbalScoreLabelText,
    highestDegreeLabelText,
    homeAddress1LabelText,
    homeAddress2LabelText,
    homeCityLabelText,
    homeCountyStateLabelText,
    homePostcodeLabelText,
    industryLabelText,
    linkedInProfileLabelText,
    loginMessage,
    nationalityLabelText,
    yourEducationProfileTitle,
    careerAspirationsLabelText,
    workCompanyNameLabelText,
    gmatInformationTitle,
    familyNameLabelText,
    yourAspirationsTitle,
    homeCountryLabelText,
    facebookAccountLabelText,
    twitterAccountLabelText,
    personalInformationTitleLabelText,
    secondNationalityLabelText,
    skypeAccountLabelText,
    telephoneNumberLabelText,
    titleLabelText,
    workJobTitleLabelText,
    yearsManagerialExperienceLabelText,
    yearsRelevantExperienceLabelText,
    preferredStudyLocationLabelText,
    preferredStudyFormatLabelText,
    intendedYearOfStudyLabelText,
    changeEmailText,
    changePasswordText,
    deactivateAccountText,
    yourRightText,
  } = content;

  const [personalDetails, setPersonalDetails] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const { siteSettings } = useSettings();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const personalDetailsAPI = async () => {
      if (user) {
        try {
          const details = await getPersonalDetails(getCookie("access_token"));
          const titles = await getTitles();
          const genders = await getGenders();
          const industries = await getIndustries();
          const careerAspirations = await getCareerAspirations();
          const preferredStudyFormats = await getPreferredStudyFormat();
          const preferredStudyLocations = await getPreferredStudyLocation();
          const intendedYearsOfStudy = await getIntendedYearsOfStudy();
          const title = findMatchingSelectValue(titles, details?.result?.title);
          const gender = findMatchingSelectValue(
            genders,
            details?.result?.gender
          );
          const industry = findMatchingSelectValue(
            industries,
            details?.result?.industry
          );
          const careerAspiration = findMatchingSelectValue(
            careerAspirations,
            details?.result?.careerAspiration
          );
          const intendedYearOfStudy = findMatchingSelectValue(
            intendedYearsOfStudy,
            details?.result?.intendedYearOfStudy
          );
          const preferredStudyFormat = findMatchingSelectValue(
            preferredStudyFormats,
            details?.result?.preferredStudyFormat
          );
          const preferredStudyLocation = findMatchingSelectValue(
            preferredStudyLocations,
            details?.result?.preferredStudyLocation
          );

          setPersonalDetails({
            ...details?.result,
            title,
            gender,
            industry,
            careerAspiration,
            intendedYearOfStudy,
            preferredStudyFormat,
            preferredStudyLocation,
          });
        } catch (e) {
          console.log(e);
          setError(true);
        }
      }
    };

    personalDetailsAPI();
  }, [user]);

  if (error) {
    return <ApiError />;
  }

  const FieldJSX = ({ labelText = "", dataText }) => (
    <div className={theme["field-row"]}>
      <label>{labelText}:</label>
      {dataText ? <p>{dataText}</p> : ""}
    </div>
  );

  const aboutYouFields = [
    { labelText: titleLabelText, dataText: personalDetails?.title },

    { labelText: firstNameLabelText, dataText: personalDetails?.firstName },

    { labelText: familyNameLabelText, dataText: personalDetails?.lastName },

    { labelText: genderLabelText, dataText: personalDetails?.gender },

    { labelText: nationalityLabelText, dataText: personalDetails?.nationality },

    {
      labelText: secondNationalityLabelText,
      dataText: personalDetails?.secondNationality,
    },
  ];

  const personalInformationFields = [
    // To do: will be implemented later
    /*    {
      labelText: emailLabelText,
      dataText: personalDetails?.emailAddress,
    },*/
    {
      labelText: telephoneNumberLabelText,
      dataText: personalDetails?.telephoneNumber,
    },
    {
      labelText: homeAddress1LabelText,
      dataText: personalDetails?.address?.addressLine1,
    },
    {
      labelText: homeAddress2LabelText,
      dataText: personalDetails?.address?.addressLine2,
    },
    {
      labelText: homeCityLabelText,
      dataText: personalDetails?.address?.city,
    },
    {
      labelText: homeCountyStateLabelText,
      dataText: personalDetails?.address?.stateCounty,
    },
    {
      labelText: homePostcodeLabelText,
      dataText: personalDetails?.address?.postCode,
    },
    {
      labelText: homeCountryLabelText,
      dataText: personalDetails?.address?.homeCountry,
    },

    {
      labelText: facebookAccountLabelText,
      dataText: personalDetails?.facebookProfile,
    },

    {
      labelText: twitterAccountLabelText,
      dataText: personalDetails?.twitterHandle,
    },

    {
      labelText: skypeAccountLabelText,
      dataText: personalDetails?.skypeId,
    },

    {
      labelText: linkedInProfileLabelText,
      dataText: personalDetails?.linkedInUrl,
    },
  ];

  const professionalDetails = personalDetails?.employment;
  const professionalProfileFields = [
    {
      dataText: professionalDetails?.jobTitle,
      labelText: workJobTitleLabelText,
    },

    {
      dataText: professionalDetails?.company,
      labelText: workCompanyNameLabelText,
    },

    {
      dataText: personalDetails?.yearsOfExperience,
      labelText: yearsRelevantExperienceLabelText,
    },

    {
      dataText: personalDetails?.yearsOfManagerialExperience,
      labelText: yearsManagerialExperienceLabelText,
    },

    {
      dataText: professionalDetails?.address?.addressLine1,
      labelText: homeAddress1LabelText,
    },

    {
      dataText: professionalDetails?.address?.addressLine2,
      labelText: homeAddress2LabelText,
    },

    {
      dataText: professionalDetails?.address?.city,
      labelText: homeCityLabelText,
    },

    {
      dataText: professionalDetails?.address?.stateCounty,
      labelText: homeCountyStateLabelText,
    },

    {
      dataText: professionalDetails?.address?.postCode,

      labelText: homePostcodeLabelText,
    },

    {
      dataText: professionalDetails?.address?.homeCountry,
      labelText: homeCountryLabelText,
    },
    {
      dataText: personalDetails?.industry,
      labelText: industryLabelText,
    },
  ];

  const educationProfileFields = [
    {
      dataText: personalDetails?.education?.academicInstitution,
      labelText: educationalInstitutesLabelText,
    },
    {
      dataText: personalDetails?.education?.highestDegree,
      labelText: highestDegreeLabelText,
    },
  ];

  const greInformationFields = [
    {
      dataText: personalDetails?.scores?.verbalScore,
      labelText: greVerbalScoreLabelText,
    },
    {
      dataText: personalDetails?.scores?.verbalPercentile,
      labelText: greVerbalPercentileLabelText,
    },
    {
      dataText: personalDetails?.scores?.quantativeScore,
      labelText: greQuantitativeScoreLabelText,
    },
    {
      dataText: personalDetails?.scores?.quantativePercentile,
      labelText: greQuantitativePercentileLabelText,
    },
    {
      dataText: personalDetails?.scores?.awaScore,
      labelText: greAwaScoreLabelText,
    },
    {
      dataText: personalDetails?.scores?.awaPercentile,
      labelText: greAwaPercentileLabelText,
    },
  ];

  const aspirationsFields = [
    {
      dataText: personalDetails?.careerAspiration,
      labelText: careerAspirationsLabelText,
    },

    {
      dataText: personalDetails?.intendedYearOfStudy,
      labelText: intendedYearOfStudyLabelText,
    },

    {
      dataText: personalDetails?.preferredStudyFormat,
      labelText: preferredStudyFormatLabelText,
    },

    {
      dataText: personalDetails?.preferredStudyLocation,
      labelText: preferredStudyLocationLabelText,
    },
  ];

  if (!personalDetails) {
    return (
      <div className={styles.spinner}>
        <ClipLoader
          color="#001e62"
          size={70}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className="component form">
            <h1 className="h2"></h1>
          </div>
          <section
            className={clsx(
              theme.component,
              theme.form,
              theme["my-profile"],
              theme.cf
            )}
          >
            <div className={theme["fields-floated"]}>
              {!user ? (
                <p>{loginMessage}</p>
              ) : (
                <>
                  <h2 className={theme["section-heading"]}>{aboutYouTitle}</h2>
                  {aboutYouFields.map((field, index) => (
                    <FieldJSX
                      key={`${field.labelText}-${index}`}
                      labelText={field.labelText}
                      dataText={field.dataText}
                    />
                  ))}
                  <div
                    className={clsx(theme["field-row"], theme["edit-details"])}
                  >
                    <Link href="/edit-profile-details" passHref>
                      <a className="cta">Edit Details</a>
                    </Link>
                  </div>

                  <h2 className={theme["section-heading"]}>
                    <span>{personalInformationTitleLabelText}</span>
                  </h2>
                  {personalInformationFields.map((field, index) => (
                    <FieldJSX
                      key={`${field.labelText}-${index}`}
                      labelText={field.labelText}
                      dataText={field.dataText}
                    />
                  ))}

                  <div
                    className={clsx(theme["field-row"], theme["edit-details"])}
                  >
                    <Link href="/edit-profile-details" passHref>
                      <a className="cta">Edit Details</a>
                    </Link>
                  </div>

                  <h2 className={theme["section-heading"]}>
                    <span>{yourProfessionalProfileTitle}</span>
                  </h2>

                  {professionalProfileFields.map((field, index) => (
                    <FieldJSX
                      key={`${field.labelText}-${index}`}
                      labelText={field.labelText}
                      dataText={field.dataText}
                    />
                  ))}

                  <div
                    className={clsx(theme["field-row"], theme["edit-details"])}
                  >
                    <Link href="/edit-profile-details" passHref>
                      <a className="cta">Edit Details</a>
                    </Link>
                  </div>

                  <h2 className={theme["section-heading"]}>
                    <span>{yourEducationProfileTitle}</span>
                  </h2>

                  {educationProfileFields.map((field, index) => (
                    <FieldJSX
                      key={`${field.labelText}-${index}`}
                      labelText={field.labelText}
                      dataText={field.dataText}
                    />
                  ))}

                  <h3 className={theme["sub-heading"]}>
                    {gmatInformationTitle}
                  </h3>
                  <FieldJSX
                    dataText={personalDetails?.scores?.gmatScore}
                    labelText={gmatTotalScoreLabelText}
                  />

                  <h3 className={theme["sub-heading"]}>
                    {gmatFocusInformationTitle}
                  </h3>
                  <FieldJSX
                    dataText={personalDetails?.scores?.gmatFocusTotalScore}
                    labelText={gmatFocusScoreLabelText}
                  />

                  <h3 className={theme["sub-heading"]}>
                    {greInformationTitle}
                  </h3>

                  {greInformationFields.map((field, index) => (
                    <FieldJSX
                      key={`${field.labelText}-${index}`}
                      labelText={field.labelText}
                      dataText={field.dataText}
                    />
                  ))}

                  <div
                    className={clsx(theme["field-row"], theme["edit-details"])}
                  >
                    <Link href="/edit-profile-details" passHref>
                      <a className="cta">Edit Details</a>
                    </Link>
                  </div>

                  <h2 className={theme["section-heading"]}>
                    <span>{yourAspirationsTitle}</span>
                  </h2>

                  {aspirationsFields.map((field, index) => (
                    <FieldJSX
                      key={`${field.labelText}-${index}`}
                      labelText={field.labelText}
                      dataText={field.dataText}
                    />
                  ))}

                  <div
                    className={clsx(theme["field-row"], theme["edit-details"])}
                  >
                    <Link href="/edit-profile-details" passHref>
                      <a className="cta">Edit Details</a>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </section>
        </Grid>
        <Grid column sm={12} md={6}>
          <h2 className={theme["section-heading"]}>Login Details</h2>
          <ul>
            <li>Email: {personalDetails?.emailAddress}</li>
            {/*To do: will be implemented later
            <li>
              <Cta
                icon="icon-arrow"
                url="/edit-profile-details"
                linkText={changeEmailText}
                type="tertiary"
              />
            </li>*/}

            <li>
              <Cta
                icon="icon-arrow"
                url={
                  siteSettings ? siteSettings?.changePasswordPage?.slug : "#"
                }
                linkText={changePasswordText}
                type="tertiary"
              />
            </li>

            <li>
              <Cta
                icon="icon-arrow"
                url={
                  siteSettings ? siteSettings?.deactivateAccountPage?.slug : "#"
                }
                linkText={deactivateAccountText}
                type="tertiary"
              />
            </li>

            <li>
              <Cta
                icon="icon-arrow"
                url={
                  siteSettings ? siteSettings?.yourRightsToDataPage?.slug : "#"
                }
                linkText={yourRightText}
                type="tertiary"
              />
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr(
  "MyProfilePersonalDetails",
  styles
)(MyProfilePersonalDetails);

import { themr } from "@friendsofreactjs/react-css-themr";
import { useEffect, useState } from "react";
import styles from "./C138_EmbeddableForm.module.scss";

export const EmbeddableCheckbox = (props: {
  value: string;
  label: string;
  onChange: (value: string, checkedState: boolean) => void;
  customClass?: string;
  theme?: any;
}) => {
  const { value, label, onChange, customClass, theme } = props;
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    onChange(value, checked);
  }, [checked]);

  return (
    <div className={`checkbox ${theme["checkbox"]}`}>
      <input
        id={value}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <span className="tick"></span>
      <label htmlFor={value}>{label}</label>
    </div>
  );
};

export default themr("EmbeddableCheckbox", styles)(EmbeddableCheckbox);

/* COMPONENT - C284 Video podcast lightbox 1.0 */
/* global SC */
"use strict";

import setGADataLayer from "@utilities/setGADataLayer";
import * as basicLightbox from "basiclightbox";
// import './soundcloud';

export class VideoPodcastLightbox {
  constructor(element, options) {
    this.root = element;
    this.options = options;
    this.options.clickedClass = this.options.clickedClass || "clicked";
    this.playIcon = this.root.querySelector(".play-media");
    this.lightbox;
    this.lightboxType;

    if (this.playIcon && this.playIcon.dataset.youtube) {
      this.lightboxType = "youtube";
      this.lightbox = basicLightbox.create(
        `
                <a class="close-lightbox" href="#close"><svg width="20" height="20" viewBox="5 5 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#fff" focusable="false"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd"></path></svg></a>
                <div class="video-wrapper">
                <iframe src="https://www.youtube.com/embed/${this.playIcon.dataset.youtube}?enablejsapi=1&modestbranding=1" width="80%" height="80%" frameborder="0"></iframe>
                </div>
                <a class="end" href="#close"></a>
            `,
        {
          onClose: () => {
            document.body.classList.remove("lightbox-open");
            this.playIcon.focus();
          },
        }
      );
    }

    if (this.playIcon && this.playIcon.dataset.vimeo) {
      this.lightboxType = "vimeo";
      this.lightbox = basicLightbox.create(
        `
                <a class="close-lightbox" href="#close"><svg width="20" height="20" viewBox="5 5 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#fff" focusable="false"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd"></path></svg></a>
                <div class="video-wrapper">
                <iframe src="https://player.vimeo.com/video/${this.playIcon.dataset.vimeo}" width="80%" height="80%" frameborder="0"></iframe>
                </div>
                <a class="end" href="#close"></a>
            `,
        {
          onClose: () => {
            document.body.classList.remove("lightbox-open");
            this.playIcon.focus();
          },
        }
      );
    }

    if (this.playIcon && this.playIcon.dataset.podcast) {
      this.lightboxType = "podcast";
      this.lightbox = basicLightbox.create(
        `
                <a class="close-lightbox" href="#close"><svg width="20" height="20" viewBox="5 5 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#fff" focusable="false"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd"></path></svg></a>
                <iframe width="100%" height="200" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${this.playIcon.dataset.podcast}&${this.playIcon.dataset.podcastParams}"></iframe>
                <a class="end" href="#close"></a>
            `,
        {
          onShow: (instance) => {
            (function () {
              try {
                // Generic method for pushing the dataLayer event
                // Use a Custom Event Trigger with "scEvent" as the event name
                // Remember to create Data Layer Variables for eventCategory, eventAction, and eventLabel
                var sendDl = function (cat, act, lab) {
                  setGADataLayer({
                    event: "scEvent",
                    ga_event: {
                      category: cat,
                      action: act,
                      label: lab,
                    },
                  });
                };

                // initWidget is called when a SoundCloud iframe is found on the page
                var initWidget = function (w) {
                  var currentSound, act, pos, q1, q2, q3, go, lab;
                  var cat = "SoundCloud";
                  var widget = SC.Widget(w);
                  var lastPlay = 0;

                  // Events.READY is dispatched when the widget has been loaded
                  widget.bind(SC.Widget.Events.READY, function () {
                    // Get the title of the currently playing sound
                    widget.getCurrentSound(function (cs) {
                      lab = cs.title;
                    });

                    // Fire a dataLayer event when Events.PLAY is dispatched
                    //Prevent double hits due to API bug by checking it has been 1 second since last hit
                    widget.bind(SC.Widget.Events.PLAY, function () {
                      if (Date.now() - lastPlay > 1000) {
                        act = "Play";
                        sendDl(cat, act, lab);
                        lastPlay = Date.now();
                      }
                    });

                    // Fire a dataLayer event when Events.PAUSE is dispatched
                    // The only exception is when the sound ends, and the auto-pause is not reported
                    widget.bind(SC.Widget.Events.PAUSE, function (obj) {
                      pos = Math.round(obj.relativePosition * 100);
                      if (pos !== 100) {
                        act = "Pause";
                        sendDl(cat, act, lab);
                      }
                    });

                    // As the play progresses, send events at 25%, 50% and 75%
                    widget.bind(SC.Widget.Events.PLAY_PROGRESS, function (obj) {
                      go = false;
                      pos = Math.round(obj.relativePosition * 100);
                      if (pos === 25 && !q1) {
                        act = "25%";
                        q1 = true;
                        go = true;
                      }
                      if (pos === 50 && !q2) {
                        act = "50%";
                        q2 = true;
                        go = true;
                      }
                      if (pos === 75 && !q3) {
                        act = "75%";
                        q3 = true;
                        go = true;
                      }
                      if (go) {
                        sendDl(cat, act, lab);
                      }
                    });

                    // When the sound finishes, send an event at 100%
                    widget.bind(SC.Widget.Events.FINISH, function () {
                      act = "100%";
                      q1 = q2 = q3 = false;
                      sendDl(cat, act, lab);
                    });
                  });
                };

                // For each SoundCloud iFrame, initiate the API integration
                var i, len;
                var iframes = instance
                  .element()
                  .querySelectorAll('iframe[src*="api.soundcloud.com"]');

                for (i = 0, len = iframes.length; i < len; i += 1) {
                  initWidget(iframes[i]);
                }
              } catch (e) {
                console.log("Error with SoundCloud API: " + e.message);
              }
            })();
          },
          onClose: () => {
            document.body.classList.remove("lightbox-open");
            this.playIcon.focus();
          },
        }
      );
    }

    this.applyListeners();
  }

  applyListeners() {
    if (this.lightbox) {
      this.playIcon.setAttribute("aria-haspopup", true);
      this.playIcon.addEventListener("click", (e) => {
        e.preventDefault();
        this.lightbox.show();
        document.body.classList.add("lightbox-open");
        this.lightbox.element().classList.add("basicLightbox--iframe");
        this.lightbox.element().classList.add("video-podcast-lightbox-panel");
        this.lightbox.element().classList.add(`is--${this.lightboxType}`);
        this.lightbox
          .element()
          .querySelector(".close-lightbox")
          .addEventListener("click", (e) => {
            e.preventDefault();
            document.body.classList.remove("lightbox-open");
            this.lightbox.close();
          });
        this.lightbox
          .element()
          .querySelector(".close-lightbox")
          .addEventListener("keydown", (e) => {
            switch (e.keyCode) {
              case 9:
                if (e.shiftKey) {
                  e.preventDefault();
                  this.lightbox.element().querySelector("iframe").focus();
                }
                break;
            }
          });
        setTimeout(() => {
          this.lightbox.element().querySelector(".close-lightbox").focus();
        }, 200);

        this.lightbox
          .element()
          .querySelector(".end")
          .addEventListener("focus", () => {
            this.lightbox.element().querySelector(".close-lightbox").focus();
          });
      });
    }
  }
}

const Component = {
  elements: [],
  objects: [],
  init: (className, options = {}) => {
    let i;
    Component.elements = document.querySelectorAll(className);
    for (i = 0; i < Component.elements.length; i += 1) {
      Component.objects.push(
        new VideoPodcastLightbox(
          Component.elements[i],
          Object.assign({}, options)
        )
      );
    }
  },
};
export default Component;

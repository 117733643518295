import React from "react";
import Grid from "@components/Grid/Grid";
import RenderComponents from "@utilities/renderComponents";

export interface EMBAGTemplateProps {
  bodyContent?: any;
}

export const EMBAGTemplate = (props: { content: EMBAGTemplateProps }) => {
  const { content } = props;
  const { bodyContent } = content;

  return (
    <Grid row>
      <Grid column sm={12}>
        {RenderComponents(bodyContent)}
      </Grid>
    </Grid>
  );
};

export default EMBAGTemplate;

import { formatAddress } from "@utilities/formatAddress";
import { EventDetailProps } from "../C37.5_EventDetail";

import IconExternal from "../../../public/image/svg/external.svg";

const LocationSection = (
  props: { theme?: any } & Pick<
    EventDetailProps,
    "eventLocationName" | "eventLocationGoogleMapsLink"
  >
) => {
  const { eventLocationName, eventLocationGoogleMapsLink } = props;
  return (
    <>
      {eventLocationName && <p>{formatAddress(eventLocationName)}</p>}
      {eventLocationGoogleMapsLink && (
        <>
          <i className="icon-external">
            <IconExternal />
          </i>
          <a
            className="directions"
            target="_blank"
            href={eventLocationGoogleMapsLink}
            rel="noreferrer"
          >
            Find us
          </a>
        </>
      )}
    </>
  );
};

export default LocationSection;

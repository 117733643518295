import {
  NavGroupHeading,
  NavLinkList,
  NavLinkType,
} from "@customTypes/megaNav";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { NavigationStateContext } from "../../context/navigationContext";
import IconChevron from "../../public/image/svg/chevron.svg";
import styles from "./MegaNav.module.scss";
import { useRouter } from "next/router";
import { navigationGAClick } from "@utilities/navigationGAClick";
import Link from "@components/Link/Link";
import { getModifiedLinks } from "@components/C01_SiteHeader/utils";

const TabLinkList = ({ mainMenuLink, theme }) => {
  const router = useRouter();
  const {
    tabSelectedIndex,
    setTabSelectedIndex,
    setTabSelected,
    setMainNavSelected,
    handleLeave,
    handleReset,
    setPrimaryNavSelectedIndex,
    setPrimaryNavSelected,
  } = useContext(NavigationStateContext);

  const handleNavigation = (index: number, url?: string) => {
    if (url) {
      router.push(url);
      handleLeave();
    }
  };
  let indexNavLinkList = 0;

  const [mainMenuLinkData, setMainMenuLinkData] = useState<any>(
    mainMenuLink?.linkList
  );

  useEffect(() => {
    setMainMenuLinkData(getModifiedLinks(mainMenuLink?.linkList));
  }, []);

  const handleLinkClick = (e, tabOwnLink, currentTabLinkIndex) => {
    e.preventDefault();
    if (tabOwnLink?.fields?.url && tabOwnLink?.fields?.url) {
      navigationGAClick(e);
      handleNavigation(currentTabLinkIndex, tabOwnLink?.fields?.url);
      handleReset();
    } else {
      e.stopPropagation();
    }
  };

  return (
    <ul>
      {mainMenuLinkData?.map((tabLink, index) => {
        const tabLinkType = tabLink?.sys?.contentType?.sys?.id;
        switch (tabLinkType) {
          case NavLinkType.NavLinkList:
            const tabLinkWithType = tabLink as NavLinkList;
            const tabOwnLink = tabLinkWithType?.fields?.ownLink;
            const currentTabLinkIndex = indexNavLinkList;
            ++indexNavLinkList;
            const linkText: string | undefined = tabOwnLink?.fields?.linkText;
            const itemKey = linkText ? linkText + index : "nll" + index;
            const ownLinkUrl = tabOwnLink?.fields?.url;

            return (
              <li
                key={itemKey}
                className={clsx(
                  tabSelectedIndex == currentTabLinkIndex && theme.active
                )}
                onMouseEnter={() => {
                  setMainNavSelected(false);
                  setTabSelected(true);
                  setTabSelectedIndex(currentTabLinkIndex);
                  setPrimaryNavSelectedIndex(0);
                  setPrimaryNavSelected(true);
                }}
              >
                <>
                  <a
                    href={
                      tabOwnLink?.fields && ownLinkUrl
                        ? tabOwnLink?.fields?.url
                        : "#"
                    }
                    onClick={(e) =>
                      handleLinkClick(e, tabOwnLink, currentTabLinkIndex)
                    }
                  >
                    {tabOwnLink?.fields?.linkText}
                  </a>

                  <IconChevron
                    onClick={(e) =>
                      handleLinkClick(e, tabOwnLink, currentTabLinkIndex)
                    }
                  />
                </>
              </li>
            );

          case NavLinkType.NavGroupHeading:
            const tabHeadingWithType = tabLink as NavGroupHeading;
            return (
              <li
                key={`${tabHeadingWithType?.fields?.label}${index}`}
                className={
                  tabHeadingWithType.fields?.label ? styles.subtitle : undefined
                }
                onClick={(e) => e.stopPropagation()}
              >
                {tabHeadingWithType.fields?.label}
              </li>
            );
          case NavLinkType.GeneralLink:
            return (
              <li key={`${tabLink?.fields?.linkText}${index}`}>
                <Link href={tabLink?.fields?.url || "#"}>
                  <a
                    onClick={(e) => {
                      if (tabLink?.fields?.url) {
                        navigationGAClick(e);
                        handleReset();
                      } else {
                        e.stopPropagation();
                      }
                    }}
                  >
                    {tabLink?.fields?.linkText}
                  </a>
                </Link>
              </li>
            );
        }
      })}
    </ul>
  );
};

export default themr("TabLinkList", styles)(TabLinkList);

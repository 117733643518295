import { themr } from "@friendsofreactjs/react-css-themr";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-hook-inview";

import styleNames from "@utilities/react/style-names";
import styles from "./C252_DynamicPie.module.scss";

export interface extendedPie {
  title: string;
  colour: string;
  theme?: any;
  categoryText1: string;
  categoryText2: string;
  categoryText3: string;
  categoryText4: string;
  categoryText5: string;
  categoryText6: string;
  categoryText7: string;
  categoryText8: string;
  categoryText9: string;
  categoryText10: string;
  categoryPercentage1: string;
  categoryPercentage2: string;
  categoryPercentage3: string;
  categoryPercentage4: string;
  categoryPercentage5: string;
  categoryPercentage6: string;
  categoryPercentage7: string;
  categoryPercentage8: string;
  categoryPercentage9: string;
  categoryPercentage10: string;
}

export const DynamicPieExtended = (props: extendedPie) => {
  const { theme, colour, title } = props;

  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });
  const blurTimeout: any = useRef();
  const graphicRef: any = useRef();
  const listRef: any = useRef();
  const percentageData = Array.from(Array(10).keys())
    .map((i) => {
      if (
        props[`categoryText${i + 1}`] &&
        props[`categoryPercentage${i + 1}`]
      ) {
        return {
          title: props[`categoryText${i + 1}`],
          percentage: props[`categoryPercentage${i + 1}`],
        };
      }

      return null;
    })
    .filter((item) => item);

  const [hoverClasses, setHoverClasses] = useState([""]);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const [hoverText, setHoverText] = useState({ title: "", percentage: "" });
  const [circlePositions, setCirclePositions]: [Array<any>, Function] =
    useState([]);

  const angle = (cx, cy, ex, ey) => {
    var dy = ey - cy;
    var dx = ex - cx;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    if (theta < 0) {
      theta = 360 + theta; // range [0, 360)
    }
    theta = theta + 90;
    if (theta > 360) {
      theta = theta - 360;
    }
    return theta;
  };

  const getOffset = (evt) => {
    var el = evt.target,
      x = 0,
      y = 0,
      elBounds = el.getBoundingClientRect();

    x = evt.clientX - elBounds.left;
    y = evt.clientY - elBounds.top;

    return { x: x, y: y };
  };

  const blur = () => {
    blurTimeout.current = setTimeout(() => {
      setHoverClasses([""]);
    }, 500);
  };

  const hover = (perc, current, last) => {
    clearTimeout(blurTimeout.current);
    let halfway = (current - last) / 2 + last,
      circle = graphicRef.current.querySelector("svg").getBoundingClientRect(),
      graphic = graphicRef.current.getBoundingClientRect(),
      widthDiff = graphic.width - circle.width,
      heightDiff = graphic.height - circle.height,
      r = circle.width / 2,
      top,
      left,
      coords = getPosOnCircle(0, -(r - 30), halfway * 3.6),
      direction = "up";

    left =
      "calc((" + r + "px + (" + widthDiff + "px / 2)) + " + coords.x + "px)";
    top =
      "calc((" + r + "px + (" + heightDiff + "px / 2)) + " + coords.y + "px)";

    if (halfway > 87.5 || halfway < 12.5) {
      direction = "down";
    } else if (halfway > 52.5) {
      direction = "right";
    } else if (halfway < 37.5) {
      direction = "left";
    }

    setHoverClasses(["active", direction]);
    setHoverPosition({ top, left });
    setHoverText(perc);
  };

  const getPosOnCircle = (oX, oY, angle) => {
    var radius = Math.sqrt(oX * oX + oY * oY);
    var oTheta = Math.atan2(oX, oY);
    var nTheta = oTheta - (angle * Math.PI) / 180;
    return { x: radius * Math.sin(nTheta), y: radius * Math.cos(nTheta) };
  };

  useEffect(() => {
    if (graphicRef.current && listRef.current) {
      var circles = graphicRef.current.querySelectorAll("svg path:not(.blank)");
      var listItems = listRef.current.querySelectorAll("li i");

      percentageData?.forEach((percentage, i) => {
        let colour, a;
        a = i * Math.floor(10 / percentageData.length);
        colour = window
          .getComputedStyle(circles[circles.length - 1 - a], null)
          .getPropertyValue("stroke");

        if (listItems[i]) {
          listItems[i].style.backgroundColor = colour;
        }
      });
    }
  }, [graphicRef, listRef]);

  useEffect(() => {
    if (isVisible && graphicRef.current) {
      let running = 0,
        last = 0,
        circlePositions: Array<any> = [];
      var circles = graphicRef.current.querySelectorAll("svg path:not(.blank)");

      percentageData?.forEach((percentage, i) => {
        running = running + parseFloat(percentage?.percentage);
        i = i * Math.floor(10 / percentageData.length);
        circles[circles.length - 1 - i].style.strokeDasharray =
          running - 0.25 + ",100";
        circles[
          circles.length - 1 - i
        ].previousElementSibling.style.strokeDasharray = running + ",100";

        circlePositions.push({
          percentage: percentage,
          running: running,
          last: last,
        });
        last = running;
      });
      setCirclePositions(circlePositions);
    }
  }, [isVisible, graphicRef]);

  // const dataPercent =

  return (
    <div
      ref={ref}
      className={`${theme.chart} ${theme[colour]} ${
        isVisible && theme.animate
      }`}
      data-percent="@Html.Raw(Model.DataPercent)"
    >
      <div ref={graphicRef} className={theme.graphic}>
        <div
          className={`${theme.hover} ${styleNames(theme, hoverClasses)}`}
          style={hoverPosition}
        >
          <div className={theme.inner}>
            {hoverText.title}
            <span>{hoverText.percentage}</span>
          </div>
        </div>
        <div className={theme.number}>
          <p>{title}</p>
        </div>
        <svg
          viewBox="-2 -2 40 40"
          className={theme["circular-chart"]}
          onMouseMove={(e) => {
            const node = e.target as HTMLElement;
            let test = getOffset(e),
              bounds = node.getBoundingClientRect(),
              angle2 = angle(
                bounds.width / 2,
                bounds.width / 2,
                test.x,
                test.y
              ),
              percentage = angle2 / 3.6;
            circlePositions.forEach((pos) => {
              if (pos.running >= percentage && pos.last < percentage) {
                hover(pos.percentage, pos.running, pos.last);
              }
            });
          }}
          onMouseLeave={() => {
            blur();
          }}
        >
          <path
            className={`${theme.circle}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.two}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.three}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.four}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.five}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.six}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.seven}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.eight}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.nine}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.ten}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            className={`${theme.circle} ${theme.blank} blank ${theme.hov}`}
            strokeDasharray="0, 100"
            d="M18 2.0845
	    a 15.9155 15.9155 0 0 1 0 31.831
	    a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
        </svg>
      </div>
      <div className={theme.list} ref={listRef}>
        <ul>
          {percentageData?.map((percentage, i) => {
            return (
              <li key={`${percentage?.title}${i}`}>
                <i></i> {percentage?.title}{" "}
                <strong>{percentage?.percentage} %</strong>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default themr("DynamicPieExtended", styles)(DynamicPieExtended);

export enum ProgrammeTypeGroup {
  MBA = "MBA",
  MAM = "MAM",
  MIF = "MIF",
  MIM = "MIM",
  MFA = "MFA",
  EMBA = "EMBA",
  EMBAG = "EMBAG",
  SLOAN = "SLOAN",
  MBA1Y = "MBA1Y",
}

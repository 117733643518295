import { MQ_AND_BELOW } from "@customTypes/Breakpoints";
import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { ProgrammeType } from "@customTypes/ProgrammeType";
import { themr } from "@friendsofreactjs/react-css-themr";
import { fetchApi } from "@utilities/fetchApi";
import { useDevice } from "@utilities/react/get-device/get-device";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";
import Slider from "react-slick";
import styles from "./C272_ProgrammeComparisonTable.module.scss";
import { ProgrammeDE } from "./C272_ProgrammeDE";
import { ProgrammeEE } from "./C272_ProgrammeEE";

export interface ProgrammeComparisonTableProps {
  programmeType: ProgrammeType;
}

export const ProgrammeComparisonTable = (props: {
  content: ProgrammeComparisonTableProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { programmeType } = content;
  const [programmes, setProgrammes] = useState<ReactElement[]>([]);
  const [programmeDetails, setProgrammeDetails] = useState<ProgrammeDetails[]>(
    []
  );

  const { query } = useRouter();
  const device = useDevice();
  const isMobile = device === "mobile";
  const [useCarousel, setUseCarousel] = useState(isMobile);

  useEffect(() => {
    if (!query || !query.p) return;

    const typeCodes = query.p
      ? [...new Set((query.p as String).split(","))]
      : [];

    if (!typeCodes.length) return;

    const getData = async () => {
      const programmeDetails = await fetchApi(
        "/api/getProgrammeComparisonData",
        {
          programmeType,
          typeCodes,
        }
      );

      setProgrammeDetails(programmeDetails);
    };
    getData();

    setTimeout(() => {
      adjustHeight();
    }, 500);

    setUseCarousel(window.innerWidth <= MQ_AND_BELOW.MOBILE);

    window.addEventListener("resize", () => {
      adjustHeight();

      setUseCarousel(window.innerWidth <= MQ_AND_BELOW.MOBILE);
    });
  }, [query]);

  useEffect(() => {
    if (!programmeDetails || !programmeDetails.length) {
      setProgrammes([]);
      return;
    }

    const programmes = programmeDetails.map((programmeDetail, index) => {
      const Programme =
        programmeType === ProgrammeType.DE ? ProgrammeDE : ProgrammeEE;
      programmeDetail.slug = programmeDetail.externalUrl
        ? programmeDetail.externalUrl
        : programmeDetail.slug;
      return (
        <Programme
          key={`programme-table-${index}`}
          theme={theme}
          count={index + 1}
          programmeDetails={programmeDetail}
        />
      );
    });

    setProgrammes(programmes);
  }, [programmeDetails]);

  const adjustHeight = () => {
    setTimeout(function () {
      // Side labels
      const sideLabels = [
        ...document.querySelectorAll(
          ".programme-items .labels [data-reference]"
        ),
      ];

      // All cells within table
      const tableCells = [
        ...document.querySelectorAll<HTMLElement>(
          ".programme-column [data-reference]"
        ),
      ];

      // Table heading
      const tableHeadings = [
        ...document.querySelectorAll<HTMLElement>(
          ".programme-column .top-heading"
        ),
      ];

      // set all table cell height to auto
      for (let i = 0; i <= tableCells.length; i++) {
        if (tableCells[i]) {
          tableCells[i].style.height = "auto";
        }
      }

      let titleHeight = 0;

      // Get of of all title heading and set titleHeight to highest value
      for (let i = 0; i <= tableHeadings.length; i++) {
        if (tableHeadings[i]) {
          if (tableHeadings[i].offsetHeight > titleHeight) {
            titleHeight = tableHeadings[i].offsetHeight;
          }
        }
      }

      // Set height of title headings
      for (let i = 0; i <= tableHeadings.length; i++) {
        if (tableHeadings[i]) {
          tableHeadings[i].style.height = titleHeight + "px";
        }
      }

      // loop through all side labels
      for (let i = 0; i < sideLabels.length; i++) {
        const filteredTarget = [];
        let tallestCellHeight = 0;
        const sideLabelDataVal = sideLabels[i].getAttribute("data-reference");

        // loop through all table cells
        for (let index = 0; index < tableCells.length; index++) {
          const element: any = tableCells[index];

          // Match side label data-reference value with table cell data-reference
          if (element.getAttribute("data-reference") == sideLabelDataVal) {
            let currectCellHeight = element.offsetHeight;

            // get tallest cell height
            if (currectCellHeight > tallestCellHeight) {
              tallestCellHeight = currectCellHeight;
            }

            // push all matching items into array
            // @ts-ignore
            filteredTarget.push(element);
          }

          // loop through filteredTarget items and apply height value
          for (let index = 0; index < filteredTarget.length; index++) {
            const element = filteredTarget[index];
            // @ts-ignore
            element.style.height = tallestCellHeight + "px";
          }
        }
      }
    }, 0);
  };

  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    className: `${theme.slider} programme-comparison-table`,
    infinite: false,
    dots: true,
    speed: 500,
    arrows: false,
    appendDots: (dots) => (
      <>
        <div className={theme.dots}>
          <ul className="slick-dots" aria-label="Carousel slide controls">
            {dots}
          </ul>
        </div>
      </>
    ),
  };

  if (!programmes) return null;

  return (
    <div className={`component ${theme["programme-comparison-table"]} wrapper`}>
      <div className="items-wrapper">
        <div className={`${theme["programme-items"]} programme-items`}>
          <ul
            id="programme-column-wrapper"
            data-block_length={
              useCarousel ? "" : programmeDetails && programmeDetails.length
            }
          >
            {useCarousel ? (
              <Slider {...sliderSettings}>{programmes}</Slider>
            ) : (
              programmes
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default themr(
  "ProgrammeComparisonTable",
  styles
)(ProgrammeComparisonTable);

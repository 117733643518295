// this is the list of components that have an API call to the backend, if we build a new component that has an API call we need to add it to this list
export const componentsWithAPICall = [
  "contactUsForm",
  "consultantForm",
  "deccFees",
  "downloadBrochureForm",
  "eeFees",
  "eventRegistrationForm",
  "myProfileApplications",
  "myProfileEditProfileDetails",
  "myProfilePersonalDetails",
  "myProfilePreferences",
  "giftAidForAdvancementForm",
  "howToApplyPage",
  "loginOrRegister",
  "phdFees",
  "programmeEnquiryForm",
  "reserveAPlace",
  "registrationForm",
  "registerInterestForm",
  "requestCallBackForm",
  "submitCv",
  "viewWebinarForm",
];

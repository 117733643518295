export const FacultyProfileStyles = (isMobile) => `

.results {
  position: relative;
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.lbs-result-list-wrapper .details {
  background-color: #fff;
  color: #001e62;
  height: 100%;
  padding: 1.6rem 0 12rem;
  position: relative;
}

.lbs-result-list-wrapper .details h2 {
  line-height: 1.26667;
  font-size: 3rem;
  padding: 0 1.6rem;
  margin-bottom: 1.7rem;
}


.lbs-result-list-wrapper .text-with-icon {
  line-height: 1.5;
  display: flex;
  margin-bottom: 2rem;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  font-family: Montserrat,sans-serif;
}

.lbs-result-list-wrapper .text-with-icon>span {
  display: flex;
  align-items: start;
  min-width: 3.5rem;
}

.lbs-result-list-wrapper .text-with-icon>span svg {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
  fill: #5f739b;
}

.lbs-result-list-wrapper .bottom-cta {
  background-color: #001e62;
  padding: 1.2rem 2.5rem;
  font-family: Montserrat,sans-serif;
  font-weight: 700;
  display: flex;
}

.lbs-result-list-wrapper .CoveoResultLink {
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 0.1rem;
  display: inline;
  word-wrap: normal;
}

.lbs-result-list-wrapper .cta {
  background: none;
    border: 0;
    width: auto;
    color: #ebe8e5;
    font-size: 1.8rem;
    padding: 0 1rem 0 3.5rem;
    position: relative;
}

.lbs-result-list-wrapper .cta.tertiary svg {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  left: 0;
  top: calc(50% - 0.1rem);
  transform: translateY(-50%);
  fill: #ebe8e5;
}

.lbs-result-list-wrapper h2 a {
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 0.1rem;
  padding-bottom: 0.4rem;
  color: inherit;
  text-decoration: none;
  font-weight: 400;
}

.lbs-result-list-wrapper .summary-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Montserrat,sans-serif;
}

.lbs-result-list-wrapper .imageLink {
  position: relative;
  transition: transform .3s;
  ${isMobile ? "" : "background-color: #d7d2cb"};
  background-image: url(data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%224%204%2016%2016%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20focusable%3D%22false%22%3E%3Ctitle%3EProfile%3C%2Ftitle%3E%3Cpath%20fill%3D%22%23ebe8e5%22%20d%3D%22M12%205.9a2.1%202.1%200%20110%204.2%202.1%202.1%200%20010-4.2zm0%209c2.97%200%206.1%201.46%206.1%202.1v1.1H5.9V17c0-.64%203.13-2.1%206.1-2.1zM12%204C9.79%204%208%205.79%208%208s1.79%204%204%204%204-1.79%204-4-1.79-4-4-4zm0%209c-2.67%200-8%201.34-8%204v3h16v-3c0-2.66-5.33-4-8-4z%22%2F%3E%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: auto 50%;
}

.lbs-result-list-wrapper .imageLink.placeholder {
  padding-top: 75.26%;
}

.lbs-result-list-wrapper .imageLink:hover {
  transform: scale(1.1);
}

.lbs-result-list-wrapper .imageLink  img {
  min-height: 100%;
  min-width: 100%;
}

.lbs-result-list-wrapper .desktop picture {
  background: #ebe8e5;
  height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.results .summary-text-container {
  position: absolute;
  bottom: 0;
  height: 11rem;
  left: 0;
  background-color: #fff;
  padding: 1.6rem 5.3rem 1.6rem 1.6rem;
  margin: 0;
  cursor: pointer;
  transition: height .1s ease-in-out;
  width: 100%;
}

.results .summary-text-container:before {
  height: 0.1rem;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: #ebe8e5;
}

.results .summary-text-container:after {
  top: 1.2rem;
  right: 1.3rem;
  height: 3rem;
  width: 3rem;
  transform: rotate(90deg);
  background-repeat: no-repeat;
  background-position: 50%;
}

.results .summary-text-container:after, 
.results .summary-text-container:before {
  content: "";
  position: absolute;
}

.lbs-result-list-wrapper .result-root {
  display: ${isMobile ? "block" : "flex"};
  height: 100%;
}

.results .mobile-only {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: auto 50%;
  display: inline-block;
  height: 10rem;
  min-width: 10rem;
  position: relative;
  width: 10rem;
  display : ${!isMobile ? "none" : "block"}
}

.lbs-result-list-wrapper .desktop {
  display : ${isMobile ? "none" : "flex"}
}

${
  isMobile
    ? `.info-container {
    display: -ms-flexbox;
    display: flex;
    min-height: 12rem;
    padding-left:1.6rem;
  };
`
    : ""
}
`;

import { Author } from "@customTypes/Author";
import { createContext, ReactNode, useContext } from "react";

export interface BSRContentPageContextProps {
  authorsHeading?: string;
  authorsList?: Author[];
}

interface BSRContentPageProviderProps {
  children: ReactNode;
  details: BSRContentPageContextProps;
}

export const BSRContentPageContext = createContext<BSRContentPageContextProps>(
  {}
);

export const BSRContentPageProvider = ({
  children,
  details,
}: BSRContentPageProviderProps) => {
  const { authorsHeading, authorsList } = details;

  return (
    <BSRContentPageContext.Provider
      value={{
        authorsHeading,
        authorsList,
      }}
    >
      {children}
    </BSRContentPageContext.Provider>
  );
};

export const useBSRContentPages = () => useContext(BSRContentPageContext);

import Grid from "@components/Grid/Grid";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styles from "./C221_AuthorBanner.module.scss";

export interface AuthorProps {
  articleAuthorName: string;
  articleAuthorFacultyProfile?: {
    facultyProfileData?: {
      profilePositionTitle: string;
    };
  };
  articleAuthorDescription: string;
}

export const AuthorBanner = (props: { theme?: any }) => {
  const { theme } = props;
  const [author, setAuthor] = useState<null | AuthorProps>(null);
  const { query } = useRouter();

  useEffect(() => {
    if (query.articleauthor && !author) {
      const getAuthor = async () => {
        const authorData = await fetch(
          `/api/getArticleAuthorByName?name=${query.articleauthor || ""}`
        );
        setAuthor(await authorData.json());
      };

      getAuthor();
    }
  }, [query]);

  if (!author) {
    return null;
  }

  return (
    <Grid row>
      <Grid column sm={12}>
        <div className={theme["article-authors"]}>
          <div className="wrapper">
            <h1>{author?.articleAuthorName}</h1>
            {author?.articleAuthorFacultyProfile?.facultyProfileData
              ?.profilePositionTitle ? (
              <p>
                {
                  author.articleAuthorFacultyProfile?.facultyProfileData
                    ?.profilePositionTitle
                }
              </p>
            ) : (
              <>
                {author?.articleAuthorDescription && (
                  <p>{author.articleAuthorDescription}</p>
                )}
              </>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default themr("AuthorBanner", styles)(AuthorBanner);

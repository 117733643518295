import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { EventDetailProps } from "../C37.5_EventDetail";

const DescriptionSection = (
  props: { theme?: any } & Pick<
    EventDetailProps,
    | "eventDetails"
    | "eventDetailsTinyMce"
    | "eventShortDescription"
    | "eventLongDescription"
  >
) => {
  const {
    eventDetails,
    eventDetailsTinyMce,
    eventShortDescription,
    eventLongDescription,
  } = props;

  const isRegularEventDetailsText = (
    <RichTextDisplay
      richText={eventDetails}
      tinyMceRichText={eventDetailsTinyMce}
    />
  );

  return (
    <>
      {eventDetails || eventDetailsTinyMce?.richText?.length ? (
        isRegularEventDetailsText
      ) : (
        <>
          {eventShortDescription && (
            <p
              dangerouslySetInnerHTML={{
                __html: eventShortDescription,
              }}
            ></p>
          )}
          {eventLongDescription && (
            <p
              dangerouslySetInnerHTML={{
                __html: eventLongDescription,
              }}
            ></p>
          )}
        </>
      )}
    </>
  );
};

export default DescriptionSection;

import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C45_AlumniJobBoard.module.scss";
import clsx from "clsx";
import Grid from "@components/Grid/Grid";
import FormField from "@components/Form/FormFields/FormFields";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import { useRouter } from "next/router";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";

export interface UserContactDetailsProps {
  formTitle: string;
  emailLabelText: string;
  emailHintText?: string;
  titleLabelText: string;
  firstNameLabelText: string;
  lastNameLabelText: string;
  schoolLabelText: string;
  schoolList: string[];
  programmeLabelText: string;
  graduationYearLabelText: string;
  contactEmailLabelText: string;
  contactEmailHintText?: string;
  formBottomDescriptionText?: string;
  mailTo: string;
  successPage: {
    fields: {
      slug: string;
    };
  };
}

let firstInteract = 0;

export const C45_AlumniJobBoard = (props: {
  content: UserContactDetailsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    formTitle,
    emailLabelText,
    emailHintText,
    firstNameLabelText,
    lastNameLabelText,
    schoolLabelText,
    schoolList,
    programmeLabelText,
    graduationYearLabelText,
    contactEmailLabelText,
    contactEmailHintText,
    formBottomDescriptionText,
    successPage,
  } = content;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onBlur",
  });

  const router = useRouter();

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "AlumniJobBoard",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const FormFields = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      hintText: emailHintText,
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
    },
    {
      propertyName: "school",
      validation: {
        isRequired: true,
      },
      labelText: schoolLabelText,
      options: schoolList?.map((school) => ({ id: school, value: school })),
      formType: "select",
      isEmptyFirstOption: false,
    },
    {
      propertyName: "programme",
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      labelText: programmeLabelText,
    },
    {
      propertyName: "graduationYear",
      validation: {
        isRequired: true,
        minLength: 4,
        maxLength: 4,
        integerValidator: true,
      },
      labelText: graduationYearLabelText,
    },
    {
      propertyName: "contactEmailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: contactEmailLabelText,
      hintText: contactEmailHintText,
    },
  ];

  const redirectToSuccessfulPage = async () => {
    setGADataLayer({
      event: "formComplete",
      formName: "AlumniJobBoard",
      businessUnit: getBusinessUnit(),
      programmeCode: "",
      eventType: "",
    });
    const successPageUrl = successPage?.fields?.slug ?? "/404";
    console.log("successPageUrl", successPageUrl);
    await router.push(successPageUrl);
  };

  const onSubmit = async (data) => {
    setError("");
    setLoading(true);

    try {
      await fetch("/.netlify/functions/triggerAlumniJobBoardEmail", {
        method: "POST",
        body: JSON.stringify({
          contactEmailAddress: data.contactEmailAddress,
          emailAddress: data.emailAddress,
          firstName: data.firstName,
          lastName: data.lastName,
          graduationYear: data.graduationYear,
          programme: data.programme,
          school: data.school,
          mailTo: content.mailTo,
        }),
      });

      await redirectToSuccessfulPage();
      setLoading(false);
    } catch (e) {
      console.log(e);
      setError("Something went wrong");
      setLoading(false);
    }
  };

  const renderRegularFormFields = (field, index) => {
    return (
      <FormField
        formType={field?.formType}
        watch={watch}
        validation={field?.validation}
        type={field.type ? field.type : "text"}
        register={register}
        property={field.dataText}
        key={`${field?.labelText}${index}`}
        hintText={field?.hintText}
        isEmptyFirstOption={field?.isEmptyFirstOption}
        placeholder={field.labelText}
        options={field?.options}
        name={field?.propertyName}
        errors={formState.errors}
      />
    );
  };

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(theme.component, "component", theme.form)}>
            <h1 className="h2">{formTitle}</h1>
          </div>
          <section
            className={clsx(
              theme.component,
              theme["job-board"],
              theme["form"],
              "form",
              theme.cf
            )}
          >
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className={theme["fields-floated"]}>
                <div className="form-row">
                  <section className={theme["form-group-wrapper"]}>
                    {error && <FormErrorMessage key={"error"} text={error} />}
                    {FormFields.map((field, index) => {
                      return renderRegularFormFields(field, index);
                    })}
                  </section>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: formBottomDescriptionText ?? "",
                  }}
                />
                <p>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo(0, 0);
                    }}
                  >
                    <u>Privacy statement</u>
                  </a>
                </p>

                <div className={theme["btn-and-text-wrapper"]}>
                  <SubmitButton
                    loading={loading}
                    id="submitButton"
                    text="Submit"
                  />
                </div>
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("C45_AlumniJobBoard", styles)(C45_AlumniJobBoard);

import NextImage, { ImageProps, StaticImageData } from "next/legacy/image";
import contentfulLoader from "../../services/ContentfulImageLoader";

export type NextImageProps = {
  src: string | StaticImageData;
  isCrop?: boolean;
  isSmartCrop?: boolean;
  cropFocus?:
    | "center"
    | "top"
    | "right"
    | "left"
    | "bottom"
    | "top_right"
    | "top_left"
    | "bottom_right"
    | "bottom_left"
    | "faces";
} & ImageProps;

const Image = ({
  loading = "lazy",
  src,
  isCrop,
  isSmartCrop,
  cropFocus,
  ...rest
}: NextImageProps) => {
  if (String(src).startsWith("//")) src = `https:${src}`;
  return (
    <NextImage
      loading={loading}
      loader={() =>
        contentfulLoader({
          isCrop,
          src: src as string,
          isSmartCrop,
          cropFocus,
          width: rest.width as number,
          height: rest.height as number,
          quality: rest.quality as number,
          layout: rest.layout,
        })
      }
      src={src}
      {...rest}
    />
  );
};

export default Image;

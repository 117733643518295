import { fetchApi } from "@utilities/fetchApi";
import { NextRouter } from "next/router";

export const checkBrochureUrl = (
  data: any,
  urlBrochureType: string,
  setBrochureContentId: (value: string) => void
) => {
  const brochureContent =
    data?.programmeBrochurePage || data?.programmeBrochure;
  const brochureLink = brochureContent?.slug || brochureContent?.url;
  const employmentReportContent =
    data?.employmentReportFormPage || data?.employmentReportForm;
  const employmentReportLink =
    employmentReportContent?.slug || employmentReportContent?.url;
  switch (urlBrochureType) {
    case "download a brochure":
      if (brochureLink && data?.programmeBrochureExternal) {
        return data?.programmeBrochureExternal || null;
      }
      if (brochureLink && !data?.programmeBrochureExternal) {
        setBrochureContentId(brochureContent?.sys?.id);
        return brochureLink || null;
      }
      if (data?.programmeBrochureExternal && !brochureLink) {
        return data?.programmeBrochureExternal || null;
      }
      break;

    case "class directory":
      setBrochureContentId(data?.classDirectoryForm?.sys?.id);
      return data?.classDirectoryForm.url || null;

    case "employment report":
      setBrochureContentId(employmentReportContent?.sys?.id);
      return employmentReportLink || null;
  }
};

export const prepareSubmitData = (
  data,
  selectedIdValues,
  router,
  canContactByEmail,
  genuinePath,
  isHrProfessional,
  selectedNationalCountry,
  selectedCountry,
  selectedCountryState,
  mappedBrochureType,
  brochureContentId,
  institutions,
  coursesYouAreInterestedIn
) => {
  const institutionId =
    institutions?.find((item) => item.value === data?.institution)?.id || null;

  const courseYouAreInterestedInId =
    coursesYouAreInterestedIn?.find(
      (item) => item.value === data?.courseYouAreInterestedInSelect
    )?.id || null;

  const availability = data?.availability?.toString().replace(/,/g, "\n");
  return {
    profile: {
      isStayInformed: canContactByEmail || data?.isStayInformed === "true",
      emailAddress: data?.emailAddress || null,
      title: +selectedIdValues.title || null,
      firstName: data?.firstName || null,
      lastName: data?.lastName || null,
      password: data?.password || null,
      callbackUrl: genuinePath,
    },
    programmeType: {
      isProgrammeTypeRequired: !!router?.query?.programmeTypeCode,
      programmeTypeCode:
        router?.query?.programmeTypeCode || courseYouAreInterestedInId || null,
      programmeTypeGroup: router?.query?.programmeTypeGroup || null,
    },
    yearsOfExperience: +data?.yearsRelevanceExperience || null,
    academicInstitution:
      data?.institutionFreeText || data?.institution || data?.university,
    yearsOfManagerialExperience: +data?.yearsManagerialExperience || null,
    industry: +selectedIdValues.industry || null,
    //new fields
    prerequisiteInstitution: institutionId || null,
    prerequisiteProgrammeTitle: data?.masterProgrammeDegreeTitle || null,
    //end of new fields
    jobLevel: +selectedIdValues.seniorityLevel || null,
    isHRProfessional: isHrProfessional || null,
    programmeTypesOfInterest:
      router?.query?.programmeTypeGroup === "EMBAG"
        ? ["EMBAG"]
        : data?.selectedProgrammesList || null,
    nationality: selectedNationalCountry || null,
    homeCountry: selectedCountry || null,
    countryState: selectedCountryState || null,
    city: data?.city || null,
    telephoneNumber: data?.telephone || null,
    company: data?.companyName || null,
    jobTitle: data?.jobTitle || null,
    yearOfGraduation: +data?.graduationYear || null,
    brochureType: mappedBrochureType,
    brochureContentId: brochureContentId,
    comments: `Motivations: ${data?.motivationText};\n Availability:\n ${availability}`,
  };
};

import clsx from "clsx";
import moment from "moment";
import { EventDetailProps } from "../C37.5_EventDetail";

import IconCalendar from "../../../public/image/svg/calendar.svg";
import IconDuration from "../../../public/image/svg/duration.svg";

const DateTimeSection = (
  props: { theme?: any } & Pick<EventDetailProps, "eventStarts" | "eventEnds">
) => {
  const { theme, eventStarts, eventEnds } = props;
  const showEventEndTime = eventStarts && eventEnds;
  const fullDateFormat = "dddd DD MMM yyy";
  const timeFormat = "HH:mm";

  const oneDayEvent: boolean =
    eventStarts !== null &&
    eventEnds !== null &&
    moment.utc(eventStarts).format(fullDateFormat) ===
      moment.utc(eventEnds).format(fullDateFormat);
  const renderDataTime = () => {
    if (oneDayEvent) {
      return <h3>{moment.utc(eventStarts).format(fullDateFormat)}</h3>;
    }

    return (
      <>
        {eventStarts && (
          <>
            {eventEnds && <p className="small">From: </p>}
            <h3>{moment.utc(eventStarts).format(fullDateFormat)}</h3>
          </>
        )}

        {eventEnds && (
          <>
            <p className="small">To: </p>
            <h3>{moment.utc(eventEnds).format(fullDateFormat)}</h3>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <p className={clsx("label", theme["label"])}>
        <i className={theme["icon-calendar"]}>
          <IconCalendar />
        </i>
        Date
      </p>

      {renderDataTime()}

      <p className={clsx("label", theme["label"])}>
        <i className={theme["icon-duration"]}>
          <IconDuration />
        </i>
        Time
      </p>
      {showEventEndTime && (
        <h3>
          {moment.utc(eventStarts).format(timeFormat)} -{" "}
          {moment.utc(eventEnds).format(timeFormat)}{" "}
        </h3>
      )}
    </>
  );
};

export default DateTimeSection;

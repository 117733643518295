import { useEffect, useRef, useState } from "react";
import styles from "./SelectAutocomplete.module.scss";
import clsx from "clsx";
import { themr } from "@friendsofreactjs/react-css-themr";
import useOnClickOutside from "../../utils/hooks/useOnClickOutside";
import { SelectAutocompleteProps } from "@components/SelectAutocomplete/interfaces";

const SelectAutocomplete = (props: SelectAutocompleteProps) => {
  const {
    value,
    theme,
    register,
    errors,
    name,
    validation,
    placeholder,
    options,
    getOptions,
    setValue,
    clearErrors,
  } = props;

  const hasError = errors && errors[name];

  const [isFocused, setIsFocused] = useState(false);
  const [isShowOptions, setShowOptions] = useState(false);

  useEffect(() => {
    setIsFocused(!!value);
  }, [value]);

  const handleOptionSelect = (option) => {
    setValue(name, option);
    getOptions(option);
    clearErrors(name);
    setIsFocused(!!value);
    setShowOptions(false);
  };

  const handleOnBlur = () => {
    setIsFocused(!!value);
    setShowOptions(false);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleInputOnChange = (e) => {
    setValue(name, e.target.value);
    getOptions(e.target.value);
    setShowOptions(true);
  };

  const fieldRef: any = useRef();

  useOnClickOutside(fieldRef, () => handleOnBlur());

  return (
    <div
      className={clsx(
        "form",
        "form-row",
        theme["form-row"],
        "field-row",
        theme["country-section"],
        isFocused && "focused",
        hasError && "error"
      )}
    >
      <div className="form-label base4">
        <div className="label">
          <label htmlFor="HomeCountry">{placeholder}</label>
        </div>
      </div>
      <div className="form-field base6">
        <div className="field" ref={fieldRef}>
          <>
            <input
              name={name}
              className={clsx("smart-focus", theme["homecountry-box"])}
              type="text"
              value={value}
              autoComplete="off"
              {...register(name, {
                onChange: handleInputOnChange,
                onFocus: handleOnFocus,
                ...validation,
              })}
            />
            {isShowOptions && options?.length > 0 && (
              <ul className={theme["countries-wrapper"]}>
                {options?.map((option, index) => (
                  <li
                    key={`${option}${index}`}
                    className={theme["countries-item"]}
                    value={option}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </>
        </div>

        {hasError && (
          <div className="form-row error">
            <div className="form-field base6">
              <div
                className={clsx(
                  "field",
                  "message-wrapper",
                  "inline-message",
                  "field_error_msg"
                )}
                tabIndex={0}
              >
                <p className={clsx("message", "active")}>
                  {errors[name]?.message}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default themr("SelectAutocomplete", styles)(SelectAutocomplete);

import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C210_3_ArticleBlockQuote.module.scss";
import clsx from "clsx";
import Grid from "@components/Grid/Grid";

export interface ArticleBlockQuoteProps {
  quoteText?: string;
  personName?: string;
  personDesignation?: string;
  displayAsLargeQuote?: boolean;
  inSublayout?: boolean;
}

export const ArticleBlockQuote = (props: {
  content: ArticleBlockQuoteProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    quoteText,
    personName,
    personDesignation,
    displayAsLargeQuote,
    inSublayout,
  } = content;

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12} md={!inSublayout ? 8 : 12}>
          <section
            className={clsx(
              "component",
              "cf",
              theme["article-blockquote"],
              displayAsLargeQuote && theme["large-quote"]
            )}
          >
            <span>
              <blockquote>{quoteText}</blockquote>
              <p className="quote-name">{personName}</p>
            </span>
            <p className="quote-position">{personDesignation}</p>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("ArticleBlockQuote", styles)(ArticleBlockQuote);

import ImageWrapper from "@components/Image/ImageWrapper";
import SwiperNavigation from "@components/Swiper/SwiperNavigation";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useDevice } from "@utilities/react/get-device/get-device";
import clsx from "clsx";
import { useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";
import { Swiper as SwiperProps } from "swiper/types";

import styles from "./ImageCarousel.module.scss";

export interface ImageCarouselProps {
  label?: string;
  title: string;
  description: string;
  carouselImages?: ImageWrapperType[];
}

const ImageCarousel = ({
  content,
  theme,
}: {
  content: ImageCarouselProps;
  theme?: any;
}) => {
  const { label, title, description, carouselImages } = content;
  const [slide, setSlide] = useState(1);
  const [swiper, setSwiper] = useState<SwiperProps>();
  const device = useDevice();

  const variation = device === "mobile" ? "square" : "fraction";

  return (
    <div className={theme["image-carousel"]}>
      <div className={theme["block-container"]}>
        <div className={theme["text-content"]}>
          {label && <p className={clsx("label", theme.label)}>{label}</p>}
          <h2>{title}</h2>
          {description && <p>{description}</p>}
        </div>
      </div>

      {carouselImages && carouselImages.length > 0 && (
        <div className={clsx("wrapper", theme["wrapper"])}>
          {swiper && (
            <>
              <SwiperNavigation
                className={theme["image-carousel-prev"]}
                swiper={swiper}
                direction="prev"
                perPage={1}
                variation={variation}
                hideOnEdge={true}
              />
              <SwiperNavigation
                className={theme["image-carousel-next"]}
                swiper={swiper}
                direction="next"
                perPage={1}
                variation={variation}
                hideOnEdge={true}
              />
            </>
          )}

          <SwiperCarousel
            allowTouchMove={false}
            spaceBetween={10}
            slidesPerView={"auto"}
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            onSlideChange={({ activeIndex }) => {
              setSlide(activeIndex + 1);
            }}
            onSwiper={(swiper) => {
              setSwiper(swiper);
            }}
          >
            {carouselImages.map((carouselImage, index) => (
              <SwiperSlide
                key={`${carouselImage?.fields?.image?.fields?.file?.url}${index}`}
              >
                <ImageWrapper image={carouselImage} />
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        </div>
      )}
    </div>
  );
};

export default themr("ImageCarousel", styles)(ImageCarousel);

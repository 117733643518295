import Grid from "@components/Grid/Grid";
import React from "react";
import Columns from "./Columns";

export interface SublayoutWhiteAndBlueProps {
  content: {
    columns: any;
    backgroundColour?: string;
  };
}

const SublayoutWhiteAndBlue = (props: any) => {
  const { columns } = props;

  return (
    <div className="sublayout-1 outer-wrap white-blue">
      <Grid row customClass="grid-row">
        <Columns
          columns={columns}
          columnTotal={2}
          customSize={[6, 6]}
          type="white-blue"
        />
      </Grid>
    </div>
  );
};

export default SublayoutWhiteAndBlue;

import Arrow from "../../public/assets2019/img/svg/arrow-carousel.svg";

export interface QuestionSubjectTypes {
  id: string;
  value: string;
}

export interface MultiSelectProps {
  labelText?: string;
  questionSubjects?: Array<QuestionSubjectTypes>;
  setSelectedOptionId: Function;
  selectedSubject?: string;
  setSelectedSubject: Function;
  register: any;
}

const MultiSelect = (props: MultiSelectProps) => {
  const {
    labelText,
    questionSubjects,
    setSelectedOptionId,
    selectedSubject,
    setSelectedSubject,
    register,
  } = props;

  const setSelectValues = (event) => {
    event.target.setAttribute("id", event.target.selectedOptions[0].id);
    setSelectedOptionId(event.target.id);
    setSelectedSubject(event.target.value);
  };

  return questionSubjects && Array.isArray(questionSubjects) ? (
    <div className="form-row field-row select-wrapper">
      <div className="form-label base4">
        <div className="label">
          <label htmlFor="Question">{labelText}</label>
        </div>
      </div>
      <div className="form-field base6">
        <div className="field select">
          <select
            value={selectedSubject}
            {...register("Question", {
              onChange: setSelectValues,
            })}
          >
            {questionSubjects?.map((option) => (
              <option value={option.value} key={option.id} id={option.id}>
                {option.value}
              </option>
            ))}
          </select>

          <div className="tick icon-chevron">
            <i className="icon-chevron">
              <Arrow></Arrow>
            </i>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default MultiSelect;

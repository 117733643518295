import Image from "@components/Image/Image";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { themr } from "@friendsofreactjs/react-css-themr";
import dynamic from "next/dynamic";
import NextImage from "components/Image/NextImage";
import { useState } from "react";
import videoIcon from "../../public/image/vidicon.png";

import styles from "./C09_VideoVimeoAndYoutube.module.scss";
//@ts-ignore
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

export interface VideoVimeoAndYoutubeProps {
  inSublayout?: boolean;
  videoCode: string;
  isVimeoCode?: boolean;
  overlayImage: ImageWrapperType;
}

export const VideoVimeoAndYoutube = (props: {
  content: VideoVimeoAndYoutubeProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { inSublayout, videoCode, isVimeoCode, overlayImage } = content;

  const videoUrl = isVimeoCode
    ? `https://vimeo.com/${videoCode}`
    : `https://www.youtube.com/watch?v=${videoCode}&modestbranding=1`;

  const posterImage = isVimeoCode
    ? overlayImage?.fields?.image?.fields?.file?.url
    : overlayImage
      ? overlayImage?.fields?.image?.fields?.file?.url
      : videoCode
        ? `https://img.youtube.com/vi/${videoCode}/hqdefault.jpg`
        : null;
  const posterAltText: string = overlayImage?.fields?.altText || "";
  const [isPlaying, setIsPlaying] = useState(false);
  const videoJSX = () => {
    return !videoCode ? null : (
      <div
        className={`component ${theme.youtube} ${
          inSublayout ? theme["two-col"] : ""
        }`}
      >
        <div className={theme["youtube-embed"]}>
          {!isPlaying ? (
            <div
              className={theme["overlay-image"]}
              onClick={() => setIsPlaying(true)}
            >
              <div
                className={theme["play-icon"]}
                aria-label="play icon that starts video"
              >
                <div className={theme["img-wrapper"]}>
                  <NextImage
                    src={videoIcon}
                    alt="play icon"
                    width={160}
                    height={160}
                    layout="responsive"
                  />
                </div>
              </div>
              {
                posterImage ? (
                  inSublayout ? (
                    <Image
                      src={posterImage}
                      alt={posterAltText}
                      width={640}
                      height={360}
                      isSmartCrop
                    />
                  ) : (
                    <Image
                      src={posterImage}
                      alt={posterAltText}
                      desktopWidth={1440}
                      desktopHeight={810}
                      width={640}
                      height={360}
                      isSmartCrop
                    />
                  )
                ) : null //need to change null to a fallback image
              }
            </div>
          ) : (
            <ReactPlayer
              url={videoUrl}
              playing={isPlaying}
              width="100%"
              height="100%"
              controls={true}
            />
          )}
        </div>
      </div>
    );
  };

  return <>{inSublayout ? <div>{videoJSX()}</div> : <>{videoJSX()}</>}</>;
};

export default themr("VideoVimeoAndYoutube", styles)(VideoVimeoAndYoutube);

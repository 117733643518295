import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C214_TopicBanner.module.scss";
import clsx from "clsx";
import C200_1_BrandIconTitle from "@components/C200_1_BrandIconTitle/C200_1_BrandIconTitle";

interface Props {
  theme?: any;
}
export const TopicBanner = (props: Props) => {
  let bannerTitle = "";
  let urlParams: any = [];
  if (typeof window !== "undefined") {
    urlParams = new URLSearchParams(window.location.search);
    const params = {};
    urlParams.forEach((value, key) => {
      params[key] = value;
    });

    for (const key in params) {
      if (window.location?.href?.includes("articletopics")) {
        bannerTitle = params[key];
      }
    }
  }
  if (!bannerTitle) return <></>;
  return (
    <div className={styles.cTopicBanner}>
      <C200_1_BrandIconTitle />
      <div className={styles.banner}>
        <div className={clsx("wrapper")}>
          <div className={styles["content-wrapper"]}>
            <h1>{bannerTitle}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default themr("TopicBanner", styles)(TopicBanner);

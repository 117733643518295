import setGADataLayer from "./setGADataLayer";

export const navigationGAClick = (e) => {
  const link = e.currentTarget;
  const linkText = link.text;
  const linkUrl = link.href;
  const linkPath = linkUrl?.replace(process.env.NEXT_PUBLIC_URL, "");
  const linkBreadcrumb = linkPath?.replace("/", "").replaceAll("/", ">");
  const trackModel = {
    linkText,
    linkUrl,
    linkPath,
    linkBreadcrumb,
    event: "click_nav_item",
  };
  setGADataLayer(trackModel);
};

import Arrow from "../../public/assets2019/img/svg/arrow-carousel.svg";
import { useEffect, useState } from "react";
import styles from "./CountryNumber.module.scss";
import clsx from "clsx";
import { themr } from "@friendsofreactjs/react-css-themr";
import { FieldErrors } from "react-hook-form";

export interface OptionsDataTypes {
  code: string;
  dial_code: string;
  name: string;
}

export interface CountryNumbersThemeTypes {
  base6: string;
  countryCodeAndPhone: string;
  countryDialSelect: string;
  countrycodefield: string;
  ["form-row"]: string;
  telfield: string;
}

export interface CountryNumberProps {
  register: Function;
  watch: Function;
  theme: CountryNumbersThemeTypes;
  dialCodeLabelText: string;
  telephoneInputPlaceholderText: string;
  telephoneNumberHintText: string;
  optionsData: Array<OptionsDataTypes>;
  selectedDialCode: string;
  setSelectedDialCode: Function;
  numberValue: string;
  setNumberValue: Function;
  errors: FieldErrors;
  fieldIsRequired: boolean;
}

const CountryNumber = (props: CountryNumberProps) => {
  const {
    register,
    watch,
    theme,
    dialCodeLabelText,
    telephoneInputPlaceholderText,
    telephoneNumberHintText,
    optionsData,
    selectedDialCode,
    setSelectedDialCode,
    numberValue,
    setNumberValue,
    errors,
    fieldIsRequired,
  } = props;

  const numberInputSettings = {
    name: "telephone",
    type: "text",
    placeholder: telephoneInputPlaceholderText,
    hintText: telephoneNumberHintText,
    validation: {
      isRequired: fieldIsRequired,
      minLength: 7,
      maxLength: 15,
      telephone: true,
    },
  };

  const inputValue = watch(numberInputSettings?.name, false);
  const hasError = errors && errors[numberInputSettings?.name];
  const [isFocused, setIsFocused] = useState(false);

  const handlePrefilledNumberInputValue = (event) => {
    event.target.setAttribute("id", event.target.selectedOptions[0].id);
    setNumberValue(event.target.id);
    setIsFocused(true);
    setSelectedDialCode(event.target.value);
  };

  const handleOnFocus = () => {
    if (numberValue) {
      setIsFocused(true);
      return;
    }
    setIsFocused(true);
  };

  const handleOnBlur = () => {
    if (numberValue) {
      setIsFocused(true);
      return;
    }
    setIsFocused(false);
  };

  useEffect(() => {
    if (optionsData) {
      setIsFocused(false);
    } else {
      if (inputValue) {
        setIsFocused(true);
      }
    }
  }, [optionsData]);

  useEffect(() => {
    setIsFocused(!!numberValue);
  }, [numberValue]);

  const maxLengthMessage = numberInputSettings?.validation?.telephone
    ? `Your ${numberInputSettings?.placeholder} cannot be more than '${numberInputSettings?.validation?.maxLength}' characters long`
    : `The field ${numberInputSettings?.placeholder} must be a string or array type with a maximum length of '${numberInputSettings?.validation?.maxLength}'`;

  const minLengthMessage = numberInputSettings?.validation?.telephone
    ? `Your ${numberInputSettings?.placeholder} cannot be less than '${numberInputSettings?.validation?.minLength}' characters long`
    : `The field ${numberInputSettings?.placeholder} must be a string or array type with a minimum length of '${numberInputSettings?.validation?.minLength}'`;

  const validationRules = {
    ...(numberInputSettings?.validation?.isRequired && {
      required: `${numberInputSettings?.placeholder} is required`,
    }),
    ...(numberInputSettings?.validation?.maxLength && {
      maxLength: {
        value: numberInputSettings?.validation?.maxLength,
        message: maxLengthMessage,
      },
    }),
    ...(numberInputSettings?.validation?.minLength && {
      minLength: {
        value: numberInputSettings?.validation?.minLength,
        message: minLengthMessage,
      },
    }),
    ...(numberInputSettings?.validation?.telephone && {
      pattern: {
        value: /^[+()]*[ 0-9]+$/,
        message: `Telephone number: Only numbers, brackets and + allowed`,
      },
    }),
  };

  return (
    <div
      onBlur={handleOnBlur}
      className={clsx(
        "form",
        "form-row",
        theme["form-row"],
        theme["countryCodeAndPhone"],
        isFocused && "focused",
        hasError && "error"
      )}
    >
      <div className={clsx(theme["base6"], theme.countrycodefield)}>
        <div className="form-row field-row select-wrapper">
          <div className={clsx(theme["base4"], theme["countryCodeLabel"])}>
            <div className="label">
              <label htmlFor="SelectCountrydialCode">{dialCodeLabelText}</label>
            </div>
          </div>

          <div className="form-field base6">
            <div className="field select">
              {optionsData && (
                <>
                  <select
                    className={clsx(theme.countryDialSelect)}
                    value={selectedDialCode}
                    {...register("SelectCountrydialCode", {
                      onChange: handlePrefilledNumberInputValue,
                    })}
                  >
                    <option value="" />
                    {optionsData &&
                      optionsData?.map((option) => (
                        <option
                          value={`${option.name}(${option.dial_code})`}
                          key={option.code}
                          id={option.dial_code}
                        >
                          {`${option.name}(${option.dial_code})`}
                        </option>
                      ))}
                  </select>
                </>
              )}

              <div className="tick icon-chevron">
                <i className="icon-chevron">
                  <Arrow></Arrow>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(theme["base6"], theme.telfield)}>
        <div className={clsx(theme["form-row"], "field-row")}>
          <div className="form-label base4">
            <div className="label">
              <label htmlFor="TelephoneNumber">
                {telephoneInputPlaceholderText}
              </label>
            </div>
          </div>
          <div className="form-field base6">
            <div className="field">
              <input
                onFocus={handleOnFocus}
                value={numberValue}
                className="smart-focus"
                type="text"
                {...register(numberInputSettings?.name, {
                  ...validationRules,
                  onChange: (event) => setNumberValue(event.target.value),
                })}
              />

              {(errors[numberInputSettings?.name]?.message ||
                numberInputSettings?.hintText) && (
                <>
                  <div
                    className={clsx(
                      "field",
                      "message-wrapper",
                      "inline-message",
                      errors[numberInputSettings?.name] && "field_error_msg"
                    )}
                    tabIndex={0}
                  >
                    <p
                      className={clsx(
                        "message",
                        errors[numberInputSettings?.name]?.message && "active"
                      )}
                    >
                      {errors[numberInputSettings?.name]?.message &&
                        numberInputSettings?.hintText}
                    </p>
                  </div>

                  <div
                    className={clsx(
                      "field",
                      "message-wrapper",
                      "inline-message",
                      errors[numberInputSettings?.name] && "field_error_msg"
                    )}
                    tabIndex={0}
                  >
                    <p
                      className={clsx(
                        "message",
                        errors[numberInputSettings?.name]?.message && "active"
                      )}
                    >
                      {errors[numberInputSettings?.name]?.message
                        ? errors[numberInputSettings?.name]?.message
                        : numberInputSettings?.hintText}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default themr("CountryNumber", styles)(CountryNumber);

import { createContext, useState } from "react";

export interface StoryContextProps {
  updateNavigation: Function;
  setLineHeights: Function;
  activeIndex: number;
  darkNav: boolean;
  lineHeights: number[];
}

export const StoryContext = createContext<StoryContextProps>({
  updateNavigation: () => {},
  setLineHeights: () => {},
  activeIndex: 0,
  darkNav: true,
  lineHeights: [],
});

export const StoriesProvider = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [darkNav, setDarkNav] = useState(false);
  const [lineHeights, setLineHeights] = useState([]);

  const updateNavigation = (
    index: number,
    isDarkNav: boolean = true,
    scroll: boolean = false,
    item: any = null
  ) => {
    setActiveIndex(index);
    setDarkNav(isDarkNav);

    if (scroll && item) {
      const nextSection = item?.parentNode?.nextElementSibling;

      window.scroll({
        top: nextSection.getBoundingClientRect().top + window.scrollY,
        behavior: "smooth",
      });
    }
  };

  return (
    <StoryContext.Provider
      value={{
        updateNavigation,
        setLineHeights,
        activeIndex,
        darkNav,
        lineHeights,
      }}
    >
      {children}
    </StoryContext.Provider>
  );
};

//requested by FreshEgg
export const getBusinessUnit = () => {
  if (typeof window === "undefined") return "";
  switch (true) {
    case window.location.href.includes("masters-degrees"):
      return "DE";
    case window.location.href.includes("executive-education"):
      return "EE";
    default:
      return "";
  }
};

//Requested by Croud
export const getPageCategory = () => {
  if (typeof window === "undefined") return "";
  const slug = window.location.href;
  const slugArr = slug
    .replace(`${process.env.NEXT_PUBLIC_URL as string}/`, "")
    .split("/");
  const pageCategory =
    slugArr.length > 2
      ? slugArr.reverse()[1]
      : slugArr.length === 2
        ? slugArr[1]
        : slugArr[0];
  return pageCategory === "" ? "Homepage" : pageCategory.replace(/-/g, " ");
};

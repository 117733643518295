export const PublicationDetailResultsTemplate = (props) => {
  const { result } = props;

  const publicationTitles = result.title?.split("|");
  const publicationTitle =
    publicationTitles?.length != 0 ? publicationTitles[0] : result.title;

  return (
    <>
      {/* add the date */}
      <div className="coveo-result">
        <div>
          {result.raw.publicationyear !== null && (
            <p className="coveo-year">{result.raw.publicationyear}</p>
          )}
          <a className="coveo-link" href={result.clickUri}>
            {result.title && <h3 className="h3">{publicationTitle}</h3>}
          </a>
          {result.raw.publicationauthorslist && (
            <p className="page-description">
              <span>{result.raw.publicationauthorslist}</span>
            </p>
          )}
          {result.raw.publicationpublishingdetails && (
            <p className="page-details">
              <span>{result.raw.publicationpublishingdetails}</span>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default PublicationDetailResultsTemplate;

import C282_Electives from "@components/C282_Electives/C282_Electives";
import { Course } from "@components/C282_Electives/ElectiveItem";
import { useSettings } from "@utilities/context/settings";
import { fetchApi } from "@utilities/fetchApi";
import { useEffect, useState } from "react";
import { courseTypeOrder } from "@components/C282_SubjectAreaCourses/constants";

export interface SubjectAreaCoursesProps {
  singleDisplay?: boolean;
  subjectAreaName: string;
  defaultOpen?: boolean;
}

const sortByType = (a, b) => {
  return (
    courseTypeOrder.indexOf(a.courseTypeCode) -
    courseTypeOrder.indexOf(b.courseTypeCode)
  );
};

const SubjectAreaCourses = (props: { content: SubjectAreaCoursesProps }) => {
  const { content } = props;
  const { subjectAreaName } = content;
  const [courses, setCourses] = useState<Array<Course>>([]);
  const { courseTypeSettings } = useSettings();

  useEffect(() => {
    const getData = async () => {
      const courseData: Course[] = await fetchApi("/api/getCoursesByField", {
        field: "subjectAreaName",
        value: subjectAreaName,
      });

      const courseList = courseData.map((course) => ({
        ...(courseTypeSettings &&
          courseTypeSettings.courses.find(
            (settings) => settings.courseTypeCode === course.courseType
          )),
        ...course,
      }));

      setCourses(courseList.sort(sortByType));
    };
    if (subjectAreaName) {
      getData();
    }
  }, [subjectAreaName]);

  return (
    <C282_Electives
      courses={courses}
      {...content}
      columns={courses.length < 4 ? 3 : 4}
    />
  );
};

export default SubjectAreaCourses;

import styles from "./Error.module.scss";
import Grid from "@components/Grid/Grid";
import { useContext } from "react";
import { SettingsContext } from "@utilities/context/settings";

export const GenericApiError = () => {
  const { siteSettings } = useContext(SettingsContext);

  return (
    <div className={`wrapper ${styles.errorWrapper}`}>
      <Grid row>
        <Grid column sm={8}>
          <p className={styles["Error"]}>{siteSettings?.genericApiError}</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default GenericApiError;

import { LinkAsset } from "@customTypes/generalLink";
import { Icons } from "@customTypes/icons";
import { clsx } from "clsx";
import Link from "@components/Link/Link";
import ClipLoader from "react-spinners/ClipLoader";
import IconArrowSmall from "../../public/assets2019/img/svg/arrow.svg";
import IconCall from "../../public/assets2019/img/svg/call.svg";
import IconDownload from "../../public/assets2019/img/svg/download.svg";
import IconExternal from "../../public/assets2019/img/svg/external.svg";
import IconInformation from "../../public/assets2019/img/svg/information.svg";
import IconPDF from "../../public/assets2019/img/svg/pdf.svg";
import IconArrow from "../../public/image/svg/arrow.svg";
import IconChevron from "../../public/image/svg/chevron.svg";
import IconPlay from "../../public/image/svg/play.svg";
import IconCross from "../../public/image/svg/cross.svg";
import IconSearch from "../../public/image/svg/search.svg";
import IconEmail from "../../public/image/svg/email.svg";

interface CtaProps {
  onClick?: any;
  preventDefault?: boolean;
  asset?: any;
  theme?: any;
  linkText: string;
  helpText?: string;
  assets?: LinkAsset;
  contentReference?: {
    fields: {
      slug: string;
    };
  };
  type: "primary" | "secondary" | "tertiary";
  url: string;
  btnTheme?: string;
  icon?: Icons;
  customClass?: string;
  contentClass?: string;
  openInNewTab?: boolean;
  noStyle?: boolean;
  light?: boolean;
  id?: string;
  loading?: boolean;
}

const iconLookup = {
  "icon-arrow": IconArrow,
  "icon-arrow-small": IconArrowSmall,
  "icon-play": IconPlay,
  "icon-chevron": IconChevron,
  "icon-call": IconCall,
  "icon-information": IconInformation,
  "icon-pdf": IconPDF,
  "icon-download": IconDownload,
  "icon-external": IconExternal,
  "icon-cross": IconCross,
  "icon-search": IconSearch,
  "icon-email": IconEmail,
};

export const Cta = ({
  linkText,
  type,
  helpText,
  url,
  btnTheme = "",
  contentReference,
  icon,
  customClass = "",
  contentClass = "",
  noStyle = false,
  openInNewTab,
  onClick,
  preventDefault = true,
  assets,
  light,
  id = "",
  loading = false,
}: CtaProps) => {
  const IconClass = assets?.fields?.file?.contentType
    ? assets.fields.file.contentType === "application/pdf"
      ? "icon-pdf"
      : "icon-download"
    : icon;
  const Icon = assets?.fields?.file?.contentType
    ? assets.fields.file.contentType === "application/pdf"
      ? iconLookup["icon-pdf"]
      : iconLookup["icon-download"]
    : !!icon
      ? iconLookup[icon]
      : null;

  const isContentReferenceUrl = !!contentReference?.fields.slug;
  const target = openInNewTab ? { target: "_blank" } : null;

  let ctaUrl = url;

  if (contentReference?.fields.slug) {
    ctaUrl = `/${contentReference.fields.slug}`;
  } else {
    if (assets?.fields?.file && !url) {
      ctaUrl = `https:${assets?.fields?.file.url}`;
    }
  }

  const handleCLick = (e) => {
    if (onClick) {
      if (preventDefault) {
        e.preventDefault();
      }
      onClick(e);
    }
  };

  function formatBytes(bytes, decimals = 0) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["bytes", "kb", "mb"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
  }

  const ctaJSX = () => (
    <Link href={ctaUrl || "#"} passHref>
      <a
        id={id}
        onClick={handleCLick}
        className={clsx(type, customClass, btnTheme, {
          light,
          cta: !noStyle,
          download: !isContentReferenceUrl && assets,
          loading: loading,
        })}
        {...target}
      >
        {loading && (
          <ClipLoader
            color="#001e62"
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
        {Icon && (
          <span className={`${contentClass} ${IconClass}`}>
            <Icon />
          </span>
        )}
        <span>
          {linkText}
          {!isContentReferenceUrl && assets && (
            <sub> ({formatBytes(assets.fields.file.details.size)})</sub>
          )}
        </span>
      </a>
    </Link>
  );

  return (
    <>
      {!helpText ? (
        ctaJSX()
      ) : (
        <div className="cta-with-message">
          {ctaJSX()}
          <div
            className={clsx("field", "base6", "message-wrapper", {
              light,
              btnTheme,
            })}
          >
            <p className="message">{helpText}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Cta;

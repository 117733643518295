export const programmeTypeGroupFieldsInfo = {
  EXECED: {
    userAvailability: { enabled: true },
    emailAddress: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },

    yearsManagerialExperience: { enabled: true },
    countryNumber: { enabled: true },
    motivationText: { enabled: true },

    password: { enabled: true },
    confirmPassword: { enabled: true },

    courseYouAreInterestedInSelect: { enabled: true },
    jobTitle: { enabled: true },
  },
  EEAndOnlineCourses: {
    userAvailability: { enabled: true },
    emailAddress: { enabled: true },
    firstName: { enabled: true },
    lastName: { enabled: true },

    yearsManagerialExperience: { enabled: true },
    countryNumber: { enabled: true },
    motivationText: { enabled: true },

    password: { enabled: true },
    confirmPassword: { enabled: true },
    jobTitle: { enabled: true },
  },
};

export const permanentFields = [
  "emailAddress",
  "title",
  "firstName",
  "lastName",
];

export const programmesListData = {
  MIM: [
    {
      id: "10001",
      name: "Masters in Management",
      value: "MIM",
    },
    {
      id: "10002",
      name: "Global MiM",
      value: "GMIM",
    },
  ],
  MIF: [
    {
      id: "10003",
      name: "Masters in Finance Full-time",
      value: "MIFFT",
    },
    {
      id: "10004",
      name: "Masters in Finance Part-time",
      value: "MIFPT",
    },
  ],
  EMBA: [
    {
      id: "10005",
      name: "Executive MBA (London)",
      value: "EMBA London",
    },
    {
      id: "10006",
      name: "Executive MBA (Dubai)",
      value: "EMBA Dubai",
    },
  ],
};

export const availabilityListData = {
  monday: [
    {
      id: "10001",
      name: "Monday AM",
      value: "Monday AM",
    },
    {
      id: "10002",
      name: "Monday PM",
      value: "Monday PM",
    },
  ],
  tuesday: [
    {
      id: "10003",
      name: "Tuesday AM",
      value: "Tuesday AM",
    },
    {
      id: "10004",
      name: "Tuesday PM",
      value: "Tuesday PM",
    },
  ],
  wednesday: [
    {
      id: "10005",
      name: "Wednesday AM",
      value: "Wednesday AM",
    },
    {
      id: "10006",
      name: "Wednesday PM",
      value: "Wednesday PM",
    },
  ],
  thursday: [
    {
      id: "10007",
      name: "Thursday AM",
      value: "Thursday AM",
    },
    {
      id: "10008",
      name: "Thursday PM",
      value: "Thursday PM",
    },
  ],
  friday: [
    {
      id: "10009",
      name: "Friday AM",
      value: "Friday AM",
    },
    {
      id: "10010",
      name: "Friday PM",
      value: "Friday PM",
    },
  ],
};

import React from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import styles from "./index.module.scss";

export type MissingContentBlockProps = {
  error: any;
  component?: string;
  contentType?: string;
};

const MissingContent: React.FC<MissingContentBlockProps> = (props) => {
  const { error, component, contentType } = props;
  const appInsights = useAppInsightsContext();
  const e = {};
  Object.getOwnPropertyNames(error).forEach(function (propName) {
    e[propName] = error[propName];
  });

  const errorMessage = error?.stack
    ? JSON.stringify(error.stack.split("\n")[0])
    : `There has been an error with ${component}`;

  if (process.env.NEXT_PUBLIC_NETLIFY_CONTEXT === "production") {
    appInsights.trackEvent({
      name: "componentError",
      properties: {
        message: `there has been an error with component`,
        componentName: component,
      },
    });
  }

  return process.env.NEXT_PUBLIC_NETLIFY_CONTEXT === "production" ? (
    <></>
  ) : (
    <div className={styles.base}>
      <span>{errorMessage}</span>
      {component ? <span>{component}</span> : null}
      {component ? <span>{contentType}</span> : null}
    </div>
  );
};

export default MissingContent;

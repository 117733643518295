export const programmeTypesValues = [
  "download a brochure",
  "programme calendar",
  "class directory",
  "employment report",
];
export const intArray = [0, 1, 2, 3];

export const mapProgrammeTypeToInt = (programme) => {
  const index = programmeTypesValues.indexOf(programme.toLowerCase());
  if (index !== -1) {
    return intArray[index];
  }
  return null;
};

/* COMPONENT - C50.1 Results 1.0 */
"use strict";

export class Results {
  constructor(element, options) {
    this.root = element;
    this.options = options;
    this.summaryText = this.root.querySelector(".summary-text");
    this.summaryContainer = this.root.querySelector(".summary-text-container");
    this.detailsElement = this.root.querySelector(".details");
    this.fullText = this.summaryText ? this.summaryText.innerHTML : "";
    this.collapsedHeight = 110;
    this.ellipsistext = "\u2026 ";
    this.ellipsis = document.createTextNode(this.ellipsistext);
    this.expanded = false;
    this.noAccordion = false;

    this.applyListeners();

    setTimeout(() => {
      this.summaryText = this.root.querySelector(".summary-text");
      this.summaryContainer = this.root.querySelector(
        ".summary-text-container"
      );
      this.fullText = this.summaryText ? this.summaryText.innerHTML : "";
      this.fullText = this.fullText.trim();

      if (this.summaryContainer) {
        if (this.summaryText.scrollHeight <= this.collapsedHeight - 30) {
          this.noAccordion = true;
          this.root.classList.add("no-accordion");
          this.summaryContainer.removeAttribute("tabindex");
        } else {
          this.truncateText(this.collapsedHeight - 30);
        }
      } else {
        this.root.classList.add("no-summary");
      }
    }, 100);
  }

  truncateText(maxHeight) {
    const element = this.summaryText;
    const text = this.fullText;
    const separator = text.indexOf(" ") !== -1 ? " " : "\u3000";
    const words = text.split(separator);

    for (let a = words.length; a >= 0; a--) {
      element.textContent = this.addEllipsis(words.slice(0, a).join(separator));

      if (this.fits(maxHeight)) {
        element.classList.add("truncated");
        break;
      }
    }
  }

  addEllipsis(text) {
    var remove = [" ", "\u3000", ",", ";", ".", "!", "?"];
    let str = this.fullText;

    while (remove.indexOf(text.slice(-1)) > -1) {
      text = text.slice(0, -1);
    }

    while (remove.indexOf(str.slice(-1)) > -1) {
      str = str.slice(0, -1);
    }

    if (text !== str) {
      text += this.ellipsis.textContent;
    } else {
      text = this.fullText;
    }

    return text;
  }

  fits(maxHeight) {
    return this.summaryText.scrollHeight <= maxHeight;
  }

  expandText() {
    const expandedHeight = this.detailsElement.clientHeight;
    this.summaryContainer.style.height = expandedHeight + "px";
    this.truncateText(expandedHeight - 30);
  }

  collapseText() {
    this.summaryContainer.style.height = this.collapsedHeight + "px";
    this.truncateText(this.collapsedHeight - 30);
  }

  toggleShowMore() {
    if (this.summaryContainer.classList.contains("open") && !this.noAccordion) {
      this.summaryContainer.classList.remove("open");
      this.expanded = false;
      this.collapseText();
    } else if (!this.noAccordion) {
      this.summaryContainer.classList.add("open");
      this.expanded = true;
      this.expandText();
    }
  }

  handleEnter(e) {
    if (e.keyCode === 13) {
      this.toggleShowMore();
    }
  }

  applyListeners() {
    let debounce;

    if (this.summaryContainer) {
      this.summaryContainer.addEventListener(
        "click",
        this.toggleShowMore.bind(this)
      );
      this.summaryContainer.addEventListener(
        "keyup",
        this.handleEnter.bind(this)
      );

      window.addEventListener("resize", () => {
        const height = this.expanded
          ? this.detailsElement.clientHeight
          : this.collapsedHeight;
        clearTimeout(debounce);

        debounce = setTimeout(() => {
          this.truncateText(height - 30);
        }, 100);
      });
    }
  }
}

const Component = {
  elements: [],
  objects: [],
  init: (className, options = {}) => {
    let i;
    Component.elements = document.querySelectorAll(className);
    for (i = 0; i < Component.elements.length; i += 1) {
      Component.objects.push(
        new Results(Component.elements[i], Object.assign({}, options))
      );
    }
  },
};
export default Component;

import React from "react";
import Link from "@components/Link/Link";
import { isGeneralLink } from "@customTypes/generalLink";
import { navigationGAClick } from "@utilities/navigationGAClick";

const SecondaryLinkList = ({ secondaryLinkList, handleReset }) =>
  secondaryLinkList.filter(isGeneralLink).map((secondaryLink, index) => (
    <li key={`${secondaryLink?.fields?.linkText}${index}`}>
      <Link href={secondaryLink?.fields?.url || "#"}>
        <a
          onClick={(e) => {
            navigationGAClick(e);
            handleReset();
          }}
        >
          {secondaryLink?.fields?.linkText}
        </a>
      </Link>
    </li>
  ));

export default SecondaryLinkList;

import { useEffect } from "react";
import Cta from "@components/Cta/Cta";
import Lightbox from "@components/Lightbox/Lightbox";
import { ctaLink } from "@customTypes/generalLink";
import { Media } from "@customTypes/media";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useDevice } from "@utilities/react/get-device/get-device";
import dynamic from "next/dynamic";
import { useRef, useState } from "react";
import { useInView } from "react-hook-inview";
import videoIcon from "../../public/image/vidicon.png";
import clsx from "clsx";
import styleNames from "../../utils/react/style-names";
import styles from "./C46_HeaderBanner.module.scss";
import Image from "@components/Image/NextImage";
import RichTextFullWidth from "@components/C16_RichTextFullWidth/C16_RichTextFullWidth";

//@ts-ignore
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

interface ImageProps {
  altText: string;
  title: string;
  description: string;
  url: string;
}

export interface HeaderBannerProps {
  title: string;
  secondaryTitle: string;
  sectionLabel: string;
  isLight?: boolean;
  bannerType?: "Video Background" | "Video Overlay" | "Image";
  localVideo?: Media;
  description?: string;
  showChevron?: boolean;
  ctaLink?: ctaLink;
  mobileImage?: ImageProps;
  desktopImage?: ImageProps;
  videoLinkText?: string;
  isVimeoCode?: boolean;
  videoCode?: string;
  hideLine?: boolean;
  subDescription?: {
    content: any;
    contentTinyMce: any;
  };
}

const HeaderBanner = (props: { content: HeaderBannerProps; theme?: any }) => {
  const { content, theme } = props;

  const {
    title,
    isLight,
    localVideo,
    description,
    showChevron,
    ctaLink,
    mobileImage,
    desktopImage,
    bannerType,
    videoLinkText,
    secondaryTitle,
    isVimeoCode,
    videoCode,
    hideLine,
    subDescription,
  } = content;

  const bannerContent: any = useRef(null);

  const [hideImage, setHideImage] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showLightbox, setShowLightbox] = useState(false);
  const [isClient, setisClient] = useState(false);

  useEffect(() => {
    if (typeof window === "object") {
      setisClient(true);
    }
  }, []);

  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  const playVideo = () => {
    setHideImage(true);
    setIsPlaying(true);
  };

  const onVideoEnd = () => {
    setHideImage(false);
    setIsPlaying(false);
  };

  const scrollDown = (event) => {
    event.preventDefault();
    let rect = bannerContent?.current?.getBoundingClientRect();

    window.scroll({
      top:
        rect.top +
        rect.height +
        (window.scrollY || document.documentElement.scrollTop),
      left: 0,
      behavior: "smooth",
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      scrollDown(event);
    }
  };

  const closeLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    }, 300);
  };

  const getCodeUrl = isVimeoCode
    ? `https://vimeo.com/${videoCode}`
    : `https://www.youtube.com/watch?v=${videoCode}&modestbranding=1`;
  const videoSrc = videoCode
    ? getCodeUrl
    : localVideo
      ? localVideo.fields.file.url
      : null;

  const device = useDevice();
  const isMobile = device === "mobile";

  const isVideoBackground = bannerType === "Video Background";
  const isVideoOverlay = bannerType === "Video Overlay";
  const isImage = bannerType === "Image";

  const checkImageWidth = isVideoOverlay ? "665" : "486";
  const imageWidth = isVideoBackground ? "1094" : checkImageWidth;

  const checkImageHeight = isVideoOverlay ? "604" : "486";
  const imageHeight = isVideoBackground ? "615" : checkImageHeight;

  const checkMobileImageSize = mobileImage
    ? mobileImage && mobileImage.url
    : desktopImage && desktopImage.url;

  return (
    <div
      ref={ref}
      className={styleNames(theme, [
        "header-banner-image",
        "flush",
        !desktopImage && "no-image",
        !desktopImage && "full-width",
        desktopImage && isVideoBackground && "page-video",
        desktopImage && isVideoOverlay && "type-one",
        desktopImage && isImage && "type-three",
        hideImage && "played",
        isVisible && "animate",
        !isLight &&
          (typeof window === "object" && window?.location?.pathname === "/"
            ? "blue-main-page"
            : "blue"),
        hideLine && "hide-line",
      ])}
    >
      <div
        ref={bannerContent}
        className={`${theme["main-content"]} ${isImage ? "wrapper" : ""}`}
      >
        <div className={`${theme["text-with-line"]} wrapper`}>
          <div className={theme["content-wrapper"]}>
            <div className={theme.content}>
              {isVideoBackground ? (
                <>
                  {title && title.trim().startsWith("<h1") ? (
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  ) : (
                    <h1
                      className={styleNames(theme, [
                        secondaryTitle && "small-page-title",
                      ])}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  )}
                  {secondaryTitle && <h2>{secondaryTitle}</h2>}
                </>
              ) : (
                <>
                  <span className={theme["section-label"]}>
                    {secondaryTitle}
                  </span>
                  {title && title.startsWith("<h1") ? (
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  ) : (
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                  )}
                </>
              )}

              {description && <p>{description}</p>}
              {subDescription && (
                <RichTextFullWidth
                  content={subDescription?.content}
                  contentTinyMce={subDescription?.contentTinyMce}
                  addBorder={false}
                />
              )}
              {videoLinkText && isVideoOverlay && (
                <Cta
                  onClick={() => setShowLightbox(true)}
                  type="tertiary"
                  url={""}
                  linkText={videoLinkText}
                  openInNewTab={false}
                  icon="icon-play"
                  btnTheme="play"
                  customClass="icon-play"
                />
              )}
              {ctaLink && ctaLink?.linkText && ctaLink?.url && (
                <p>
                  <Cta type="tertiary" {...ctaLink} icon="icon-arrow" />
                </p>
              )}
            </div>
          </div>
          {!isImage && !hideLine && (
            <span className={theme.line}>
              <span className={theme["inner-line"]}></span>
            </span>
          )}
        </div>
        {desktopImage && (
          <div
            className={`${theme["image-wrapper"]} ${
              !isImage ? "flush-no-padding" : ""
            }`}
          >
            <div
              className={clsx(
                theme["inner-wrapper"],
                isVideoBackground && videoSrc ? styles["videoRatio"] : undefined
              )}
            >
              {isVideoBackground && videoSrc && (
                <>
                  {!isPlaying && (
                    <div
                      onClick={playVideo}
                      className={styleNames(theme, ["play-icon"])}
                      aria-label="play icon that starts video"
                    >
                      <Image
                        layout="fixed"
                        src={videoIcon}
                        alt="play icon"
                        width={54}
                        height={54}
                      />
                    </div>
                  )}
                  {isPlaying && isClient && (
                    <ReactPlayer
                      className={styleNames(theme, ["react-player"])}
                      url={videoSrc}
                      controls={false}
                      playing={isPlaying}
                      muted
                      width="100%"
                      height="100%"
                      onReady={() => setHideImage(true)}
                      onEnded={onVideoEnd}
                    />
                  )}
                </>
              )}
              <div className={theme["picture"]}>
                <Image
                  src={
                    !isMobile
                      ? `${desktopImage.url}`
                      : checkMobileImageSize || ""
                  }
                  alt={desktopImage.altText || ""}
                  width={imageWidth}
                  height={imageHeight}
                />
              </div>
            </div>
            {!hideLine && (
              <span className={styleNames(theme, ["line", "image"])}>
                <span className={theme["inner-line"]}></span>
              </span>
            )}
          </div>
        )}
        {isVideoBackground && showChevron && (
          <span
            className={theme["slide-down"]}
            tabIndex={0}
            onClick={scrollDown}
            onKeyDown={handleKeyPress}
          >
            <span className={theme["chevron"]}></span>
          </span>
        )}
      </div>
      {showLightbox && videoSrc && (
        <Lightbox isBanner onCloseFunction={closeLightbox}>
          <ReactPlayer
            width="100%"
            height="100%"
            url={videoSrc}
            controls={isVimeoCode}
            onEnded={onVideoEnd}
          />
        </Lightbox>
      )}
    </div>
  );
};

export default themr("HeaderBanner", styles)(HeaderBanner);

import Accordion from "@components/C11_VerticalAccordion/Accordion";
import Cta from "@components/Cta/Cta";
import { Image as ImageProps } from "@customTypes/image";
import { Link as LinkType, Link } from "@customTypes/link";
import { themr } from "@friendsofreactjs/react-css-themr";
import styleNames from "../../utils/react/style-names";
import styles from "./C25_NavigationManual.module.scss";
import Image from "@components/Image/Image";

export interface Fields {
  title: string;
  titleUrl?: LinkType;
  description: string;
  learnMoreLink: Link;
  accordionTitle: string;
  expandLinks: boolean;
  flip: boolean;
  accordionOpen: boolean;
  programmeBlockLinks: Link[];
  image: ImageProps;
}

interface ProgrammeBlockProps extends Fields {
  theme?: any;
  fullWidth?: boolean;
}

export const ProgrammeBlock = (props: ProgrammeBlockProps) => {
  const {
    title,
    titleUrl,
    description,
    learnMoreLink,
    accordionTitle,
    accordionOpen,
    expandLinks,
    flip,
    programmeBlockLinks,
    image,
    theme,
    fullWidth = false,
  } = props;

  const getLinks = () =>
    expandLinks
      ? programmeBlockLinks.slice(0, 15)
      : programmeBlockLinks.slice(0, 20);

  const programmeBlock = (
    <>
      {titleUrl?.fields?.url ? (
        <h4>
          <a href={titleUrl?.fields?.url}>{titleUrl?.fields?.linkText}</a>
        </h4>
      ) : (
        <h4>{title}</h4>
      )}
      <p>{description}</p>
      {learnMoreLink && (
        <Cta {...learnMoreLink.fields} type="tertiary" icon="icon-arrow" />
      )}
      {programmeBlockLinks?.length > 0 && (
        <Accordion
          accordionItems={[
            {
              fields: {
                title: (
                  <>
                    <span className="chevron"></span>
                    <span>{accordionTitle}</span>
                  </>
                ),
                content: getLinks().map((link) => ({
                  ...link.fields,
                })),
              },
            },
          ]}
          singleDisplay={false}
          defaultOpen={accordionOpen}
          title={accordionTitle}
        />
      )}
    </>
  );

  return (
    <div
      className={styleNames(theme, [
        "programme-block",
        programmeBlockLinks?.length > 0 && "accordion-block",
        !image && "no-img",
        flip && "flip",
      ])}
    >
      {image && (
        <div className={theme["image-wrapper"]}>
          <div className={theme["inner-wrap"]}>
            <Image
              src={image.fields.file.url}
              alt={image.fields.title}
              width={590}
              height={590}
            />
          </div>
        </div>
      )}
      <div className={theme["programme-info"]}>
        {image && fullWidth ? (
          <div className={theme["inner-wrapper"]}>{programmeBlock}</div>
        ) : (
          programmeBlock
        )}
      </div>
    </div>
  );
};

export default themr("ProgrammeBlock", styles)(ProgrammeBlock);

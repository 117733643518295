export const getPrefix = (url: string | undefined) => {
  if (!url) return;
  return url?.charAt(0) === "/" ? url : `/${url}`;
};

export const getModifiedLinks = (linkList) => {
  return linkList?.map((item) => {
    return {
      ...item,
      fields: {
        ...item.fields,
        ownLink: {
          ...item.fields?.ownLink,
          fields: {
            ...item.fields?.ownLink?.fields,
            url: getPrefix(item?.fields?.ownLink?.fields?.url),
          },
        },
      },
    };
  });
};

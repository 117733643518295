import IconExternal from "../../../public/image/svg/external.svg";

const LinksSection = (props: { theme?: any; links: any[] }) => {
  const { theme, links } = props;

  return (
    <ul className={theme["link-list"]}>
      {links.map((link, index) => (
        <li key={`link-${index}`}>
          <i className="icon-external">
            <IconExternal />
          </i>
          <a href={link.eventRelatedUrlValue} target="_blank" rel="noreferrer">
            {link.eventRelatedUrlDescription}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LinksSection;

import { useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { themr } from "@friendsofreactjs/react-css-themr";

import FormField from "@components/Form/FormFields/FormFields";
import Image from "@components/Image/Image";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { gatedContent } from "../../services/forms";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { useDevice } from "@utilities/react/get-device/get-device";
import SubmitButton from "@components/SubmitButton/SubmitButton";

import styles from "./PopupGate.module.scss";
interface PopupGateProps {
  content: {
    introText: string;
    errorMessage: string;
    thankYouMessage: any;
    thankYouMessageTinyMce: TinyMceRichText;
    thankYouTitle: string;
    description: any;
    descriptionTinyMce: TinyMceRichText;
    bannerImage: ImageWrapperType;
    bannerImageMobile: ImageWrapperType;
    emailLabelText: string;
    familyNameLabelText: string;
    firstNameLabelText: string;
  };
  onClose: Function;
  articlePath: string;
  guid: string;
  activePopup: "subscribeScreen" | "errorScreen";
  theme?: any;
}
export const PopupGate = ({
  content,
  articlePath,
  onClose,
  guid,
  activePopup,
  theme,
}: PopupGateProps) => {
  const {
    introText,
    errorMessage,
    description,
    descriptionTinyMce,
    bannerImage,
    bannerImageMobile,
    emailLabelText,
    familyNameLabelText,
    firstNameLabelText,
  } = content;

  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onBlur",
  });

  const router = useRouter();

  const [activeStep, setActiveStep] = useState<
    "subscribeScreen" | "errorScreen"
  >(activePopup);

  const [loading, setLoading] = useState<boolean>(false);

  const formFields = [
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        lastName: true,
        stringValidator: true,
      },
      labelText: familyNameLabelText,
    },

    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
    },
  ];

  const onSubmit = async (formData) => {
    setLoading(true);
    let data = {
      profile: formData,
      marketingClusterId: guid,
    };
    try {
      const response = await gatedContent(
        data,
        sessionStorage.getItem("crm_campaign")
      );

      if (response.status === 201) {
        router.push(articlePath);
      } else {
        setActiveStep("errorScreen");
        setLoading(false);
        const error = await response.json();
        console.log(error);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const device = useDevice();
  const isMobile = device === "mobile";

  const LinkImageJSX = () => (
    <>
      <a className={styles.cancel} onClick={() => onClose()}>
        ×
      </a>
      <Image
        src={
          !isMobile || (isMobile && !bannerImageMobile)
            ? bannerImage?.fields.image.fields.file.url
            : bannerImageMobile?.fields.image.fields.file.url
        }
        alt={
          !isMobile
            ? bannerImage?.fields?.altText || ""
            : bannerImageMobile?.fields?.altText || ""
        }
        width={450}
        height={!isMobile ? 100 : 50}
        desktopWidth={900}
        desktopHeight={100}
      />
    </>
  );

  return (
    <div
      className={clsx(
        "component",
        "subscribeThink",
        styles.show,
        styles["subscribe-think"]
      )}
    >
      <div className="wrapper subscribeThink">
        {activeStep === "subscribeScreen" && (
          <div id="subscribeScreen" className={styles["overlay-content"]}>
            <div
              className={clsx(
                styles["subscribe-screen-content"],
                styles["popup"],
                "wrapper"
              )}
            >
              <LinkImageJSX />
              <h4>{introText}</h4>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={clsx(
                  theme["form"],
                  "form",
                  theme["my-profile"],
                  "my-profile"
                )}
              >
                <div className={styles["fields-floated"]}>
                  {formFields.map(
                    ({ labelText, propertyName, validation }, index) => (
                      <div
                        className={clsx(
                          "form-row",
                          styles["form-row"],
                          "field-row",
                          styles["field-row"]
                        )}
                        key={`${labelText}${index}`}
                      >
                        <FormField
                          watch={watch}
                          validation={validation}
                          type="text"
                          register={register}
                          placeholder={labelText}
                          name={propertyName || ""}
                          errors={formState.errors}
                        />
                      </div>
                    )
                  )}
                </div>
                <RichTextDisplay
                  richText={description}
                  tinyMceRichText={descriptionTinyMce}
                />
                <SubmitButton
                  loading={loading}
                  id="submitButton"
                  text="Subscribe"
                />
              </form>
            </div>
          </div>
        )}
        {activeStep === "errorScreen" && (
          <div id="errorScreen" className={styles["overlay-content"]}>
            <div className={clsx(styles["popup"], "wrapper")}>
              <LinkImageJSX />
              <p className={styles.error}>{errorMessage}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default themr("PopupGate", styles)(PopupGate);

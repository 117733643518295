export enum BREAKPOINTS {
  MOBILE = 0,
  TABLET = 768,
  DESKTOP = 1024,
  WIDESCREEN = 1920,
}

export enum MQ_AND_BELOW {
  MOBILE = BREAKPOINTS.TABLET - 0.02,
  TABLET = BREAKPOINTS.DESKTOP - 0.02,
  DESKTOP = BREAKPOINTS.WIDESCREEN - 0.02,
}

export enum MQ_AND_UP {
  MOBILE = BREAKPOINTS.MOBILE,
  TABLET = BREAKPOINTS.TABLET,
  DESKTOP = BREAKPOINTS.DESKTOP,
}

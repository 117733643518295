import React, { Suspense } from "react";

// Templates
import BSRContentPage from "@components/__templates__/BSRContentPage/BSRContentPage";
import CampaignLandingPage from "@components/__templates__/CampaignLandingPage/CampaignLandingPage";
import ClusterPage from "@components/__templates__/ClusterPage/ClusterPage";
import Content from "@components/__templates__/Content/Content";
import Content2019Page from "@components/__templates__/Content2019Page/Content2019Page";
import ContributorProfileDetail from "@components/__templates__/ContributorProfileDetail/ContributorProfileDetail";
import DPOPage from "@components/__templates__/DPOPage/DPOPage";
import EELandingPage from "@components/__templates__/EELandingPage/EELandingPage";
import EMBAGTemplate from "@components/__templates__/EMBAGTemplate/EMBAGTemplate";
import EventsDetail from "@components/__templates__/EventsDetail/EventsDetail";
import FacultyProfileDetail from "@components/__templates__/FacultyProfileDetail/FacultyProfileDetail";
import InteractSSO from "@components/__templates__/InteractSSO/InteractSSO";
import NewsDetail from "@components/__templates__/NewsDetail/NewsDetail";
import ProgrammeDetailsPage from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsPage";
import PublicationDetail from "@components/__templates__/PublicationDetail/PublicationDetail";
import StoryPage from "@components/__templates__/StoryPage/StoryPage";
import StudentProfileDetail from "@components/__templates__/StudentProfileDetail/StudentProfileDetail";
import SuccessBrochurePage from "@components/__templates__/SuccessBrochurePage/SuccessBrochurePage";
import RegisterInterestSuccessPage from "@components/__templates__/RegisterInterestSuccessPage/RegisterInterestSuccessPage";
import CommonSuccessPage from "@components/__templates__/CommonSuccessPage/CommonSuccessPage";

// Sublayouts
import Sublayout from "@components/__SubLayouts__/Sublayout";

// Components
import ArticleSummary from "@components/ArticleSummary/ArticleSummary";
import C031_SubNavigationDE from "@components/C03.1_SubNavigationDE/C03.1_SubNavigationDE";
import C03_AnchorHeading from "@components/C03_AnchorHeading/C03_AnchorHeading";
import C03_SubNavigation from "@components/C03_SubNavigation/C03_SubNavigation";
import C09_VideoVimeoAndYoutube from "@components/C09_VideoVimeoAndYoutube/C09_VideoVimeoAndYoutube";
import C11_VerticalAccordion from "@components/C11_VerticalAccordion/C11_VerticalAccordion";
import C141_FacultyProfilePublications from "@components/C14.1_FacultyProfilePublications/C14.1_FacultyProfilePublications";
import C15_SocialSharing from "@components/C15_SocialSharing/C15_SocialSharing";
import C16_RichTextFullWidth from "@components/C16_RichTextFullWidth/C16_RichTextFullWidth";
import C19_1_PromotionalArticlesBSR from "@components/C19.1_PromotionalArticlesBSR/C19.1_PromotionalArticlesBSR";
import C19_2_PromotionalArticlesNews from "@components/C19.2_PromotionalArticlesNews/C19.2_PromotionalArticlesNews";
import C19_3_1_PromotionalFullWidth from "@components/C19.3.1_PromotionalFullWidth/C19.3.1_PromotionalFullWidth";
import C20_2_PromotionalEventsFullWidth from "@components/C20.2_PromotionalEventsFullWidth/C20.2_PromotionalEventsFullWidth";
import C200_1_BrandIconTitle from "@components/C200_1_BrandIconTitle/C200_1_BrandIconTitle";
import C203_PopularTopics from "@components/C203_PopularTopics/C203_PopularTopics";
import C207_PopularAuthors from "@components/C207_PopularAuthors/C207_PopularAuthors";
import C20_1_PromotionalArticleSingle from "@components/C20_PromotionalArticles/C20_1_PromotionalArticleSingle";
import C20_1_PromotionalManuallyPopulated from "@components/C20_PromotionalArticles/C20_1_PromotionalManuallyPopulated";
import C20_PromotionalArticles from "@components/C20_PromotionalArticles/C20_PromotionalArticles";
import C210_2_ArticleRichTextTriple from "@components/C210_2_ArticleRichTextTriple/C210_2_ArticleRichTextTriple";
import C210_3_ArticleBlockQuote from "@components/C210_3_ArticleBlockQuote/C210_3_ArticleBlockQuote";
import C214_TopicBanner from "@components/C214_TopicBanner/C214_TopicBanner";
import C221_AuthorBanner from "@components/C221_AuthorBanner/C221_AuthorBanner";
import C225_LatestBlogs from "@components/C225_LatestBlogPosts/C225_LatestBlogPosts";
import C241_UpcomingProgrammes from "@components/C241_UpcomingProgrammes/C241_UpcomingProgrammes";
import C245_AnimatedNumbers from "@components/C245_AnimatedNumbers/C245_AnimatedNumbers";
import C248_TabbedContent from "@components/C248_TabbedContent/C248_TabbedContent";
import C249_FacultyProfileCarousel from "@components/C249_FacultyProfileCarousel/C249_FacultyProfileCarousel";
import C24_NavigationalCarousel from "@components/C24_NavigationalCarousel/C24_NavigationalCarousel";
import C250_RelatedProgrammes from "@components/C250_RelatedProgrammes/C250_RelatedProgrammes";
import C252_DynamicPieExtended from "@components/C252_DynamicPie/C252_DynamicPieExtended";
import C252_DynamicPieStandard from "@components/C252_DynamicPie/C252_DynamicPieStandard";
import C253_StoriesCallToAction from "@components/C253_StoriesContainer/C253_StoriesCallToAction/C253_StoriesCallToAction";
import C253_StoriesFullWidth from "@components/C253_StoriesContainer/C253_StoriesFullWidth/C253_StoriesFullWidth";
import C253_StoriesIconLink from "@components/C253_StoriesContainer/C253_StoriesIconLink/C253_StoriesIconLink";
import C253_StoriesTextAndImageRight from "@components/C253_StoriesContainer/C253_StoriesTextAndImageRight/C253_StoriesTextAndImageRight";
import C254_ProgrammeCalendar from "@components/C254_ProgrammeCalendar/C254_ProgrammeCalendar";
import C256_ProofPointWrapper from "@components/C256_ProofPointWrapper/C256_ProofPointWrapper";
import C25_NavigationManual from "@components/C25_NavigationManual/C25_NavigationManual";
import C261_Contact from "@components/C261_Contact/C261_Contact";
import C263_ResponsiveImage from "@components/C263_ResponsiveImage/C263_ResponsiveImage";
import C265_ProgrammesByCareerStage from "@components/C265_ProgrammesByCareerStage/C265_ProgrammesByCareerStage";
import C270_CtaContainer from "@components/C270_CtaContainer/C270_CtaContainer";
import C270_CtaContainerLink from "@components/C270_CtaContainer/C270_CtaContainerLink";
import C270_GatedCtaContainerLink from "@components/C270_CtaContainer/C270_GatedCtaContainerLink";
import C272_ProgrammeComparisonTable from "@components/C272_ProgrammeComparisonTable/C272_ProgrammeComparisonTable";
import C27_NavigationalQuote from "@components/C27_NavigationalQuote/C27_NavigationalQuote";
import C280_ArticleDivider from "@components/C280_ArticleDivider/C280_ArticleDivider";
import C281_EditorsChoice from "@components/C281_EditorsChoice/C281_EditorsChoice";
import C282_FacultyCourses from "@components/C282_FacultyCourses/C282_FacultyCourses";
import C282_ProgrammeCourses from "@components/C282_ProgrammeCourses/C282_ProgrammeCourses";
import C282_SubjectAreaCourses from "@components/C282_SubjectAreaCourses/C282_SubjectAreaCourses";
import C283_DonateTool from "@components/C283_DonateTool/C283_DonateTool";
import C286_ExplorePublications from "@components/C286_ExplorePublications/C286_ExplorePublications";
import C29_NavigationalEventsListing from "@components/C29_NavigationalEventsListing/C29_NavigationalEventsListing";
import C44_ContactUsForm from "@components/C44_ContactUsForm/C44_ContactUsForm";
import C45_AlumniJobBoard from "@components/C45_AlumniJobBoard/C45_AlumniJobBoard";
import C46_HeaderBanner from "@components/C46_HeaderBanner/C46_HeaderBanner";
import C63_1_MyProfilePersonalDetails from "@components/C63_1_MyProfilePersonalDetails/C63_1_MyProfilePersonalDetails";
import C64_MyProfilePreferences from "@components/C64_MyProfilePreferences/C64_MyProfilePreferences";
import C65_MyProfileApplications from "@components/C65_MyProfileApplications/C65_MyProfileApplications";
import C66_MyProfileSavedContent from "@components/C66_MyProfileSavedContent/C66_MyProfileSavedContent";
import C68_ChangePassword from "@components/C68_ChangePassword/C68_ChangePassword";
import C69_MyProfileEditProfileDetails from "@components/C69_MyProfileEditProfileDetails/C69_MyProfileEditProfileDetails";
import C71_EventRegistrationForm from "@components/C71_EventRegistrationForm/C71_EventRegistrationForm";
import C721_ProgrammeDetailsBarDE from "@components/C72.1_ProgrammeDetailsBarDE/C72.1_ProgrammeDetailsBarDE";
import C72_ProgrammeDetailsBar from "@components/C72_ProgrammeDetailsBar/C72_ProgrammeDetailsBar";
import C74_ReserveAPlace from "@components/C74_ReserveAPlace/C74_ReserveAPlace";
import COV03_ProgrammeInPageSearch from "@components/COV03_ProgrammeInPageSearch/COV03_ProgrammeInPageSearch";
import ConsultantForm from "@components/ConsultantForm/ConsultantForm";
import CoveoSearchComponent from "@components/CoveoSearchComponent/CoveoSearchComponent";
import DeactivateAccount from "@components/DeactivateAccount/DeactivateAccount";
import DPOTestimonial from "@components/DPOTestimonial/DPOTestimonial";
import DeccPayment from "@components/DeccPayment/DeccPayment";
import EePayment from "@components/EePayment/EePayment";
import DownloadBrochureForm from "@components/DownloadBrochureForm/DownloadBrochureForm";
import RequestCallBackForm from "@components/RequestCallBackForm/RequestCallBackForm";
import RegisterInterestForm from "@components/RegisterInterestForm/RegisterInterestForm";
import EmailSignup from "@components/EmailSignup/EmailSignup";
import GiftAidForAdvancementForm from "@components/GiftAidForAdvancementForm/GiftAidForAdvancementForm";
import ImageCarousel from "@components/ImageCarousel/ImageCarousel";
import InteractApplicationConfirmation from "@components/InteractApplicationConfirmation/InteractApplicationConfirmation";
import LoginOrRegister from "@components/LoginOrRegister/LoginOrRegister";
import MyProfileTabItems from "@components/MyProfileTabItems/MyProfileTabItems";
import News from "@components/News/News";
import PaymentConfirmation from "@components/PaymentConfirmation/PaymentConfirmation";
import PhdFees from "@components/PhdFees/PhdFees";
import ProgrammeApplyNowLink from "@components/ProgrammeApplyNowLink/ProgrammeApplyNowLink";
import ProgrammeEnquiryForm from "@components/ProgrammeEnquiry/ProgrammeEnquiry";
import ProgrammeReserveAPlaceCta from "@components/ProgrammeReserveAPlaceCta/ProgrammeReserveAPlaceCta";
import RegistrationForm from "@components/RegistrationForm/RegistrationForm";
import SiteMap from "@components/SiteMap/SiteMap";
import SubmitCv from "@components/SubmitCv/SubmitCv";
import SubscribeThink from "@components/SubscribeThink/SubscribeThink";
import HowToApplyPage from "@components/HowToApplyPage/HowToApplyPage";
import ErrorBoundary from "@components/ErrorBoundary";
import BlackBaudGenericForm from "@components/BlackbaudGenericForm/BlackbaudGenericForm";
import Auth0Error from "@components/Auth0Error/Auth0Error";
import ServerlessFunctionError from "@components/ServerlessFunctionError/ServerlessFunctionError";
import SelectAutocomplete from "@components/SelectAutocomplete/SelectAutocomplete";
import CTAGatedContent from "@components/CtaGatedContent/CtaGatedContent";
import AvailabilitySelector from "@components/AvailabilitySelector/AvailabilitySelector";

const Templates = {};

const Components = {
  dpoPage: DPOPage,
  facultyProfilePublicationsTabs: C141_FacultyProfilePublications,
  anchorHeading: C03_AnchorHeading,
  authorBanner: C221_AuthorBanner,
  subscribeThink: SubscribeThink,
  animatedNumbers: C245_AnimatedNumbers,
  articleBlockQuote: C210_3_ArticleBlockQuote,
  myProfileTabItems: MyProfileTabItems,
  articleDivider: C280_ArticleDivider,
  articleRichTextTriple: C210_2_ArticleRichTextTriple,
  articleSummary: ArticleSummary,
  brandIconTitle: C200_1_BrandIconTitle,
  bsrContentPage: BSRContentPage,
  c11AccordionWrapper: C11_VerticalAccordion,
  c20PromotionalArticles: C20_PromotionalArticles,
  c252DynamicPieStandard: C252_DynamicPieStandard,
  contact: C261_Contact,
  content: Content,
  content2019Page: Content2019Page,
  contributorProfileDetail: ContributorProfileDetail,
  ctaContainer: C270_CtaContainer,
  ctaContent: C270_CtaContainerLink,
  gatedCtaContent: C270_GatedCtaContainerLink,
  clusterPage: ClusterPage,
  campaignLandingPage2019: CampaignLandingPage,
  c68ChangePassword: C68_ChangePassword,
  deactivateAccount: DeactivateAccount,
  donateTool: C283_DonateTool,
  dpoTestimonial: DPOTestimonial,
  dynamicPie: C252_DynamicPieExtended,
  editorsChoice: C281_EditorsChoice,
  eeLandingPage: EELandingPage,
  emailSignup: EmailSignup,
  embagTemplate: EMBAGTemplate,
  eventsDetail: EventsDetail,
  facultyCourses: C282_FacultyCourses,
  facultyProfileCarousel: C249_FacultyProfileCarousel,
  facultyProfileDetail: FacultyProfileDetail,
  giftAidForAdvancementForm: GiftAidForAdvancementForm,
  registrationForm: RegistrationForm,
  genericCoveoSearchComponent: CoveoSearchComponent,
  headerBanner: C46_HeaderBanner,
  imageCarousel: ImageCarousel,
  interactSso: InteractSSO,
  interactApplicationConfirmation: InteractApplicationConfirmation,
  latestBlogPosts: C225_LatestBlogs,
  loginOrRegister: LoginOrRegister,
  myProfileApplications: C65_MyProfileApplications,
  myProfileEditProfileDetails: C69_MyProfileEditProfileDetails,
  myProfilePersonalDetails: C63_1_MyProfilePersonalDetails,
  myProfilePreferences: C64_MyProfilePreferences,
  myProfileSavedContent: C66_MyProfileSavedContent,
  navigationalCarousel: C24_NavigationalCarousel,
  navigationalEventsListing: C29_NavigationalEventsListing,
  navigationalQuote: C27_NavigationalQuote,
  navigationManual: C25_NavigationManual,
  news: News,
  newsDetail: NewsDetail,
  popularAuthors: C207_PopularAuthors,
  popularTopics: C203_PopularTopics,
  programmeCalendar: C254_ProgrammeCalendar,
  programmeComparisonTable: C272_ProgrammeComparisonTable,
  programmeCourses: C282_ProgrammeCourses,
  programmeDetails: ProgrammeDetailsPage,
  programmeEnquiryForm: ProgrammeEnquiryForm,
  programmeInPageSearch: COV03_ProgrammeInPageSearch,
  programmeReserveAPlaceCta: ProgrammeReserveAPlaceCta,
  programmeApplyNowLink: ProgrammeApplyNowLink,
  programmesByCareerStage: C265_ProgrammesByCareerStage,
  programmeDetailsBarDe: C721_ProgrammeDetailsBarDE,
  programmeDetailsBar: C72_ProgrammeDetailsBar,
  promotionalArticlesBsr: C19_1_PromotionalArticlesBSR,
  promotionalArticleSingle: C20_1_PromotionalArticleSingle,
  promotionalArticlesNews: C19_2_PromotionalArticlesNews,
  promotionalEventsFullWidth: C20_2_PromotionalEventsFullWidth,
  promotionalFullWidth: C19_3_1_PromotionalFullWidth,
  promotionalManuallyPopulated: C20_1_PromotionalManuallyPopulated,
  proofPointWrapper: C256_ProofPointWrapper,
  publicationDetail: PublicationDetail,
  quote: C27_NavigationalQuote,
  relatedProgrammes: C250_RelatedProgrammes,
  reserveAPlace: C74_ReserveAPlace,
  eventRegistrationForm: C71_EventRegistrationForm,
  contactUsForm: C44_ContactUsForm,
  alumniJobBoard: C45_AlumniJobBoard,
  consultantForm: ConsultantForm,
  downloadBrochureForm: DownloadBrochureForm,
  requestCallBackForm: RequestCallBackForm,
  registerInterestForm: RegisterInterestForm,
  responsiveImage: C263_ResponsiveImage,
  richText: C16_RichTextFullWidth,
  socialSharing: C15_SocialSharing,
  storiesCallToAction: C253_StoriesCallToAction,
  storiesFullWidth: C253_StoriesFullWidth,
  storiesIconLink: C253_StoriesIconLink,
  storiesTextAndImageRight: C253_StoriesTextAndImageRight,
  storyPage: StoryPage,
  studentProfileDetail: StudentProfileDetail,
  subjectAreaCourses: C282_SubjectAreaCourses,
  sublayout: Sublayout,
  submitCv: SubmitCv,
  deccFees: DeccPayment,
  eeFees: EePayment,
  phdFees: PhdFees,
  paymentConfirmation: PaymentConfirmation,
  subNavigationDe: C031_SubNavigationDE,
  subNavigation: C03_SubNavigation,
  downloadBrochureSuccessPage: SuccessBrochurePage,
  registerInterestSuccessPage: RegisterInterestSuccessPage,
  tabbedContent: C248_TabbedContent,
  upcomingProgrammes: C241_UpcomingProgrammes,
  verticalAccordion: C11_VerticalAccordion,
  videoVimeoAndYoutube: C09_VideoVimeoAndYoutube,
  siteMap: SiteMap,
  howToApplyPage: HowToApplyPage,
  blackbaudGenericForm: BlackBaudGenericForm,
  commonSuccessPage: CommonSuccessPage,
  auth0error: Auth0Error,
  serverlessFunctionError: ServerlessFunctionError,
  selectAutocomplete: SelectAutocomplete,
  topicBanner: C214_TopicBanner,
  ctaGatedContent: CTAGatedContent,
  availabilitySelector: AvailabilitySelector,
  explorePublications: C286_ExplorePublications,
};

type BlockProps = {
  component: string;
  uid: string;
};

export const Index = (
  block: BlockProps,
  otherProps?: object,
  globalContent?,
  index?
) => {
  const key = `${block.uid}-${index}`;
  if (typeof Components[block?.component] !== "undefined") {
    return (
      <ErrorBoundary key={key} component={block.component}>
        <Suspense fallback={<div>Loading</div>}>
          {React.createElement(Components[block.component], {
            key,
            index,
            content: block,
            ...otherProps,
            globalContent,
          })}
        </Suspense>
      </ErrorBoundary>
    );
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key }
  );
};
export default Index;

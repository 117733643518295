import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C283_DonateTool.module.scss";
import clsx from "clsx";
import IconChevron from "../../public/assets2019/img/svg/chevron.svg";
import { ReactNode, useState } from "react";

interface DonateSelect {
  id: string;
  describeBy: string;
  onChange: Function;
  options: any; //needs changing from any to proper type;
  defaultOption: string;
}

export const DonateSelect = (props: { content: DonateSelect; theme?: any }) => {
  const { content, theme } = props;

  const { id, describeBy, onChange, options, defaultOption } = content;
  const [open, setOpen] = useState(false);

  return (
    <div className="field">
      <div className={clsx(theme.select, "select")}>
        <select
          id={id}
          aria-describedby={describeBy}
          className={clsx("select-option", open && "open")}
          onClick={() => {
            setOpen(!open);
          }}
          onBlur={() => {
            setOpen(false);
          }}
          onChange={(e) => {
            onChange(e);
          }}
        >
          <option value="">{defaultOption}</option>
          {options}
        </select>
        <div className="tick icon-chevron">
          <IconChevron />
        </div>
      </div>
    </div>
  );
};

export default themr("DonateSelect", styles)(DonateSelect);

import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C03_AnchorHeading.module.scss";

export interface AnchorHeadingProps {
  mainTitle: string;
  description: string;
  pageTitle: string;
  anchorKey: string;
}

export const AnchorHeading = (props: {
  content: AnchorHeadingProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { mainTitle, description, pageTitle, anchorKey } = content;
  const cleanAnchor = anchorKey?.replace(/\s+/g, "");

  return (
    <div className={`component wrapper ${theme["subnav-header"]}`}>
      {!mainTitle && !description ? (
        <h2 id={cleanAnchor}>{pageTitle}</h2>
      ) : (
        <>
          <div className={theme["top-heading"]}>
            <h2 id={cleanAnchor}>{pageTitle}</h2>
          </div>
          <div className={theme["title-with-intro"]}>
            <div className={theme["left-section"]}>
              <h3>{mainTitle}</h3>
            </div>
            <div className={theme["right-section"]}>
              <p>{description}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default themr("AnchorHeading", styles)(AnchorHeading);

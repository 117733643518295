import Components from "@components/__Components__/Components";

export function RenderComponents(components, inSublayout = false) {
  if (!components) {
    return null;
  }

  return components.map((block: any, index: number) => {
    const component = {
      component: block?.sys?.contentType?.sys?.id || block?.sys?.contentType,
      uid: block.sys.id,
      tags: block?.metadata?.tags?.length > 0 ? block.metadata.tags : null,
      inSublayout,
      ...block.fields,
    };
    return Components(component, block.fields, null, index);
  });
}

export default RenderComponents;

import { BREAKPOINTS, MQ_AND_BELOW } from "@customTypes/Breakpoints";
import { ContentfulImage } from "@customTypes/ContentfulImage";
import { ContentfulImageFormat } from "@customTypes/ContentfulImageFormat";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { themr } from "@friendsofreactjs/react-css-themr";
import styleNames from "../../utils/react/style-names";

import styles from "./C263_ResponsiveImage.module.scss";

export interface ResponsiveImageProps {
  image: ImageWrapperType;
  includeWrapper: boolean;
  mobileAspectRatio: ASPECT_RATIO;
  maintainQuality: boolean;
}

type generateSrcSetOptions = {
  format?: ContentfulImageFormat;
  maxWidth?: number;
  mobile?: boolean;
};

type ASPECT_RATIO = "Retain Aspect Ratio" | "Square (1:1)" | "9:5";

const MOBILE_MAX_WIDTH = MQ_AND_BELOW.MOBILE;
const DESKTOP_MAX_WIDTH = BREAKPOINTS.DESKTOP;

const ResponsiveImage = (props: {
  content: ResponsiveImageProps;
  theme?: any;
}) => {
  const { content, theme } = props;

  const image = content?.image?.fields?.image;
  const altText = content?.image?.fields?.altText;
  const { includeWrapper, mobileAspectRatio, maintainQuality } = content;

  if (!image) return null;

  const generateSrcSet = (
    image: ContentfulImage,
    { format, mobile }: generateSrcSetOptions = {}
  ): string => {
    let params = new URLSearchParams();

    const src = image.fields.file.url;
    const imageWidth = image.fields.file.details.image.width;
    const contentType = image.fields.file.contentType;
    let width = imageWidth;

    if (format && !["image/gif"].includes(contentType)) {
      params.set("fm", format);
    }

    if (mobile) {
      if (imageWidth > MOBILE_MAX_WIDTH) width = MOBILE_MAX_WIDTH;

      if (mobileAspectRatio === "Square (1:1)") {
        params.set("h", width.toString());
      } else if (mobileAspectRatio === "9:5") {
        const height = Math.round((5 / 9) * width);
        params.set("h", height.toString());
      }
    } else {
      if (maintainQuality) {
        return `${src}?q=100`;
      } else if (imageWidth > DESKTOP_MAX_WIDTH) {
        width = DESKTOP_MAX_WIDTH;
      }
    }

    params.set("w", width.toString());
    params.set("fit", "fill");
    params.set("q", "70");

    return `${src}?${params.toString()} ${width}w`;
  };

  return (
    <div
      className={`component ${styleNames(theme, ["responsive-image"])} ${
        includeWrapper && "wrapper"
      }`}
    >
      <picture>
        <source
          srcSet={generateSrcSet(image, {
            format: "avif",
            mobile: true,
          })}
          media={`(max-width: ${MOBILE_MAX_WIDTH}px)`}
          type="image/avif"
        />
        <source
          srcSet={generateSrcSet(image, {
            format: "webp",
            mobile: true,
          })}
          media={`(max-width: ${MOBILE_MAX_WIDTH}px)`}
          type="image/webp"
        />
        <source
          srcSet={generateSrcSet(image, {
            mobile: true,
          })}
          media={`(max-width: ${MOBILE_MAX_WIDTH}px)`}
          type={image.fields.file.contentType}
        />
        <source
          srcSet={generateSrcSet(image, {
            format: "avif",
          })}
          media={`(min-width: ${MOBILE_MAX_WIDTH + 1}px)`}
          type="image/avif"
        />
        <source
          srcSet={generateSrcSet(image, {
            format: "webp",
          })}
          media={`(min-width: ${MOBILE_MAX_WIDTH + 1}px)`}
          type="image/webp"
        />
        <source
          srcSet={generateSrcSet(image)}
          media={`(min-width: ${MOBILE_MAX_WIDTH + 1}px)`}
          type={image.fields.file.contentType}
        />

        <img src={image.fields.file.url} alt={altText} />
      </picture>
    </div>
  );
};

export default themr("ResponsiveImage", styles)(ResponsiveImage);

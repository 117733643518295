import C19_PromotionalArticles, {
  ArticlePromotionType,
} from "@components/C19_PromotionalArticles/C19_PromotionalArticles";
import { fetchApi } from "@utilities/fetchApi";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export interface PromotionalArticlesBSRProps {
  isBlue: boolean;
  researchAreas?: string[];
  selectedAuthor?: any;
  numberOfItems?: number;
  bsrArticles?: any;
}

const PromotionalArticlesBSR = ({
  content,
}: {
  content: PromotionalArticlesBSRProps;
}) => {
  const { bsrArticles, isBlue, researchAreas, selectedAuthor, numberOfItems } =
    content;
  const [articles, setArticles] = useState<ArticlePromotionType[]>([]);
  const { asPath } = useRouter();

  useEffect(() => {
    const getData = async () => {
      const articles = (
        await Promise.all([
          fetchApi("/api/getBSRArticlesByResearchArea", {
            researchAreas,
            limit: numberOfItems,
            omitSlug: asPath.split("#")[0].split("?")[0].slice(1),
          }),
          fetchApi("/api/getBSRArticlesByAuthor", {
            selectedAuthor: selectedAuthor?.fields?.articleAuthorName,
            limit: numberOfItems,
            omitSlug: asPath.split("#")[0].split("?")[0].slice(1),
          }),
        ])
      ).flat();
      setArticles(articles);
    };

    if (bsrArticles) {
      setArticles(
        bsrArticles?.map((article) => ({
          title: article.fields.articleTitle,
          description: article.fields.shortDescription,
          image: article.fields.articleThumbnail || null,
          authors:
            article.fields.authorsList?.map(
              ({ fields: { articleAuthorName } }) => articleAuthorName
            ) || null,
          slug: article.fields.slug,
        }))
      );
    } else if ((researchAreas || selectedAuthor) && numberOfItems) {
      getData();
    }
  }, []);

  return (
    <C19_PromotionalArticles
      articles={articles?.map((article) => ({
        ...article,
        subtitle: "Think at London Business School",
      }))}
      isBlue={isBlue}
      ctaText="Find out more"
    />
  );
};

export default PromotionalArticlesBSR;

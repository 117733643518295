import { ProgrammeType } from "@customTypes/ProgrammeType";
import { createContext, ReactNode, useContext } from "react";

export interface ProgrammeDetailsPageContextProps {
  type?: ProgrammeType;
  programmeTypeCode?: string;
  programmeDeliveryText?: string;
  programmeCareerStagesEeList?: string[];
  programmeCareerStagesDeList?: string[];
  programmeAttribute?: string[];
  programmeApplyOnlineParameterValue?: string;
}

interface ProgrammeDetailsPageProviderProps {
  children: ReactNode;
  data: ProgrammeDetailsPageContextProps;
}

export const ProgrammeDetailsPageContext =
  createContext<ProgrammeDetailsPageContextProps>({});

export const ProgrammeDetailsPageProvider = ({
  children,
  data,
}: ProgrammeDetailsPageProviderProps) => {
  const {
    type,
    programmeTypeCode,
    programmeDeliveryText,
    programmeCareerStagesEeList,
    programmeCareerStagesDeList,
    programmeAttribute,
    programmeApplyOnlineParameterValue,
  } = data;

  return (
    <ProgrammeDetailsPageContext.Provider
      value={{
        type,
        programmeTypeCode,
        programmeDeliveryText,
        programmeCareerStagesEeList,
        programmeCareerStagesDeList,
        programmeAttribute,
        programmeApplyOnlineParameterValue,
      }}
    >
      {children}
    </ProgrammeDetailsPageContext.Provider>
  );
};

export const useProgrammeDetailsPage = () =>
  useContext(ProgrammeDetailsPageContext);

export const mockFacultyDetailsContext = {
  type: "EE",
  programmeDescription:
    "Reach your potential. Gain the knowledge, practical skills and confidence to step up to a general management role.",
  programmeTitle: "Accelerated Development Programme",
  programmeAlumniStatus: "Yes",
  programmeTypeCode: "ADP",
  programmeApplyOnlineParameterValue: "9",
  programmeType: "",
  programmeBrochureExternal: "",
  programmeApplyNowExternalLink: "",
  programmeDetailsExternalLink: "",
  programmeContent: "",
  entryRequirements: "",
  keyDifferentiators: "",
  averageWorkExperienceInClass: "",
  classProfile: "",
  programmeFeature:
    "Prepare to encounter an eclectic mix of cultural and leadership styles. Learning together, strong bonds are made and ideas challenged and tested. Experience a blend of thought-leading faculty and guest speaker insights, experiential activities, intensive coaching and peer-to-peer discussions.",
  programmeReason: "",
  keyTakeAway:
    "Profound personal and professional change awaits you. Develop the skills to take your next career step, and impact your team, your role and your organisation. Gain the knowledge and confidence to transition from functional expert to business leader quickly and effectively.",
  programmeDeliveryText:
    "*105 hours of faculty-led learning\n*Executive coaching with industry experts\n*Immersive experiential sessions",
  whoAttends:
    "Experienced managers and leaders in a phase of transition or change who are seeking to broaden their thinking and business skill set.",
  testimonialQuote:
    "It gave me the confidence to move forward and move up the ranks",
  testimonialName: "John Vermilye, Owner and Chairman, Travel Sentry",
  seniority: "Mid career",
  programmeLocationInfo: "",
  programmeTagsHelpText: "",
  equirySendToEmail: "clientsolutions@london.edu",
  programmeCareerStagesEeList: ["Early career", "Mid career"],
  programmeAttribute: [
    "Topic:Courses in London",
    "Topic:General Management",
    "Topic:Leadership",
  ],
};

export const useFacultyDetails = () => useContext(ProgrammeDetailsPageContext);

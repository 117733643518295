export const getMenuLevel = (data: any[]) => {
  return data?.map((item) => {
    return item?.fields;
  });
};

export const handleItemClick = (
  mobileMenuHistory,
  setMobileMenuHistory,
  menuItem,
  ownLink?,
  promoItems?,
  promoCtAs?
) => {
  if (!menuItem) {
    setMobileMenuHistory([]);
    return;
  }
  let buff: any[] = [...mobileMenuHistory];
  const currentItemIndex = buff.findIndex((item) => item.visibility === true);
  buff = buff.slice(0, currentItemIndex + 1);
  const level = getMenuLevel(menuItem);
  buff = buff.map((item) => {
    item.visibility = false;
    return item;
  });
  buff.push({
    items: level,
    ownLink: ownLink,
    promoItems: promoItems,
    promoCtAs: promoCtAs,
    visibility: true,
  });
  setMobileMenuHistory(buff);
};

export const handleBackClick = (mobileMenuHistory, setMobileMenuHistory) => {
  let buff: any[] = [...mobileMenuHistory];
  const currentItemIndex = buff.findIndex((item) => item.visibility === true);
  buff[currentItemIndex].visibility = false;
  buff[currentItemIndex - 1].visibility = true;
  setMobileMenuHistory(buff);
};

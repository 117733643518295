import Cta from "@components/Cta/Cta";
import { EventDetailProps } from "../C37.5_EventDetail";

const ActionsSection = (
  props: {
    theme?: any;
    isCampaignNowFull?: boolean;
    eventNowFullMessage?: string;
    eventBookingUrl?: string;
  } & Pick<
    EventDetailProps,
    "eventClosed" | "eventClosedMessage" | "eventSignupUrl"
  >
) => {
  const {
    theme,
    eventClosed,
    eventClosedMessage,
    eventSignupUrl,
    isCampaignNowFull,
    eventNowFullMessage,
    eventBookingUrl,
  } = props;

  if (isCampaignNowFull)
    return <span className="bold">{eventNowFullMessage}</span>;

  if (eventClosed) return <p>{eventClosedMessage}</p>;

  if (eventBookingUrl)
    return (
      <Cta
        url={eventBookingUrl}
        type="primary"
        linkText="Register now"
        customClass={theme["cta"]}
      />
    );

  if (eventSignupUrl)
    return (
      <Cta
        url={eventSignupUrl}
        type="primary"
        linkText="Register now"
        customClass={theme["cta"]}
      />
    );

  return <span className="bold">{eventNowFullMessage}</span>;
};

export default ActionsSection;

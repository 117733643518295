import Grid from "@components/Grid/Grid";
import React from "react";
import Columns from "./Columns";

export interface Sublayout7030Props {
  content: {
    columns: any;
    backgroundColour?: string;
  };
}

const Sublayout7030 = (props: any) => {
  const { columns, backgroundColour } = props;

  return (
    <div
      className={`sublayout outer-wrap ${
        backgroundColour ? backgroundColour : ""
      }`}
    >
      <div className="wrapper">
        <Grid row>
          <Columns columns={columns} columnTotal={2} customSize={[8, 4]} />
        </Grid>
      </div>
    </div>
  );
};

export default Sublayout7030;

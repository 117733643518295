export function objectToFormData(
  obj,
  formData = new FormData(),
  parentKey = ""
) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propName = parentKey ? `${parentKey}.${key}` : key;
      if (
        typeof obj[key] === "object" &&
        !(obj[key] instanceof File) &&
        !Array.isArray(obj[key])
      ) {
        objectToFormData(obj[key], formData, propName);
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((value, index) => {
          const fieldName = `${key}[${index}]`;
          formData.append(fieldName, value);
        });
      } else if (obj[key] instanceof File) {
        formData.append(propName, obj[key], obj[key].name);
      } else {
        formData.append(propName, obj[key]);
      }
    }
  }
  return formData;
}

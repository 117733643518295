import { StoriesContainer } from "@components/C253_StoriesContainer/C253_StoriesContainer";
import { StoriesProvider } from "@components/C253_StoriesContainer/C253_StoriesProvider";
import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./../../C253_StoriesContainer/C253_StoriesContainer.module.scss";
import Head from "next/head";
import { ContentfulImage } from "@customTypes/ContentfulImage";
import { Nationality } from "@customTypes/Nationality";
import { ProgrammeDetails } from "@customTypes/programmeDetails";

export interface StoryPageProps {
  bodyContent?: any;
  profileSurname?: string;
  profileFirstName?: string;
  profilePhoto: any;
  profileNationality: Nationality;
  profileProgramme: { fields: ProgrammeDetails };
  profileIsAmbassador: Boolean;
  profileIsScholar: Boolean;
  tags: any;
}

export const StoryPage = (props: { content: StoryPageProps; theme?: any }) => {
  const { content, theme } = props;

  function getTagValueByProp(prop, pageTags) {
    const tag = pageTags?.find((t) => t?.name?.split(":")[0] == prop);
    return tag?.name?.split(":")[1];
  }

  return (
    <StoriesProvider>
      <Head>
        <meta name="profilesurname" content={content.profileSurname || ""} />
        <meta name="profirstname" content={content.profileFirstName || ""} />
        <meta
          name="profilenationality"
          content={content.profileNationality?.fields?.nationality || ""}
        />
        <meta
          name="profileprogramme"
          content={content.profileProgramme?.fields?.programmeTitle || ""}
        />
        <meta
          name="profileisambassador"
          content={
            content.profileIsAmbassador ? "Ambassador" : "Student/Alumni"
          }
        />
        <meta
          name="profileisscholar"
          content={content.profileIsScholar ? "Scholar" : "Student/Alumni"}
        />
        <meta
          name="profilephotosmallurl"
          content={content.profilePhoto?.fields?.image?.fields?.file?.url || ""}
        />
        <meta
          name="studenttype"
          content={
            content.tags ? getTagValueByProp("studenttype", content.tags) : ""
          }
        />
      </Head>
      <StoriesContainer content={content} theme={theme} />
    </StoriesProvider>
  );
};

export default themr("StoryPage", styles)(StoryPage);

import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import clsx from "clsx";
import Link from "@components/Link/Link";
import { useStudentDetails } from "../../context/studentDetail";
import IconDegree from "../../public/image/svg/degree.svg";
import IconGlobal from "../../public/image/svg/global.svg";
import IconProfile from "../../public/image/svg/profile.svg";
import IconScholarship from "../../public/image/svg/scholarship.svg";

import styles from "./C52.2_StudentDetails.module.scss";

const StudentDetails = (props: { theme?: any }) => {
  const { theme } = props;
  const {
    profileFirstname,
    profileIsScholar,
    profileJobPostProgramme,
    profileJobPreProgramme,
    profileNationality,
    profilePhd,
    profileProgramme,
    profileSurname,
    profileText,
    profileTextTinyMce,
    scholarshipTitle,
  } = useStudentDetails();
  const { programmeRoute, siteSettings } = useSettings();

  return (
    <div className="wrapper">
      <div className={clsx("component", theme["student-details"])}>
        <h1>
          {profileFirstname} {profileSurname}
        </h1>
        <div className={theme["rich-text"]}>
          <ul className={theme["alumni-list-details"]}>
            {(!!profileProgramme || !!profilePhd) && (
              <li className={theme["programme"]}>
                <i className={theme["icon-degree"]}>
                  <IconDegree />
                </i>
                <strong>Programme: </strong>{" "}
                {!!profileProgramme ? (
                  <Link
                    href={programmeRoute(
                      profileProgramme.type,
                      profileProgramme.slug
                    )}
                  >
                    <a>{profileProgramme.programmeTitle}</a>
                  </Link>
                ) : (
                  profilePhd
                )}
              </li>
            )}

            {!!profileNationality && (
              <li className={theme["nationality"]}>
                <i className={theme["icon-global"]}>
                  <IconGlobal />
                </i>
                <strong>Nationality:</strong> {profileNationality}
              </li>
            )}

            {!!scholarshipTitle && profileIsScholar && (
              <li className={theme["scholarship"]}>
                <i>
                  <IconScholarship />
                </i>
                <strong>Scholarship:</strong>{" "}
                {siteSettings?.scholarshipFundingUrl ? (
                  <Link href={siteSettings?.scholarshipFundingUrl}>
                    <a>{scholarshipTitle}</a>
                  </Link>
                ) : (
                  scholarshipTitle
                )}
              </li>
            )}

            {!!profileJobPreProgramme && (
              <li className={theme["job"]}>
                <i className={theme["icon-profile"]}>
                  <IconProfile />
                </i>
                <strong>Job Pre-programme:</strong> {profileJobPreProgramme}
              </li>
            )}

            {!!profileJobPostProgramme && (
              <li className={theme["job"]}>
                <i className={theme["icon-profile"]}>
                  <IconProfile />
                </i>
                <strong>Job Post-programme:</strong> {profileJobPostProgramme}
              </li>
            )}
          </ul>
          <RichTextDisplay
            richText={profileText}
            tinyMceRichText={profileTextTinyMce}
          />
        </div>
      </div>
    </div>
  );
};

export default themr("StudentDetails", styles)(StudentDetails);

import C15_SocialSharing from "@components/C15_SocialSharing/C15_SocialSharing";
import Grid from "@components/Grid/Grid";
import { SearchLandingType } from "@customTypes/SearchLandingType";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import RenderComponents from "@utilities/renderComponents";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import Head from "next/head";
import Link from "@components/Link/Link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Image from "../../Image/Image";
import styles from "../article.module.scss";

export interface NewsDetailProps {
  newsTitle: string;
  shortDescription?: string;
  showShortDescription?: boolean;
  date: string;
  bodyContent?: any;
  thumbnailImage?: ImageWrapperType;
  articleImage?: ImageWrapperType;
  tags?: any;
  newsTopic?: string[];
  basePageTemplate: {
    fields: {
      pageTitle: string;
    };
  };
}

export const NewsDetail = (props: {
  content: NewsDetailProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    shortDescription,
    showShortDescription,
    newsTitle,
    date,
    bodyContent,
    thumbnailImage,
    articleImage,
    tags,
    newsTopic,
    basePageTemplate: {
      fields: { pageTitle },
    },
  } = content;

  const [url, setUrl] = useState("");
  const { route } = useRouter();

  const { siteSettings } = useSettings();

  const searchPage = siteSettings?.searchResultsPagesCollection?.items?.filter(
    ({ type }) => type === SearchLandingType.NewsTopics
  )[0];

  useEffect(() => {
    const url = document.URL;
    setUrl(url);
  }, [route]);

  const formattedDate = format(parseISO(date), "dd MMMM yyyy");

  const schemaData = {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${url}`,
    },
    headline: `${pageTitle}`,
    image: [`https:${thumbnailImage?.fields?.image?.fields?.file?.url}`],
    datePublished: `${formattedDate}`,
    dateModified: `${formattedDate}`,
    author: {
      "@type": "Person",
      name: "London Business School",
    },
    publisher: {
      "@type": "Organization",
      name: "London Business School",
      logo: {
        "@type": "ImageObject",
        url: "https://images.ctfassets.net/hxo16fanegqh/4KD0tsxILYEZKUprJxltZT/127b23210dfc15b641376e29117796a5/lbs-logo.png",
      },
    },
    description: `${shortDescription}`,
  };

  return (
    <>
      <Head>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

        <meta name="articledateyear" content={format(parseISO(date), "yyyy")} />
        <meta
          name="articledate"
          content={format(parseISO(date), "yyyy/MM/dd")}
        />
        <meta
          name="articledatetext"
          content={format(parseISO(date), "dd MMMM yyyy")}
        />
        {newsTopic && (
          <meta
            name="newstopic"
            content={newsTopic.map((topic) => topic).join(";")}
          />
        )}
        <meta
          name="articledatemonth"
          content={format(parseISO(date), "MMMM")}
        />
        <meta name="newstitle" content={newsTitle} />
        <meta name="ShortDescription" content={shortDescription} />
        {!!thumbnailImage && (
          <>
            <meta
              key="articleimagealt"
              name="articleimagealt"
              content={thumbnailImage?.fields?.altText}
            />
            <meta
              key="articleimageurl"
              name="articleimageurl"
              content={thumbnailImage?.fields?.image?.fields.file?.url}
            />
            <meta
              key="twitter:image"
              name="twitter:image"
              content={`${thumbnailImage?.fields?.image?.fields.file?.url}?w=1200&h=1200`}
            />
            <meta
              key="og:image"
              property="og:image"
              content={`${thumbnailImage?.fields?.image?.fields.file?.url}?w=1200&h=600`}
            />
          </>
        )}
      </Head>
      <div className="wrapper">
        <Grid row>
          <Grid column sm={12}>
            <section
              className={clsx("component", theme["article-header"], "cf")}
            >
              <div className="wrapper">
                <Grid row>
                  <Grid column sm={12}>
                    <h1>{pageTitle}</h1>
                    {showShortDescription && shortDescription && (
                      <p>{shortDescription}</p>
                    )}
                  </Grid>
                </Grid>
                <Grid row>
                  <Grid column sm={12}>
                    <div className={theme["article-details-wrapper"]}>
                      <Grid row>
                        <Grid column sm={12} md={8}>
                          <div className={theme["article-details"]}>
                            <div className={theme["date-read-time"]}>
                              <span className={theme.date}>
                                {format(parseISO(date), "dd MMMM yyyy")}
                              </span>
                            </div>
                          </div>
                          <div
                            className={clsx(
                              "component",
                              "tag",
                              theme.tag,
                              "cf"
                            )}
                          >
                            {tags &&
                              tags.map((item) => {
                                const tagName = item.name.split(":")[1];
                                return (
                                  <Link
                                    key={`newsTopic-${tagName}`}
                                    href={`/${searchPage?.url?.slug}#f:${searchPage?.queryParams}=[${tagName}]`}
                                  >
                                    {tagName}
                                  </Link>
                                );
                              })}
                          </div>
                        </Grid>
                        <Grid column sm={12} md={4}>
                          <C15_SocialSharing
                            socialLinks={
                              siteSettings?.globalSocialShareCollection
                            }
                            isSocialIconDark
                            small
                            mobileAlignment
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
            {articleImage && (
              <section
                className={clsx(
                  "component",
                  theme["article-images"],
                  "full-width",
                  "cf"
                )}
              >
                <Image
                  src={articleImage.fields.image.fields.file.url}
                  alt={articleImage?.fields?.altText || ""}
                  width={570}
                  height={173}
                  desktopWidth={1140}
                  desktopHeight={346}
                />
              </section>
            )}
          </Grid>
        </Grid>
      </div>
      {RenderComponents(bodyContent)}
    </>
  );
};

export default themr("NewsDetail", styles)(NewsDetail);

export enum ProgrammeCode {
  MBA = "MBA",
  MIF = "MIF",
  MFA = "MFA",
  MIM = "MIM",
  MAM = "MAM",
  EMBAG = "EMBAG",
  EMBA = "EMBA",
  SLOAN = "SLOAN",
}

import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C69_MyProfileEditProfileDetails.module.scss";

import {
  getIndustries,
  getCareerAspirations,
  getGenders,
  getPersonalDetails,
  getPreferredStudyFormat,
  getPreferredStudyLocation,
  getTitles,
  setPersonalDetails,
  getIntendedYearsOfStudy,
  getCountries,
} from "../../services/forms";
import { useForm } from "react-hook-form";
import { useEffect, useState, useContext } from "react";
import Grid from "@components/Grid/Grid";
import { UserContext } from "../../context/user";
import clsx from "clsx";
import FormField from "@components/Form/FormFields/FormFields";
import { useRouter } from "next/router";
import { prepareDataForSubmit } from "./submitData";
import { findMatchingSelectValue } from "@utilities/mapSelectValue";
import getCookie from "@utilities/getCookie";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";
import { useSettings } from "@utilities/context/settings";
import { handleSubmitFormErrors } from "@utilities/handleSubmitFormErrors";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import SelectAutocomplete from "@components/SelectAutocomplete/SelectAutocomplete";

export interface ProfileDetailsProps {
  aboutYouTitle: string;
  countryCodeLabelText: string;
  educationalInstitutesLabelText: string;
  emailLabelText: string;
  firstNameLabelText: string;
  genderLabelText: string;
  gmatScoreLabelText: string;
  gmatTotalScoreLabelText: string;
  gmatFocusScoreLabelText: string;
  gmatFocusInformationTitle: string;
  greAwaPercentileLabelText: string;
  greAwaScoreLabelText: string;
  greInformationTitle: string;
  greQuantitativePercentileLabelText: string;
  greQuantitativeScoreLabelText: string;
  greVerbalPercentileLabelText: string;
  greVerbalScoreLabelText: string;
  highestDegreeLabelText: string;
  homeAddress1LabelText: string;
  homeAddress2LabelText: string;
  homeCityLabelText: string;
  homeCountyStateLabelText: string;
  homePostcodeLabelText: string;
  industryLabelText: string;
  linkedInProfileLabelText: string;
  loginMessage: string;
  yourProfessionalProfileTitle: string;
  nationalityLabelText: string;
  personalInformationTitleLabelText: string;
  screenNameLabelText: string;
  secondNationalityLabelText: string;
  skypeAccountLabelText: string;
  telephoneNumberLabelText: string;
  titleLabelText: string;
  workJobTitleLabelText: string;
  yearsManagerialExperienceLabelText: string;
  yearsRelevantExperienceLabelText: string;
  yourEducationProfileTitle: string;
  careerAspirationsLabelText: string;
  workCompanyNameLabelText: string;
  gmatInformationTitle: string;
  familyNameLabelText: string;
  yourAspirationsTitle: string;
  homeCountryLabelText: string;
  facebookAccountLabelText: string;
  twitterAccountLabelText: string;
  aboutYouDescription: string;
  telephoneNumberHintText: string;
  preferredStudyLocationLabelText: string;
  preferredStudyFormatLabelText: string;
  intendedYearOfStudyLabelText: string;
}

let firstInteract = 0;

export const C69_MyProfileEditProfileDetails = (props: {
  content: ProfileDetailsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    aboutYouDescription,
    aboutYouTitle,
    educationalInstitutesLabelText,
    firstNameLabelText,
    genderLabelText,
    gmatTotalScoreLabelText,
    gmatFocusScoreLabelText,
    gmatFocusInformationTitle,
    greAwaPercentileLabelText,
    greAwaScoreLabelText,
    greInformationTitle,
    yourProfessionalProfileTitle,
    greQuantitativePercentileLabelText,
    greQuantitativeScoreLabelText,
    greVerbalPercentileLabelText,
    greVerbalScoreLabelText,
    highestDegreeLabelText,
    homeAddress1LabelText,
    homeAddress2LabelText,
    homeCityLabelText,
    homeCountyStateLabelText,
    homePostcodeLabelText,
    industryLabelText,
    linkedInProfileLabelText,
    loginMessage,
    nationalityLabelText,
    yourEducationProfileTitle,
    careerAspirationsLabelText,
    workCompanyNameLabelText,
    gmatInformationTitle,
    familyNameLabelText,
    yourAspirationsTitle,
    homeCountryLabelText,
    facebookAccountLabelText,
    twitterAccountLabelText,
    personalInformationTitleLabelText,
    telephoneNumberHintText,
    secondNationalityLabelText,
    skypeAccountLabelText,
    telephoneNumberLabelText,
    titleLabelText,
    workJobTitleLabelText,
    yearsManagerialExperienceLabelText,
    yearsRelevantExperienceLabelText,
    preferredStudyLocationLabelText,
    preferredStudyFormatLabelText,
    intendedYearOfStudyLabelText,
  } = content;

  const [countriesHome, setCountriesHome] = useState<any>([]);
  const [countriesWork, setCountriesWork] = useState<any>([]);
  const [countriesNationality, setCountriesNationality] = useState<any>([]);
  const [countriesSecondNationality, setCountriesSecondNationality] =
    useState<any>([]);

  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [careerAspirations, setCareerAspirations] = useState<any>(null);
  const [preferredStudyLocation, setPreferredStudyLocation] =
    useState<any>(null);
  const [titles, setTitles] = useState<any>(null);
  const [industries, setIndustries] = useState<any>(null);
  const [genders, setGenders] = useState<any>(null);
  const [preferredStudyFormat, setPreferredStudyFormat] = useState<any>(null);
  const [intendedYearsOfStudy, setIntendedYearsOfStudy] = useState<any>(null);
  const [errorHook, setErrorHook] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [handledSubmitFormErrors, setHandledSubmitFormErrors] =
    useState<Array<string> | null>(null);
  const router = useRouter();
  const { apiErrorMessages } = useSettings();

  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    reset,
    formState,
    watch,
    setValue,
    clearErrors,
  } = useForm({ mode: "onBlur", reValidateMode: "onChange" });

  useEffect(() => {
    const personalDetails = async () => {
      if (user) {
        try {
          const personalDetails = await getPersonalDetails(
            getCookie("access_token")
          );
          const genders = await getGenders();
          const titles = await getTitles();
          const industries = await getIndustries();
          const careerAspirations = await getCareerAspirations();
          const preferredStudyFormat = await getPreferredStudyFormat();
          const preferredStudyLocation = await getPreferredStudyLocation();
          let intendedYearsOfStudy = await getIntendedYearsOfStudy();
          let today = new Date();
          let year = today.getFullYear();
          intendedYearsOfStudy = intendedYearsOfStudy.filter((item) => {
            return item.value === "N/A" || item.value >= year;
          });
          setTitles(titles);
          setCareerAspirations(careerAspirations);
          setPreferredStudyLocation(preferredStudyLocation);
          setIndustries(industries);
          setGenders(genders);
          setPreferredStudyFormat(preferredStudyFormat);
          setIntendedYearsOfStudy(intendedYearsOfStudy);
          setUserPersonalDetails(personalDetails?.result);
        } catch (e) {
          setErrorHook(true);
        }
      }
    };

    personalDetails();
  }, [user]);

  const sendGAEvent = (event) => {
    setGADataLayer({
      event,
      formName: "Edit profile",
      programmeCode: "",
      businessUnit: getBusinessUnit(),
    });
  };

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "Edit profile",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const aboutYouFields = [
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      labelText: titleLabelText,
      dataText: titles
        ? titles.find((title) => title.id === userPersonalDetails?.title)?.value
        : "",
      formType: "select",
      options: titles,
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
      dataText: userPersonalDetails?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: familyNameLabelText,
      dataText: userPersonalDetails?.lastName,
    },
    {
      propertyName: "gender",
      formType: "select",
      options: genders,
      labelText: genderLabelText,
      dataText: genders
        ? genders.find((gender) => gender.id === userPersonalDetails?.gender)
            ?.value
        : "",
    },
    {
      fieldType: "selectAutocomplete",
      validation: {
        validate: (value) =>
          !!hasCountryMatch(value, countriesNationality) ||
          countriesNationality.length === 0 ||
          "Unknown country entered",
      },
      propertyName: "nationality",
      labelText: nationalityLabelText,
      dataText: userPersonalDetails?.nationality,
    },
    {
      fieldType: "selectAutocomplete",
      validation: {
        validate: (value) =>
          !!hasCountryMatch(value, countriesSecondNationality) ||
          countriesSecondNationality.length === 0 ||
          "Unknown country entered",
      },
      propertyName: "secondNationality",
      labelText: secondNationalityLabelText,
      dataText: userPersonalDetails?.secondNationality,
    },
  ];

  const personalInformationFields = [
    // To do: will be implemented later
    /*    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      dataText: userPersonalDetails?.emailAddress,
    },*/
    {
      validation: {
        telephone: true,
      },
      hintText: telephoneNumberHintText,
      propertyName: "telephoneNumber",
      labelText: telephoneNumberLabelText,
      dataText: userPersonalDetails?.telephoneNumber,
    },
    {
      validation: {
        maxLength: 50,
      },
      propertyName: "HomeAddressLine1",
      labelText: homeAddress1LabelText,
      dataText: userPersonalDetails?.address?.addressLine1,
    },
    {
      validation: {
        maxLength: 50,
      },
      propertyName: "HomeAddressLine2",
      labelText: homeAddress2LabelText,
      dataText: userPersonalDetails?.address?.addressLine2,
    },
    {
      validation: {
        maxLength: 50,
      },
      propertyName: "HomeCity",
      labelText: homeCityLabelText,
      dataText: userPersonalDetails?.address?.city,
    },
    {
      validation: {
        maxLength: 50,
      },
      propertyName: "HomeStateCounty",
      labelText: homeCountyStateLabelText,
      dataText: userPersonalDetails?.address?.stateCounty,
    },
    {
      validation: {
        maxLength: 50,
      },
      propertyName: "HomePostCode",
      labelText: homePostcodeLabelText,
      dataText: userPersonalDetails?.address?.postCode,
    },
    {
      fieldType: "selectAutocomplete",
      validation: {
        validate: (value) =>
          !!hasCountryMatch(value, countriesHome) ||
          countriesHome.length === 0 ||
          "Unknown country entered",
      },
      propertyName: "HomeCountry",
      labelText: homeCountryLabelText,
      dataText: userPersonalDetails?.address?.homeCountry,
    },

    {
      validation: {
        maxLength: 50,
      },
      propertyName: "facebookAccount",
      labelText: facebookAccountLabelText,
      dataText: userPersonalDetails?.facebookProfile,
    },

    {
      validation: {
        maxLength: 50,
      },
      propertyName: "twitterHandle",
      labelText: twitterAccountLabelText,
      dataText: userPersonalDetails?.twitterHandle,
    },

    {
      validation: {
        maxLength: 50,
      },
      propertyName: "skypeId",
      labelText: skypeAccountLabelText,
      dataText: userPersonalDetails?.skypeId,
    },

    {
      validation: {
        maxLength: 50,
      },
      propertyName: "linkedInProfile",
      labelText: linkedInProfileLabelText,
      dataText: userPersonalDetails?.linkedInUrl,
    },
  ];

  const professionalProfileFields = [
    {
      validation: {
        maxLength: 50,
      },
      dataText: userPersonalDetails?.employment?.jobTitle,
      propertyName: "jobTitle",
      labelText: workJobTitleLabelText,
    },

    {
      validation: {
        maxLength: 50,
      },
      dataText: userPersonalDetails?.employment?.company,
      propertyName: "companyName",
      labelText: workCompanyNameLabelText,
    },

    {
      validation: {
        range: { min: 0, max: 100 },
        integerValidator: true,
      },
      dataText: userPersonalDetails?.yearsOfExperience,

      propertyName: "yearsRelevantExperience",
      labelText: yearsRelevantExperienceLabelText,
    },

    {
      dataText: userPersonalDetails?.yearsOfManagerialExperience,
      validation: {
        range: { min: 0, max: 100 },
        integerValidator: true,
      },
      propertyName: "yearsOfManagerialExperience",
      labelText: yearsManagerialExperienceLabelText,
    },

    {
      validation: {
        maxLength: 50,
      },
      dataText: userPersonalDetails?.employment?.address?.addressLine1,
      propertyName: "WorkAddressLine1",
      labelText: homeAddress1LabelText,
    },

    {
      validation: {
        maxLength: 50,
      },
      dataText: userPersonalDetails?.employment?.address?.addressLine2,

      propertyName: "WorkAddressLine2",
      labelText: homeAddress2LabelText,
    },

    {
      validation: {
        maxLength: 50,
      },
      dataText: userPersonalDetails?.employment?.address?.city,
      propertyName: "WorkCity",
      labelText: homeCityLabelText,
    },
    {
      validation: {
        maxLength: 50,
      },
      dataText: userPersonalDetails?.employment?.address?.stateCounty,
      propertyName: "WorkStateCounty",
      labelText: homeCountyStateLabelText,
    },
    {
      dataText: userPersonalDetails?.employment?.address?.postCode,
      propertyName: "WorkPostCode",
      labelText: homePostcodeLabelText,
    },
    {
      fieldType: "selectAutocomplete",
      dataText: userPersonalDetails?.employment?.address?.homeCountry,
      propertyName: "WorkCountry",
      labelText: homeCountryLabelText,
      validation: {
        validate: (value) =>
          !!hasCountryMatch(value, countriesWork) ||
          countriesWork.length === 0 ||
          "Unknown country entered",
      },
    },
    {
      formType: "select",
      options: industries,
      dataText: industries
        ? industries.find(
            (industry) => industry.id === userPersonalDetails?.industry
          )?.value
        : "",
      propertyName: "industry",
      labelText: industryLabelText,
    },
  ];

  const educationProfileFields = [
    {
      dataText: userPersonalDetails?.education?.academicInstitution,
      validation: {
        maxLength: 50,
      },
      propertyName: "academicInstitution",
      labelText: educationalInstitutesLabelText,
    },
    {
      dataText: userPersonalDetails?.education?.highestDegree,
      propertyName: "highestDegree",
      labelText: highestDegreeLabelText,
    },
  ];

  const greInformationFields = [
    {
      validation: {
        range: { min: 0, max: 5000 },
        integerValidator: true,
      },
      dataText: userPersonalDetails?.scores?.verbalScore,
      propertyName: "greVerbalScore",
      labelText: greVerbalScoreLabelText,
    },
    {
      validation: {
        range: { min: 0, max: 100 },
        integerValidator: true,
      },
      dataText: userPersonalDetails?.scores?.verbalPercentile,
      propertyName: "greVerbalPercentile",
      labelText: greVerbalPercentileLabelText,
    },
    {
      validation: {
        range: { min: 0, max: 5000 },
        integerValidator: true,
      },
      dataText: userPersonalDetails?.scores?.quantativeScore,
      propertyName: "greQuantitativeScore",
      labelText: greQuantitativeScoreLabelText,
    },
    {
      validation: {
        range: { min: 0, max: 100 },
        integerValidator: true,
      },
      dataText: userPersonalDetails?.scores?.quantativePercentile,
      propertyName: "greQuantitativePercentile",
      labelText: greQuantitativePercentileLabelText,
    },
    {
      validation: {
        range: { min: 0, max: 5000 },
        integerValidator: true,
      },
      dataText: userPersonalDetails?.scores?.awaScore,
      propertyName: "awaScore",
      labelText: greAwaScoreLabelText,
    },
    {
      validation: {
        range: { min: 0, max: 100 },
        integerValidator: true,
      },
      dataText: userPersonalDetails?.scores?.awaPercentile,
      propertyName: "awaPercentileScore",
      labelText: greAwaPercentileLabelText,
    },
  ];

  const gmatInformationFields = [
    {
      validation: {
        integerValidator: true,
        range: { min: 0, max: 5000 },
      },
      dataText: userPersonalDetails?.scores?.gmatScore,
      propertyName: "gmatScore",
      labelText: gmatTotalScoreLabelText,
    },
  ];

  const gmatFocusInformationFields = [
    {
      validation: {
        integerValidator: true,
        range: { min: 0, max: 5000 },
      },
      dataText: userPersonalDetails?.scores?.gmatFocusTotalScore,
      propertyName: "gmatFocusTotalScore",
      labelText: gmatFocusScoreLabelText,
    },
  ];

  const aspirationsFields = [
    {
      formType: "select",
      options: careerAspirations,
      dataText: findMatchingSelectValue(
        careerAspirations,
        userPersonalDetails?.careerAspiration
      ),
      propertyName: "careerAspirations",
      labelText: careerAspirationsLabelText,
    },

    {
      formType: "select",
      options: intendedYearsOfStudy,
      dataText: findMatchingSelectValue(
        intendedYearsOfStudy,
        userPersonalDetails?.intendedYearOfStudy
      ),
      propertyName: "intendedYEarOfStudy",
      labelText: intendedYearOfStudyLabelText,
    },
    {
      formType: "select",
      options: preferredStudyFormat,
      dataText: findMatchingSelectValue(
        preferredStudyFormat,
        userPersonalDetails?.preferredStudyFormat
      ),
      propertyName: "preferredStudyFormat",
      labelText: preferredStudyFormatLabelText,
    },

    {
      formType: "select",
      options: preferredStudyLocation,
      dataText: findMatchingSelectValue(
        preferredStudyLocation,
        userPersonalDetails?.preferredStudyLocation
      ),
      propertyName: "preferredStudyLocation",
      labelText: preferredStudyLocationLabelText,
    },
  ];

  useEffect(() => {
    if (userPersonalDetails) {
      const inputProp = [
        ...aboutYouFields,
        ...personalInformationFields,
        ...professionalProfileFields,
        ...educationProfileFields,
        ...greInformationFields,
        ...gmatInformationFields,
        ...gmatFocusInformationFields,
        ...aspirationsFields,
      ].reduce((o, key) => ({ ...o, [key.propertyName]: key.dataText }), {});
      reset(inputProp);
    }
  }, [userPersonalDetails]);

  const hasCountryMatch = (value: string, countries) =>
    countries && countries.find((country) => country === value);

  const onSubmit = async (data) => {
    setHandledSubmitFormErrors(null);
    setLoading(true);
    const dataForSubmit = prepareDataForSubmit(
      data,
      titles,
      genders,
      industries,
      careerAspirations,
      preferredStudyFormat,
      preferredStudyLocation,
      intendedYearsOfStudy
    );

    try {
      const result = await setPersonalDetails(
        getCookie("access_token"),
        dataForSubmit
      );
      setLoading(false);

      if (result.status !== 200) {
        const handledSubmitFormErrors = handleSubmitFormErrors(
          result,
          apiErrorMessages
        );

        setHandledSubmitFormErrors(handledSubmitFormErrors);
        window.scrollTo(0, 0);
        setHasSubmitted(false);
      } else {
        setHasSubmitted(true);
      }
    } catch (e) {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        null,
        apiErrorMessages
      );
      setHandledSubmitFormErrors(handledSubmitFormErrors);
      setErrorHook(true);
      setLoading(false);
    }
  };

  if (hasSubmitted) {
    sendGAEvent("formComplete");
    router.push("/my-profile");
  }

  const setCountriesFeed = (value: string[], fieldName: string) => {
    switch (fieldName) {
      case "HomeCountry":
        setCountriesHome(value);
        break;
      case "WorkCountry":
        setCountriesWork(value);
        break;
      case "nationality":
        setCountriesNationality(value);
        break;
      case "secondNationality":
        setCountriesSecondNationality(value);
        break;
    }
  };

  const getCountriesFeed = (fieldName: string) => {
    switch (fieldName) {
      case "HomeCountry":
        return countriesHome;
      case "WorkCountry":
        return countriesWork;
      case "nationality":
        return countriesNationality;
      case "secondNationality":
        return countriesSecondNationality;
      default:
        return [];
    }
  };

  const fetchCountryData = async (term: string, fieldName: string) => {
    try {
      if (!term) {
        setCountriesFeed([], fieldName);
        return;
      }
      const countriesResponse = await getCountries(term);
      setCountriesFeed(countriesResponse, fieldName);
    } catch (e) {
      console.log("Faulty request");
      setCountriesFeed([], fieldName);
    }
  };

  const renderField = (field, index) => {
    if (field.fieldType === "selectAutocomplete") {
      return (
        <SelectAutocomplete
          key={`${field.labelText}${index}`}
          placeholder={field.labelText + " (optional)"}
          name={field.propertyName}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          errors={formState.errors}
          value={watch(field.propertyName)!}
          getOptions={(term) => fetchCountryData(term, field.propertyName)}
          options={getCountriesFeed(field.propertyName)}
          validation={field.validation}
        />
      );
    } else {
      return (
        <FormField
          formType={field?.formType}
          watch={watch}
          validation={field?.validation}
          type="text"
          register={register}
          property={field.dataText}
          key={`${field.labelText}${index}`}
          placeholder={field.labelText}
          options={field.options}
          name={field?.propertyName}
          errors={formState.errors}
        />
      );
    }
  };

  return (
    <div className={clsx(theme["edit-details"], "wrapper")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <section
            className={clsx(
              theme.component,
              "form",
              theme["my-profile"],
              theme.cf
            )}
          >
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className={theme["fields-floated"]}>
                {!user ? (
                  <p>{loginMessage}</p>
                ) : (
                  <>
                    <Grid row>
                      <Grid column sm={8}>
                        <h2 className={theme["section-title"]}>
                          {aboutYouTitle}
                        </h2>
                        <p>{aboutYouDescription}</p>
                      </Grid>
                    </Grid>

                    {errorHook && (
                      <FormErrorMessage
                        failedRichText={
                          "Something went wrong. Please try again."
                        }
                        failedTinyMceRichText={
                          "Something went wrong. Please try again."
                        }
                      />
                    )}
                    {handledSubmitFormErrors &&
                      handledSubmitFormErrors.map((error, index) => (
                        <FormErrorMessage key={index} text={error} />
                      ))}
                    <section className={theme["form-group-wrapper"]}>
                      <div className={theme["form-row"]}></div>
                      {aboutYouFields.map((field, index) =>
                        renderField(field, index)
                      )}
                    </section>

                    <h2 className={theme["section-heading"]}>
                      <span>{personalInformationTitleLabelText}</span>
                    </h2>
                    {personalInformationFields.map((field, index) =>
                      renderField(field, index)
                    )}

                    <h2 className={theme["section-heading"]}>
                      <span>{yourProfessionalProfileTitle}</span>
                    </h2>

                    {professionalProfileFields.map((field, index) =>
                      renderField(field, index)
                    )}

                    <h2 className={theme["section-heading"]}>
                      <span>{yourEducationProfileTitle}</span>
                    </h2>

                    {educationProfileFields.map((field, index) =>
                      renderField(field, index)
                    )}

                    <h3 className="sub-heading">{gmatInformationTitle}</h3>
                    {gmatInformationFields.map((field, index) =>
                      renderField(field, index)
                    )}

                    <h3 className="sub-heading">{gmatFocusInformationTitle}</h3>
                    {gmatFocusInformationFields.map((field, index) =>
                      renderField(field, index)
                    )}

                    <h3 className="sub-heading">{greInformationTitle}</h3>

                    {greInformationFields.map((field, index) =>
                      renderField(field, index)
                    )}

                    <h2 className={theme["section-heading"]}>
                      <span>{yourAspirationsTitle}</span>
                    </h2>

                    {aspirationsFields.map((field, index) =>
                      renderField(field, index)
                    )}

                    <div className="btn-and-text-wrapper">
                      <div className="button-wrapper form-row">
                        <SubmitButton
                          loading={loading}
                          id="submitButton"
                          text="Submit"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr(
  "C69_MyProfileEditProfileDetails",
  styles
)(C69_MyProfileEditProfileDetails);

import { themr } from "@friendsofreactjs/react-css-themr";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-hook-inview";
import { useDevice } from "../../utils/react/get-device/get-device";
import styleNames from "../../utils/react/style-names";
import Cta from "../Cta/Cta";

import Image from "@components/Image/Image";
import { GeneralLink } from "@customTypes/generalLink";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import styles from "./C27_NavigationalQuote.module.scss";

interface QuoteProps {
  quoteId?: number;
  content: QuoteType;
  theme?: any;
  onReadMoreClick?: (clicked?: number) => void;
}

export interface QuoteType {
  authorName: string;
  quote: string;
  authorJobTitle?: string;
  ctaLink?: GeneralLink;
  label?: string;
  optionalCta?: GeneralLink;
  desktopImage?: ImageWrapperType;
  fullWidth?: boolean;
  longQuote?: string;
}

export const Quote = ({
  quoteId,
  content,
  theme,
  onReadMoreClick,
}: QuoteProps) => {
  const {
    fullWidth,
    label,
    quote,
    authorName,
    authorJobTitle,
    desktopImage,
    ctaLink,
    optionalCta,
    longQuote,
  } = content;
  const [showText, setShowText] = useState(false);

  const isCarousel = typeof quoteId !== "undefined";

  const handleClick = () => {
    !isMobile &&
      typeof quoteId != undefined &&
      onReadMoreClick &&
      onReadMoreClick(quoteId);
    isMobile && setShowText(!showText);
  };

  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  const line: any = useRef(null);
  const blockquote: any = useRef(null);
  const footnote: any = useRef(null);

  const device = useDevice();
  const isMobile = device === "mobile";

  const setLineHeight = () => {
    if (!isMobile) {
      const height =
        footnote.current.offsetTop -
        blockquote.current.offsetTop -
        blockquote.current.offsetHeight +
        40;
      line.current.dataset.height = height + "px";
      line.current.style.height = height + "px";
    } else {
      line.current.removeAttribute("style");
    }
  };

  useEffect(() => {
    setLineHeight();
  }, [isVisible, isMobile]);

  const fullQuote = longQuote ? quote + " " + longQuote : quote;

  const textToRender = !showText ? "Read More" : "Read Less";

  return (
    <div
      className={styleNames(theme, [
        "navigational-quote",
        isVisible && "animate",
        !desktopImage && "no-img",
        !isCarousel && !fullWidth && "half",
      ])}
      ref={ref}
    >
      <div className={theme["block-container"]}>
        <div className={theme["text-content"]}>
          {fullWidth && <p className={theme.label}>{label}</p>}
          {isCarousel && (quote.length > 216 || longQuote) ? (
            <blockquote ref={blockquote}>
              {!showText ? (
                <>
                  <span>
                    {quote.length > 216
                      ? `"${quote.substring(0, 216)}`
                      : `"${quote}"`}
                  </span>
                  <span>{quote.length > 216 ? '..."' : ""}</span>
                </>
              ) : (
                <span>
                  {`"${
                    fullQuote.length > 730
                      ? fullQuote.substring(0, 730)
                      : fullQuote
                  }"`}
                </span>
              )}
            </blockquote>
          ) : (
            <blockquote ref={blockquote}>&quot;{quote}&quot;</blockquote>
          )}
          {isCarousel && (quote.length > 216 || longQuote) && (
            <a onClick={handleClick} className={`${theme["read-more-btn"]}`}>
              {isMobile ? textToRender : "Read More"}
            </a>
          )}
          {desktopImage && fullWidth && (
            <div className={theme.image}>
              <Image
                alt={desktopImage?.fields.altText || ""}
                src={desktopImage?.fields.image.fields.file.url}
                desktopHeight={704}
                desktopWidth={800}
                width={768}
                height={517}
              />
            </div>
          )}
          <div className={theme.line} ref={line}></div>
          <div ref={footnote} className={theme.footnote}>
            <div>
              <p className={theme.name}>{authorName}</p>
              {authorJobTitle ? <p>{authorJobTitle}</p> : null}
            </div>
            <div>
              {ctaLink && (
                <div className={theme.mainCta}>
                  <Cta {...ctaLink.fields} type="secondary" light={true} />
                </div>
              )}
              {optionalCta && (
                <Cta
                  {...optionalCta.fields}
                  type="tertiary"
                  light={true}
                  icon="icon-arrow-small"
                />
              )}
            </div>
          </div>
        </div>
        {fullWidth && desktopImage && (
          <div className={theme.image}>
            <Image
              alt={desktopImage?.fields.altText || ""}
              src={desktopImage?.fields.image.fields.file.url}
              desktopHeight={704}
              desktopWidth={800}
              width={768}
              height={517}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default themr("Quote", styles)(Quote);

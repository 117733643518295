import Grid from "@components/Grid/Grid";
import RenderComponents from "@utilities/renderComponents";

export interface ColumnsProps {
  columns: any;
  columnTotal?: number;
  customSize?: number[];
  type?: string;
}

const Columns = (props: ColumnsProps) => {
  const { columns, columnTotal, customSize, type } = props;

  const defaultSize = columns.length > 1 ? 12 / columns.length : 12;
  const size = customSize ? customSize : defaultSize;

  return columns?.map((column, index) => {
    const columnContent = column?.fields?.content;
    const mdSize = typeof size === "number" ? size : size[index];

    // Used to dictate if this is a small column in a sublayout
    // If it's a custom column size (ie. 70-30 or 70-25 sublayout)
    // Only set the small parameter on the small column
    const isSmall =
      (typeof size !== "number" && index > 0) ||
      (typeof size === "number" && columns?.length > 1);

    // Do not render additional columns from the CMS if this layout should only cater for 2
    if (columnTotal && index + 1 > columnTotal) {
      return;
    }

    return (
      <Grid column sm={12} md={mdSize} key={`column${index}`}>
        {type === "white-blue" ? (
          <div className={`half-wrap-${index === 0 ? "left" : "right"}`}>
            {RenderComponents(columnContent, true)}
          </div>
        ) : (
          RenderComponents(columnContent, isSmall)
        )}
      </Grid>
    );
  });
};

export default Columns;

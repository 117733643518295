import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import { useEffect, useState } from "react";
import styles from "./C282_Electives.module.scss";
import ElectiveItem, { Course } from "./ElectiveItem";

export interface ElectivesProps {
  courses: Course[];
  singleDisplay?: boolean;
  defaultOpen?: boolean;
  displayFacultyList?: boolean;
  columns?: number;
  theme?: any;
}

const Electives = (props: ElectivesProps) => {
  const {
    courses,
    singleDisplay = true,
    defaultOpen = false,
    displayFacultyList = true,
    columns = 4,
    theme,
  } = props;
  const getInitialOpenStates = (item) =>
    item.map((item, index) => (index === 0 ? defaultOpen : false));
  const [openStates, setOpenStates] = useState<boolean[]>([]);

  const handleOpenStateChange = (index, newState) => {
    setOpenStates((previousStates) => {
      const newStates = [...previousStates];
      newStates[index] = newState;
      return newStates;
    });
  };

  const closeAll = () => {
    setOpenStates((previousStates) => previousStates.map(() => false));
  };

  useEffect(() => {
    if (courses && courses.length) setOpenStates(getInitialOpenStates(courses));
  }, [courses]);

  return courses && courses.length ? (
    <div className="outer-wrap white">
      <div
        className={clsx(
          "component",
          theme.electives,
          "electives",
          "single-display",
          "blue"
        )}
        data-row-items={columns}
      >
        <div className="wrapper">
          <ul className={theme["elective-accordion"]}>
            {courses.length > 0 &&
              courses.map((course, index) => (
                <ElectiveItem
                  key={`${course.courseTypeTitle}-${index}`}
                  index={index}
                  course={course}
                  openStates={openStates}
                  closeAll={closeAll}
                  handleOpenStateChange={handleOpenStateChange}
                  singleDisplay={singleDisplay}
                  displayFacultyList={displayFacultyList}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  ) : null;
};

export default themr("Electives", styles)(Electives);

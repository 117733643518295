import Grid from "@components/Grid/Grid";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useDevice } from "@utilities/react/get-device/get-device";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";
import ContactDetails, { ContactDetailsProps } from "./C261_ContactDetails";
import LinkedInContactDetails, {
  ContactLinkedInProps,
} from "./C261_LinkedInContactDetails";

import { Pagination } from "swiper/modules";
import styles from "./C261_Contact.module.scss";

export interface ContactDetailsExtendedProps {
  contactsExtended: {
    contactDetails: ContactDetailsProps;
    contactLinkedInDetails: ContactLinkedInProps;
  }[];
  isExtended?: boolean;
  theme?: any;
}

const SliderContent = ({ contacts, theme, isExtended }) => (
  <li className={theme["contact-container"]} key={contacts}>
    <Grid row expanded>
      <Grid column sm={12} lg={6}>
        {contacts.contactLinkedInDetails && (
          <LinkedInContactDetails {...contacts.contactLinkedInDetails} />
        )}
      </Grid>
      <Grid column sm={12} lg={6}>
        <div className={theme["contact-details"]}>
          {contacts.contactDetails && (
            <ContactDetails
              isExtended={isExtended}
              {...contacts.contactDetails}
            />
          )}
        </div>
      </Grid>
    </Grid>
  </li>
);

const ContactDetailsExtended = ({
  contactsExtended,
  isExtended,
  theme,
}: ContactDetailsExtendedProps) => {
  const device = useDevice();
  const isMobile = device === "mobile";

  return (
    <ul className={theme["contact-list"]}>
      {isMobile ? (
        <SwiperCarousel
          className="on-dark"
          loop={true}
          pagination={{ clickable: true }}
          modules={[Pagination]}
        >
          {contactsExtended &&
            contactsExtended.map((contacts, i) => (
              <SwiperSlide key={`${contacts.contactDetails?.title}${i}`}>
                <SliderContent
                  contacts={contacts}
                  isExtended={isExtended}
                  theme={theme}
                />
              </SwiperSlide>
            ))}
        </SwiperCarousel>
      ) : (
        <>
          {contactsExtended &&
            contactsExtended.map((contacts, i) => (
              <SliderContent
                key={`${contacts.contactDetails?.title}${i}`}
                contacts={contacts}
                isExtended={isExtended}
                theme={theme}
              />
            ))}
        </>
      )}
    </ul>
  );
};

export default themr("ContactDetailsExtended", styles)(ContactDetailsExtended);

import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import { useContext, useEffect, useRef, useState } from "react";
import { ProgrammeContext } from "../../context/programmeComparison";
import ChevronSvg from "../../public/image/svg/chevron.svg";
import CrossSvg from "../../public/image/svg/cross.svg";
import styles from "./C242_ComparisonOverlay.module.scss";

export const ComparisonOverlay = ({ theme }: { theme?: any }) => {
  const { programmeSettings } = useSettings();
  const { programmes, removeProgramme, type } = useContext(ProgrammeContext);
  const {
    programmeComparisonOverlayCtaLinkEe,
    programmeComparisonOverlayCtaLinkDe,
    programmeComparisonOverlayText,
    programmeComparisonOverlayMoreText,
    programmeComparisonOverlayOneMoreText,
    programmeComparisonOverlayCtaText,
  } = programmeSettings;
  const [expanded, setExpanded] = useState(true);
  const [collapseHeight, setCollapseHeight] = useState(0);
  const collapseRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (collapseRef.current && expanded) {
      setCollapseHeight(collapseRef.current.getBoundingClientRect().height);
    }
  }, [collapseRef, expanded, programmes]);

  return (
    <div
      className={`${theme["comparison-overlay"]} ${
        programmes.length && theme["has-items"]
      }`}
    >
      <div className={`${theme["inner-wrapper"]} wrapper`}>
        <Grid row>
          <Grid column sm={1}></Grid>
          <Grid column sm={10}>
            <div
              className={theme["toggle-wrapper"]}
              tabIndex={0}
              aria-expanded={expanded}
              data-open={expanded}
              onClick={() => setExpanded(!expanded)}
            >
              <span className={theme["icon-chevron"]}>
                <ChevronSvg />
              </span>
              <p>{programmeComparisonOverlayText}</p>
            </div>
            <div
              className={theme["collapse-wrapper"]}
              aria-hidden={!expanded}
              style={{ height: expanded ? `${collapseHeight}px` : 0 }}
            >
              <div className={theme["inner-wrapper"]} ref={collapseRef}>
                <div className={theme["programmes-wrapper"]}>
                  {programmes?.map((programme, i) => (
                    <div className={theme.programme} key={`${theme.title}${i}`}>
                      <div className={theme["inner-wrapper"]}>
                        <span className={theme.title}>
                          {programme.programmeTitle}
                        </span>
                      </div>
                      <span
                        className={theme["remove-block"]}
                        aria-label="remove programme"
                        tabIndex={0}
                        role="button"
                      >
                        <span
                          className="icon-cross"
                          onClick={() => {
                            removeProgramme(programme);
                          }}
                        >
                          <CrossSvg />
                        </span>
                      </span>
                    </div>
                  ))}

                  {programmes.length < 4 && (
                    <div className={theme["programme-blank"]}>
                      <div className={theme["inner-wrapper"]}>
                        <span className={theme.title}>
                          {programmes?.length < 2
                            ? programmeComparisonOverlayOneMoreText
                            : programmeComparisonOverlayMoreText}
                        </span>
                      </div>
                      <span className={theme["plus-icon"]}></span>
                    </div>
                  )}
                </div>
                <div className="button-wrapper">
                  {programmes.length > 1 && (
                    <Cta
                      type="tertiary"
                      light={true}
                      icon="icon-arrow-small"
                      url={`${
                        type === "DE"
                          ? programmeComparisonOverlayCtaLinkDe?.slug
                          : programmeComparisonOverlayCtaLinkEe?.slug
                      }?p=${programmes
                        ?.map((programme) => programme.programmeCode)
                        .join(",")}`}
                      linkText={programmeComparisonOverlayCtaText || ""}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default themr("ComparisonOverlay", styles)(ComparisonOverlay);

import { AspectRatio as Ratio } from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";
import { useDevice } from "../../utils/react/get-device/get-device";
import styleNames from "../../utils/react/style-names";
import styles from "../Image/Image.module.scss";
import clsx from "clsx";

export type ImageProps = {
  src: string;
  alt: string;
  width: number;
  height: number;
  desktopWidth?: number;
  desktopHeight?: number;
  isCrop?: boolean;
  isSmartCrop?: boolean;
  cropFocus?:
    | "center"
    | "top"
    | "right"
    | "left"
    | "bottom"
    | "top_right"
    | "top_left"
    | "bottom_right"
    | "bottom_left"
    | "faces";
  eager?: boolean;
  pad?: boolean;
  imageRef?;
  className?: string;
};

export const Image = ({
  src,
  alt,
  width,
  height,
  desktopWidth,
  desktopHeight,
  isCrop,
  isSmartCrop,
  cropFocus,
  eager,
  pad,
  imageRef,
  className,
}: ImageProps) => {
  const device = useDevice();
  const isMobile = device === "mobile";
  const srcWithParams = (device = "mobile", format = "webp", x2 = false) => {
    //remove any pre-applied modifiers
    let modifiedSrc: string[] | string = src.split("?");
    modifiedSrc = `${modifiedSrc[0]}?`;

    const isDesktop = ["desktop", "desktop-large"].includes(device);

    // Image extension type
    modifiedSrc = `${modifiedSrc}&fm=${format}`;

    if (format === "avif") {
      modifiedSrc = `${modifiedSrc}&q=70`;
    }

    if (isDesktop && desktopWidth) {
      modifiedSrc = `${modifiedSrc}&w=${
        x2 ? (desktopWidth * 2).toString() : desktopWidth?.toString()
      }`;
    } else {
      modifiedSrc = `${modifiedSrc}&w=${
        x2 ? (width * 2).toString() : width.toString()
      }`;
    }

    if (isDesktop && desktopHeight) {
      modifiedSrc = `${modifiedSrc}&h=${
        x2 ? (desktopHeight * 2).toString() : desktopHeight?.toString()
      }`;
    } else {
      modifiedSrc = `${modifiedSrc}&h=${
        x2 ? (height * 2).toString() : height.toString()
      }`;
    }

    // Crops to image dimensions
    if (isCrop) {
      modifiedSrc = `${modifiedSrc}&fit=fill`;
    } else if (isSmartCrop) {
      // and focuses on faces
      modifiedSrc = `${modifiedSrc}&fit=fill&f=faces`;
    } else if (cropFocus) {
      // or focuses on selected option
      modifiedSrc = `${modifiedSrc}&fit=fill&f=${cropFocus}`;
    }

    if (pad) {
      modifiedSrc = `${modifiedSrc}&fit=pad`;
    }

    return modifiedSrc;
  };

  const ratioCalculator = (width: number, height: number) => {
    const aspectRatio = width / height;
    const widthT = height * aspectRatio;
    const heightT = width / aspectRatio;
    return widthT / heightT;
  };

  const ratio = ratioCalculator(width, height);
  const ratioDesktop = ratioCalculator(
    desktopWidth || width,
    desktopHeight || height
  );

  const srcParamsDefault = srcWithParams();
  const srcParamsDefaultx2 = srcWithParams("mobile", "webp", true);
  const srcParamsAvifDesktop = srcWithParams("desktop", "avif");
  const srcParamsAvifDesktopx2 = srcWithParams("desktop", "avif", true);
  const srcParamsAvif = srcWithParams("mobile", "avif");
  const srcParamsAvifx2 = srcWithParams("mobile", "avif", true);
  const srcParamsJpeg = srcWithParams("mobile", "jpg");
  const srcParamsPng = srcWithParams("desktop", "png");

  const imageWidth = desktopWidth && !isMobile ? desktopWidth : width;
  const imageHeight = desktopHeight && !isMobile ? desktopHeight : height;

  return src?.includes(".svg") ? (
    <img src={src} width={width} height={height} alt="alt" />
  ) : (
    <Ratio
      className={clsx(
        styles.Ratio,
        "Image",
        className,
        styles[className as string]
      )}
      ratio={isMobile ? ratio : ratioDesktop}
    >
      <picture className={styleNames(styles, ["Wrapper"])}>
        {(desktopWidth || desktopHeight) && (
          <>
            <source
              srcSet={`${srcParamsAvifDesktop}, ${srcParamsAvifDesktopx2} 2x`}
              type="image/Avif"
              media="(min-width: 767px)"
            />
            <source
              srcSet={`${srcWithParams("desktop")}, ${srcWithParams(
                "desktop",
                "webp",
                true
              )} x2`}
              type="image/webp"
              media="(min-width: 767px)"
            />

            <source
              srcSet={srcParamsPng}
              type="image/png"
              media="(min-width: 767px)"
            />
            <source
              srcSet={srcParamsJpeg}
              type="image/jpeg"
              media="(min-width: 767px)"
            />
          </>
        )}
        <source
          srcSet={`${srcParamsAvif}, ${srcParamsAvifx2} 2x`}
          type="image/avif"
        />
        <source
          srcSet={`${srcParamsDefault}, ${srcParamsDefaultx2} x2`}
          type="image/webp"
        />
        <source srcSet={srcParamsPng} type="image/png" />
        <source srcSet={srcParamsJpeg} type="image/jpeg" />

        <img
          className={styleNames(styles, ["Image"])}
          src={srcParamsAvif}
          alt={alt}
          width={imageWidth}
          height={imageHeight}
          loading={eager ? "eager" : "lazy"}
          ref={imageRef}
        />
      </picture>
    </Ratio>
  );
};
export default Image;

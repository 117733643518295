import richTextify from "@utilities/richtext";

const RichTextDisplay = ({ richText, tinyMceRichText }) => {
  let rtContent = tinyMceRichText?.richText;
  const rex = /<a\s+(?:[^>]*?\s+)?href=(["'])(..\/\/*?)/gm;
  const matchArr = rtContent?.match(rex);
  if (matchArr) {
    for (let i = 0; i < matchArr.length; i++) {
      const newLink = matchArr[i].replace('href="../', 'href="/');
      rtContent = rtContent.replace(matchArr[i], newLink);
    }
  }
  return (
    <>
      {richText && richText?.content?.length ? (
        richTextify(richText)
      ) : tinyMceRichText?.richText ? (
        <div dangerouslySetInnerHTML={{ __html: rtContent }} />
      ) : null}
    </>
  );
};

export default RichTextDisplay;

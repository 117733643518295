export const getPaymentErrors = (response) => {
  if (response?.title) return [response.title];
  return response?.message ? [response.message] : response.messages;
};

export const getCountryCode = (country: string, countriesIso) => {
  const isoCountry = countriesIso.find(
    (c) => c.countryName.toLowerCase() === country.toLowerCase()
  );
  return isoCountry ? isoCountry.countryAlpha2Code : "gb";
};

export const hasCountryMatch = (value: string, countries) =>
  countries && countries.find((country) => country === value);

import React, { useContext } from "react";
import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { useSettings } from "@utilities/context/settings";
import Cta from "@components/Cta/Cta";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import { UserContext } from "../../context/user";

export interface ProgrammeApplyNowLinkProps {
  profileProgramme: { fields: ProgrammeDetails };
  linkText: string;
}

export const ProgrammeApplyNowLink = (props: {
  content: ProgrammeApplyNowLinkProps;
}) => {
  const { content } = props;
  const { profileProgramme, linkText } = content;
  const programme = profileProgramme.fields?.programmeTypeCode;
  const applyID = profileProgramme.fields?.programmeApplyOnlineParameterValue;
  const { siteSettings } = useSettings();
  const { user } = useContext(UserContext);

  const applyNow = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];

  if (!programme || !applyID || !applyNow?.url.slug) return null;

  return (
    <>
      <p>Submit your application through our online application form.</p>
      <div className="button-wrapper" style={{ width: "200px" }}>
        <Cta
          linkText={linkText}
          type="primary"
          url={
            user
              ? `/interact?applyID=${applyID}`
              : `${applyNow?.url.slug}?&applyID=${applyID}`
          }
        />
      </div>
    </>
  );
};

export default ProgrammeApplyNowLink;

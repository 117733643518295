import { useContext, useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import { UserContext } from "../../../context/user";
import { getInteractWebsiteLoginCredentials } from "../../../services/forms";

export interface InteractSSOProps {
  slug: string;
  interactBaseUrl: string;
  applyOnlineBaseUrl: string;
  applyOnlinePasswordParameterName: string;
  applyOnlineUsernameParameterName: string;
  applyOnlineParameterName: string;
}

const InteractSSO = (props: { content: InteractSSOProps }) => {
  const { content } = props;
  const {
    applyOnlineBaseUrl,
    applyOnlinePasswordParameterName,
    applyOnlineUsernameParameterName,
    applyOnlineParameterName,
  } = content;
  const { push, query } = useRouter();
  const { user } = useContext(UserContext);
  const [interactCredentials, setInteractCredentials] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (user && user["userData"]["email"]) {
      setUserEmail(user["userData"]["email"]);
    }
    push("/my-profile"); //Push user to registration page
  }, [user]);

  useEffect(() => {
    const readyToSubmit =
      user && query?.applyID && interactCredentials && formRef?.current;

    if (readyToSubmit) {
      formRef.current.submit();
    } else {
      push("/");
    }
  }, [interactCredentials, user, query]);

  useEffect(() => {
    const fetchLoginDetails = async () => {
      const interactWebsiteLoginCredentials =
        await getInteractWebsiteLoginCredentials();
      setInteractCredentials(interactWebsiteLoginCredentials);
    };

    fetchLoginDetails();
  }, []);

  return (
    <form
      ref={formRef}
      id="ssoForm"
      method="post"
      name="ssoForm"
      action={applyOnlineBaseUrl}
      autoComplete="off"
    >
      <input
        type="hidden"
        name={applyOnlineUsernameParameterName}
        value={userEmail || ""}
        autoComplete="off"
      />
      <input
        type="hidden"
        name={applyOnlinePasswordParameterName}
        value={(interactCredentials && interactCredentials["password"]) || ""}
        autoComplete="off"
      />
      <input
        type="hidden"
        name={applyOnlineParameterName}
        value={query?.applyID || ""}
      />
    </form>
  );
};

export default InteractSSO;

import clsx from "clsx";

export interface CheckboxProps {
  register: Function;
  theme: any;
  value: string;
  label: string;
  isChecked: boolean;
  setIsChecked: Function;
  customClass?: string;
}

const Checkbox = (props: CheckboxProps) => {
  const { register, theme, value, label, isChecked, setIsChecked } = props;

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div
        className={clsx("form-row", theme["form-row"], theme["programmesList"])}
      >
        <div className="form-field base6">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                value={value}
                checked={isChecked}
                {...register("hrProfessionalCheckbox")}
                onChange={handleCheckboxChange}
              />
              <span className="tick"></span>
              {label}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
export default Checkbox;

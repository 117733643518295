import React, { useState, useEffect } from "react";
import { setCookie, getCookie } from "@utilities/cookies";
import { useSettings } from "@utilities/context/settings";
import { useDevice } from "@utilities/react/get-device/get-device";
import IconCross from "../../public/image/svg/cross.svg";
import styles from "./NotificationBanner.module.scss";

export const NotificationBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  //const [isBannerClosed, setIsBannerClosed] = useState(true);
  const { siteSettings } = useSettings();
  const device = useDevice();
  //const isMobile = device === "mobile";

  /*useEffect(() => {
    const isBannerClosed = getCookie("notificationBannerClosed");
    setIsBannerClosed(!!isBannerClosed);
  }, []);*/
  if (typeof window !== "undefined") {
    useEffect(() => {
      setIsVisible(true);
    }, [window.location?.pathname]);
  }
  const handleClose = () => {
    setIsVisible(false);
    //setCookie({ cookieName: "notificationBannerClosed", value: true });
  };

  /*if (isBannerClosed) {
    return null;
  }*/
  return siteSettings?.turnOnNotificationBanner ? (
    <div
      className={styles["notification-banner"]}
      style={{ display: isVisible ? "flex" : "none" }}
    >
      <span
        className={styles.close}
        tabIndex={0}
        aria-label="close"
        onClick={handleClose}
      >
        <IconCross />
      </span>
      <p>{siteSettings?.notificationBannerMobile}</p>
    </div>
  ) : null;
};

export default NotificationBanner;

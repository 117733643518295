import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import { format, parseISO } from "date-fns";
import styles from "./C272_ProgrammeComparisonTable.module.scss";
import { convertToBulletedList } from "@utilities/convertToBulletedList";
import { isUrl } from "@utilities/regex";
import Link from "@utilities/link";

interface ProgrammeEEProps {
  count: number;
  programmeDetails: ProgrammeDetails;
  theme?: any;
}

export const ProgrammeEE = (props: ProgrammeEEProps) => {
  const { count, programmeDetails, theme } = props;

  const {
    programmeSettings: {
      programmeDeliveryLabel,
      programmeDurationLabel,
      programmeFeaturesLabel,
      programmeKeyTakeAwaysLabel,
      programmeLocationLabel,
      programmeNextStartDateLabel,
      programmeFeesLabel,
      programmeTestimonialLabel,
      programmeWhoAttendsLabel,
      fallbackProgrammeIterationFee,
      fallbackProgrammeIterationDuration,
      fallbackProgrammeIterationLocation,
      programmeInfoCtaLabel,
      noProgrammeDateAvailableMessage,
    },
  } = useSettings();

  const {
    slug,
    keyTakeAway,
    programmeFeature,
    programmeTitle,
    testimonialName,
    testimonialQuote,
    whoAttends,
    programmeDeliveryText,
    programmeImportData,
  } = programmeDetails;

  const renderTestimonialName = testimonialName ? ` - ${testimonialName}` : "";
  const link = slug.match(isUrl) ? slug : `/${slug}`;
  return (
    <li className={`${theme["programme-column"]} programme-column`}>
      <div
        className={`${theme["top-heading"]} top-heading`}
        data-reference="10"
      >
        {
          <Link href={link}>
            <h3>{programmeTitle}</h3>
          </Link>
        }
      </div>
      <div className={theme.info}>
        {count === 1 && (
          <div className={theme["labels-wrapper"]}>
            <div className={`${theme.labels} labels`}>
              <span data-reference="1" aria-hidden="true">
                <span>{programmeFeaturesLabel}</span>
              </span>
              <span data-reference="2" aria-hidden="true">
                <span>{programmeKeyTakeAwaysLabel}</span>
              </span>
              <span data-reference="3" aria-hidden="true">
                <span>{programmeWhoAttendsLabel}</span>
              </span>
              <span data-reference="4" aria-hidden="true">
                <span>{programmeDeliveryLabel}</span>
              </span>
              <span data-reference="5" aria-hidden="true">
                <span>{programmeDurationLabel}</span>
              </span>
              <span data-reference="6" aria-hidden="true">
                <span>{programmeLocationLabel}</span>
              </span>
              <span data-reference="7" aria-hidden="true">
                <span>{programmeFeesLabel}</span>
              </span>
              <span data-reference="8" aria-hidden="true">
                <span>{programmeTestimonialLabel}</span>
              </span>
              <span data-reference="9" aria-hidden="true">
                <span>{programmeNextStartDateLabel}</span>
              </span>
            </div>
          </div>
        )}
        <div data-reference="1">
          <span className={`${theme["inner-wrapper"]} leftside-text`}>
            <span className={theme["d-invis"]}>{programmeFeaturesLabel}</span>
            {programmeFeature}
          </span>
        </div>
        <div data-reference="2">
          <span className={`${theme["inner-wrapper"]} leftside-text`}>
            <span className={theme["d-invis"]}>
              {programmeKeyTakeAwaysLabel}
            </span>
            {keyTakeAway}
          </span>
        </div>
        <div data-reference="3">
          <span className={`${theme["inner-wrapper"]} leftside-text`}>
            <span className={theme["d-invis"]}>{programmeWhoAttendsLabel}</span>
            {whoAttends}
          </span>
        </div>
        <div data-reference="4">
          <span
            className={`${theme["inner-wrapper"]} rich-text ${theme["rich-text"]}`}
          >
            <span className={theme["d-invis"]}>{programmeDeliveryLabel}</span>
            {convertToBulletedList(programmeDeliveryText)}
          </span>
        </div>
        <div data-reference="5">
          <span className={theme["inner-wrapper"]}>
            <span className={theme["d-invis"]}>{programmeDurationLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeDuration
              ? programmeImportData[0]?.programmeDuration
              : fallbackProgrammeIterationDuration}
          </span>
        </div>
        <div data-reference="6">
          <span className={theme["inner-wrapper"]}>
            <span className={theme["d-invis"]}>{programmeLocationLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeLocation
              ? programmeImportData[0]?.programmeLocation
              : fallbackProgrammeIterationLocation}
          </span>
        </div>
        <div data-reference="7">
          <span className={theme["inner-wrapper"]}>
            <span className={theme["d-invis"]}>{programmeFeesLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeFee
              ? programmeImportData[0]?.programmeFee
              : fallbackProgrammeIterationFee}
          </span>
        </div>
        <div data-reference="8">
          <span className={theme["inner-wrapper"]}>
            <span className={theme["d-invis"]}>
              {programmeTestimonialLabel}
            </span>
            {`"${testimonialQuote}"${renderTestimonialName}`}
          </span>
        </div>
        <div data-reference="9">
          <span className={theme["inner-wrapper"]}>
            <span className={theme["d-invis"]}>
              {programmeNextStartDateLabel}
            </span>
            {programmeImportData && programmeImportData[0]?.programmeStartDate
              ? format(
                  parseISO(programmeImportData[0].programmeStartDate),
                  "MMMM dd, yyyy"
                )
              : noProgrammeDateAvailableMessage}
          </span>
        </div>
      </div>
      <div className={theme["bottom-ctas"]}>
        {
          <Link href={link} className="cta">
            {programmeInfoCtaLabel}
          </Link>
        }
      </div>
    </li>
  );
};

export default themr("ProgrammeEE", styles)(ProgrammeEE);

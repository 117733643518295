import React from "react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, FieldErrors } from "react-hook-form";

import IconCalendar from "../../../public/image/svg/calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import { useDevice } from "@utilities/react/get-device/get-device";

import styles from "./FormFields.module.scss";
import { LINKEDIN_URL_PATTERN_REG_EXP } from "../../../constants";

export interface FormFieldProps {
  type?: string;
  name: string;
  placeholder: any;
  property?: string;
  selectedIdValues?: Object;
  setSelectedIdValues?: Function;
  register: any;
  required?: boolean;
  hintText?: string;
  options?: any;
  watch?: any;
  errors?: FieldErrors;
  validation?: validationProp;
  selectValue?: string;
  setSelectValue?: Function;
  formType?: string;
  optionalText?: boolean;
  selectWrapper?: boolean;
  control?: any;
  readonly?: boolean;
  disabled?: any;
  onChange?: (e: React.FormEvent) => void;
  isEmptyFirstOption?: boolean;
}

interface validationProp {
  telephone?: boolean;
  isRequired?: boolean;
  isLinkedIn?: boolean;
  lastName?: boolean;
  maxLength?: number;
  firstName?: boolean;
  integerValidator?: boolean;
  decimalValidator?: boolean;
  maxValue?: number;
  range?: { min: number; max: number };
  email?: boolean;
  password?: boolean;
  dependency?: any;
  stringValidator?: boolean;
  confirmPassword?: boolean;
  alphaNumeric?: string;
}

const FormField = ({
  formType = "input",
  type = "text",
  optionalText = true,
  register,
  validation = {} as validationProp,
  selectValue,
  setSelectValue,
  hintText,
  name,
  errors,
  property,
  selectedIdValues,
  setSelectedIdValues,
  watch,
  disabled,
  options,
  placeholder,
  control,
  readonly = false,
  isEmptyFirstOption = true,
  onChange,
}: FormFieldProps) => {
  const inputValue = watch(name, false);
  const hasError = errors && errors[name];
  const [isFocused, setIsFocused] = useState(false);

  const device = useDevice();
  const isMobile = device === "mobile";

  const handleOnBlur = () => {
    if (inputValue) {
      return;
    }
    setIsFocused(false);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnChange = (event) => {
    event.target.setAttribute("id", event.target.selectedOptions[0].id);
    setSelectedIdValues &&
      setSelectedIdValues({
        ...selectedIdValues,
        [name]: event.target.id,
      });
    setSelectValue && setSelectValue(event.target.value);

    onChange ? onChange(event) : null;
  };

  useEffect(() => {
    if (options) {
      setIsFocused(false);
    } else {
      if (property || inputValue) {
        setIsFocused(true);
      }
    }
  }, [property, options]);

  const maxLengthMessage =
    validation?.lastName || validation?.firstName || validation?.email
      ? `Your ${placeholder} cannot be more than '${validation?.maxLength}' characters long`
      : `The field ${placeholder} must be a string or array type with a maximum length of '${validation?.maxLength}'`;

  const validationRules = {
    ...(validation?.isLinkedIn && {
      validate: {
        validate: (value) => {
          return value || validation.dependency?.name
            ? true
            : `CV or LinkedIn profile is required`;
        },
      },
      pattern: {
        value: LINKEDIN_URL_PATTERN_REG_EXP,
        message: `Please enter a valid ${placeholder}`,
      },
    }),
    ...(!options &&
      validation?.isRequired && {
        required: `${placeholder} is required`,
      }),
    ...(options &&
      validation?.isRequired && {
        required: `You have to select an option`,
      }),
    ...(validation?.maxLength && {
      maxLength: { value: validation?.maxLength, message: maxLengthMessage },
    }),
    ...(validation?.range && {
      min: {
        value: validation?.range?.min,
        message: `The field ${placeholder} must be between ${validation?.range?.min} and ${validation.range.max}.`,
      },
      max: {
        value: validation?.range?.max,
        message: `The field ${placeholder} must be between ${validation?.range?.min} and ${validation.range.max}.`,
      },
    }),
    ...(validation?.alphaNumeric && {
      pattern: {
        value: /^[a-zA-Z0-9_ ]+$/,
        message: `Please enter a valid ${placeholder}`,
      },
    }),
    ...(validation?.email && {
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Please enter a valid Email",
      },
    }),
    ...(validation?.password && {
      pattern: {
        value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/,
        message:
          "Your password must contain: At least 12 characters, Lower case letters (a-z), Upper case letters (A-Z), Numbers(0-9)",
      },
    }),
    ...(validation?.confirmPassword && {
      validate: (value: string) => {
        if (watch("password") !== value) {
          return "Your passwords do not match";
        }
      },
    }),
    ...(validation?.telephone && {
      pattern: {
        value: /^[+()]*[ 0-9]+$/,
        message: `Telephone number: Only numbers, brackets and + allowed`,
      },
    }),
    ...(validation?.stringValidator && {
      pattern: {
        value: /^[a-z ,.'-]+$/i,
        message: `Please enter a valid ${placeholder}`,
      },
    }),
    ...(validation?.integerValidator && {
      pattern: {
        value: /^[0-9]+$/,
        message: `The field ${placeholder} must be a number`,
      },
    }),
    ...(validation?.decimalValidator && {
      pattern: {
        value: /^\d+([.]\d{1,2})?$/,
        message: `The field ${placeholder} must be a valid number`,
      },
    }),
  };
  return (
    <div
      onBlur={handleOnBlur}
      className={clsx(
        "form",
        "form-row",
        "field-row",
        isFocused && "focused",
        (options || type === "datetime-local" || type === "date") &&
          "select-wrapper",
        hasError && "error"
      )}
    >
      {!options && formType === "select" ? null : (
        <>
          <div className="form-label base4">
            <div className="label">
              <label htmlFor={name} className={disabled && styles.disabled}>
                {placeholder}
                {optionalText && !validation?.isRequired && (
                  <span className="optional-field"> (optional)</span>
                )}
              </label>
            </div>
          </div>

          <div className="form-field base6">
            <div className={clsx("field", options && "select")}>
              {formType === "input" && (
                <input
                  onFocus={handleOnFocus}
                  className={`${disabled && styles.disabled} smart-focus`}
                  {...register(name, validationRules)}
                  type={type}
                  readOnly={readonly}
                  disabled={disabled || false}
                />
              )}
              {formType === "datetime-local" && (
                <>
                  {isMobile ? (
                    <Controller
                      control={control}
                      name={name}
                      defaultValue={new Date()}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <DatePicker
                            showIcon
                            placeholderText="dd/mm/yyyy"
                            selected={value}
                            onChange={onChange}
                          />
                          <IconCalendar />
                        </>
                      )}
                    />
                  ) : (
                    <input
                      onFocus={handleOnFocus}
                      className="smart-focus"
                      {...register(name, validationRules)}
                      type={type}
                      readOnly={readonly}
                    />
                  )}
                  <IconCalendar />
                </>
              )}
              {formType === "select" ? (
                <>
                  <select
                    {...register(name, {
                      ...validationRules,
                      onChange: handleOnChange,
                    })}
                    value={selectValue}
                  >
                    {isEmptyFirstOption && <option value="" id=""></option>}
                    {options &&
                      options?.map((option, index) => (
                        <option
                          key={`${option.id}${index}`}
                          value={option.value}
                          id={option.id}
                        >
                          {option.value}
                        </option>
                      ))}
                  </select>
                  <div className="tick icon-chevron">
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 8 12"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      focusable="false"
                    >
                      <path d="M2 0L.59 1.41 5.17 6 .59 10.59 2 12l6-6z"></path>
                    </svg>
                  </div>
                </>
              ) : null}
              {formType === "textarea" && (
                <textarea
                  onFocus={handleOnFocus}
                  {...register(name, validationRules)}
                />
              )}
            </div>
            {/* Some fields on our forms have an additional error message to be displayed */}
            {errors && errors[name]?.additionalErrorMessage && (
              <div
                className={clsx(
                  "field",
                  "message-wrapper",
                  "inline-message",
                  errors && errors[name]?.message && "field_error_msg"
                )}
                tabIndex={0}
              >
                <p className={clsx("message", "active")}>
                  {errors && errors[name]?.hintText
                    ? errors[name]?.hintText
                    : hintText}
                </p>
              </div>
            )}
            {((!disabled && errors && errors[name]?.message) || hintText) && (
              <div
                className={clsx(
                  "field",
                  "message-wrapper",
                  "inline-message",
                  errors && errors[name]?.message && "field_error_msg"
                )}
                tabIndex={0}
              >
                <p
                  className={clsx(
                    "message",
                    errors && errors[name]?.message && "active"
                  )}
                >
                  {errors && errors[name]?.message
                    ? errors[name]?.message
                    : hintText}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FormField;
